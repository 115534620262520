import { radius } from 'api/google/maps/utils';
import { API_URL } from 'config';

export async function findByPostalCode(postalCode) {
  const address = postalCode;
  const geocoder = new window.google.maps.Geocoder();

  return new Promise(resolve => {
    geocoder.geocode({ address }, async (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (results && results.length > 0) {
          const { geometry } = results[0];

          if (geometry.bounds) {
            const center = geometry.bounds.getCenter();
            const ne = geometry.bounds.getNorthEast();
            const lat = center.lat();
            const lng = center.lng();

            // in km
            const r = radius(center, ne) / 1000;

            const response = await fetch(`${API_URL}/landing/find_plants_in_area?radius=${r}&location=${lat},${lng}`);
            const data = await response.json();

            if (data.length > 0) {
              return resolve(true);
            }
          }
        }
      }

      resolve(false);
    });
  });
}
