import { css } from 'styled-components';

const button = css`
  min-width: 96px;
  max-width: 120px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  height: 40px;
`;

export default {
  button
};
