import React, { useCallback } from 'react';
import styled from 'styled-components';
import Label from '@src/components/common/controls/Label';
import DropZone from '@src/components/common/controls/DropZone';
import { ErrorMessage } from '@src/components/common/index';

interface Props {
  id: any;
  field: { name: string; value: any };
  className?: any;
  form: { errors?: any; touched: boolean; setFieldValue: Function };
  label: string;
  [x: string]: any;
}

const FileInput: React.FC<Props> = ({
  id,
  field,
  className,
  form: { errors, touched, setFieldValue },
  label,
  ...restProps
}): JSX.Element | null => {
  const { name, value } = field;
  const invalid = errors[name] && touched[name];

  const { placeholder } = restProps;

  const onChange = useCallback(
    selectedFiles => {
      setFieldValue(name, selectedFiles);
    },
    [name, setFieldValue]
  );

  const onReset = useCallback(() => {
    setFieldValue(name, []);
  }, [name, setFieldValue]);

  return (
    <InputGroup className={className}>
      {label && (
        <Label htmlFor={id}>
          {label}
          {invalid && <ErrorMessage message={errors[name]} />}
        </Label>
      )}

      {/* <DropZone id={id} name={name} value={value} placeholder={placeholder} onChange={onChange} onReset={onReset} /> */}
    </InputGroup>
  );
};

export default FileInput;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;
