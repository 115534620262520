import React from 'react';
import styled from 'styled-components';
import { ORDER_BROKRETE_URL } from '@src/config';

const Root = styled.div`
  display: flex;
  max-width: 130px;
  min-width: 130px;
  max-height: 80px;
  min-height: 80px;

  box-sizing: border-box;

  cursor: pointer;

  padding: 10px;

  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  border: solid 1px #f6f6f6;
  background-color: #ffffff;

  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 110px;
  max-height: 60px;
  height: auto;
  width: auto;

  object-fit: contain;
`;

const PartnerItem: React.FC<{ logo?: any; code?: string }> = ({ logo, code }): JSX.Element | null => {
  const onClick = () => {
    const win = window.open(`${ORDER_BROKRETE_URL}/${code}`, '_blank');
    win.focus();
  };

  return (
    <Root onClick={onClick} className="partner-line-item">
      <Image src={logo} />
    </Root>
  );
};

export default PartnerItem;
