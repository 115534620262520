import styled from 'styled-components';

import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Counter = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  font-size: 22px;
  font-family: ${fontFamily.extraBold};
  font-weight: ${fontWeight.extraBold};
  color: ${colors.ALGAE_GREEN};
  background-color: ${colors.WHITE};
`;

export default Counter;
