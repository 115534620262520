import { color } from '@shared/lib';
import styled from 'styled-components';
import { breakpoints, fontFamily, colors, fontWeight } from '@src/defaultStyles';
import { ContentContainer, RootContainer } from '@src/components/common';

const Root = styled(RootContainer)`
  padding: 75px 80px 75px 80px;
  width: unset;

  @media (${breakpoints.tablet}) {
    padding: 60px 40px 60px 40px;
  }

  @media (${breakpoints.mobile}) {
    padding: 60px 20px 50px 20px;
  }
`;

const Content = styled(ContentContainer)`
  max-width: 1140px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 40px;
  align-items: center;

  @media (${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;
const TechnologiesSectionHeader = styled.div`
  font-size: 42px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.bold};
  color: ${colors.EBONY_CLAY};

  @media (${breakpoints.tablet}) {
    font-size: 42px;
  }

  @media (${breakpoints.tablet}) {
    font-size: 28px;
  }
`;
const TechnologiesSectionRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;

export { Root, Content, TechnologiesSectionHeader, TechnologiesSectionRoot };
