import React from 'react';
import styled from 'styled-components';

import { Text } from '@src/components/common/index';
import { breakpoints, colors } from '@src/defaultStyles';
import DownArrowIcon from '@src/components/common/DownArrowIcon';
import RightArrowIcon from '@src/components/common/RightArrowIcon';

const Link = styled(Text)`
  display: flex;
  text-align: right;
  font-size: 17px;
  font-weight: 700;
  color: ${colors.CORNFLOWER_BLUE};
`;

const ItemAndLink = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 20px;

  @media (${breakpoints.tablet}) {
    text-align: center;
  }
`;

type Props = {
  arrowDirection?: string;
  onLinkClick: Function;
  linkTitle: string;
};
const ItemLink: React.FC<Props> = ({ arrowDirection, onLinkClick, linkTitle }): JSX.Element | null => {
  return (
    <ItemAndLink onClick={onLinkClick}>
      <Link>{linkTitle}</Link>
      {arrowDirection === 'down' ? <DownArrowIcon /> : <RightArrowIcon />}
    </ItemAndLink>
  );
};

export default ItemLink;
