import styled from 'styled-components';
import { Heading, Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

export const Root = styled.div``;

export const PageHeading = styled(Heading)`
  margin-bottom: 10px;
  font-size: 52px;
  text-align: left;
  line-height: 1.23;
  letter-spacing: 1px;

  @media (${breakpoints.mobile}) {
    font-size: 32px;
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Title = styled(Text)`
  display: inline;
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.bold};
`;

export const Right = styled.div`
  display: block;
  color: ${colors.BATTLESHIP_GREY};
`;

export const Left = styled.div``;

export const Muted = styled(Text)`
  color: ${colors.BATTLESHIP_GREY};
`;

export const Salary = styled(Text)`
  color: ${colors.ALGAE_GREEN};
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.bold};
`;

export const Description = styled.div`
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 16px;

  & > *:last-child {
    margin-bottom: 0px;
  }
`;

export const Paragraph = styled.span`
  display: inline-block;
  margin-bottom: 10px;
`;
