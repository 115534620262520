import React, { useMemo } from 'react';
import ScrolledList from '../ScrolledList';
import { SelectMenuPropTypes } from '../selectPropTypes';
import Group from '@src/components/common/Select/SelectMenu/Group/Group';
import isEmpty from '@src/lib/isEmpty';
import { Root, Menu, ItemWrapper, GroupTitle } from './styled';

interface Props {
  containerStyles?: any;
  menuStyles?: any;
  menuTitle?: string;
  items?: any;
  active?: boolean;
  itemRenderer?: any;
  onItemClick?: Function;
  onItemOver?: Function;
  dropdownMenuHeight?: number;
  cursorPosition?: any;
  onMouseMove?: Function;
  scrollable?: boolean;
  prepareItem?: any;
}

const SelectMenu: React.FC<Props> = ({
  containerStyles,
  menuStyles,
  menuTitle,
  items,
  active,
  itemRenderer: ItemRenderer,
  onItemClick,
  onItemOver,
  dropdownMenuHeight,
  cursorPosition,
  onMouseMove,
  scrollable,
  prepareItem
}): JSX.Element | null => {
  const onClick = item => {
    onItemClick && onItemClick(item);
  };
  // preventing blur on item click
  const onMouseDown = event => {
    event.preventDefault();
  };

  const ScrollContent = useMemo(() => {
    if (!Array.isArray(items)) {
      return Object.keys(items).map((key, gidx) => {
        return (
          <Group
            key={`gr_${gidx}`}
            group={items[key]}
            itemRenderer={ItemRenderer}
            onItemOver={onItemOver}
            onClick={onClick}
            onMouseDown={onMouseDown}
            prepareItem={prepareItem}
          />
        );
      });
    } else {
      return items.map((item, i) => {
        const { title, icon, options, price } = item;

        return (
          <ItemWrapper key={i} onClick={() => onClick(item)}>
            <ItemRenderer item={prepareItem(item)} />
          </ItemWrapper>
        );
      });
    }
  }, [items]);

  return (
    <Root styles={containerStyles} onMouseMove={onMouseMove}>
      <Menu active={active} styles={menuStyles}>
        {scrollable ? (
          <ScrolledList contentViewHeight={dropdownMenuHeight} cursorPosition={cursorPosition}>
            {!isEmpty(menuTitle) ? <GroupTitle>{menuTitle}</GroupTitle> : <React.Fragment />}
            {ScrollContent}
          </ScrolledList>
        ) : (
          <React.Fragment>
            {!isEmpty(menuTitle) ? <GroupTitle>{menuTitle}</GroupTitle> : <React.Fragment />}
            {ScrollContent}
          </React.Fragment>
        )}
      </Menu>
    </Root>
  );
};

SelectMenu.propTypes = {
  ...SelectMenuPropTypes
};

SelectMenu.defaultProps = {
  active: false,
  items: []
};

export default SelectMenu;
