import ClipLoaderSpinner from './ClipLoaderSpinner';
import BarLoaderSpinner from './BarLoaderSpinner';
import BeatLoaderSpinner from './BeatLoaderSpinner';
import BounceLoaderSpinner from './BounceLoaderSpinner';
import CircleLoaderSpinner from './CircleLoaderSpinner';
import ClimbingBoxLoaderSpinner from './ClimbingBoxLoaderSpinner';
import ClockLoaderSpinner from './ClockLoaderSpinner';
import DotLoaderSpinner from './DotLoaderSpinner';
import FadeLoaderSpinner from './FadeLoaderSpinner';
import GridLoaderSpinner from './GridLoaderSpinner';
import HashLoaderSpinner from './HashLoaderSpinner';
import MoonLoaderSpinner from './MoonLoaderSpinner';
import PacmanLoaderSpinner from './PacmanLoaderSpinner';
import PropagateLoaderSpinner from './PropagateLoaderSpinner';
import PuffLoaderSpinner from './PuffLoaderSpinner';
import PulseLoaderSpinner from './PulseLoaderSpinner';
import RingLoaderSpinner from './RingLoaderSpinner';
import RiseLoaderSpinner from './RiseLoaderSpinner';
import RotateLoaderSpinner from './RotateLoaderSpinner';
import ScaleLoaderSpinner from './ScaleLoaderSpinner';
import SkewLoaderSpinner from './SkewLoaderSpinner';
import SquareLoaderSpinner from './SquareLoaderSpinner';
import SyncLoaderSpinner from './SyncLoaderSpinner';

export default {
  ClipLoaderSpinner,
  BarLoaderSpinner,
  BeatLoaderSpinner,
  BounceLoaderSpinner,
  CircleLoaderSpinner,
  ClimbingBoxLoaderSpinner,
  ClockLoaderSpinner,
  DotLoaderSpinner,
  FadeLoaderSpinner,
  GridLoaderSpinner,
  HashLoaderSpinner,
  MoonLoaderSpinner,
  PacmanLoaderSpinner,
  PropagateLoaderSpinner,
  PuffLoaderSpinner,
  PulseLoaderSpinner,
  RingLoaderSpinner,
  RiseLoaderSpinner,
  RotateLoaderSpinner,
  ScaleLoaderSpinner,
  SkewLoaderSpinner,
  SquareLoaderSpinner,
  SyncLoaderSpinner
};
