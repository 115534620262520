import React, { useCallback } from 'react';
import { formikForm, TextInput, Dropdown, PhoneNumberInput } from '@src/components/common/forms';
import { Field } from 'formik';
import composeValidators from '@src/lib/composeValidators';
import { email, phone, required } from '@src/lib/validators';
import styled, { css } from 'styled-components';
import { colors } from '@src/defaultStyles';
import { Button, Locker } from '@src/components/common';
import I18n from 'i18next';
import { ICONS } from '@src/assets/icons';
import useCountriesOptions from '@src/pages/InvitePage/useCountriesOptions';
import useCitiesOptions from '@src/pages/InvitePage/useCitiesOptions';
import useFormFieldResetOnChange from '@src/pages/InvitePage/useFormFieldResetOnChange';
import { InviteTypeEnum } from '@shared/interfaces';

export const DefaultInitialFormValues = {
  name: '',
  phone: '',
  company_name: '',
  country: '',
  city: '',
  password: '',
  password_confirm: ''
  // contractor_type: ''
};

const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  padding: 30px;
  border: 1px solid ${colors.LIGHT};
  border-radius: 15px;

  input {
    background-color: ${colors.VERY_LIGHT_PINK};
  }

  label {
    color: ${colors.CHARCOAL_GREY};
  }

  textarea {
    min-height: 120px;
    height: 120px;
    background-color: ${colors.VERY_LIGHT_PINK};
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  grid-gap: 20px;

  align-self: center;
  margin-top: 20px;
`;

const ResetOnChangeFields = ['city'];

const InviteForm = ({ type, editable, handleSubmit, isSubmitting, isValid, values, initialValues }) => {
  useFormFieldResetOnChange('country', ResetOnChangeFields);

  const { country } = values;
  const { countryOptions } = useCountriesOptions();
  const { cityOptions } = useCitiesOptions(country);

  const prepareItem = useCallback((item, asTitle = false) => {
    return {
      icon: ICONS.FLAGS[item.code],
      title: item.name
    };
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      {isSubmitting && (
        <Locker
          locked
          styles={css`
            height: 100%;
            position: absolute;
          `}
        />
      )}

      <Field
        name="name"
        id="name"
        label="Name"
        placeholder="Name"
        disabled={!editable}
        component={TextInput}
        validate={editable ? required : undefined}
      />

      {'email' in initialValues && (
        <Field
          name="email"
          id="email"
          label="Email"
          placeholder="Email"
          component={TextInput}
          validate={composeValidators(required, email)}
        />
      )}

      {(type === InviteTypeEnum.PartnerContractor || type === InviteTypeEnum.PartnerDriver) && (
        <Field
          name="phone"
          id="phone"
          label={I18n.t('signUp.input.phone.label')}
          placeholder={I18n.t('signUp.input.phone.placeholder')}
          disabled={!editable}
          component={PhoneNumberInput}
          validate={editable ? composeValidators(phone, required) : undefined}
        />
      )}

      {type === InviteTypeEnum.PartnerContractor && (
        <>
          {/*<Field*/}
          {/*  name='company_name'*/}
          {/*  id='company_name'*/}
          {/*  label='Company Name'*/}
          {/*  placeholder='Company Name'*/}
          {/*  component={TextInput}*/}
          {/*  validate={required}*/}
          {/*/>*/}

          <Field
            name="country"
            id="country"
            label="Country"
            toggleButtonStyles={css`
              min-height: 50px;
              max-height: 50px;
              border: 1px solid ${colors.LIGHT_GREY};
            `}
            disabled={!editable}
            options={countryOptions}
            prepareItem={prepareItem}
            component={Dropdown}
            // validate={required}
          />

          {/*{country && cityOptions.length > 0 && (*/}
          {/*  <Field*/}
          {/*    name='city'*/}
          {/*    id='city'*/}
          {/*    label='City'*/}
          {/*    toggleButtonStyles={css`*/}
          {/*    min-height: 50px;*/}
          {/*    max-height: 50px;*/}
          {/*    border: 1px solid ${colors.LIGHT_GREY};*/}
          {/*  `}*/}
          {/*    options={cityOptions}*/}
          {/*    prepareItem={(type) => ({ title: type.name })}*/}
          {/*    component={Dropdown}*/}
          {/*    // validate={required}*/}
          {/*  />*/}
          {/*)}*/}

          {/*<Field*/}
          {/*  name='contractor_type'*/}
          {/*  id='contractor_type'*/}
          {/*  label='Contractor Type'*/}
          {/*  toggleButtonStyles={css`*/}
          {/*    min-height: 50px;*/}
          {/*    max-height: 50px;*/}
          {/*    border: 1px solid ${colors.LIGHT_GREY};*/}
          {/*  `}*/}
          {/*  options={CONTRACTOR_TYPES}*/}
          {/*  prepareItem={(type) => ({ title: I18n.t(`common.contractor.${type}`) })}*/}
          {/*  component={Dropdown}*/}
          {/*  validate={required}*/}
          {/*/>*/}
        </>
      )}

      {type === InviteTypeEnum.PartnerDriver && (
        <>
          <Field
            name="country"
            id="country"
            label="Country"
            toggleButtonStyles={css`
              min-height: 50px;
              max-height: 50px;
              border: 1px solid ${colors.LIGHT_GREY};
            `}
            disabled={!editable}
            options={countryOptions}
            prepareItem={prepareItem}
            component={Dropdown}
            // validate={required}
          />

          {country && cityOptions.length > 0 && (
            <Field
              name="city"
              id="city"
              label="City"
              toggleButtonStyles={css`
                min-height: 50px;
                max-height: 50px;
                border: 1px solid ${colors.LIGHT_GREY};
              `}
              disabled={!editable}
              options={cityOptions}
              prepareItem={type => ({ title: type.name })}
              component={Dropdown}
              // validate={required}
            />
          )}
        </>
      )}

      {editable && (
        <>
          <Field
            name="password"
            id="password"
            type="password"
            label={I18n.t('signUp.input.password.label')}
            placeholder={I18n.t('signUp.input.password.placeholder')}
            component={TextInput}
          />

          <Field
            name="password_confirm"
            id="password_confirm"
            type="password"
            label={I18n.t('signUp.input.confirmPassword.label')}
            placeholder={I18n.t('signUp.input.confirmPassword.placeholder')}
            component={TextInput}
          />
        </>
      )}

      <ButtonGroup>
        {/*<Button type='button' primary disabled={isSubmitting} onClick={() => onReject({ setSubmitting })}>*/}
        {/*  Decline*/}
        {/*</Button>*/}
        <Button type="submit" primary disabled={isSubmitting || !isValid}>
          Accept
        </Button>
      </ButtonGroup>
    </Form>
  );
};

export default formikForm(InviteForm, {
  initialValues: DefaultInitialFormValues,
  validateOnChange: true,
  validateOnBlur: true
});
