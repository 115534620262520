import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FACEBOOK_LINK, TWITTER_LINK } from 'config';
import { ButtonGroup, ContentContainer, Heading, Text } from 'components/common';
import SocialIcon from 'components/common/Sociallcon';
import { breakpoints, colors, fontFamily, fontWeight } from 'defaultStyles';
import RootContainer from 'components/common/RootContainer';
import SubscribeForm from 'pages/LandingPage/screens/SubscribeScreen/SubscribeForm';
import { subscribe } from 'api/core';
import WeWillReachYouModal from 'pages/LandingPage/modals/WeWillReachYouModal';

function SubscribeScreen() {
  const { t } = useTranslation();
  const [modalIsOpened, setModalOpened] = useState(false);

  const onSubmit = useCallback(async ({ email }, actions) => {
    await subscribe(email);

    actions.resetForm();

    setModalOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpened(false);
  }, []);

  return (
    <Root>
      <Container>
        <Title>{t('landing.subscribeScreen.title')}</Title>
        <InfoBold>{t('landing.subscribeScreen.sendEmailText')}</InfoBold>
        <Info>{t('landing.subscribeScreen.bestPriceText')}</Info>

        <SubscribeForm onSubmit={onSubmit} />

        <SocialHeading align="center">{t('landing.subscribeScreen.findUsText')}</SocialHeading>
        <ButtonGroup marginRight={20}>
          <TwLink href={TWITTER_LINK} target="_blank" rel="noopener noreferrer">
            <SocialIcon icon="twitter" width={25} height={20} />
            <SocialButtonLabel>Twitter</SocialButtonLabel>
          </TwLink>

          <FbLink href={FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">
            <SocialIcon icon="facebook" width={24} height={24} />
            <SocialButtonLabel>Facebook</SocialButtonLabel>
          </FbLink>
        </ButtonGroup>

        <WeWillReachYouModal opened={modalIsOpened} onClose={closeModal} />
      </Container>
    </Root>
  );
}

export default SubscribeScreen;

const Root = styled(RootContainer)`
  //min-height: 674px;
  display: flex;
  padding-bottom: 120px;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${colors.BACKGROUND_LIGHT};

  @media (${breakpoints.tablet}) {
    padding-top: 80px;
  }

  @media (${breakpoints.mobile}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;
const Container = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 20px;

  @media (${breakpoints.tablet}) {
    font-size: 36px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 28px;
  }
`;

const Info = styled(Text)`
  text-align: center;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 50px;

  @media (${breakpoints.tablet}) {
    font-size: 22px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 22px;
  }
`;

const InfoBold = styled(Info)`
  font-family: ${fontFamily.extraBold};
  font-weight: ${fontWeight.extraBold};
  margin-bottom: 20px;
`;

const SocialHeading = styled(Heading)`
  margin-bottom: 30px;
  font-size: 22px;
`;

const SocialLink = styled.a`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  min-height: 50px;
  transition: none;
  color: ${colors.WHITE};
  text-decoration: none;

  img {
    marginright: '8px';
  }

  &:hover {
    color: ${colors.WHITE};
    transform: none;
  }

  @media (${breakpoints.mobile}) {
    width: 80px;
  }
`;

const SocialButtonLabel = styled(Text)`
  font-size: 14px;
  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};
  color: ${colors.WHITE};
  margin-left: 8px;

  @media (${breakpoints.mobile}) {
    display: none;
  }
`;

const TwLink = styled(SocialLink)({
  backgroundColor: colors.BACKGROUND_TWITTER
});

const FbLink = styled(SocialLink)({
  backgroundColor: colors.BACKGROUND_FACEBOOK
});
