import React from 'react';
import styled from 'styled-components';
import { ICONS } from '@src/assets/icons';

const iconToSource = {
  twitter: ICONS.TWITTER_ICON,
  facebook: ICONS.FACEBOOK_ICON,
  messanger: ICONS.MESSENGER_ICON
};

const SocialIcon: React.FC<{ icon: string; [x: string]: any }> = ({ icon, ...restProps }): JSX.Element | null => {
  return iconToSource[icon] && <Root {...restProps} src={iconToSource[icon]} />;
};
export default SocialIcon;
const Root = styled.img(props => ({
  width: `${props.width || 32}px`,
  height: `${props.height || 32}px`
}));
