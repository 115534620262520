import { colors, breakpoints } from '@src/defaultStyles';
import styled from 'styled-components';
const Main = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const Root = styled.div`
  position: relative;
  width: ${({ noArrows }) => (noArrows ? '100%' : '90%')};
  height: 100%;
  user-select: none;
  overflow: hidden;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: baseline;
  transition: 0.2s;
`;
const ItemWrapper = styled.div``;
const ItemContent = styled.div``;
const ArrowButtons = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  font-size: 45px;
  color: ${colors.CHARCOAL_GREY};
  z-index: 99;
  :disabled {
    color: ${colors.LIGHT_GRAY_2};
  }
`;
const GoRightButton = styled(ArrowButtons)`
  right: 0px;
  top: 42%;
  cursor: pointer;
`;
const GoLeftButton = styled(ArrowButtons)`
  top: 42%;
  left: 0px;
  cursor: pointer;
`;
const Icon = styled.img({
  marginRight: '15px',
  height: '25px',
  width: '20px'
});
const DotsWrapper = styled.div`
  display: flex;
  margin-top: 50px;
  @media (${breakpoints.tablet}) {
    margin-top: 30px;
  }
`;
const Dot = styled.div`
  width: ${({ active }) => (active ? '21px' : '10px')};
  height: 10px;
  background-color: ${({ active }) => (active ? colors.ALGAE_GREEN : '#E3E4E9')};
  border-radius: ${({ active }) => (active ? '100px' : '50%')};
  margin-right: 10px;
`;

export { Root, Content, ItemWrapper, ItemContent, GoRightButton, GoLeftButton, Icon, Dot, DotsWrapper, Main };
