import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const CloseButton = styled.div`
  position: relative;
  width: 34px;
  height: 34px;
  opacity: 0.8;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:before,
  &:after {
    position: absolute;
    content: ' ';
    left: 16px;
    height: 34px;
    width: 2px;
    background-color: ${colors.BATTLESHIP_GREY};
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export default CloseButton;
