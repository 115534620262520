import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { Contractor } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.contractor.addManager');

const query = gql`
  mutation AddManager($email: String, $phone: String) {
    contractorManagerAdd(email: $email, phone: $phone) {
      success
      contractor {
        members {
          ...member
        }
      }
    }
  }
  ${fragments.member}
`;

type ServerResponse = {
  contractorManagerAdd: Response;
};

type Response = {
  success: boolean;
  contractor: Contractor;
};

export default async (
  { email, phone }: { email?: string; phone?: string },
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      email,
      phone
    },
    options
  );

  return result.contractorManagerAdd;
};
