import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { query } from '@src/api/core/gql';

const useCountriesOptions = () => {
  const [state, setState] = useState({
    countryOptions: [],
    loaded: false
  });

  useEffect(() => {
    query(countriesQuery).then(({ countries }) => {
      setState({ countryOptions: countries, loaded: true });
    });
  }, [setState]);

  return state;
};

export default useCountriesOptions;

const countriesQuery = gql`
  query countries {
    countries {
      code
      id
      name
    }
  }
`;
