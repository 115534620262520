import React from 'react';
import styled from 'styled-components';
import RootContainer from '@src/components/common/RootContainer';
import { ContentContainer } from '@src/components/common';
import Logo from '@src/components/common/Logo';
import { breakpoints } from '@src/defaultStyles';

const BrokreteLogo: React.FC<{ containerStyles?: any }> = ({ containerStyles }): JSX.Element | null => {
  return (
    <Root>
      <Container css={containerStyles}>
        <Logo />
      </Container>
    </Root>
  );
};
export default BrokreteLogo;
const Root = styled(RootContainer)`
  position: absolute;
  padding-top: 30px;
`;

const Container = styled(ContentContainer)`
  position: relative;

  @media (${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
  }

  ${props => props.css}
`;
