import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ReactFlagsSelect from 'react-flags-select';
import { Input } from '@src/components/common';
import InputGroup from '@src/components/common/controls/InputGroup';
import { breakpoints, colors } from '@src/defaultStyles';
import CountryData from './CountryData';
import formatNumber from '@src/components/PhoneNumberInput/utils';

type Props = {
  name?: string;
  placeholder?: string;
  enableAreaCodes?: boolean | Array<string>;
  regions?: string | Array<string>;
  onlyCountries?: Array<string>;
  preferredCountries?: Array<string>;
  excludeCountries?: Array<string>;
  preserveOrder?: Array<string>;
  localization?: { [x: string]: any };
  masks?: { [x: string]: any };
  areaCodes?: { [x: string]: any };
  predecessor?: string;
  innerRef?: any;
  onChange?: Function;
  countries: any;
  id: any;
  onBlur?: Function;
  disabled?: boolean;
  disableCountryCode?: boolean;
  defaultMask?: any;
  country?: string;
  value?: any;
};
export default class PhoneNumberInput extends PureComponent<Props, { [x: string]: any }> {
  inputRef: any = React.createRef();

  static defaultProps = {
    initialCountry: 'CA',
    countries: ['CA', 'US'],

    enableAreaCodes: false,
    regions: '',
    onlyCountries: [],
    preferredCountries: [],
    excludeCountries: [],
    preserveOrder: [],
    localization: {},
    masks: {},
    areaCodes: {},
    predecessor: '+'
  };
  constructor(props) {
    super(props);

    const { onlyCountries } = new CountryData(
      props.enableAreaCodes,
      props.regions,
      props.countries,
      props.preferredCountries,
      props.excludeCountries,
      props.preserveOrder,
      props.localization,
      props.masks,
      props.areaCodes,
      '+'
    );

    const selectedCountry = onlyCountries.find(o => o.iso2 === props.country.toLowerCase()) || null;
    let formattedNumber = '';
    if (selectedCountry) {
      formattedNumber = formatNumber(
        (props.value || '').replace('+', ''),
        selectedCountry.format || this.getDefaultMask(selectedCountry),
        selectedCountry.dialCode
      );
    }

    this.state = {
      onlyCountries: onlyCountries,
      country: props.initialCountry,
      selectedCountry: selectedCountry,
      formattedNumber: formattedNumber
    };
  }

  initInput = ref => {
    this.inputRef = ref;

    if (this.props.innerRef) {
      if (typeof this.props.innerRef === 'function') {
        this.props.innerRef(ref);
      } else {
        //TODO UNCOMMENT
        // this.props.innerRef = ref;
      }
    }
  };

  onSelectCountry = countryCode => {
    const { selectedCountry, onlyCountries } = this.state;

    const currentSelectedCountry = selectedCountry;
    const newSelectedCountry = onlyCountries.find(country => country.iso2.toLowerCase() === countryCode.toLowerCase());

    if (!newSelectedCountry) {
      return;
    }

    const unformattedNumber = this.state.formattedNumber.replace(' ', '').replace('(', '').replace(')', '').replace('-', '');
    const newNumber =
      unformattedNumber.length > 1
        ? unformattedNumber.replace(currentSelectedCountry.dialCode, newSelectedCountry.dialCode)
        : newSelectedCountry.dialCode;
    const formattedNumber = formatNumber(
      newNumber.replace(/\D/g, ''),
      newSelectedCountry.format || this.getDefaultMask(newSelectedCountry),
      newSelectedCountry.dialCode
    );

    this.setState(
      {
        selectedCountry: newSelectedCountry,
        formattedNumber
      },
      () => {
        this.cursorToEnd();
        if (this.props.onChange) {
          this.props.onChange(formattedNumber.replace(/[^0-9]+/g, ''));
        }
      }
    );
  };

  getDefaultMask = country => {
    return this.props.predecessor + ''.padEnd(country.dialCode.length, '.') + ' ' + this.props.defaultMask;
  };

  cursorToEnd = () => {
    const input = this.inputRef;
    input.focus();
    const len = input.value.length;
    input.setSelectionRange(len, len);
  };

  handleInputChange = e => {
    const { disableCountryCode, predecessor } = this.props;

    const { selectedCountry } = this.state;

    const { value } = e.target;

    let formattedNumber = disableCountryCode ? '' : predecessor;
    let newSelectedCountry = selectedCountry;

    if (e.target.value.replace(/\D/g, '').length > 15) {
      return;
    }

    if (value === this.state.formattedNumber) return;

    // ie hack
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    if (value.length > 0) {
      const inputNumber = value.replace(/\D/g, '');

      formattedNumber = newSelectedCountry
        ? formatNumber(
            inputNumber,
            newSelectedCountry.format || this.getDefaultMask(newSelectedCountry),
            newSelectedCountry.dialCode
          )
        : inputNumber;
      newSelectedCountry = newSelectedCountry.dialCode ? newSelectedCountry : selectedCountry;
    }

    let caretPosition = e.target.selectionStart;
    const oldFormattedText = this.state.formattedNumber;
    const diff = formattedNumber.length - oldFormattedText.length;

    this.setState(
      {
        formattedNumber: formattedNumber,
        selectedCountry: newSelectedCountry
      },
      () => {
        if (diff > 0) {
          caretPosition = caretPosition - diff;
        }

        const lastChar = formattedNumber.charAt(formattedNumber.length - 1);

        if (lastChar === ')') {
          this.inputRef.setSelectionRange(formattedNumber.length - 1, formattedNumber.length - 1);
        } else if (caretPosition > 0 && oldFormattedText.length >= formattedNumber.length) {
          caretPosition = Math.max(this.state.selectedCountry.dialCode.length + 1, caretPosition);
          this.inputRef.setSelectionRange(caretPosition, caretPosition);
        }

        if (this.props.onChange) {
          this.props.onChange(formattedNumber.replace(/[^0-9]+/g, ''));
        }
      }
    );
  };

  labels = () => {
    const { onlyCountries } = this.state;

    return onlyCountries.reduce((memo, { iso2, name, dialCode }) => {
      if (!memo[iso2.toUpperCase()]) {
        memo[iso2.toUpperCase()] = `${name} +${dialCode}`;
      }

      return memo;
    }, {});
  };

  render() {
    const { countries, id, name, placeholder, onBlur, disabled } = this.props;

    const { formattedNumber, country } = this.state;

    return (
      <Root>
        <ReactFlagsSelect
          selected={country.toUpperCase()}
          countries={countries}
          customLabels={this.labels()}
          showSelectedLabel={false}
          selectedSize={20}
          optionsSize={20}
          disabled={disabled}
          onSelect={this.onSelectCountry}
        />

        <Input
          id={id}
          ref={this.initInput}
          name={name}
          placeholder={placeholder}
          value={formattedNumber}
          disabled={disabled}
          onChange={this.handleInputChange}
          onBlur={onBlur}
        />
      </Root>
    );
  }
}

const Root = styled(InputGroup)`
  position: relative;

  .flag-select {
    position: unset;
    background-color: ${colors.VERY_LIGHT_PINK};
    border-right: 1px solid ${colors.CLOUDY_BLUE};

    .selected--flag--option:before {
      width: 80px;
      top: 0px;
      left: 0px;
    }

    @media (${breakpoints.tablet}) {
      .flag-options {
        width: 100%;
      }
    }
  }
`;
