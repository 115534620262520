import React from 'react';
import styled from 'styled-components';
import PartnersLine from '@src/components/PartnersLine';

const Partners = ({ shadow = false }) => {
  return (
    <Root>
      <PartnersLine shadow={shadow} />
    </Root>
  );
};

const Root = styled.div`
  width: 100vw;

  display: flex;
  justify-content: center;
`;

export default Partners;
