import React from 'react';
import styled from 'styled-components';
import Label from '@src/components/common/controls/Label';
import Input from '@src/components/common/controls/Input';
import { ErrorMessage } from '@src/components/common/index';

interface Props {
  id: any;
  className?: any;
  field: { name: string };
  form: { errors?: any; touched: boolean };
  label: string;
  hidden: boolean;
  [x: string]: any;
}

const TextInput: React.FC<Props> = ({
  id,
  field,
  className,
  form: { errors, touched },
  label,
  hidden,
  ...restProps
}): JSX.Element | null => {
  const { name } = field;
  const invalid = errors[name] && touched[name];

  return (
    <InputGroup className={className}>
      {label && (
        <Label htmlFor={id}>
          {label}
          {invalid && <ErrorMessage message={errors[name]} />}
        </Label>
      )}
      <Input name={name} id={id} hidden={hidden} {...field} {...restProps} />
    </InputGroup>
  );
};

export default TextInput;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;
