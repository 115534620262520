import styled, { css } from 'styled-components';

import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

import { Text as TextCommon } from '@src/components/common';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 0;

  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50px;

  border: 2px solid ${props => (props.hovered ? colors.ALGAE_GREEN : colors.WHITE)};
`;

const Color = styled.img`
  width: 80px;
  height: 80px;

  border-radius: 50%;
`;

const Text = styled(TextCommon)`
  margin-top: 5px;

  font-family: ${fontFamily.regular};
  font-size: 14px;
  font-weight: ${fontWeight.normal};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
`;

const Price = styled(TextCommon)`
  font-family: ${fontFamily.extraBold};
  font-size: 14px;
  font-weight: ${fontWeight.extraBold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
`;

const Svg = styled.svg`
  #border {
    stroke: ${props => (props.hovered ? colors.WHITE : colors.CLOUDY_BLUE)};
  }

  #icon {
    fill: ${props => (props.hovered ? colors.ALGAE_GREEN : colors.CLOUDY_BLUE)};
  }
`;

export { Root, Container, Svg, Color, Text, Price, Wrapper };

export default {
  Root,
  Container,
  Svg,
  Color,
  Text,
  Price,
  Wrapper
};
