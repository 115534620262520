import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import * as fragments from '../fragments';

import { Options, query as apolloQuery } from '@src/api/core/gql';
import { Order, Partner, Region } from '@shared/interfaces';
import { buildRegionInput } from '../helper';

const logger = logs.getLogger('api.brokrete.order.get');

const query = gql`
  query Parters($region: RegionInput, $savedOnly: Boolean) {
    partners(visibleOnly: true, savedOnly: $savedOnly, region: $region) {
      values {
        ...partner
      }
    }
  }
  ${fragments.partner}
`;

type ServerResponse = {
  partners: Response;
};

type Response = {
  values: Array<Partner>;
};

type Props = {
  region?: Region;
  savedOnly?: boolean;
};

export default async (props: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await apolloQuery(
    query,
    {
      region: buildRegionInput(props.region),
      savedOnly: props.savedOnly
    },
    options
  );

  return result.partners;
};
