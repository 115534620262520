import styled from 'styled-components';
import { breakpoints, colors, fontWeight } from '@src/defaultStyles';
import { Text } from '@src/components/common';
import sanitize from '@src/lib/sanitize';
import RightArrowIcon from '@src/components/common/RightArrowIcon';
import React from 'react';
import DownArrowIcon from '@src/components/common/DownArrowIcon';

type Props = {
  items: ListItem[];
};

export type ListItem = {
  icon: string;
  heading: string;
  text: string;
  link: string;
  arrowDirection: 'down' | 'right';
  onLinkClick: () => void;
};

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 35px;
  margin: 30px 0 0 0;

  @media (${breakpoints.tablet}) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 60px;
    max-width: 450px;
    width: 100%;
    margin: 0 auto;
  }

  @media (${breakpoints.tablet}) {
    grid-row-gap: 50px;
  }
`;

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 65px 56px 200px auto;
  grid-row-gap: 15px;
  align-items: flex-start;

  @media (${breakpoints.tablet}) {
    grid-template-rows: 90px auto auto auto;
    justify-items: center;
    grid-row-gap: 0;
  }
`;

const ItemIcon = styled.img`
  width: 65px;
  margin-bottom: 15px;

  @media (${breakpoints.mobile}) {
    //width: 70px;
    //margin-bottom: 25px;
  }

  @media (${breakpoints.tablet}) {
    margin-bottom: 25px;
  }
`;

const ItemHeading = styled.div`
  font-size: 17px;
  font-weight: ${fontWeight.semibold};
  color: ${colors.DARK_MINT};
  max-width: 360px;
  width: 100%;
  line-height: 1.55;
  letter-spacing: 1px;
  align-self: flex-start;
  margin-top: 10px;

  @media (${breakpoints.tablet}) {
    text-align: center;
    margin-top: 0;
    margin-bottom: 30px;
  }

  @media (${breakpoints.mobile}) {
    text-align: center;
    margin-bottom: 10px;
  }
`;

const ItemText = styled.div`
  display: flex;
  text-align: justify;
  font-size: 17px;
  font-weight: ${fontWeight.light};
  line-height: 1.65;
  color: ${colors.ALTO};

  @media (${breakpoints.tablet}) {
    text-align: center;
    min-height: auto;
  }

  @media (${breakpoints.mobile}) {
    //padding-top: 15px;
  }
`;

const ItemLink = styled(Text)`
  display: flex;
  text-align: right;
  font-size: 17px;
  font-weight: 700;
  color: ${colors.CORNFLOWER_BLUE};
`;

const ItemAndLink = styled.div`
  display: flex;
  cursor: pointer;
  margin-top: 20px;

  @media (${breakpoints.tablet}) {
    text-align: center;
  }
`;

const ItemsList: React.FC<Props> = ({ items }: Props) => {
  return (
    <ItemsContainer>
      {items.map(({ icon, heading, text, link, onLinkClick, arrowDirection }, idx) => {
        return (
          <ItemWrapper key={idx}>
            <ItemIcon src={icon} />
            <ItemHeading tag="h3" dangerouslySetInnerHTML={sanitize(heading)} />
            <ItemText dangerouslySetInnerHTML={sanitize(text)} />

            {link && (
              <ItemAndLink onClick={onLinkClick}>
                <ItemLink>{link}</ItemLink>
                {arrowDirection === 'down' ? <DownArrowIcon /> : <RightArrowIcon />}
              </ItemAndLink>
            )}
          </ItemWrapper>
        );
      })}
    </ItemsContainer>
  );
};

export default ItemsList;
