import gql from 'graphql-tag';
import { useEffect, useMemo, useState } from 'react';
import { query } from '@src/api/core/gql';

const useCitiesOptions = country => {
  const [state, setState] = useState({
    cityOptions: [],
    loaded: false
  });

  const filteredOptions = useMemo(() => {
    if (country) {
      return state.cityOptions.filter(cityOption => cityOption.country.id === country.id);
    }

    return state.cityOptions;
  }, [state.cityOptions, country]);

  useEffect(() => {
    query(citiesQuery).then(({ cities }) => {
      setState({ cityOptions: cities, loaded: true });
    });
  }, [setState]);

  return { cityOptions: filteredOptions, loaded: state.loaded };
};

export default useCitiesOptions;

const citiesQuery = gql`
  query cities {
    cities {
      country {
        id
      }
      id
      name
    }
  }
`;
