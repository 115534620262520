import React from 'react';
import { Element } from 'react-scroll';
import ContactsScreen from '@src/components/common/FooterWithContacts';
import { Header, ScheduleDemoButton, WatchVideoButton } from '@src/components/pages';
import { Content, PageWrapper } from './styled';
import LdJsonInclude from '@src/pages/Services/EcommerceDevelopment/LdJsonInclude';
import ReadyToStarted from '@src/components/common/FooterWithContacts/ReadyToStarted';
import Story from '@src/components/common/Story';

import MainTopScreen from '@src/components/common/MainTopScreen';
import CompanyLogos from '@src/components/common/CompanyLogos';
import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';
import { ContactUsButton } from '@src/components/common';
import styled, { css } from 'styled-components';
import { breakpoints, colors } from '@src/defaultStyles';
import TextAndImageSection from '@src/components/common/TextAndImageSection';
import ImageAndTextSection from '@src/components/common/ImageAndTextSection';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import SuperPowers from '@src/components/common/SuperPowers';
import OrderManagement from '@src/components/common/OrderManagement';

const concreteDashboardImages = {
  mobile: IMAGES.SMART_CODEV_LOGO,
  tablet: IMAGES.SMART_CODEV_LOGO,
  default: IMAGES.SMART_CODEV_LOGO
};

const concreteDashboardKoeffs = {
  mobile: 0.574,
  tablet: 0.527,
  default: 0.544
};
const whiteLabelAppKoeffs = {
  mobile: 1.1,
  tablet: 1.1,
  default: 1.303
};

const MarcotteButton = styled.img`
  position: relative;
  top: 5px;
  width: 159px;
  height: 34px;
  @media (${breakpoints.mobile}) {
    padding-top: 10px;
  }
`;

const EcommerceDevelopment = () => {
  const brokreteInfo = 'brokreteInfo';
  const { mobile, tablet } = useMediaQuery();

  const size = mobile ? 'mobile' : tablet ? 'tablet' : 'default';

  const concreteDashboardImage = concreteDashboardImages[size];
  const concreteDashboardKoeff = concreteDashboardKoeffs[size];

  return (
    <PageWrapper>
      {/* <LdJsonInclude /> */}

      <Header light commerce displayStartSelling />

      <Content>
        <MainTopScreen
          backgroundImage={IMAGES.ECOMMERCE_DEVELOPMENT_TOP_SCREEN}
          titleStyles={css`
            color: ${colors.LIGHT_BLUE_2};
          `}
          subtitleStyles={css`
            color: ${colors.WHITE};
          `}
          styles={css`
            @media (${breakpoints.tablet}) {
              background-position-x: calc((100vw - 924px) - 121px) !important;
            }
            @media (${breakpoints.mobile}) {
              background-position-x: calc((100vw - 467px) - 115px) !important;
            }
          `}
          news={{
            chip: I18n.t('services.ecommerceDevelopment.topScreen.headerIntroduceChip'),
            text: I18n.t('services.ecommerceDevelopment.topScreen.headerIntroduceText')
          }}
          title={I18n.t('services.ecommerceDevelopment.topScreen.title')}
          subtitle={I18n.t('services.ecommerceDevelopment.topScreen.subtitle')}
          footerContent={
            <>
              <ScheduleDemoButton />
              <ContactUsButton />
              <WatchVideoButton videoUrl={'https://www.youtube.com/watch?v=mgooqyWMTxk'} />
            </>
          }
        />
        <ContactsScreen />
      </Content>
    </PageWrapper>
  );
};

export default EcommerceDevelopment;
