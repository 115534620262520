import Config from '../config';
import { date } from '../lib';

export interface User {
  id: string;
  contractor: Contractor;
  contractorMember: ContractorMember;
}

export interface ContractorMember {
  id: string;
  contractor: Contractor;
  identities: Array<Identity>;
  info: ContractorMemberInfo;
  other: Array<ContractorMember>;
  role: ContractorMemberRole;
}

export type ContractorMemberRole = 'owner' | 'manager';

export interface ContractorMemberInfo {
  name: string;
  avatar: string;
}

export interface Contractor {
  id: string;
  info: ContractorInfo;
  companyInfo: ContractorCompanyInfo;
  account?: Account;
  referralCode: string;
  earnings: number;
  activeStatus: string;
  referees: Array<Contractor>;
  owner: ContractorMember;
  members: Array<ContractorMember>;
  verified: boolean;
  savedPartners: Array<Partner>;
  lockedPartner: Partner;
}

export type ContractorType = typeof Config.contractor.types[number];

export interface ContractorInfo {
  type: ContractorType;
  additional: ContractorAdditionalInfo;
  country?: Country;
  name: string;
}

export interface ContractorAdditionalInfo {
  skip?: boolean;
  averageVolumeConcretePerYear?: string;
  averageVolumeAggregatePerYear?: string;
}

export interface ContractorCompanyInfo {
  name: string;
  address: string;
  logo: string;
}

export interface Identity {
  provider: 'email' | 'phone';
  uid: string;
  confirmed: boolean;
}

export interface PaymentCard {
  id?: number;
  uid?: string;
  brand?: string;
  last4?: string;
  expMonth?: number;
  expYear?: number;
  number?: string;
  cvv?: string;
}

export interface LatLng {
  lat: number;
  lng: number;
}

export interface LatLngBounds {
  west: number;
  east: number;
  north: number;
  south: number;
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Place {
  id: string;
  location: Location;
  mainText: string;
  secondaryText: string;
  state: string;
  country: string;
  city?: string;
  zip?: string;
}

export interface DeliveryMethod {
  name: string;
  caption: string;
  description: string;
}

export type ProductIndustry = 'ready_mix_concrete' | 'volumetric_concrete' | 'aggregate_and_stone' | 'related_products';

export interface ProductPermissions {
  decorates: boolean;
  options: boolean;
  trucks: boolean;
}

export interface Product {
  id: string;
  name: string;
  industry: ProductIndustry;
  image: string;
  permissions: ProductPermissions;
}

export interface Image {
  id: string;
  source: string;
}

export interface ResourceImage {
  id: string;
  image: Image;
  createdAt: string;
}

export interface OrderImage extends ResourceImage {
  type: string;
}

export interface ProjectImage extends ResourceImage {}

export interface Region extends Location {
  deltaLatitude?: number;
  deltaLongitude?: number;
  radius?: number;
}

export interface Plant {
  id: string;
  name: string;
  address: Place;
  deliveryRadius: number;
  pickup: boolean;
}

export type CountryCode = string;

export interface Country {
  id: string;
  name: string;
  code: CountryCode;
  currency: string;
}

export interface City {
  id: string;
  name: string;
  country: Country;
  address: Place;
}

export interface ProductDecorate {
  id: string;
  name: string;
  image: string;
}

export interface ProductType {
  id: string;
  name: string;
  group: string;
  image: string;
  description: string;
  descriptionImages: Array<string>;
  specification: string;
}

export interface Price {
  id: string;
  value: number;
  originalValue: number;
  content: 'unit' | 'load' | 'onetime';
  exchangeRate: number;
  formula?: { value: string };
  formulaArguments: { [key: string]: number };
}

export interface ProductDecoratePrice {
  productDecorate: ProductDecorate;
  price: Price;
}

export interface ProductTypePrice {
  productType: ProductType;
  price: Price;
  units: string;
}

export interface ProductPrice {
  productTypePrice: ProductTypePrice;
  productDecoratePrice?: ProductDecoratePrice;
  quantity?: number;
}

export interface Option {
  id: string;
  name: string;
}

export interface OptionPrice {
  id?: string;
  option: Option;
  price: Price;
}

export interface Fee {
  id: string;
  name: string;
  type: 'common' | 'underload' | 'delivery' | 'washout' | 'tax' | 'seasonal' | 'one_time' | 'timeframe';
  code?: 'waiting_time_deposit';
  details: {
    from?: number;
    to?: number;
    multiplier?: number;
  };
}

export interface FeePrice {
  id?: string;
  fee: Fee;
  price: Price;
}

export interface AvailabilityTimes {
  day: date.Date;
  timezone: string;
  openedValues: Array<date.Date>;
  values: Array<date.Date>;
}

export type OrderStatus =
  | 'live'
  | 'pouring'
  | 'delivering'
  | 'delivered'
  | 'on_hold'
  | 'canceled'
  | 'error'
  | 'unknown'
  | 'ready_to_pickup';

export type TimeBetweenTrucks = typeof Config.order[ProductIndustry]['timeBetweenTrucks'][number];

export interface Order {
  id: string;
  address: Place;
  timezone: string;
  deliveryTime: string;
  pouringTime: string;
  lockToUpdateTime: string;
  lockToCancelTime: string;
  deliveryDetails: string;
  prices: Array<OrderPrice>;
  product: Product;
  quantity: number;
  timeBetweenTrucks: TimeBetweenTrucks;
  totalPrice: number;
  deliveryMethod: DeliveryMethod;
  trucks: Array<number>;
  trucksConfig: TrucksConfig;
  images: Array<OrderImage>;
  status: OrderStatus;
  holdLimits: number;

  hasToBeRated: boolean;

  currency: string;
  trucksDrivers: Array<OrderTruck>;
  project: Project;
  partner: Partner;

  route: {
    distance: number;
  };

  plant: Plant;
  pickup: boolean;
  link: string;

  askForPayment: boolean;
  totalPaid: number;

  units: string;
  updatedAt: string;
}

export interface Driver {
  id: string;
  orders?: OrderTruck[];
}

export interface OrderTruck {
  deliveryTime: string;
  driver?: Driver;
  order: Order;
  quantity: number;
  status: string;
  location: Location;

  duration?: number;
}

export interface OrderPrice {
  id: string;
  fee?: Fee;
  option?: Option;
  price: Price;
  productDecorate?: ProductDecorate;
  productType?: ProductType;
  value: number;
  quantity: number;
  anchorPrice?: OrderPrice;
}

export interface Account {
  balance: number;
  credit: number;
  creditExpiredAt: string;
  id: string;
}

export interface TrucksConfig {
  deliveryMethod: DeliveryMethod;
  quantity: {
    min: number;
    max: number;
    step: number;
  };
  count: {
    max: number;
  };
  order: {
    minQuantity: number;
    minAmount: number;
  };
}

export interface ApiResponse {
  success: boolean;
  intent?: { client_secret: string };
}

export interface Project {
  id: string;
  name: string;
  type: string;
  address: Place;
  spent: number;
  orders: Array<Partial<Order>>;
  images: Array<ProjectImage>;
  createdAt: string;
  status: 'active' | 'completed' | 'deleted';
}

export interface Partner {
  id: string;
  name: string;
  code: string;

  logo: string;
  largeLogo: string;
  banner: string;

  address: Place;

  email: string;
  phone: string;
  website: string;

  caption: string;
  shortCaption: string;
  termsAndConditions: string;
  description: string;
  hasApp: boolean;
  appAndroidUrl: string;
  appIosUrl: string;
  driverAppAndroidUrl?: string;
  driverAppIosUrl?: string;
  qrAppUrl: string;
  qrDriverUrl: string;

  productCategories: {
    count: number;
  };
}

export interface Member {
  active: boolean;
  avatar: string;
  id: string;
  name: string;
  role: string;
}

export interface Invite {
  id: string;
  redirectTo: string;
  partner?: Partner;
  contractor?: Contractor;
  status: InviteStatusEnum;
  type: InviteTypeEnum;
  pending: boolean;
  targetContractor?: Contractor;
  targetDriver?: Driver;
  targetMember?: Member;
}

export enum InviteTypeEnum {
  ContractorManager = 'contractor_manager',
  PartnerContractor = 'partner_contractor',
  PartnerManager = 'partner_manager',
  PartnerDriver = 'partner_driver'
}

export enum InviteStatusEnum {
  confirmed = 'confirmed',
  pending = 'pending',
  canceled = 'canceled'
}
