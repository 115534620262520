import I18n from 'i18next';

export const ContactSectionConfig = [
  {
    title: I18n.t('header.services'),
    sections: [
      {
        text: 'Custom Software Development',
        link: '/services/custom-software-development'
      },
      {
        text: 'Web Development',
        link: '/services/web-development'
      },
      {
        text: 'Mobile Apps Development',
        link: '/services/mobile-apps-development'
      },
      {
        text: 'Ecommerce Development',
        link: '/services/ecommerce-development'
      },
      {
        text: 'Product Development',
        link: '/services/product-development'
      },
      {
        text: 'Cloud Application Development',
        link: '/services/cloud-application-development'
      },
      {
        text: 'Software Testing & QA',
        link: '/services/software-testing-qa'
      },
      {
        text: 'UI/UX Design',
        link: '/services/ui-ux-design'
      }
    ]
  },
  {
    title: I18n.t('header.technologies'),
    sections: [
      {
        text: 'Backend',
        link: '/technologies/backend'
      },
      {
        text: 'Frontend',
        link: '/technologies/frontend'
      },
      {
        text: 'Database & API',
        link: '/technologies/database-api'
      },
      {
        text: 'DevOps',
        link: '/technologies/dev-ops'
      },
      {
        text: 'Mobile',
        link: '/technologies/mobile'
      },
      {
        text: 'Others',
        link: '/technologies/others'
      }
    ]
  },
  {
    title: I18n.t('common.others'),
    sections: [
      {
        text: 'About Us',
        link: '/about'
      }
    ]
  }
];
