import React from 'react';

import { Root, Check } from './styled';
import { ICONS } from '@src/assets/icons';
interface Props {
  styles?: any;
  checked?: boolean;
  width?: number | string;
  height?: number | string;
  round?: boolean;
  onClick?: Function;
}

const Checkbox: React.FC<Props> = ({ styles, checked, width, height, round, onClick }): JSX.Element | null => (
  <Root styles={styles} checked={checked} round={round} width={width} height={height} onClick={onClick}>
    <Check src={ICONS.CHECK} />
  </Root>
);

export default Checkbox;
