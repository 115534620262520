import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RootContainer } from '../common';
import { colors } from '@src/defaultStyles';

const Shadow = styled.div`
  position: fixed;
  height: 100vh;
  color: black;
  width: 100vw;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;
const DrawerWrapper = styled.div`
  display: flex;
  position: fixed;
  right: ${({ isOpen }) => (isOpen ? 0 : '-50vw')};
  top: 0;
  z-index: 1000;
  width: 50vw;
  background-color: white;
  transition: 0.3s;
`;
const CloseIcon = styled.div`
  position: absolute;
  right: 50px;
  top: 50px;
`;
const MenuItems = styled(Link)`
  text-decoration: none;
  color: ${({ active }) => (active ? 'red' : colors.DARK_GRAY)};
  font-size: 12px;
  padding: 10px 0;
  border-right: ${({ active }) => (active ? `3px solid ${colors.DARK_GRAY}` : `3px solid transparent`)};
  padding-right: 50px;
`;
const SectionsItems = styled.div`
  overflow-y: scroll;
  width: 50vw;
  height: 90vh;

  background-color: ${colors.WILD_SAND};
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SectionItemRoot = styled(Link)`
  text-decoration: none;
  width: 80%;
  margin: 0 10%;
  color: ${colors.BLACK};
`;
const SectionTitle = styled.div`
  margin-bottom: 10px;
  font-weight: 600;
  &:hover {
    color: ${colors.LIGHT_BLUE_2};
  }
`;
const SectionDescription = styled.div`
  margin-bottom: 20px;
  color: ${colors.DARK_GRAY};
  font-size: 10px;
`;
const MenuTitle = styled.div`
  color: ${colors.DARK_GRAY};
  font-size: 10px;
  margin-bottom: -10px;
  padding-right: 50px;
`;
const MenuItemsWrapper = styled.div`
  height: 100vh;
  width: 50vw;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
`;
export {
  Shadow,
  DrawerWrapper,
  CloseIcon,
  MenuItems,
  SectionsItems,
  SectionItemRoot,
  SectionTitle,
  SectionDescription,
  MenuTitle,
  MenuItemsWrapper
};
