import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FACEBOOK_LINK, MESSENGER_LINK, TWITTER_LINK } from 'config';
import { Button, ButtonGroup, ContentContainer, Heading, Input, InputGroup as Group, Text } from 'components/common';
import SocialIcon from 'components/common/Sociallcon';
import GmailContactsModal from './GmailContactsModal';
import { breakpoints, colors, fontFamily, fontWeight } from 'defaultStyles';
import RootContainer from 'components/common/RootContainer';
import withGoogleContacts from 'hoc/withGoogleContacts';
import sanitize from '@src/lib/sanitize';

function InviteFriendsScreen({ authorized, contacts, loading, error, signIn, fetchContacts }) {
  const { t } = useTranslation();

  const [opened, setOpened] = useState();

  useEffect(() => {
    if (authorized) {
      fetchContacts();
    }
  }, [authorized, fetchContacts]);

  const showModal = useCallback(async () => {
    if (authorized) {
      setOpened(true);
    } else {
      const signedIn = await signIn();

      if (signedIn) {
        fetchContacts();
        setOpened(true);
      }
    }
  }, [authorized, fetchContacts, signIn]);

  const closeModal = useCallback(() => {
    setOpened(false);
  }, []);

  const onSend = useCallback(async () => {
    if (authorized) {
      // TODO
    } else {
      await signIn();
    }
  }, [authorized, signIn]);

  return (
    <Root>
      <Container>
        <Title>{t('landing.inviteFriends.title')}</Title>
        <Info>{t('landing.inviteFriends.info')}</Info>

        <InputGroup>
          <InputContainer>
            <InputField placeholder={t('landing.inviteFriends.input.placeholder')} />
            <Hint>
              <Text fontSize={16}>{t('landing.inviteFriends.importFrom')}</Text>
              <Import
                fontSize={16}
                fontFamily={fontFamily.semiBold}
                fontWeight={fontWeight.semiBold}
                dangerouslySetInnerHTML={sanitize(t('landing.inviteFriends.gmail'))}
                onClick={showModal}
              />
            </Hint>
          </InputContainer>
          <SendButton width={150} primary onClick={onSend}>
            {authorized ? t('landing.inviteFriends.button.send') : t('landing.inviteFriends.button.authorize')}
          </SendButton>
        </InputGroup>

        <SocialHeading align="center">{t('landing.inviteFriends.shareOnSocials')}</SocialHeading>
        <ButtonGroup marginRight={20}>
          <TwLink href={TWITTER_LINK} target="_blank" rel="noopener noreferrer">
            <SocialIcon icon="twitter" width={25} height={20} />
            <SocialButtonLabel>Twitter</SocialButtonLabel>
          </TwLink>

          <FbLink href={FACEBOOK_LINK} target="_blank" rel="noopener noreferrer">
            <SocialIcon icon="facebook" width={24} height={24} />
            <SocialButtonLabel>Facebook</SocialButtonLabel>
          </FbLink>

          <MsLink href={MESSENGER_LINK} target="_blank" rel="noopener noreferrer">
            <SocialIcon icon="messanger" width={24} height={25} />
            <SocialButtonLabel>Messenger</SocialButtonLabel>
          </MsLink>
        </ButtonGroup>
      </Container>

      <GmailContactsModal loading={loading} contacts={contacts} error={error} opened={opened} onClose={closeModal} />
    </Root>
  );
}

export default withGoogleContacts(InviteFriendsScreen);

const Root = styled(RootContainer)`
  //min-height: 674px;
  display: flex;
  padding-bottom: 120px;
  justify-content: center;
  box-sizing: border-box;
  background-color: ${colors.BACKGROUND_LIGHT} @media (${breakpoints.tablet}) {
    padding-top: 80px;
  }

  @media (${breakpoints.mobile}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;
const Container = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 20px;

  @media (${breakpoints.tablet}) {
    font-size: 36px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 28px;
  }
`;

const Info = styled(Text)`
  text-align: center;
  margin-bottom: 50px;
  font-size: 32px;

  @media (${breakpoints.tablet}) {
    font-size: 22px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 22px;
  }
`;

const InputGroup = styled(Group)`
  margin-bottom: 82px;
  width: 100%;
  justify-content: center;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const InputContainer = styled.div`
  position: relative;
  max-width: 360px;
  width: 100%;
  margin-right: 10px;

  @media (${breakpoints.mobile}) {
    margin-right: 0;
  }
`;

const Hint = styled.div`
  position: absolute;
  display: block;
  margin-top: 5px;

  @media (${breakpoints.mobile}) {
    position: relative;
    text-align: center;
    margin-bottom: 30px;
  }
`;

const InputField = styled(Input)`
  margin-top: auto;

  @media (${breakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

const SocialHeading = styled(Heading)`
  margin-bottom: 30px;
  font-size: 22px;
`;

const SocialLink = styled.a`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  min-height: 50px;
  transition: none;
  color: ${colors.WHITE};
  text-decoration: none;

  img {
    marginright: '8px';
  }

  &:hover {
    color: ${colors.WHITE};
    transform: none;
  }

  @media (${breakpoints.mobile}) {
    width: 80px;
  }
`;

const Import = styled(Text)`
  cursor: pointer;
`;
const SocialButtonLabel = styled(Text)`
  font-size: 14px;
  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};
  color: ${colors.WHITE};
  margin-left: 8px;

  @media (${breakpoints.mobile}) {
    display: none;
  }
`;

const TwLink = styled(SocialLink)({
  backgroundColor: colors.BACKGROUND_TWITTER
});

const FbLink = styled(SocialLink)({
  backgroundColor: colors.BACKGROUND_FACEBOOK
});

const MsLink = styled(SocialLink)({
  backgroundColor: colors.BACKGROUND_MESSANGER
});

const SendButton = styled(Button)`
  @media (${breakpoints.mobile}) {
    width: 100%;
  }
`;
