import * as React from 'react';
import { CAREERS, CAREERS_LOADING, PAGE_OFFER, PAGE_OFFER_LOADING, LOCATION } from './storeConsts';
import { ICONS } from '@src/assets/icons';
const AppStateProvider = React.createContext({});

const initialState = {
  [CAREERS]: { offers: [], meta: {} },
  [CAREERS_LOADING]: false,
  [PAGE_OFFER]: {},
  [PAGE_OFFER_LOADING]: false,
  [LOCATION]: {
    country: { value: 'CA', icon: ICONS.FLAGS.CA },
    location: null
  }
};
const reducer = (state: typeof initialState, val) => ({ ...state, ...val });

const CTXProvider: React.FC<{ children: JSX.Element }> = ({ children }): JSX.Element | null => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return <AppStateProvider.Provider value={[state, dispatch]}>{children}</AppStateProvider.Provider>;
};

export { CTXProvider, AppStateProvider as CTXConsumer };
