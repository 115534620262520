import React from 'react';
import styled, { CSSProp } from 'styled-components';
import { breakpoints } from '@src/defaultStyles';
import { Partner } from '@shared/interfaces';

const Root = styled.div<{ styles?: CSSProp }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  object-fit: contain;
  box-sizing: border-box;

  @media (${breakpoints.compact}) {
    padding: unset;
  }

  ${props => props.styles};
`;

const Image = styled.img`
  max-width: calc(100% - 10px);
  max-height: calc(100% - 10px);
  width: auto;
  height: auto;

  cursor: pointer;
`;

type Props = {
  styles?: CSSProp;
  partner: Partner;
};

const Component: React.FunctionComponent<Props> = ({ styles, partner }) => {
  return (
    <Root styles={styles}>
      <Image src={partner.logo} alt="logo" />
    </Root>
  );
};

export default Component;
