import React, { useState, useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Field } from 'formik';
import composeValidators from '@src/lib/composeValidators';
import { email, required } from '@src/lib/validators';
import { Button, Label } from '@src/components/common';
import { formikForm, TextInput, TextAreaInput } from '@src/components/common/forms';
import { breakpoints, colors, fontWeight } from '@src/defaultStyles';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { database } from '@src/firebase';
import { ref, push, child, update } from 'firebase/database';
import WeWillReachYouModal from '@src/pages/LandingPage/modals/WeWillReachYouModal';

interface Props {
  isSubmitting: boolean;
  isValid: boolean;
  dirty: boolean;
  [x: string]: any;
  theme?: 'light' | 'dark';
}
const ContactUsForm: React.FC<Props> = ({ isSubmitting, isValid, dirty, ...restProps }): JSX.Element | null => {
  const { t } = useTranslation();
  const closeModal = useCallback(() => {
    setModalOpened(false);
  }, []);
  const [modalIsOpened, setModalOpened] = useState(false);
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      message: ''
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('This field is required'),
      email: Yup.string().required('This field is required'),
      message: Yup.string().required('This field is required')
    }),
    onSubmit: async values => {
      setModalOpened(true);
      formik.resetForm();
      const { fullName, email, message } = values;
      const obj = {
        fullName: fullName,
        email: email,
        message: message
      };
      const newPostKey = push(child(ref(database), 'posts')).key;
      const updates = {};
      updates['/' + newPostKey] = obj;
      return update(ref(database), updates);
    }
  });

  return (
    <Form onSubmit={formik.handleSubmit} {...restProps}>
      <InputGroup>
        <Label color={colors.GRAY}>{t('contactSection.form.nameTitle')}</Label>
        <FieldInput
          {...formik.getFieldProps('fullName')}
          //name="fullName"
          id="fullName"
          placeholder={t('contactSection.form.namePlaceholder')}
          component={TextInput}
          validate={required}
        />
      </InputGroup>

      <InputGroup>
        <Label color={colors.GRAY}>{t('contactSection.form.emailTitle')}</Label>
        <FieldInput
          {...formik.getFieldProps('email')}
          //name="email"
          id="email"
          placeholder={t('contactSection.form.emailPlaceholder')}
          component={TextInput}
          validate={composeValidators(email, required)}
        />
      </InputGroup>

      <InputGroup>
        <Label color={colors.GRAY}>{t('contactSection.form.messageTitle')}</Label>
        <FieldInput
          {...formik.getFieldProps('message')}
          //name="message"
          id="message"
          placeholder={t('contactSection.form.messagePlaceholder')}
          component={TextAreaInput}
          validate={required}
        />
      </InputGroup>

      <SubmitButton type="submit" primary>
        {t('contactSection.form.button')}
      </SubmitButton>
      <WeWillReachYouModal opened={modalIsOpened} onClose={closeModal} />
    </Form>
  );
};

export default formikForm(ContactUsForm, {
  initialValues: {
    fullName: '',
    email: '',
    message: ''
  }
});

ContactUsForm.defaultProps = {
  theme: 'light'
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 471px;

  @media (${breakpoints.tablet}) {
  }
`;

const SubmitButton = styled(Button)`
  font-size: 14px;
  font-weight: ${fontWeight.semibold};
  width: 150px;
  height: 40px;
  letter-spacing: 0.5px;
  margin-left: auto;
  background-color: #ff8916;
  color: ${colors.WHITE};

  @media (${breakpoints.tablet}) {
    font-size: 16px;
    font-weight: ${fontWeight.medium};
    margin: 0 auto;
  }
  @media (${breakpoints.mobile}) {
    width: 100%;
  }
`;

const InputGroup = styled.div({
  //marginBottom: '20px'
});

const FieldInput = styled(Field)({
  boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.1)',
  borderRadius: 4,
  fontSize: 16,
  fontWeight: 400
});
