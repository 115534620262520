import styled, { css } from 'styled-components';

import ContentContainer from '@src/components/common/ContentContainer';
import RootContainer from '@src/components/common/RootContainer';
import { Heading } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled(RootContainer)`
  position: relative;
  background-color: ${colors.WHITE};
  box-sizing: border-box;
  padding-top: 75px;
  padding-bottom: 75px;

  @media (${breakpoints.tablet}) {
    padding: 60px 50px 60px 50px;
  }

  @media (${breakpoints.mobile}) {
    padding: 35px 20px 35px 20px;
  }

  ${props => props.styles}
`;
const Content = styled(ContentContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;

  max-width: 1240px;

  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const PageHeading = styled(Heading)`
  font-size: 42px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.bold};
  text-align: center;
  color: ${colors.BLACK};
  margin-bottom: 100px;
  line-height: 64px;

  @media (${breakpoints.tablet}) {
    margin-bottom: 60px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 60px;
  }
`;
const RightBlock = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;

  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`;

const LeftBlock = styled.div`
  align-self: center;

  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`;

const FooterIntroduce = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      margin: 5px 0;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  display: grid;
  ${({ height }) =>
    height
      ? css`
          height: ${height}px;
        `
      : ''};

  @media (${breakpoints.tablet}) {
    margin-bottom: 40px;
  }

  @media (${breakpoints.mobile}) {
    margin-bottom: 20px;
  }

  ${props => props.styles}
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  align-self: center;
`;
const HeaderIntroduce = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  background-color: ${colors.MERCURY};
  padding: 1px 2px;
  max-width: 565px;
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: 45px;
  margin-top: -50px;

  @media (${breakpoints.mobile}) {
    padding: 10px 15px;
    border-radius: 40px;
    margin-bottom: 20px;
    margin-top: 50px;
  }
`;
const HeaderIntroduceText = styled.div`
  margin: 0px 5px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  font-size: 14px;
  color: ${colors.BLACK};
  padding-left: 10px;

  @media (${breakpoints.mobile}) {
    line-height: 1.2;
  }
`;

const HeaderIntroduceChip = styled.div`
  background-color: ${colors.ALGAE_GREEN};
  padding: 1px 10px;
  border-radius: 50px;
`;

const ImageText = styled.div`
  font-size: 20px;
  font-weight: ${fontWeight.semibold};
  letter-spacing: 0.49px;
  line-height: 1.4;
  color: ${colors.GRAY};

  ${props => props.styles}
`;

export {
  ImageWrapper,
  ImageText,
  Image,
  Root,
  Content,
  PageHeading,
  RightBlock,
  LeftBlock,
  FooterIntroduce,
  HeaderIntroduce,
  HeaderIntroduceChip,
  HeaderIntroduceText
};
