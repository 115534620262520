import React, { useCallback, useEffect } from 'react';
import api from 'api';
import { send_mail } from '@src/api/core';
import useStore from 'reactContext/hooks/useStore';
import { PAGE_OFFER, PAGE_OFFER_LOADING } from 'reactContext/provider/storeConsts';

export default Component =>
  function (props) {
    const offerId = props.match.params.id.split('__')[0];

    const [{ [offerId]: offer }, setOffer] = useStore(PAGE_OFFER);
    const [, setOfferLoading] = useStore(PAGE_OFFER_LOADING);

    const getOffer = async id => {
      setOfferLoading(true);
      const { jobDetails } = await api.core.career.jobs.fetchPositionDetails(id);
      if (jobDetails === null) {
        // dispatch(replace('/career'));
      } else {
        setOffer({ [offerId]: jobDetails });
        setOfferLoading(false);
      }
    };

    useEffect(() => {
      if (!offer) getOffer(offerId);

      return () => setOffer({});
    }, []);

    const onSubmit = useCallback(async ({ fullName, email, message }, actions) => {
      await send_mail(fullName, email, `New JOB request: offer #${offerId}: ${message}`);

      actions.resetForm();
    }, []);

    return <Component {...props} onSubmit={onSubmit} offerId={offerId} />;
  };
