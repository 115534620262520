import React from 'react';
import isEmpty from '@src/lib/isEmpty';
import InputLabel from '@src/components/common/InputLabel';
import { Root } from './styled';

interface Props {
  label: string | number;
  component: React.FC;
}

const LabeledControl: React.FC<Props> = ({ label, component: Component, ...controlProps }): JSX.Element | null => {
  return (
    <Root>
      {!isEmpty(label) && <InputLabel label={label} />}
      <Component {...controlProps} />
    </Root>
  );
};

export default LabeledControl;
