import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';

const ContentContainer: React.FC<{ className?: string }> = ({ className, ...restProps }): JSX.Element | null => {
  return <Root className={cx('content-container', className)} {...restProps} />;
};

export default ContentContainer;

const Root = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`;
