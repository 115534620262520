import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const Root = styled.div`
  .card {
    background-color: ${props => props.backgroundColor};
    width: ${props => props.cardWidth};
    height: ${props => props.cardHeight};
    margin: 20px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  .image {
    position: relative;
    top: 10px;
  }
  .title {
    color: ${props => props.titleColor};
  }
  .description {
    color: ${props => props.descriptionColor};
  }
  ${props => props.styles}
`;

export { Root };
