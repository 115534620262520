import gql from 'graphql-tag';

import { Order, Partner, Place, Product, ProductPrice } from '@shared/interfaces';

import { mutation, query, Options } from '@src/api/core/gql';
import { date } from '@shared/lib';
import { buildAddressInput, buildIdInput, buildProductPricesInput } from '../helper';

const gqlQuery = gql`
  query OrderPreparatoryConfig(
    $product: IdInput!
    $productPrices: [ProductPriceInput!]
    $address: AddressInput!
    $days: [String!]!
    $order: IdInput
  ) {
    orderPreparatoryConfig(input: { category: $product, address: $address, productPrices: $productPrices }, order: $order) {
      availabilityTimes(days: $days) {
        times
        openedRules
        timezone
      }
    }
  }
`;

type ServerResponse = {
  orderPreparatoryConfig: {
    availabilityTimes: {
      times: Array<string>;
      openedRules: Array<string>;
      timezone: string;
    };
  };
};

type Response = {
  openedRules: Array<string>;
  timezone: string;
  availabilityTimes: Array<date.Date>;
};

type Props = {
  order?: Order;
  product: Product;
  productPrices: Array<ProductPrice>;
  address: Place;
  days: Array<date.Date>;
};

export default async (
  { order, product, productPrices, address, days }: Props,
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      product: buildIdInput(product),
      productPrices: buildProductPricesInput(productPrices),
      address: buildAddressInput(address),
      order: buildIdInput(order),
      days: days.map(value => value.format())
    },
    options
  );

  return {
    availabilityTimes: result.orderPreparatoryConfig.availabilityTimes.times.map(value => date.fromString(value)),
    openedRules: result.orderPreparatoryConfig.availabilityTimes.openedRules,
    timezone: result.orderPreparatoryConfig.availabilityTimes.timezone
  };
};
