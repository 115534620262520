import styled from 'styled-components';
import { ContentContainer, RootContainer } from '@src/components/common';
import { breakpoints } from '@src/defaultStyles';

const Root = styled(RootContainer)`
  padding-bottom: 100px;

  @media (${breakpoints.mobile}) {
    padding: 80px 15px;
  }
`;

const PageWrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  //margin-top: 74px;

  @media (${breakpoints.mobile}) {
    margin-top: 40px;
  }
`;

export { PageWrapper, Content, Root };
