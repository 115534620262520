import styled from 'styled-components';
import { Text } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  position: relative;

  ${props => props.styles}
`;

const Label = styled(Text)`
  margin-bottom: 10px;

  font-family: ${fontFamily.bold};
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 1px;
  color: ${colors.BATTLESHIP_GREY};

  text-transform: uppercase;
`;

const MenuWrapper = styled.div`
  position: relative;
`;

export { MenuWrapper, Root, Label };

export default {
  MenuWrapper,
  Root,
  Label
};
