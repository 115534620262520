import Storage from './cloud';

const cloud = new Storage({
  key: process.env.REACT_APP_AWS_S3_KEY,
  secret: process.env.REACT_APP_AWS_S3_SECRET,
  region: process.env.REACT_APP_AWS_S3_REGION,
  bucket: process.env.REACT_APP_AWS_S3_BUCKET
});

export { cloud };
