import { isEmpty } from '../../../lib';

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: Array<string>;
}

export class AddressObject {
  public number = '';
  public street = '';
  public city = '';
  public state = '';
  public zip = '';
  public country = '';
  public district = '';
  public region = '';

  constructor(addressComponents: Array<AddressComponent>) {
    this.build(addressComponents);
  }

  private build(addressComponents: Array<AddressComponent>) {
    const mapper: { [name: string]: { types: Array<string>; value: (c: AddressComponent) => string } } = {
      number: {
        types: ['street_number'],
        value: c => c.short_name
      },
      street: {
        types: ['street_address', 'route'],
        value: c => c.long_name
      },
      city: {
        types: ['locality', 'postal_town'],
        value: c => c.long_name
      },
      state: {
        types: ['administrative_area_level_1'],
        value: c => (!isEmpty(c.short_name) ? c.short_name : c.long_name)
      },
      zip: {
        types: ['postal_code'],
        value: c => c.long_name
      },
      country: {
        types: ['country'],
        value: c => c.long_name
      },
      district: {
        types: ['sublocality', 'sublocality_level_1', 'sublocality_level_2', 'sublocality_level_3', 'sublocality_level_4'],
        value: c => c.long_name
      },
      region: {
        types: [
          'administrative_area_level_1',
          'administrative_area_level_2',
          'administrative_area_level_3',
          'administrative_area_level_4',
          'administrative_area_level_5'
        ],
        value: c => c.long_name
      }
    };

    addressComponents.forEach(component => {
      Object.entries(mapper).forEach(([field, config]) => {
        const hasType = component.types.find(value => config.types.indexOf(value) >= 0) != null;

        if (hasType) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          this[field] = config.value(component);
        }
      });
    });
  }
}
