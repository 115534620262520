import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/defaultStyles';
import Loader from '@src/components/common/Loader';

const Locker: React.FC<{ locked: boolean; styles: any }> = ({ locked, styles }): JSX.Element | null => {
  return (
    locked && (
      <Root styles={styles}>
        <Overlay />
        <Loader color={colors.BATTLESHIP_GREY} />
      </Root>
    )
  );
};

export default Locker;

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  z-index: 2147483648;

  ${props => props.styles}
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(1px);
  background-color: ${colors.WHITE};
  opacity: 0.5;
  user-select: none;
`;
