import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ItemWrapper } from '@src/components/common/Select/SelectMenu/styled';
import { Submenu, Root } from './styled';
import styles from './styles';
import SelectMenu from '@src/components/common/Select/SelectMenu';

interface Props {
  group?: any;
  itemRenderer?: any;
  onMouseDown: (e: MouseEvent) => void;
  onItemOver: Function;
  onClick: (e: MouseEvent) => void;
  [x: string]: any;
}
const Group: React.FC<Props> = ({
  group,
  itemRenderer: ItemRenderer,
  onMouseDown,
  onItemOver,
  onClick,
  ...rest
}): JSX.Element | null => {
  const [selected, setSelected] = useState(false);
  const ref: { current: { [x: string]: any } } = useRef();

  const item = useMemo(() => {
    return {
      title: group.title,
      icon: group.icon,
      options: group.options,
      prepareItem: rest.prepareItem
    };
  }, [group]);

  useEffect(() => {
    if (selected) {
      document.addEventListener('mouseover', handleMouseLeave);
    } else {
      document.removeEventListener('mouseover', handleMouseLeave);
    }

    return () => document.removeEventListener('mouseover', handleMouseLeave);
  }, [selected]);

  const handleMouseLeave = useCallback(
    e => {
      if (!ref.current.contains(e.target)) {
        setSelected(false);
      }
    },
    [selected]
  );

  const handleMouseOver = useCallback(() => {
    setSelected(true);
  }, []);

  return (
    <Root ref={ref} className={group.title}>
      <ItemWrapper onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} styles={rest.styles}>
        <ItemRenderer item={item} />
      </ItemWrapper>

      <Submenu>
        <SelectMenu
          menuStyles={styles.menu}
          items={group['options']}
          active={selected}
          dropdownMenuHeight={400}
          itemRenderer={ItemRenderer}
          onItemClick={onClick}
          scrollable
          prepareItem={rest.prepareItem}
        />
      </Submenu>
    </Root>
  );
};

export default Group;
