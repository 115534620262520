import React from 'react';
import { useTranslation } from 'react-i18next';
import sanitize from '@src/lib/sanitize';
import I18n from 'i18next';
import { ScheduleDemoButton } from '@src/components/pages';
import { ContactSalesButton } from '@src/components/pages';
import GooglePlayButton from '@src/components/common/controls/GooglePlayButton';
import AppleStoreButton from '@src/components/common/controls/AppleStoreButton';
import {
  ReadyToStart,
  Content,
  ReadyToStartCard,
  ReadyToStartLeftSide,
  ReadyToStartRightSide,
  ReadyToStartTitle,
  ReadyToStartInfo,
  ButtonGroup
} from './styled';
import { ICONS } from '@src/assets/icons';

const ReadyToStarted: React.FC<{ appStoreMode?: boolean; tryApp?: boolean; styles?: any; suppliersReady?: boolean }> = ({
  styles,
  appStoreMode,
  tryApp = false,
  suppliersReady
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <ReadyToStart suppliersReady={suppliersReady} styles={styles}>
      <Content>
        <ReadyToStartTitle dangerouslySetInnerHTML={sanitize(I18n.t('suppliers.sell.main.titleText9'))} />
        <ReadyToStartInfo
          dangerouslySetInnerHTML={sanitize(
            tryApp ? I18n.t('suppliers.sell.main.tryApp') : I18n.t('suppliers.sell.main.infoText18')
          )}
        />
        {appStoreMode ? (
          <ButtonGroup>
            <GooglePlayButton src={ICONS.GOOGLE_PLAY_BADGE_BIG_ICON} />
            <AppleStoreButton src={ICONS.APPLE_STORE_BADGE_BIG_ICON} />
          </ButtonGroup>
        ) : (
          <ReadyToStartRightSide>
            <ScheduleDemoButton />
            <ContactSalesButton />
          </ReadyToStartRightSide>
        )}
      </Content>
    </ReadyToStart>
  );
};

export default ReadyToStarted;
