export function camelToSnakeCase(text) {
  return text
    .replace(/(.)([A-Z][a-z]+)/, '$1_$2')
    .replace(/([a-z0-9])([A-Z])/, '$1_$2')
    .toLowerCase();
}

export function toArrayBuffer(value: string) {
  const buf = new ArrayBuffer(value.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0; i < value.length; i++) {
    bufView[i] = value.charCodeAt(i);
  }

  return buf;
}
