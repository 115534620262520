import ahoy from 'ahoy.js';
import { API_URL } from '@src/config';

class Ahoy {
  static APPLE_STORE_EVENT = 'AppleStoreEvent';
  static GOOGLE_PLAY_EVENT = 'GooglePlayEvent';

  static PLATFORM_DESKTOP = 'desktop';
  static PLATFORM_IOS = 'ios';
  static PLATFORM_ANDROID = 'android';

  constructor() {
    this.__init();
  }

  __init() {
    ahoy.configure({
      urlPrefix: API_URL,
      visitsUrl: '/ahoy/visits',
      eventsUrl: '/ahoy/events',
      page: null,
      platform: 'Web',
      useBeacon: true,
      startOnReady: true,
      trackVisits: true,
      cookies: true,
      cookieDomain: null,
      headers: {},
      visitParams: {},
      withCredentials: false
    });
  }

  trackAppleStoreLinkClick({ source, platform }) {
    ahoy.track(Ahoy.APPLE_STORE_EVENT, {
      source,
      platform
    });
  }

  trackGooglePlayLinkClick({ source, platform }) {
    ahoy.track(Ahoy.GOOGLE_PLAY_EVENT, {
      source,
      platform
    });
  }
}

export const PLATFORM_DESKTOP = Ahoy.PLATFORM_DESKTOP;
export const PLATFORM_IOS = Ahoy.PLATFORM_IOS;
export const PLATFORM_ANDROID = Ahoy.PLATFORM_ANDROID;

export default new Ahoy();
