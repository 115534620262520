import React from 'react';
import styled from 'styled-components';
import { Button, Text } from 'components/common';
import { useTranslation } from 'react-i18next';
import { fontFamily, fontWeight } from 'defaultStyles';

export default function ContactItem({ contact }) {
  const { t } = useTranslation();
  const { fullName, email, sent } = contact;

  const buttonText = sent ? t('modals.gmailContacts.button.sent') : t('modals.gmailContacts.button.send');

  return (
    <Root>
      <div>
        <ContactName>{fullName}</ContactName>
        <Text>{email}</Text>
      </div>
      <ButtonSend primary disabled={sent}>
        {buttonText}
      </ButtonSend>
    </Root>
  );
}

const Root = styled.li`
  height: 75px;
  padding: 20px 0px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const ButtonSend = styled(Button)`
  padding: 10px 20px;
  margin-left: auto;
  width: 70px;
  height: 40px;
  font-size: 12px;
`;

const ContactName = styled(Text)`
  display: block;
  font-size: 16px;
  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};
  line-height: 1.25;
`;
