import React, { useCallback } from 'react';
import { Root, TextCounter } from './styled';
import { ButtonAdd, ButtonRemove } from '@src/components/common';

interface Props {
  styles?: any;
  value?: number;
  min?: number;
  step?: number;
  max?: number;
  onChange: (e: number) => void;
  onRemove: Function;
}
const Counter: React.FC<Props> = ({ styles, value, min, step, max, onChange, onRemove }): JSX.Element | null => {
  const handleMinusClick: (e: React.MouseEvent) => void = useCallback(
    (e: any) => {
      if (value - step < min) {
        onRemove && onRemove();
      } else {
        const newValue = value - step;

        if (newValue !== value) {
          onChange(newValue);
        }
      }
    },
    [onRemove, onChange]
  );

  const handlePlusClick = useCallback(() => {
    const newValue = Math.min(value + step, max);

    if (newValue !== value) {
      onChange(newValue);
    }
  }, [onChange]);

  const canAdd = value < max;
  const canRemove = onRemove || value > min;

  return (
    <Root styles={styles}>
      <ButtonRemove onClick={handleMinusClick} disabled={!canRemove} />
      <TextCounter>{value}</TextCounter>
      <ButtonAdd onClick={handlePlusClick} disabled={!canAdd} />
    </Root>
  );
};
export default Counter;
Counter.defaultProps = {
  min: 0,
  value: 0,
  step: 1,
  max: 100
};
