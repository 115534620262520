import I18n from '@src/lib/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Buttons, Content, Image, Info, Paragraph, Root, Title } from './styled';
import { ScheduleDemoButton, WatchVideoButton } from '@src/components/pages';
import { ContactUsButton } from '@src/components/common';
import sanitize from '@src/lib/sanitize';
import { IMAGES } from '@src/assets/images';
import { colors } from '@src/defaultStyles';
type Props = {
  src?: string;
  title: string;
  info: string;
  backgroundColor?: string;
  id?: string;
};
const OrderManagement: React.FC<Props> = ({ src, title, info, backgroundColor, id }): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Root id={id}>
      <Content backgroundColor={backgroundColor}>
        <Info>
          <Title>{title}</Title>
          <Paragraph>{info}</Paragraph>
          <Buttons>
            <ScheduleDemoButton />
            <ContactUsButton />
          </Buttons>
        </Info>
        <Image src={src} />
      </Content>
    </Root>
  );
};

export default OrderManagement;
