import { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { query } from '@src/api/core/gql';

const usePartners = () => {
  const [loaded, setLoaded] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    setLoaded(false);

    query(partnersQuery).then(({ partners }) => {
      if (partners) {
        const { values } = partners;
        setPartners(values);
        setLoaded(true);
      }
    });
  }, [setLoaded, setPartners]);

  return {
    loaded,
    partners
  };
};

export default usePartners;

const partnersQuery = gql`
  query partners {
    partners(visibleOnly: true) {
      values {
        id
        name
        code
        logo
      }
    }
  }
`;
