import React, { useEffect, useRef, useState } from 'react';
import { Content, Dot, DotsWrapper, GoLeftButton, GoRightButton, Icon, ItemContent, ItemWrapper, Main, Root } from './styled';
import { ICONS } from '@src/assets/icons';

type Props = {
  items: Array<JSX.Element>;
  itemsPerPage?: number;
  noDots?: boolean;
  noArrows?: boolean;
  dragEnabled?: boolean;
  activeSlide?: number;
  itemsWrapperStyle?: object;
  itemStyle?: object;
  dynamicHeight?: boolean;
  onChangeActiveSlide?: Function;
  autoPlay?: boolean;
};
const Slider: React.FC<Props> = ({
  items,
  itemsPerPage = 3,
  noDots = false,
  noArrows = false,
  dragEnabled = true,
  activeSlide,
  itemsWrapperStyle = {},
  itemStyle = {},
  dynamicHeight = false,
  onChangeActiveSlide,
  autoPlay
}): JSX.Element | null => {
  const [leftItemIndex, setLeftItemIndex] = useState(0);
  const isDragging = useRef(false);
  const dragStartPosition = useRef(0);
  const itemsWrapper: any = useRef();
  const itemsPerPageRef: any = useRef();
  const lastItemWIdth = useRef(0);
  const leftItemIndexRef = useRef(0);
  const rootElement: any = useRef();
  const ItemContentEls: { current: Array<any> } = useRef([]);

  function getHowManyItemInDisplay(): number {
    return Math.floor(itemsWrapper.current?.offsetWidth / lastItemWIdth.current);
  }

  function getMaxRight(): number {
    return items.length - (getHowManyItemInDisplay() + (leftItemIndexRef.current + 1));
  }
  const goLeft = count => {
    setLeftItemIndex(i => {
      const newI = i <= 0 || i - count <= 0 ? 0 : i - count;
      itemsWrapper.current.style.marginLeft = -1 * (newI * lastItemWIdth.current) + 'px';
      return newI;
    });
  };
  const goRight = count => {
    setLeftItemIndex(i => {
      const newI = items.length <= i + count + getHowManyItemInDisplay() ? i + getMaxRight() : i + count;
      itemsWrapper.current.style.marginLeft = -1 * (newI * lastItemWIdth.current) + 'px';
      return newI;
    });
  };

  const onStartDrag = (type: string, e) => {
    if (!dragEnabled) return;

    itemsWrapper.current.style.transition = 'none';
    const clientX = type === 'touch' ? e.targetTouches[0].clientX : e.clientX;
    dragStartPosition.current = clientX;
    isDragging.current = true;
  };

  const onTouchOrClickEnd = (type: string, e) => {
    if (!isDragging.current) return;
    itemsWrapper.current.style.transition = '0.2s';
    const clientX = type === 'touch' ? e.changedTouches[0].clientX : e.clientX;

    isDragging.current = false;

    if (dragStartPosition.current > clientX) {
      goRight(Math.round((dragStartPosition.current - clientX) / lastItemWIdth.current));
    } else if (dragStartPosition.current < clientX) {
      goLeft(Math.round((clientX - dragStartPosition.current) / lastItemWIdth.current));
    }
  };
  const onMoveTouchOrMouse = (type, e) => {
    const clientX = type === 'touch' ? e.targetTouches[0].clientX : e.clientX;
    if (isDragging.current === false) return;
    const marginLeft = -1 * (dragStartPosition.current - clientX) + -1 * (leftItemIndexRef.current * lastItemWIdth.current);
    itemsWrapper.current.style.marginLeft = marginLeft + 'px';
  };
  const detectSize = () => {
    calculateWrapperHeight();
    const _width = itemsWrapper.current.offsetWidth / (itemsPerPageRef.current - 0.005); //+0.05
    lastItemWIdth.current = _width;
    ItemContentEls.current.map(_el => {
      if (_el) {
        _el.style.width = _width + 'px';
        // _el.style.marginRight=itemsWrapper.current.offsetWidth / (itemsPerPageRef.current-0.05) - itemsWrapper.current.offsetWidth / (itemsPerPageRef.current)+'px'
      }
    });
    goLeft(0);
  };
  const calculateWrapperHeight = () => {
    if (itemsPerPage === 1) return;
    setTimeout(() => {
      const displayItems = ItemContentEls.current.slice(leftItemIndexRef.current, leftItemIndexRef.current + itemsPerPage);
      const maxHeightOfItems = displayItems.map(a => a?.offsetHeight);
      if (itemsWrapper.current) {
        itemsWrapper.current.style.height = Math.max(...maxHeightOfItems) + 'px';
        itemsWrapper.current.style.alignItems = 'flex-end';
      }
    }, 0);
  };

  useEffect(() => {
    if (typeof activeSlide === 'number') {
      itemsWrapper.current.style.marginLeft = -1 * (activeSlide * lastItemWIdth.current) + 'px';
      setLeftItemIndex(activeSlide);
    }
  }, [activeSlide]);
  useEffect(() => {
    if (onChangeActiveSlide) onChangeActiveSlide(leftItemIndex);
  }, [leftItemIndex]);

  useEffect(() => {
    leftItemIndexRef.current = leftItemIndex;
  }, [leftItemIndex]);
  useEffect(() => {
    itemsPerPageRef.current = itemsPerPage;
    detectSize();
  }, [itemsPerPage]);

  {
    autoPlay &&
      useEffect(() => {
        setInterval(() => {
          if (getMaxRight() === 0) goLeft(items.length);
          else goRight(1);
        }, 4000);
      }, []);
  }
  useEffect(() => {
    window.addEventListener('resize', detectSize);
    if (dragEnabled) {
      document.addEventListener('touchend', onTouchOrClickEnd.bind(this, 'touch'));
      document.addEventListener('mouseup', onTouchOrClickEnd.bind(this, 'click'));

      document.addEventListener('touchmove', onMoveTouchOrMouse.bind(this, 'touch'));
      document.addEventListener('mousemove', onMoveTouchOrMouse.bind(this, 'click'));
    }

    return () => {
      window.removeEventListener('resize', detectSize);
      if (dragEnabled) {
        document.removeEventListener('touchend', onTouchOrClickEnd.bind(this, 'touch'));
        document.removeEventListener('mouseup', onTouchOrClickEnd.bind(this, 'click'));

        document.removeEventListener('touchmove', onMoveTouchOrMouse.bind(this, 'touch'));
        document.removeEventListener('mousemove', onMoveTouchOrMouse.bind(this, 'click'));
      }
    };
  }, []);

  const DotsCount = Array.from(Array(items.length - itemsPerPage + 1 > 0 ? items.length - itemsPerPage + 1 : 0));

  useEffect(() => {
    if (dynamicHeight && typeof leftItemIndex === 'number') {
      calculateWrapperHeight();
    }
  }, [dynamicHeight, leftItemIndex]);
  return (
    <Main>
      {!noArrows && (
        <GoLeftButton disabled={leftItemIndex <= 0} onClick={goLeft.bind(this, 1)}>
          <Icon src={ICONS.GO_LEFT_BUTTON} />
        </GoLeftButton>
      )}
      <Root
        noArrows={noArrows}
        ref={rootElement}
        onTouchStart={onStartDrag.bind(this, 'touch')}
        onMouseDown={onStartDrag.bind(this, 'click')}
      >
        <Content ref={itemsWrapper} style={itemsWrapperStyle}>
          {items.map((Item, i) => {
            return (
              <ItemWrapper key={i} style={itemStyle}>
                <ItemContent ref={element => ItemContentEls.current.splice(i, 1, element)}>{Item}</ItemContent>
              </ItemWrapper>
            );
          })}
        </Content>
      </Root>
      {!noArrows && (
        <GoRightButton disabled={items.length <= leftItemIndex + getHowManyItemInDisplay()} onClick={goRight.bind(this, 1)}>
          <Icon src={ICONS.GO_RIGHT_BUTTON} />
        </GoRightButton>
      )}
      {!noDots && (
        <DotsWrapper>
          {DotsCount.map((a, index) => (
            <Dot key={index} active={leftItemIndex === index} />
          ))}
        </DotsWrapper>
      )}
    </Main>
  );
};
export default Slider;
