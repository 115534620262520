import React, { useCallback } from 'react';
import isEmpty from '@src/lib/isEmpty';
import { Input, Root, Units } from './styled';
import InputMask from 'react-input-mask';

interface Props {
  styles?: any;
  inputStyles?: any;
  value: number;
  units?: number | string;
  onChange: Function;
}
const NumericInput: React.FC<Props> = ({ styles, inputStyles, value, units, onChange }): JSX.Element | null => {
  const handleInputChange = useCallback(e => {
    if (onChange) {
      onChange(e.target.value);
    }
  }, []);

  return (
    <Root styles={styles}>
      <InputMask
        mask={[/[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/, /[0-9.]/]}
        maskPlaceholder={null}
        value={value || ''}
        onChange={handleInputChange}
      >
        <Input styles={inputStyles} value={value} />
      </InputMask>

      {!isEmpty(units) && <Units>{units}</Units>}
    </Root>
  );
};
export default NumericInput;
