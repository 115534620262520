import gql from 'graphql-tag';

import { isEmpty, logs } from '@shared/lib';

import * as fragments from '../fragments';

import { Options, query as apolloQuery } from '@src/api/core/gql';
import { Partner } from '@shared/interfaces';

const logger = logs.getLogger('api.brokrete.order.get');

const query = gql`
  query Parter($id: ID, $code: String) {
    partner(id: $id, code: $code) {
      ...partner
    }
  }
  ${fragments.partner}
`;

type ServerResponse = {
  partner: Partner;
};

type Response = {
  partner: Partner;
};

type Props = {
  code?: string;
  id?: string;
};

export default async (props: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await apolloQuery(
    query,
    {
      ...('id' in props &&
        !isEmpty(props.id) && {
          id: props.id
        }),
      ...('code' in props &&
        !isEmpty(props.code) && {
          code: props.code
        })
    },
    options
  );

  return result;
};
