import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';

export const CustomSoftwareDevelopmentPreviewInfo = [
  {
    title: I18n.t('services.customSoftwareDevelopment.previewInfo.item1.title'),
    description: I18n.t('services.customSoftwareDevelopment.previewInfo.item1.description'),
    image: IMAGES.REQUIREMENTS_ANALYSIS
  },
  {
    title: I18n.t('services.customSoftwareDevelopment.previewInfo.item2.title'),
    description: I18n.t('services.customSoftwareDevelopment.previewInfo.item2.description'),
    image: IMAGES.BUSINESS_ANALYSIS_PLANNING
  },
  {
    title: I18n.t('services.customSoftwareDevelopment.previewInfo.item3.title'),
    description: I18n.t('services.customSoftwareDevelopment.previewInfo.item3.description'),
    image: IMAGES.BUSINESS_DESIGN
  },
  {
    title: I18n.t('services.customSoftwareDevelopment.previewInfo.item4.title'),
    description: I18n.t('services.customSoftwareDevelopment.previewInfo.item4.description'),
    image: IMAGES.BUSINESS_CODING
  },
  {
    title: I18n.t('services.customSoftwareDevelopment.previewInfo.item5.title'),
    description: I18n.t('services.customSoftwareDevelopment.previewInfo.item5.description'),
    image: IMAGES.QUALITY_ASSURANCE_1
  },
  {
    title: I18n.t('services.customSoftwareDevelopment.previewInfo.item6.title'),
    description: I18n.t('services.customSoftwareDevelopment.previewInfo.item6.description'),
    image: IMAGES.SOFTWARE_DEPLOYMENT
  },
  {
    title: I18n.t('services.customSoftwareDevelopment.previewInfo.item7.title'),
    description: I18n.t('services.customSoftwareDevelopment.previewInfo.item7.description'),
    image: IMAGES.MAINTENANCE_SUPPORT_1
  }
];
