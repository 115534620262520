import React from 'react';

import { Root, Container, Color, Svg, Wrapper, Text, Price } from './styled';

import I18n from '@src/lib/i18n';

interface PropsType {
  onClick?: Function;
  option?: { [x: string]: any };
  product: { [x: string]: any };
}
interface StateType {
  hovered: boolean;
}
export default class extends React.Component<PropsType, StateType> {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false
    };
  }

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  handleMouseClick = e => {
    e.preventDefault();
    e.stopPropagation();

    const { onClick, option } = this.props;
    if (onClick) {
      onClick(option);
    }
  };

  render() {
    const { hovered } = this.state;

    const { option, product } = this.props;

    return (
      <Root>
        <Container hovered={hovered}>
          <Wrapper onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} onClick={this.handleMouseClick}>
            {!option ? (
              <Svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" hovered={hovered}>
                <defs>
                  <rect id="prefix__a" width="80" height="80" x="0" y="0" rx="40" />
                </defs>
                <g fill="none" fillRule="evenodd">
                  <rect id="border" width="79" height="79" x=".5" y=".5" stroke="#BABBC0" rx="39.5" />
                  <path
                    id="icon"
                    fill="#BABBC0"
                    fillRule="nonzero"
                    d="M36.986 26c-.488 0-.923.297-1.111.75-.183.458-.075.977.277 1.321l.103.104-10.552 10.622c-.445.448-.703 1.07-.703 1.708 0 .637.258 1.26.703 1.708l12 12.08c.445.447 1.064.707 1.697.707s1.252-.26 1.697-.708l10.551-10.621.104.104c.3.316.75.443 1.167.33.422-.109.75-.439.858-.864.112-.42-.014-.872-.329-1.174l-15.6-15.704c-.225-.236-.534-.363-.862-.363zm.966 3.883l10.551 10.622H27.4l10.552-10.622zM52.6 47.753s-2.4 3.496-2.4 4.831C50.2 53.92 51.273 55 52.6 55s2.4-1.08 2.4-2.416c0-1.335-2.4-4.832-2.4-4.832z"
                  />
                </g>
              </Svg>
            ) : (
              <Color src={option.productDecorate.details.image} />
            )}
          </Wrapper>
        </Container>

        {!option ? (
          <Text>{I18n.t('order.productSettings.section.color.noColor')}</Text>
        ) : (
          <React.Fragment>
            <Text>{option.productDecorate.name}</Text>
            <Price>
              {I18n.t('common.price', { value: Math.abs(option.price.value) })}/{product.details.units}
            </Price>
          </React.Fragment>
        )}
      </Root>
    );
  }
}
