import React from 'react';
import styled from 'styled-components';
import { IMAGES } from '@src/assets/images';
import { breakpoints } from '@src/defaultStyles';

const Image = styled.img`
  width: 355px;
  height: 400px;

  @media (${breakpoints.tablet}) {
    width: 300px;
    height: 338px;
  }
  @media (${breakpoints.mobile}) {
    width: 200px;
    height: 225px;
  }
`;

const Watermark: React.FC<{ className?: any }> = ({ className }): JSX.Element | null => {
  return <Image className={className} src={IMAGES.SMART_CODEV_LOGO} />;
};

export default Watermark;
