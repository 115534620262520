import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';
import Text from '@src/components/common/Text';

const Label = styled(Text)`
  margin-bottom: 10px;

  font-family: ${fontFamily.bold};
  font-size: 14px;
  font-weight: ${fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 1px;
  color: ${colors.BATTLESHIP_GREY};

  text-transform: uppercase;

  ${props => props.styles}
`;

export { Label };

export default {
  Label
};
