import gql from 'graphql-tag';
import { mutation } from '@src/api/core/gql';
import { buildIdInput } from '@shared/api/brokrete/helper';

const inviteReject = async invite => {
  return mutation(inviteRejectMutation, {
    invite: buildIdInput(invite)
  }).then(({ inviteReject }) => inviteReject.success);
};

export default inviteReject;

const inviteRejectMutation = gql`
  mutation inviteReject($invite: IdInput!) {
    inviteReject(invite: $invite) {
      success
    }
  }
`;
