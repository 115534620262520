import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.resetPassword');

const query = gql`
  mutation ResetPassword($email: String, $phone: String, $token: String!, $password: String!) {
    contractorResetPassword(email: $email, phone: $phone, resetToken: $token, newPassword: $password) {
      success
    }
  }
`;

type ServerResponse = {
  contractorResetPassword: Response;
};

type Response = {
  success: boolean;
};

export default async (
  { email, phone, token, password }: { email?: string; phone?: string; token: string; password: string },
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      email,
      phone,
      token,
      password
    },
    options
  );

  return result.contractorResetPassword;
};
