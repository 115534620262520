// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD69ZwPnHc5GO3TEJ4EPj3MChF8MGsPqTA',
  authDomain: 'smartcodev-contact-us-form.firebaseapp.com',
  databaseURL: 'https://smartcodev-contact-us-form-default-rtdb.firebaseio.com',
  projectId: 'smartcodev-contact-us-form',
  storageBucket: 'smartcodev-contact-us-form.appspot.com',
  messagingSenderId: '369800882022',
  appId: '1:369800882022:web:c60834b6dff2ce3cf38aa7',
  measurementId: 'G-65TVBGSGTY'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const database = getDatabase(app);
