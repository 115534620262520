import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { ContractorMember, Identity } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.addIdentity');

const query = gql`
  mutation AddIdentity($input: [IdentityAddInput!]) {
    contractorUpdate(identity: { add: $input }) {
      success
      contractorMember {
        identities {
          ...identity
        }
      }
      addedIdentities {
        ...identity
      }
    }
  }
  ${fragments.identity}
`;

type ServerResponse = {
  contractorUpdate: Response;
};

type Response = {
  success: boolean;
  contractorMember: ContractorMember;
  addedIdentities: Array<Identity>;
};

export default async (
  { values }: { values: Array<{ email?: string; phone?: string; active: boolean }> },
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      input: values
    },
    options
  );

  return result.contractorUpdate;
};
