import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@src/components/common';
import I18n from 'i18next';
import { fontFamily, fontWeight, breakpoints } from '@src/defaultStyles';
import { ICONS } from '@src/assets/icons';

const ButtonSchedule = styled(Button)`
  border-radius: 5px;
  height: 40px;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: ${fontFamily.regular};
  font-weight: ${fontWeight.semibold};
  box-shadow: 0 2px 4px 0px rgba(100, 100, 100, 0.5);
  @media (${breakpoints.mobile}) {
    max-width: 191px;
  }
`;
const Icon = styled.img`
  margin-right: 10px;
`;

const ScheduleDemoButton: React.FC = (): JSX.Element | null => {
  const onScheduleDemoClick = useCallback(() => {
    window.open('https://calendly.com/smartcodevinc', '_blank').focus();
  }, []);

  return (
    <ButtonSchedule primary onClick={onScheduleDemoClick}>
      <Icon src={ICONS.SCHEDULE_DEMO_ICON} />
      {I18n.t('common.scheduleDemoButton')}
    </ButtonSchedule>
  );
};

export default ScheduleDemoButton;
