import React from 'react';

import { colors } from '@src/defaultStyles';

interface Props {
  className?: any;
  color?: string;
  width?: number;
  height?: number;
  onClick: (e: React.MouseEvent) => void;
}

const IconTwitter: React.FC<Props> = ({
  className,
  color = colors.BLACK,
  width = 21,
  height = 17,
  onClick
}): JSX.Element | null => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 21 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Brokrete_main_SELL" transform="translate(-150.000000, -8111.000000)" fill={color} fillRule="nonzero">
          <g id="Group-88-Copy-2" transform="translate(150.000000, 7652.000000)">
            <g id="Group-87" transform="translate(0.000000, 0.012568)">
              <g id="Group-23" transform="translate(0.000000, 420.871949)">
                <g id="Group-24" transform="translate(0.000000, 36.980426)">
                  <g id="ic_twitter" transform="translate(0.000000, 1.978181)">
                    <path
                      d="M20.1934268,2.15182132 C19.477682,2.46320166 18.7088359,2.67274231 17.9018468,2.7672554 C18.7260378,2.28370005 19.3580172,1.51807074 19.6556833,0.60517685 C18.8853415,1.05283188 18.0312343,1.37813275 17.1217822,1.55397106 C16.3940709,0.794203033 15.357475,0.319439596 14.2101892,0.319439596 C12.006862,0.319439596 10.2208656,2.06976344 10.2208656,4.22744604 C10.2208656,4.53369776 10.256765,4.83262289 10.3240764,5.11762748 C7.0086149,4.95497704 4.06934945,3.398808 2.10086429,1.03378273 C1.75832394,1.61111852 1.561625,2.28223473 1.561625,2.9995085 C1.561625,4.35492881 2.26540332,5.55136198 3.33565494,6.25178458 C2.68198624,6.23127011 2.06646067,6.0554318 1.5287172,5.76310061 C1.5287172,5.77995178 1.5287172,5.79533763 1.5287172,5.8121888 C1.5287172,7.70611394 2.90336603,9.28572809 4.72900139,9.64399864 C4.39468799,9.73338312 4.04167697,9.78100599 3.67744739,9.78100599 C3.42091608,9.78100599 3.170368,9.75609557 2.92729898,9.71140333 C3.43512626,11.2639091 4.90849851,12.3944029 6.65410807,12.4259072 C5.289182,13.4743432 3.56900121,14.0993018 1.69923947,14.0993018 C1.37764045,14.0993018 1.05978095,14.0809853 0.74715679,14.0443524 C2.51295978,15.1528664 4.60933664,15.7998048 6.86277348,15.7998048 C14.2012144,15.7998048 18.2129751,9.84474738 18.2129751,4.68022968 C18.2129751,4.51098531 18.2092356,4.3424736 18.2017566,4.17469454 C18.9818212,3.62300185 19.6586749,2.93503446 20.1934268,2.15182132 Z"
                      id="Path"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconTwitter;
