import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScrollList, ScrollContent, ScrollVertical, ScrollBar, Wrapper } from './styled';
import styles from './styles';

interface PropsType {
  cursorPosition?: any;
  children?: any;
  contentViewWidth?: number;
  contentViewHeight?: number;
  [x: string]: any;
}
interface StateType {
  contentHeight: number;
}
class ScrolledList extends React.Component<PropsType, StateType> {
  scrollBarMinHeight: number;
  contentRef: any;
  scrollBarRef: any;
  defaultProps: any;
  constructor(props) {
    super(props);

    this.state = {
      contentHeight: 0
    };

    this.scrollBarMinHeight = 48;
    this.contentRef = React.createRef();
    this.scrollBarRef = React.createRef();
  }

  handleOnScroll = () => {
    this.updateScroll();
  };

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.cursorPosition !== null) {
      if (prevProps.cursorPosition !== this.props.cursorPosition) {
        return this.props.cursorPosition;
      }
    }

    return null;
  }

  updateScroll = () => {
    const scrollElement = this.contentRef && this.contentRef.current;
    const scrollBar = this.scrollBarRef && this.scrollBarRef.current;

    if (scrollElement && scrollBar) {
      const scrollHeight = scrollElement.scrollHeight;
      const clientHeight = scrollElement.clientHeight;
      const scrollTop = scrollElement.scrollTop;
      const scale = scrollHeight / clientHeight;

      let scrollBarHeight = Math.round(clientHeight / scale);
      // if scrollbar height is less then the minimum possible
      if (scrollBarHeight < this.scrollBarMinHeight) {
        scrollBarHeight = this.scrollBarMinHeight;
      }

      const scrollPathLength = clientHeight - scrollBarHeight;
      // setting up the scrollbar height to zero if there is nothing to scroll
      scrollBar.style.height = (scrollBarHeight >= clientHeight ? 0 : scrollBarHeight) + 'px';
      scrollBar.style.top = Math.round((scrollPathLength * scrollTop) / (scrollHeight - clientHeight)) + 'px';
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      const scrollElement = this.contentRef && this.contentRef.current;
      // Assume that children have equal height
      const itemsCount = this.props.children.length;
      const itemHeight = itemsCount > 0 ? scrollElement.scrollHeight / itemsCount : 0;
      const top = itemHeight * snapshot;
      const bottom = top + itemHeight;

      const clientTop = scrollElement.scrollTop;
      const clientBottom = scrollElement.scrollTop + scrollElement.clientHeight;

      if (bottom > clientBottom) {
        const offsetY = bottom - clientBottom;
        scrollElement.scrollTop += offsetY;
      } else if (top < clientTop) {
        scrollElement.scrollTop = top;
      }
    }

    this.updateScroll();
  }

  render() {
    const { children, contentViewHeight, contentViewWidth } = this.props;

    const scrollListStyles = {
      // height: `${contentViewHeight}px`,
      maxHeight: `${contentViewHeight}px`,
      width: `${contentViewWidth}px`
    };

    const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { styles: styles.component }));

    return (
      <Wrapper className={'ScrolledListWrapper'}>
        <ScrollList ref={this.contentRef} style={scrollListStyles} onScroll={this.handleOnScroll}>
          <ScrollContent onScroll={this.handleOnScroll}>{childrenWithProps}</ScrollContent>
        </ScrollList>

        <ScrollVertical>
          <ScrollBar ref={this.scrollBarRef} />
        </ScrollVertical>
      </Wrapper>
    );
  }
}

export default ScrolledList;
