import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: ${props => (props.width ? `${props.width}px` : '30px')};
  max-width: ${props => (props.width ? `${props.width}px` : '30px')};
  min-height: ${props => (props.height ? `${props.height}px` : '30px')};
  max-height: ${props => (props.height ? `${props.height}px` : '30px')};

  border: 1px solid ${props => (props.checked ? colors.ALGAE_GREEN : colors.MIDLLE_GREY)};
  border-radius: ${props => (props.round ? '100%' : '5px')};

  background-color: ${props => (props.checked ? colors.ALGAE_GREEN : colors.WHITE)};

  box-sizing: border-box;

  ${props => props.styles}
`;

const Check = styled.img``;

export { Check, Root };

export default {
  Check,
  Root
};
