import { array, isEmpty } from '@shared/lib';
import { AddressObject } from './AddressObject';

export class FormattedAddress {
  public main = '';
  public secondary = '';

  constructor(formattedAddress: string, addressObject: AddressObject) {
    if (!isEmpty(addressObject.number) && formattedAddress.indexOf(addressObject.number) != 0) {
      this.main = array.compact([addressObject.street, addressObject.number]).join(', ');
    } else {
      this.main = array.compact([addressObject.number, addressObject.street]).join(' ');
    }

    this.secondary = array
      .compact([addressObject.city, array.compact([addressObject.state, addressObject.zip]).join(' '), addressObject.country])
      .join(', ');

    if (isEmpty(this.main)) {
      this.main = formattedAddress;
      this.secondary = '';
    }
  }
}
