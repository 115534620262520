import React from 'react';
import { Element, scroller } from 'react-scroll';
import MainTopScreen from '@src/components/common/MainTopScreen';
import ContactsScreen from '@src/components/common/FooterWithContacts';
import ReadyToStarted from '@src/components/common/FooterWithContacts/ReadyToStarted';
import Story from '@src/components/common/Story';
import { Header, ScheduleDemoButton, WatchVideoButton } from '@src/components/pages';
import { Content, PageWrapper } from './styled';
import LdJsonInclude from '@src/pages/LandingPage/LdJsonInclude';
import I18n from 'i18next';
import { IMAGES } from '@src/assets/images';
import { ContactUsButton, ServicesSection, TechnologiesSection } from '@src/components/common';
import TextAndImageSection from '@src/components/common/TextAndImageSection';
import ImageAndTextSection from '@src/components/common/ImageAndTextSection';
import { css } from 'styled-components';
import { breakpoints, colors } from '@src/defaultStyles';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import OrderManagement from '@src/components/common/OrderManagement';
import { ScrollSection } from '@src/pages/LandingPage/screens';

const LandingPage = () => {
  const brokreteInfo = 'brokreteInfo';
  const { tablet, mobile } = useMediaQuery();

  const completeManagementImage = tablet ? IMAGES.SMART_CODEV_LOGO : IMAGES.SMART_CODEV_LOGO;

  const showMore = () => {
    scroller.scrollTo(brokreteInfo, {
      duration: 800,
      delay: 100,
      smooth: true
    });
  };

  return (
    <PageWrapper>
      {/*<Helmet>*/}
      {/*  <script*/}
      {/*    src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_AIP_KEY}&libraries=geometry,places`}*/}
      {/*    type='text/javascript'*/}
      {/*  />*/}
      {/*</Helmet>*/}

      {/* <LdJsonInclude /> */}
      <Header light commerce />
      <Content>
        <MainTopScreen
          backgroundImage={IMAGES.LANDING_TOP_SCREEN}
          titleStyles={css`
            color: #eddfaf;
          `}
          subtitleStyles={css`
            color: ${colors.WHITE};
          `}
          news={{
            chip: I18n.t('landing.topScreen.headerIntroduceChip'),
            text: I18n.t('landing.topScreen.headerIntroduceText')
          }}
          title={I18n.t('landing.topScreen.title')}
          subtitle={I18n.t('landing.topScreen.subtitle')}
          footerContent={
            <>
              <ScheduleDemoButton />
              <ContactUsButton />
              <WatchVideoButton videoUrl={'https://www.youtube.com/watch?v=mgooqyWMTxk'} />
            </>
          }
        />
        <ScrollSection />
        <Element name={'landingServices'}>
          <ServicesSection id="OFFER_SERVICES" />
        </Element>
        <Element name={'landingTechnologies'}>
          <TechnologiesSection id="TECH_STACK" />
        </Element>

        <Element name={'landingContent1'}>
          <TextAndImageSection
            id="LANDING_CONTENT_1"
            /* pageHeading={I18n.t('landing.whiteLabelApp.title')} */
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content1.title')}
            sectionInfo={I18n.t('landing.content1.info')}
            /* sectionSubHeading={I18n.t('landing.content1.subheading')} */
            image={IMAGES.LANDING_CONTENT_1}
            videoUrl={'https://www.youtube.com/watch?v=8KaJRw-rfn8&t=13s'}
            displayLink
            linkTitle={I18n.t('landing.content1.linkTitle')}
            linkUrl={'services/custom-software-development'}
            imageHeightKoeff={0.9949}
            styles={css`
              padding-top: 20px;
              padding-bottom: 15px;

              @media (${breakpoints.tablet}) {
                padding: 120px 50px 60px 50px;
              }

              @media (${breakpoints.mobile}) {
                padding: 70px 20px 35px 20px;
              }
            `}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                max-width: 511px;

                img {
                  width: 112.5%;
                  left: -6%;
                  top: -6%;
                }
              }
              @media (${breakpoints.mobile}) {
                max-width: 233px;
              }
            `}
          />
        </Element>
        <Element name={'landingContent2'}>
          <ImageAndTextSection
            id="LANDING_CONTENT_2"
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content2.title')}
            sectionInfo={I18n.t('landing.content2.info')}
            image={IMAGES.LANDING_CONTENT_2}
            videoUrl={'https://www.youtube.com/watch?v=DUg2SWWK18I'}
            displayLink
            linkTitle={I18n.t('landing.content2.linkTitle')}
            linkUrl={'services/web-development'}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                //max-width: 67.5%;
                max-width: 451px;
                img {
                  width: 105%;
                }
              }
              @media (${breakpoints.mobile}) {
                //max-width: 96%;
                max-width: 283px;
              }
            `}
          />
        </Element>
        <Element name={'landingSection1'}>
          <OrderManagement
            id="LANDING_SECTION_1"
            src={IMAGES.LANDING_SECTION_1}
            title={I18n.t('landing.section1.title')}
            info={I18n.t('landing.section1.info')}
            backgroundColor={'#6e57d2'}
          />
        </Element>
        <Element name={'landingContent3'}>
          <TextAndImageSection
            id="LANDING_CONTENT_3"
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content3.title')}
            sectionInfo={I18n.t('landing.content3.info')}
            image={IMAGES.LANDING_CONTENT_3}
            videoUrl={'https://www.youtube.com/watch?v=M988_fsOSWo'}
            displayLink
            linkTitle={I18n.t('landing.content3.linkTitle')}
            linkUrl={'services/mobile-apps-development'}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                //max-width: 67%;
                max-width: 448px;
              }
              @media (${breakpoints.mobile}) {
                //max-width: 83.5%;
                max-width: 220px;
              }
            `}
          />
        </Element>
        <Element name={'landingContent4'}>
          <ImageAndTextSection
            id="LANDING_CONTENT_4"
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content4.title')}
            sectionInfo={I18n.t('landing.content4.info')}
            image={IMAGES.LANDING_CONTENT_4}
            videoUrl={'https://www.youtube.com/watch?v=5YDVJaItmaY&t=7s'}
            displayLink
            linkTitle={I18n.t('landing.content4.linkTitle')}
            linkUrl={'services/ecommerce-development'}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                //max-width: 67.5%;
                max-width: 451px;

                img {
                  width: 105%;
                }
              }
              @media (${breakpoints.mobile}) {
                //max-width: 96%;
                max-width: 283px;
              }
            `}
          />
        </Element>
        <Element name={'landingSection2'}>
          <OrderManagement
            id="LANDING_SECTION_2"
            src={IMAGES.LANDING_SECTION_2}
            title={I18n.t('landing.section2.title')}
            info={I18n.t('landing.section2.info')}
            backgroundColor={'#1a4f76'}
          />
        </Element>
        <Element name={'landingContent5'}>
          <TextAndImageSection
            id="LANDING_CONTENT_5"
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content5.title')}
            sectionInfo={I18n.t('landing.content5.info')}
            image={IMAGES.LANDING_CONTENT_5}
            videoUrl={'https://www.youtube.com/watch?v=SD9KnFsVKsQ'}
            displayLink
            linkTitle={I18n.t('landing.content5.linkTitle')}
            linkUrl={'services/product-development'}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                //max-width: 67%;
                max-width: 448px;
              }
              @media (${breakpoints.mobile}) {
                //max-width: 83.5%;
                max-width: 220px;
              }
            `}
          />
        </Element>
        <Element name={'landingContent6'}>
          <ImageAndTextSection
            id="LANDING_CONTENT_6"
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content6.title')}
            sectionInfo={I18n.t('landing.content6.info')}
            image={IMAGES.LANDING_CONTENT_6}
            videoUrl={'https://www.youtube.com/watch?v=u27gIg8Czhg'}
            displayLink
            linkTitle={I18n.t('landing.content6.linkTitle')}
            linkUrl={'services/cloud-application-development'}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                //max-width: 67.5%;
                max-width: 451px;

                img {
                  width: 105%;
                }
              }
              @media (${breakpoints.mobile}) {
                //max-width: 96%;
                max-width: 283px;
              }
            `}
          />
        </Element>
        <Element name={'landingSection3'}>
          <OrderManagement
            id="LANDING_SECTION_3"
            src={IMAGES.LANDING_SECTION_3}
            title={I18n.t('landing.section3.title')}
            info={I18n.t('landing.section3.info')}
            backgroundColor={'#6e57d2'}
          />
        </Element>
        <Element name={'landingContent7'}>
          <TextAndImageSection
            id="LANDING_CONTENT_7"
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content7.title')}
            sectionInfo={I18n.t('landing.content7.info')}
            image={IMAGES.LANDING_CONTENT_7}
            videoUrl={'https://www.youtube.com/watch?v=SD9KnFsVKsQ'}
            displayLink
            linkTitle={I18n.t('landing.content7.linkTitle')}
            linkUrl={'services/software-testing-qa'}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                //max-width: 67%;
                max-width: 448px;
              }
              @media (${breakpoints.mobile}) {
                //max-width: 83.5%;
                max-width: 220px;
              }
            `}
          />
        </Element>
        <Element name={'landingContent8'}>
          <ImageAndTextSection
            id="LANDING_CONTENT_8"
            chip={I18n.t('landing.topScreen.headerIntroduceChip')}
            chipText={I18n.t('landing.topScreen.headerIntroduceText')}
            sectionHeading={I18n.t('landing.content8.title')}
            sectionInfo={I18n.t('landing.content8.info')}
            image={IMAGES.LANDING_CONTENT_8}
            videoUrl={'https://www.youtube.com/watch?v=u27gIg8Czhg'}
            displayLink
            linkTitle={I18n.t('landing.content8.linkTitle')}
            linkUrl={'services/ui-ux-design'}
            imageWrapperStyles={css`
              @media (${breakpoints.tablet}) {
                //max-width: 67.5%;
                max-width: 451px;

                img {
                  width: 105%;
                }
              }
              @media (${breakpoints.mobile}) {
                //max-width: 96%;
                max-width: 283px;
              }
            `}
          />
        </Element>

        {/* <Story /> */}
        {/* <Preview /> */}

        {/*  <ReadyToStarted /> */}
        <Element name={'landingContactSection'}>
          <ContactsScreen id="LANDING_CONTACT_SECTION" />
        </Element>
      </Content>
    </PageWrapper>
  );
};

export default LandingPage;
