import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import loadScript from '@src/lib/loadScript';

// https://developers.google.com/identity/sign-in/web/reference
// https://github.com/google/google-api-javascript-client

declare global {
  interface Window {
    gapi: any;
  }
}

interface PropsType {
  clientId: string;
  scopes?: string;
  onFailure?: Function;
  onSuccess?: Function;
  scriptSrc?: any;
}
interface StateType {
  ready: boolean;
  contacts: Array<any>;
  loading: boolean;
  error: any;
  authData: any;
}
export default WrappedComponent => {
  class GoogleContactsWrapper extends Component<PropsType, StateType> {
    static defaultProps = {
      scriptSrc: 'https://apis.google.com/js/client.js'
    };
    state = {
      ready: false,
      contacts: [],
      loading: false,
      error: null,
      authData: null
    };

    componentDidMount() {
      const { scriptSrc, clientId, scopes } = this.props;

      loadScript(document, scriptSrc, () => {
        window.gapi.load('auth2', async () => {
          const params = {
            client_id: clientId,
            scope: scopes,
            immediate: false,
            uxMode: 'popup'
          };

          if (!window.gapi.auth2.getAuthInstance()) {
            try {
              const response = await window.gapi.auth2.init(params);
              const { isSignedIn, currentUser } = response;

              if (isSignedIn.get()) {
                this.handleSigninSuccess(currentUser.get());
              }
            } catch (e) {
              this.onFailure(e);
            }
          } else {
            this.signIn();
          }
        });
      });
    }

    signIn = async () => {
      const auth2 = window.gapi.auth2.getAuthInstance();
      let result = false;

      try {
        const options = {};

        const response = await auth2.signIn(options);
        this.handleSigninSuccess(response);
      } catch (e) {
        this.onFailure(e);
        result = false;
      }

      return result;
    };

    handleSigninSuccess = result => {
      const basicProfile = result.getBasicProfile();
      const authResponse = result.getAuthResponse();
      const googleId = basicProfile.getId();

      const profile = {
        googleId: googleId,
        imageUrl: basicProfile.getImageUrl(),
        email: basicProfile.getEmail(),
        name: basicProfile.getName(),
        givenName: basicProfile.getGivenName(),
        familyName: basicProfile.getFamilyName()
      };

      const auth = {
        tokenObj: authResponse,
        tokenId: authResponse.id_token,
        accessToken: authResponse.access_token
      };

      this.onSuccess({ googleId: googleId, profile, auth });
    };

    onSuccess = data => {
      this.setState({ authData: data });
    };

    onFailure = error => {
      console.log('error: ', error);

      this.setState({
        error: error
      });
    };

    fetchContacts = cb => {
      if (window.gapi.auth2.getAuthInstance()) {
        this.setState({ loading: true }, async () => {
          //TODO: move in a service
          // const requestUrl = 'https://www.google.com/m8/feeds/contacts/default/full?alt=json&access_token=' + this.state.authData.auth.accessToken + '&max-results=500&v=3.0'
          // const r = await fetch(requestUrl)

          window.gapi.client.load('https://people.googleapis.com/$discovery/rest', 'v1', () => {
            const request = window.gapi.client.people.people.connections.list({
              resourceName: 'people/me',
              pageSize: 500,
              'requestMask.includeField': 'person.email_addresses,person.names'
            });

            request.execute(({ connections }) => {
              this.setState(() => {
                return {
                  contacts: this.formatResponse(connections),
                  loading: false
                };
              }, cb);
            });
          });
        });
      }
    };

    formatResponse = connections => {
      return connections
        .map(({ names, emailAddresses }) => {
          let contactDetails = {};

          if (names && names.length > 0) {
            contactDetails = {
              ...contactDetails,
              fullName: names[0].displayName,
              firstName: names[0].familyName,
              lastName: names[0].givenName
            };
          }

          if (emailAddresses && emailAddresses.length > 0) {
            contactDetails = {
              ...contactDetails,
              email: emailAddresses[0].value
            };
          }

          return contactDetails;
        })
        .filter(contact => contact.email && contact.email !== '');
    };

    render() {
      const { contacts, loading, error, authData } = this.state;

      return (
        <WrappedComponent
          authorized={authData !== null}
          contacts={contacts}
          loading={loading}
          error={error}
          fetchContacts={this.fetchContacts}
          signIn={this.signIn}
        />
      );
    }
  }

  return GoogleContactsWrapper;
};
