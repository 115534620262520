import gql from 'graphql-tag';

import { DeliveryMethod, FeePrice, Order, Place, Plant, Product, ProductPrice } from '@shared/interfaces';

import * as fragments from '../fragments';

import { query, Options } from '@src/api/core/gql';
import { buildAddressInput, buildIdInput, buildProductPricesInput } from '../helper';
import { date } from '@shared/lib';

const gqlQuery = gql`
  query OrderPreparatoryConfig(
    $product: IdInput!
    $productPrices: [ProductPriceInput!]
    $address: AddressInput!
    $currency: String
    $deliveryTime: String
    $order: IdInput
    $deliveryMethod: String
    $plant: IdInput
    $pickup: Boolean
  ) {
    orderPreparatoryConfig(
      order: $order
      input: {
        category: $product
        address: $address
        productPrices: $productPrices
        deliveryTime: $deliveryTime
        plant: $plant
        pickup: $pickup
      }
    ) {
      fees(currency: $currency, deliveryMethod: $deliveryMethod) {
        fee {
          ...fee
        }
        price {
          ...price
        }
      }
    }
  }
  ${fragments.fee}
  ${fragments.price}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  fees: Array<FeePrice>;
};

type Props = {
  product: Product;
  productPrices: Array<ProductPrice>;
  address: Place;
  currency?: string;
  deliveryTime?: date.Date;
  order?: Order;
  deliveryMethod?: DeliveryMethod;
  plant: Plant;
  pickup: boolean;
};

export default async (
  { product, productPrices, address, currency, deliveryTime, order, deliveryMethod, plant, pickup }: Props,
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      product: buildIdInput(product),
      productPrices: buildProductPricesInput(productPrices),
      address: buildAddressInput(address),
      deliveryTime: deliveryTime ? deliveryTime.format() : undefined,
      order: buildIdInput(order),
      deliveryMethod: deliveryMethod ? deliveryMethod.name : undefined,
      plant: buildIdInput(plant),
      pickup,
      currency
    },
    options
  );

  return result.orderPreparatoryConfig;
};
