import React from 'react';
import { useHistory } from 'react-router';
import I18n from 'i18next';
import ItemLink from '@src/components/common/ItemLink';
type Props = {
  linkTitle: string;
  linkUrl: string;
};
const PageLink: React.FC<Props> = ({ linkTitle, linkUrl }): JSX.Element | null => {
  const history = useHistory();

  const goToPage = () => {
    history.push(`/${linkUrl}`);
  };

  return <ItemLink linkTitle={linkTitle} onLinkClick={goToPage} />;
};

export default PageLink;
