import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { City, Partner, Product } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';
import { buildIdInput } from '../helper';

const logger = logs.getLogger('api.brokrete.config');

const gqlQuery = gql`
  query OrderPreparatoryConfig($partner: IdInput) {
    orderPreparatoryConfig(input: {}, partner: $partner) {
      products {
        ...product
      }
      cities {
        id
        name
        country {
          id
          name
          code
          currency
        }
        address {
          id
          location {
            latitude
            longitude
          }
          mainText
          secondaryText
        }
      }
    }
  }
  ${fragments.product}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  products: Array<Product>;
  cities: Array<City>;
};

export default async ({ partner }: { partner?: Partner } = {}, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      partner: buildIdInput(partner)
    },
    options
  );

  return result.orderPreparatoryConfig;
};
