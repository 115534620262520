import React from 'react';
import styled from 'styled-components';
import sanitize from '@src/lib/sanitize';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { IMAGES } from '@src/assets/images';

const Root = styled.div`
  width: 100%;
  max-width: 526px;
  padding-top: 20px;
  padding-right: 20px;
  box-sizing: content-box;
  margin: 20px;

  @media (${breakpoints.mobile}) {
    display: grid;
    padding-right: 0;
  }
`;

const Content = styled.div`
  position: relative;
  cursor: pointer;
  padding: 30px 0px 30px 30px;
  box-shadow: 16px 16px 32px -12px rgba(156, 156, 156, 0.27);
  border-radius: 20px;
  background-color: #a7c7e7;
  @media (${breakpoints.mobile}) {
    display: grid;
    padding: 64px 0 20px 0;
  }
`;

const Info = styled.div`
  margin: 0px auto;
  margin-bottom: 30px;
  padding: 20px 90px 0 20px;
  font-size: 20px;
  font-family: ${fontFamily.thin};
  font-weight: ${fontWeight.light};
  color: ${colors.EBONY_CLAY};
  line-height: 1.6;

  @media (${breakpoints.mobile}) {
    font-size: 16px;
    padding: 0 23px;
    margin-bottom: 20px;
  }
`;
const Title = styled.div`
  margin: 0px auto;
  margin-bottom: 30px;
  padding: 20px 90px 0 20px;
  font-size: 30px;
  font-family: ${fontFamily.thin};
  font-weight: ${fontWeight.light};
  color: ${colors.EBONY_CLAY};
  line-height: 1.6;

  @media (${breakpoints.mobile}) {
    font-size: 16px;
    padding: 0 23px;
    margin-bottom: 20px;
  }
`;

const Author = styled.div`
  margin: 0px auto;
  margin-left: 25px;
  font-size: 18px;
  font-weight: ${fontWeight.bold};
  color: ${colors.EBONY_CLAY};

  @media (${breakpoints.mobile}) {
    margin: 0 auto;
  }
`;

const Description = styled.div`
  max-width: 350px;
  margin-left: 25px;
  margin-bottom: 25px;
  font-size: 15px;
  font-family: ${fontFamily.regular};
  font-weight: ${fontWeight.normal};
  color: ${colors.PALE_SKY};

  @media (${breakpoints.mobile}) {
    font-size: 14px;
    margin: 0 auto;
  }
`;

const Icon = styled.img`
  position: absolute;
  top: -20px;
  right: -20px;

  @media (${breakpoints.mobile}) {
    right: unset;
    justify-self: center;
  }
`;

const LinkArrowStyled = styled.img`
  width: 10%;
  position: absolute;
  bottom: 30px;
  right: 30px;

  @media (${breakpoints.mobile}) {
    position: relative;
    bottom: unset;
    right: unset;
    margin: 20px auto 0;
    width: 37px;
    height: 32px;
  }
`;

const ServicesItem = ({ onClick, image, title, text, author, position }) => {
  return (
    <Root>
      <Content onClick={onClick}>
        <Icon src={image} width={83} height={83} />
        <Title dangerouslySetInnerHTML={sanitize(title)} />
        <Info dangerouslySetInnerHTML={sanitize(text)} />
        <Author dangerouslySetInnerHTML={sanitize(author)} />
        <Description dangerouslySetInnerHTML={sanitize(position)} />
        <LinkArrowStyled src={IMAGES.LINK_ARROW} />
      </Content>
    </Root>
  );
};

export default ServicesItem;
