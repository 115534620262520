export type RoundOptions = {
  factor?: number;
  math?: boolean;
};

export function round<T>(value: number, options: RoundOptions = {}): number {
  const { factor = 2, math = false } = options;

  const method = math ? Math.round : Math.floor;
  return method(value * Math.pow(10, factor)) / Math.pow(10, factor);
}

export function extractFactor(value: number): number {
  for (let i = 0; i < 6; i++) {
    if (value === round(value, { factor: i })) {
      return i;
    }
  }

  return 6;
}
