import React, { useCallback } from 'react';
import ReactFlagsSelect from 'react-flags-select';

interface Props {
  field: { name: string };
  form: { setFieldValue: Function };
  initialCountry: string;
  countries: Array<string>;
  showSelectedLabel?: false;
  [x: string]: any;
}
const CountrySelect: React.FC<Props> = ({
  field,
  form: { setFieldValue },
  initialCountry,
  countries,
  showSelectedLabel = false
}): JSX.Element | null => {
  const { name } = field;

  const onSelectCountry = useCallback(
    country => {
      setFieldValue(name, country);
    },
    [name, setFieldValue]
  );

  return (
    <ReactFlagsSelect
      selected={initialCountry}
      countries={countries}
      showSelectedLabel={showSelectedLabel}
      selectedSize={20}
      optionsSize={20}
      onSelect={onSelectCountry}
    />
  );
};

CountrySelect.defaultProps = {
  initialCountry: 'CA'
};

export default CountrySelect;
