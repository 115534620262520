import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import composeValidators from 'lib/composeValidators';
import { email, required, notEmpty } from 'lib/validators';
import { Button } from 'components/common';
import { formikForm, FileInput, TextInput, TextAreaInput } from 'components/common/forms';
import { colors, fontFamily, fontWeight } from 'defaultStyles';

function ApplicationForm({ handleSubmit, isSubmitting, isValid, dirty, ...restProps }) {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} {...restProps}>
      <Field
        name="fullName"
        id="fullName"
        label={t('careerPosition.applicationForm.input.fullName.label')}
        placeholder={t('careerPosition.applicationForm.input.fullName.placeholder')}
        component={TextInput}
        validate={required}
      />

      <Field
        name="email"
        id="email"
        label={t('careerPosition.applicationForm.input.email.label')}
        placeholder={t('careerPosition.applicationForm.input.email.placeholder')}
        component={TextInput}
        validate={composeValidators(email, required)}
      />

      <Field
        name="message"
        id="message"
        label={t('careerPosition.applicationForm.input.coverLetter.label')}
        placeholder={t('careerPosition.applicationForm.input.coverLetter.placeholder')}
        component={TextAreaInput}
        validate={required}
      />

      {/*<Field*/}
      {/*  name="attachments"*/}
      {/*  id="attachments"*/}
      {/*  label={t('careerPosition.applicationForm.input.attachment.label')}*/}
      {/*  placeholder={t('careerPosition.applicationForm.input.attachment.placeholder')}*/}
      {/*  component={FileInput}*/}
      {/*  validate={notEmpty}*/}
      {/*/>*/}

      <SubmitButton type="submit" primary disabled={isSubmitting || !isValid || !dirty}>
        {t('careerPosition.applicationForm.button.apply')}
      </SubmitButton>
    </Form>
  );
}

export default formikForm(ApplicationForm, {
  initialValues: {
    fullName: '',
    email: '',
    message: '',
    attachments: []
  }
});

const Form = styled.form`
  display: flex;
  flex-direction: column;

  input {
    background-color: ${colors.VERY_LIGHT_PINK};
  }

  label {
    color: ${colors.CHARCOAL_GREY};
    font-family: ${fontFamily.bold};
    font-weight: ${fontWeight.bold};
  }

  textarea {
    min-height: 120px;
    height: 120px;
    background-color: ${colors.VERY_LIGHT_PINK};
  }
`;

const SubmitButton = styled(Button)`
  margin-left: auto;
  max-width: 180px;
  box-sizing: content-box;
  width: 100%;

  ${props => {
    return props.disabled
      ? `
      pointer-events: none;
      background-color: ${colors.CLOUDY_BLUE}
      color: ${colors.WHITE};
    `
      : '';
  }}
`;
