import styled from 'styled-components';
import { Text } from '@src/components/common';
import { fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  display: flex;
  align-items: center;

  ${props => props.styles}
`;

const TextCounter = styled(Text)`
  display: inline-block;
  width: 50px;

  font-family: ${fontFamily.bold};
  font-size: 18px;
  font-weight: ${fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.2px;
  text-align: center;
`;

export { Root, TextCounter };

export default {
  Root,
  TextCounter
};
