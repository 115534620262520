import React from 'react';
import { Content, PageHeading, RightBlock, Footer, Root, PageContent, FooterItem } from './styled';
import sanitize from '@src/lib/sanitize';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { ChipedText } from '@src/components/common';
import { colors } from '@src/defaultStyles';

const MainTopScreen: React.FC<{
  styles?: any;
  titleStyles?: any;
  subtitleStyles?: any;
  backgroundImage: string[];
  news: {
    chip: string;
    text: string;
  };
  title: string;
  subtitle: string;
  footerContent: JSX.Element;
  footerStyles?: any;
}> = ({
  styles,
  titleStyles,
  subtitleStyles,
  backgroundImage,
  news,
  title,
  subtitle,
  footerContent,
  footerStyles
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { mobile } = useMediaQuery();

  const renderNewsSection = () => {
    if (news) {
      const { chip, text } = news;

      return <ChipedText chip={chip} text={text} dark textColor={colors.WHITE} />;
    }

    return null;
  };

  if (mobile) {
    return (
      <Root backgroundImage={backgroundImage} styles={styles}>
        <Content>
          <div className="header">
            {/* {renderNewsSection()} */}

            <PageHeading titleStyles={titleStyles} tag="h1" dangerouslySetInnerHTML={sanitize(title)} />
            <PageContent subtitleStyles={subtitleStyles} tag="p" dangerouslySetInnerHTML={sanitize(subtitle)} />

            <Footer styles={footerStyles}>
              <FooterItem>{footerContent}</FooterItem>
            </Footer>
          </div>
          <div className="footer" />
        </Content>
      </Root>
    );
  }

  return (
    <Root backgroundImage={backgroundImage} styles={styles}>
      <Content>
        <RightBlock>
          {renderNewsSection()}

          <PageHeading titleStyles={titleStyles} tag="h1" dangerouslySetInnerHTML={sanitize(title)} />
          <PageContent subtitleStyles={subtitleStyles} tag="p" dangerouslySetInnerHTML={sanitize(subtitle)} />
          <Footer styles={footerStyles}>{footerContent}</Footer>
        </RightBlock>
      </Content>
    </Root>
  );
};

export default MainTopScreen;
