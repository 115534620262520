import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';

export const OrderManagementFlow = ['1', '2', '3', '4', '5', '6', '7'];

export const ProductTypes = [
  {
    type: 'readyMix',
    image: IMAGES.SMART_CODEV_LOGO
  }
];

export const workWithUsAdvantages = ['6', '7', '8', '9'];

export const whatPeopleSayQuotes = [
  {
    author: 'Torus Construction Inc.',
    quote:
      "Brokrete let me order and manage my order's at anytime of the day. I don't need to do it during my working hours and be on the rush to prepare my next day. It's simply more efficient."
  },
  {
    author: ' Loren construction inc.',
    quote:
      "Even after shopping around, I've came back to Brokrete. Their prices are competitive, and I always know I will be able to get concrete delivered on site. No more stress of wondering if I will be able to have a truck or not, and all is done from an app."
  }
];

export const Partners = [
  {
    name: 'Kleverland',
    logo: IMAGES.SMART_CODEV_LOGO
  }
];

export const CompanyPhoneNumber = '+1 647 375 7176';
export const CompanyEmail = 'orders@brokrete.com';
export const CompanySalesEmail = 'info@smartcodev.com';
export const CompanyTorontoAddress = 'Toronto : 215 Spadina Avenue, ON M5T 2C7';
export const CompanyOttawaAddress =
  'Ottawa-Gatineau :  Université du Québec en Outaouais - 283 Boul Alexandre-Taché, QC, J9A 1L8, Office F-3025';

export const Addresses = {
  Toronto: {
    location: 'Thornhill',
    address: '314 John Street, ON, L3T 0A7'
  }
};

export const RealStories = [
  {
    logo: `https://brokrete.sfo2.digitaloceanspaces.com/resource/partner/ethier_sandandgravel/logo_ae24a75f42f5e20e2bec444f1bc3d30b.png`,
    message:
      '“To quickly start my startup landing page design, I was looking for a landing page UI Kit. Landify is one of the best landing page UI kit I have come across. It’s so flexible, well organised and easily editable.”',
    author: 'Jane Cooper',
    position: 'CEO at ABC Corporation',
    width: '100'
  },
  {
    logo: `https://brokrete.sfo2.digitaloceanspaces.com/resource/partner/ethier_sandandgravel/logo_ae24a75f42f5e20e2bec444f1bc3d30b.png`,
    message:
      '“I used Brokrete and created my startup within a week. The Brokrete UI Kit is simple and highly intuitive, so anyone can use it.”',
    author: 'Jane Cooper',
    position: 'CEO at ABC Corporation',
    width: '100'
  }
];

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const GOOGLE_API_SCOPES = process.env.REACT_APP_GOOGLE_API_SCOPES;
export const GOOGLE_AIP_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const GOOGLE_STORE_LINK = process.env.REACT_APP_GOOGLE_STORE_LINK;
export const APPLE_STORE_LINK = process.env.REACT_APP_APPLE_STORE_LINK;

export const FACEBOOK_LINK = process.env.REACT_APP_FACEBOOK_LINK;
export const TWITTER_LINK = process.env.REACT_APP_TWITTER_LINK;
export const MESSENGER_LINK = process.env.REACT_APP_MESSENGER_LINK;

export const ORDER_BROKRETE_URL = process.env.REACT_APP_ORDER_BROKRETE;
export const CONTACT_US_URL = process.env.REACT_APP_CONTACT_URL;

export const API_URL = process.env.REACT_APP_API;

export const DEFAULT_PARTNER_CODE = 'brokrete';
