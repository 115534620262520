import { Place } from '../../../../interfaces';

import { AddressComponent, AddressObject } from '../../lib/AddressObject';
import { FormattedAddress } from '../../lib/FormattedAddress';

interface Result {
  place_id: string;

  address_components: Array<AddressComponent>;

  formatted_address: string;

  geometry: {
    location: {
      lat: () => number | number;
      lng: () => number | number;
    };
  };

  types: Array<string>;
}

export class DetailsPlaceAdapter {
  private placeId: string;
  private location: { latitude: number; longitude: number };

  private mainText: string;
  private secondaryText: string;

  private state: string;
  private country: string;
  private city: string;
  private zip: string;

  public constructor() {}

  public from(result: Result): DetailsPlaceAdapter {
    this.placeId = result.place_id;
    this.location = {
      latitude: typeof result.geometry.location.lat == 'function' ? result.geometry.location.lat() : result.geometry.location.lat,
      longitude: typeof result.geometry.location.lng == 'function' ? result.geometry.location.lng() : result.geometry.location.lng
    };

    const addressObject = new AddressObject(result.address_components);
    const formattedAddress = new FormattedAddress(result.formatted_address, addressObject);

    this.mainText = formattedAddress.main;
    this.secondaryText = formattedAddress.secondary;
    this.state = addressObject.state;
    this.country = addressObject.country;
    this.city = addressObject.city;
    this.zip = addressObject.zip;

    return this;
  }

  public toData(): Place {
    return {
      id: this.placeId,
      mainText: this.mainText,
      secondaryText: this.secondaryText,
      location:
        this.location != null
          ? {
              latitude: this.location.latitude,
              longitude: this.location.longitude
            }
          : null,
      state: this.state,
      country: this.country,
      city: this.city,
      zip: this.zip
    };
  }
}
