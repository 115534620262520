import React from 'react';
import styled from 'styled-components';
import Label from '@src/components/common/controls/Label';
import { ErrorMessage } from '@src/components/common/index';
import TextArea from '@src/components/common/controls/TextArea';

interface Props {
  id: any;
  field: { name: string };
  form: { errors?: any; touched: boolean };
  label: string;
  hidden: boolean;
  [x: string]: any;
}

const TextAreaInput: React.FC<Props> = ({
  id,
  field,
  form: { errors, touched },
  label,
  hidden,
  ...restProps
}): JSX.Element | null => {
  const { name } = field;
  const invalid = errors[name] && touched[name];

  return (
    <InputGroup>
      <Label htmlFor={id}>
        {label}
        {invalid && <ErrorMessage message={errors[name]} />}
      </Label>
      <TextArea name={name} id={id} hidden={hidden} {...field} {...restProps} />
    </InputGroup>
  );
};

export default TextAreaInput;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;
