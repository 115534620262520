import React from 'react';
import styled from 'styled-components';
import { Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

type Props = {
  title: string;
  items: Array<any>;
};
const ListComponent: React.FC<Props> = ({ title, items }): JSX.Element | null => {
  return (
    <Root>
      {title && <Title>{title}</Title>}

      <List>
        {items.map((item, idx) => {
          return <ListItem key={idx}>{item}</ListItem>;
        })}
      </List>
    </Root>
  );
};

export default ListComponent;

const Root = styled.div`
  margin-bottom: 50px;
`;

const Title = styled(Text)`
  font-size: 24px;
  font-family: ${fontFamily.extraBold};
  font-weight: ${fontWeight.extraBold};

  margin-bottom: 20px;
`;

const List = styled.ul`
  margin: 0;
`;

const ListItem = styled.li`
  font-size: 16px;
  color: ${colors.CHARCOAL_GREY};

  padding-left: 20px;

  @media (${breakpoints.tablet}) {
    padding-left: 10px;
  }

  @media (${breakpoints.mobile}) {
    padding-left: 0px;
  }
`;
