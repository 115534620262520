import styled from 'styled-components';
import ContentContainer from '@src/components/common/ContentContainer';
import RootContainer from '@src/components/common/RootContainer';
import { Heading, Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled(RootContainer)`
  position: relative;
  background-color: ${colors.WHITE};
  box-sizing: border-box;
  padding-top: 160px;
  padding-bottom: 75px;

  @media (${breakpoints.tablet}) {
    padding: 120px 0px;
  }

  @media (${breakpoints.mobile}) {
    padding-top: 80px;
    padding-bottom: 70px;
  }
`;
const Content = styled(ContentContainer)`
  padding-top: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (${breakpoints.mobile}) {
    padding: 0px 20px;
  }
`;
const PageHeading = styled(Heading)`
  font-size: 42px;
  font-family: ${fontFamily.semiBold};
  text-align: center;
  color: ${colors.BLACK};
  margin-bottom: 120px;
  line-height: 64px;
  @media (${breakpoints.tablet}) {
    margin-bottom: 65px;
  }
  @media (${breakpoints.mobile}) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 60px;
  }
`;

const RightBlock = styled.div`
  padding-left: 60px;

  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`;

const LeftBlock = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`;
const HeaderIntroduce = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  background-color: ${colors.MERCURY};
  padding: 1px 2px;
  max-width: 565px;
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: 45px;
  margin-top: -50px;

  @media (${breakpoints.mobile}) {
    padding: 10px 15px;
    border-radius: 40px;
    margin-bottom: 20px;
    margin-top: 50px;
  }
`;

const FooterIntroduce = styled.div`
  display: flex;
  margin-top: 35px;
`;

const HeaderIntroduceText = styled.div`
  margin: 0px 5px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  font-size: 14px;
  color: ${colors.BLACK};
  padding-left: 10px;

  @media (${breakpoints.mobile}) {
    line-height: 1.2;
  }
`;

const HeaderIntroduceChip = styled.div`
  background-color: ${colors.ALGAE_GREEN};
  padding: 1px 10px;
  border-radius: 50px;
`;

const SectionInfo = styled(Text)`
  display: block;
  text-align: left;
  max-width: 500px;
  font-size: 20px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  margin-bottom: 10px;
  color: ${colors.BLACK};
  line-height: 1.5;

  @media (${breakpoints.tablet}) {
    font-size: 20px;
    text-align: center;
    align-self: center;
    max-width: 600px;
    width: 100%;
  }

  @media (${breakpoints.mobile}) {
    text-align: center;
    font-size: 18px;
    width: 100%;
  }
`;

const ImageWrapper = styled.div`
  //padding: 0 10px;
  //text-align: left;
`;

const ImageWrapper1 = styled.div`
  width: 285px;
  height: 587px;
`;

const ImageWrapper2 = styled.div``;

const Image1 = styled.img`
  position: relative;
  top: -52px;
  left: -55px;
  width: 393px;
  height: 695px;
  z-index: 2;
`;
const Image2 = styled.img`
  position: relative;
  top: 55px;
  left: -25px;
`;

const ImageTitle = styled(Text)`
  //position: relative;
  //top: 140px;
  //right: 150px;
  width: 547px;
  font-size: 20px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  color: ${colors.GRAY};
  line-height: 1.5;
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
`;

export {
  ImageWrapper,
  Image1,
  Image2,
  Root,
  Content,
  SectionInfo,
  PageHeading,
  RightBlock,
  LeftBlock,
  HeaderIntroduce,
  FooterIntroduce,
  HeaderIntroduceChip,
  HeaderIntroduceText,
  ImageTitle,
  ImageContainer,
  ImageWrapper1,
  ImageWrapper2
};
