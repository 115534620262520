import { Query } from '../Query';

export class QueryAutoComplete extends Query {
  /**
   * The text input specifying which place to search for
   */
  public input: string;

  public location: string;

  public radius: number;

  public type: string;

  /**
   * Optional parameters
   */
  public options: {
    language?: string;
    offset?: number;
    radius?: number;
    location?: string;
    type?: string;
  } = {};

  public constructor(initialParams: Partial<QueryAutoComplete>) {
    super(initialParams);
    this.name = 'autocomplete';

    Object.assign(this, initialParams);
  }
}
