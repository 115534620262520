import styled from 'styled-components';
import { breakpoints, colors } from '@src/defaultStyles';
import RootContainer from '@src/components/common/RootContainer';

const Root = styled(RootContainer)`
  background-color: ${colors.DARK_APPROAX_NERO_GRAY};
  background: rgb(34, 34, 34);
  background: linear-gradient(138deg, rgba(34, 34, 34, 1) 0%, rgba(42, 42, 42, 1) 16%);
  color: ${colors.WHITE};
  width: 100vw;
  display: flex;
  justify-content: center;
  @media (${breakpoints.mobile}) {
    flex-direction: column;
    /* padding-left: 0; */
  }
`;
const Content = styled.div`
  display: flex;

  justify-content: space-between;
  align-items: center;
  max-width: 1300px;
  width: 100%;
`;
const TextSection = styled.div`
  flex-grow: 1;
  max-width: 50%;
  cursor: pointer;
  border-left: 1px solid ${colors.GRAY};
`;
const TextItem = styled.div`
  padding-left: 50px;
  margin-top: 50px;
  color: ${colors.GRAY};
  padding-bottom: 10px;
  border-left: ${({ active }) => (active ? `4px solid ${colors.LIGHT_BLUE_2}` : '4px solid transparent')};
  transition: 0.4s;
  color: ${({ active }) => (active ? colors.WHITE : colors.GRAY)};
  :first-child {
    margin-top: 0;
    padding-top: 10px;
    margin-left: -3px;
  }
`;
const TextTitle = styled.div`
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
`;
const TextInfo = styled.div``;
const SliderContainer = styled.div`
  width: 40%;
  /* flex-grow:1; */
`;
const SliderItem = styled.div`
  width: 100%;
  @media (${breakpoints.mobile}) {
    text-align: center;
  }
`;
const SliderImage = styled.img`
  width: 100%;
  @media (${breakpoints.mobile}) {
    margin-bottom: 30px;
  }
`;
const TitleMobile = styled.div`
  font-size: 33px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`;

export { Root, Content, TextSection, TextItem, TextTitle, TextInfo, SliderContainer, SliderItem, SliderImage, TitleMobile };
