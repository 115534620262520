import I18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import { initReactI18next } from 'react-i18next';

import locales from '../assets/locales';

I18n.use(initReactI18next)
  .use(intervalPlural)
  .init({
    resources: {
      ...locales
    },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

export default I18n;
