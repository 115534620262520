import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const MenuItem: React.FC<{
  to: string;
  label: any;
  exact?: boolean;
  className?: string;
  isExternal?: boolean;
  light?: boolean;
  onClick?: Function;
}> = ({ to, label, exact, className, isExternal, light, onClick }): JSX.Element | null => {
  const match = useRouteMatch({
    path: to,
    exact: exact
  });

  return (
    <Root className={className}>
      {isExternal ? (
        <NativeLinkStyled href={to} active={match} light={light} onClick={onClick}>
          {label}
        </NativeLinkStyled>
      ) : (
        <LinkStyled to={to} active={match} light={light} onClick={onClick}>
          {label}
        </LinkStyled>
      )}
    </Root>
  );
};

export default MenuItem;

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  isExternal: PropTypes.bool,
  light: PropTypes.bool
};

MenuItem.defaultProps = {
  exact: true,
  isExternal: false,
  light: false
};

const Root = styled.div``;

const LinkStyled = styled(Link)`
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.semibold};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${props => (props.active ? '#f9d62e' : props.light ? colors.WHITE : colors.CHARCOAL_GREY)};
  text-decoration: none;
  &:hover {
    color: #e56024;
  }
`;

const NativeLinkStyled = styled.a`
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.semibold};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;

  color: ${({ active, light }) => (active ? colors.ALGAE_GREEN : light ? colors.WHITE : colors.CHARCOAL_GREY)};
  text-decoration: none;
`;
