import styled from 'styled-components';

const Root = styled.div`
  position: relative;
`;

const Submenu = styled.div`
  position: absolute;
  top: 0;
  right: -280px;
  width: 280px;
`;

export { Root, Submenu };
