import styled from 'styled-components';
import { Text } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;

  border-radius: 4px;
  border: 2px solid ${props => (props.checked ? colors.ALGAE_GREEN : colors.WHITE)};
  background-color: ${colors.WHITE};

  box-sizing: border-box;
  cursor: pointer;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    border: 2px solid ${colors.ALGAE_GREEN};
  }

  ${props => props.styles}
`;

const Title = styled(Text)`
  flex: 1;
  font-family: ${fontFamily.semiBold};
  font-size: 18px;
  font-weight: ${fontWeight.semibold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
`;

export { Root, Title, Icon };

export default {
  Root,
  Title,
  Icon
};
