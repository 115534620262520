import { css } from 'styled-components';

const selectedItemStyles = css`
  cursor: pointer;
`;

const alignRight = css`
  position: absolute;
  top: 0;
  right: calc(-100% - 5px);
`;

const menuMarginLeft = css`
  margin-left: 5px;
`;

const arrow = css`
  transition-property: transform;
  transition-duration: 0.3s;
`;

const arrowRight = css`
  transform: rotate(-90deg);
`;

const arrowRightOpened = css`
  transform: rotate(90deg);
`;

const arrowDownOpened = css`
  transform: rotate(180deg);
`;

const menuMarginTop = css`
  margin-top: 8px;
`;

const menu = css`
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export default {
  selectedItemStyles,
  menu,
  alignRight,
  menuMarginLeft,
  menuMarginTop,
  arrowRight,
  arrow,
  arrowRightOpened,
  arrowDownOpened
};
