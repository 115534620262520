import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { Contractor, ContractorCompanyInfo, ContractorInfo, ContractorMember, ContractorMemberInfo } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.updateInfo');

const query = gql`
  mutation UpdateInfo($info: ContractorUpdateInfoInput, $companyInfo: ContractorUpdateCompanyInfoInput) {
    contractorUpdate(info: $info, companyInfo: $companyInfo) {
      success
      contractor {
        info {
          type
          additional
          country {
            id
            name
            code
            currency
          }
        }
        companyInfo {
          name
          logo
          address
        }
        account {
          ...account
        }
        owner {
          ...member
        }
      }
      contractorMember {
        ...contractorMember
      }
    }
  }
  ${fragments.account}
  ${fragments.member}
  ${fragments.contractorMember}
`;

type ServerResponse = {
  contractorUpdate: Response;
};

type Response = {
  success: boolean;
  contractor: Contractor;
  contractorMember: ContractorMember;
};

type Props = {
  info?: Partial<ContractorInfo & Omit<ContractorMemberInfo, 'avatar'>>;
  companyInfo?: Partial<Omit<ContractorCompanyInfo, 'logo'>>;
};

export default async (props: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      ...props
    },
    options
  );

  return result.contractorUpdate;
};
