import { google } from '../../core';
import core from '../../../core';
import { Location, Place } from '../../interfaces';

export async function byLocation({ position, type }: { position?: Location; type?: string }): Promise<Array<Place>> {
  try {
    if (position == null) {
      position = await core.geolocation.getCurrentPosition();
    }

    if (position != null) {
      return await google.geocode.byLocation({
        location: {
          latitude: position.latitude,
          longitude: position.longitude
        },
        resultType: type
      });
    }
  } catch (e) {}

  return [];
}
