import React, { useCallback } from 'react';
import styled from 'styled-components';
import Label from '@src/components/common/controls/Label';
import { ErrorMessage } from '@src/components/common/index';
import PhoneInput from '@src/components/PhoneNumberInput/PhoneNumberInput';

interface PhoneNumberInputProps {
  id: any;
  field: { name: string; value: string; onBlur: Function };
  form: { errors: any; touched: boolean; setFieldValue: Function };
  label: string;
  disabled?: boolean;
  [x: string]: any;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  id,
  field,
  form: { errors, touched, setFieldValue },
  label,
  disabled,
  ...restProps
}): JSX.Element | null => {
  const { name, value, onBlur } = field;
  const invalid = errors[name] && touched[name];

  const onChange = useCallback(
    value => {
      setFieldValue(name, value);
    },
    [name, setFieldValue]
  );

  return (
    <InputGroup>
      <Label htmlFor={id}>
        {label}
        {invalid && <ErrorMessage message={errors[name]} />}
      </Label>
      <PhoneInputStyled>
        <PhoneInput
          id={name}
          name={name}
          initialCountry={'CA'}
          countries={['CA', 'US', 'AU']}
          country="ca"
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          {...restProps}
        />
      </PhoneInputStyled>
    </InputGroup>
  );
};

export default PhoneNumberInput;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const PhoneInputStyled = styled.div`
  .react-tel-input {
    height: 50px;
  }

  .flag-dropdown {
    width: 80px;

    .selected-flag {
      width: 100%;
    }
  }

  #phone-form-control {
    width: calc(100% - 80px);
    height: 100%;
    margin-left: 80px;
    padding-left: 10px;

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
  }
`;
