import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { Contractor, Partner } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, Options } from '@src/api/core/gql';
import { buildIdInput } from '../helper';

const logger = logs.getLogger('api.brokrete.updateInfo');

const query = gql`
  mutation ContractorPartnerUpdate($partner: IdInput!, $selected: Boolean!) {
    contractorPartnerUpdate(partner: $partner, selected: $selected) {
      success
      contractor {
        savedPartners {
          ...partner
        }
      }
    }
  }
  ${fragments.partner}
`;

type ServerResponse = {
  contractorPartnerUpdate: Response;
};

type Response = {
  success: boolean;
  contractor: Contractor;
};

type Props = {
  partner: Partner;
  selected: boolean;
};

export default async (props: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      partner: buildIdInput(props.partner),
      selected: props.selected
    },
    options
  );

  return result.contractorPartnerUpdate;
};
