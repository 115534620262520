import styled, { css } from 'styled-components';
import { breakpoints } from '@src/defaultStyles';

const MarkerList = styled.div`
  margin-bottom: 20px;

  @media (${breakpoints.tablet}) {
    margin: 0px auto 20px auto;
  }

  @media (${breakpoints.mobile}) {
    margin: 0 0 30px 0;
  }
`;

export default MarkerList;
