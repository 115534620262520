import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';

export const WebDevelopmentTechStackConfig = [
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.0'),
    techImageSrc: IMAGES.REACT_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.1'),
    techImageSrc: IMAGES.ANGULAR_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.2'),
    techImageSrc: IMAGES.VUE_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.3'),
    techImageSrc: IMAGES.NODEJS_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.4'),
    techImageSrc: IMAGES.DJANGO_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.5'),
    techImageSrc: IMAGES.RUBY_ON_RAILS_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.6'),
    techImageSrc: IMAGES.LARAVEL_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.7'),
    techImageSrc: IMAGES.ASP_NET_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.8'),
    techImageSrc: IMAGES.JAVA_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.9'),
    techImageSrc: IMAGES.MY_SQL_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.10'),
    techImageSrc: IMAGES.POSTGRE_SQL_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.11'),
    techImageSrc: IMAGES.MONGO_DB_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.12'),
    techImageSrc: IMAGES.ORACLE_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.13'),
    techImageSrc: IMAGES.AWS_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.14'),
    techImageSrc: IMAGES.GOOGLE_CLOUD_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.15'),
    techImageSrc: IMAGES.AZURE_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.16'),
    techImageSrc: IMAGES.KUBERNETES_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.17'),
    techImageSrc: IMAGES.DOCKER_LOGO
  },
  {
    techTitle: I18n.t('services.webDevelopment.techStack.itemTitle.18'),
    techImageSrc: IMAGES.JENKINS_LOGO
  }
];
export const MobileAppsDevelopmentTechStackConfig = [
  {
    techTitle: I18n.t('services.mobileAppsDevelopment.techStack.itemTitle.0'),
    techImageSrc: IMAGES.REACT_NATIVE_LOGO
  },
  {
    techTitle: I18n.t('services.mobileAppsDevelopment.techStack.itemTitle.1'),
    techImageSrc: IMAGES.FLUTTER_LOGO
  },
  {
    techTitle: I18n.t('services.mobileAppsDevelopment.techStack.itemTitle.2'),
    techImageSrc: IMAGES.KOTLIN_LOGO
  },
  {
    techTitle: I18n.t('services.mobileAppsDevelopment.techStack.itemTitle.3'),
    techImageSrc: IMAGES.SWIFT_LOGO
  }
];
export const CloudApplicationDevelopmentTechStackConfig = [
  {
    techTitle: I18n.t('services.cloudApplicationDevelopment.techStack.itemTitle.0'),
    techImageSrc: IMAGES.AWS_LOGO
  },
  {
    techTitle: I18n.t('services.cloudApplicationDevelopment.techStack.itemTitle.1'),
    techImageSrc: IMAGES.GOOGLE_CLOUD_LOGO
  },
  {
    techTitle: I18n.t('services.cloudApplicationDevelopment.techStack.itemTitle.2'),
    techImageSrc: IMAGES.AZURE_LOGO
  }
];
export const UiUxDesignTechStackConfig = [
  {
    techTitle: I18n.t('services.uiUxDesign.techStack.itemTitle.0'),
    techImageSrc: IMAGES.FIGMA_LOGO
  },
  {
    techTitle: I18n.t('services.uiUxDesign.techStack.itemTitle.1'),
    techImageSrc: IMAGES.SKETCH_LOGO
  },
  {
    techTitle: I18n.t('services.uiUxDesign.techStack.itemTitle.2'),
    techImageSrc: IMAGES.ADOBE_XD_LOGO
  }
];
export const BackendTechStackConfig = [
  {
    techTitle: I18n.t('technologies.backend.techStack.itemTitle.0'),
    techImageSrc: IMAGES.NODEJS_LOGO
  },
  {
    techTitle: I18n.t('technologies.backend.techStack.itemTitle.1'),
    techImageSrc: IMAGES.DJANGO_LOGO
  },
  {
    techTitle: I18n.t('technologies.backend.techStack.itemTitle.2'),
    techImageSrc: IMAGES.RUBY_ON_RAILS_LOGO
  },
  {
    techTitle: I18n.t('technologies.backend.techStack.itemTitle.3'),
    techImageSrc: IMAGES.LARAVEL_LOGO
  },
  {
    techTitle: I18n.t('technologies.backend.techStack.itemTitle.4'),
    techImageSrc: IMAGES.ASP_NET_LOGO
  },
  {
    techTitle: I18n.t('technologies.backend.techStack.itemTitle.5'),
    techImageSrc: IMAGES.JAVA_LOGO
  }
];
export const FrontendTechStackConfig = [
  {
    techTitle: I18n.t('technologies.frontend.techStack.itemTitle.0'),
    techImageSrc: IMAGES.REACT_LOGO
  },
  {
    techTitle: I18n.t('technologies.frontend.techStack.itemTitle.1'),
    techImageSrc: IMAGES.ANGULAR_LOGO
  },
  {
    techTitle: I18n.t('technologies.frontend.techStack.itemTitle.2'),
    techImageSrc: IMAGES.VUE_LOGO
  }
];
export const DatabaseApiTechStackConfig = [
  {
    techTitle: I18n.t('technologies.databaseApi.techStack.itemTitle.0'),
    techImageSrc: IMAGES.MY_SQL_LOGO
  },
  {
    techTitle: I18n.t('technologies.databaseApi.techStack.itemTitle.1'),
    techImageSrc: IMAGES.POSTGRE_SQL_LOGO
  },
  {
    techTitle: I18n.t('technologies.databaseApi.techStack.itemTitle.2'),
    techImageSrc: IMAGES.MONGO_DB_LOGO
  },
  {
    techTitle: I18n.t('technologies.databaseApi.techStack.itemTitle.3'),
    techImageSrc: IMAGES.ORACLE_LOGO
  }
];
export const DevOpsTechStackConfig = [
  {
    techTitle: I18n.t('technologies.devOps.techStack.itemTitle.0'),
    techImageSrc: IMAGES.KUBERNETES_LOGO
  },
  {
    techTitle: I18n.t('technologies.devOps.techStack.itemTitle.1'),
    techImageSrc: IMAGES.DOCKER_LOGO
  },
  {
    techTitle: I18n.t('technologies.devOps.techStack.itemTitle.2'),
    techImageSrc: IMAGES.JENKINS_LOGO
  }
];
