import React from 'react';
import {
  ApplicationFromTitle,
  Content,
  FormContainer,
  LoaderContainer,
  PositionDetailsContainer,
  Root
} from 'pages/CareerPositionPage/styled';
import { Loader } from 'components/common';
import PositionDetails from 'pages/CareerPositionPage/PositionDetails';
import { fontFamily, fontWeight } from 'defaultStyles';
import ApplicationForm from 'pages/CareerPositionPage/ApplicationForm';
import useStore from 'reactContext/hooks/useStore';
import { PAGE_OFFER, PAGE_OFFER_LOADING } from 'reactContext/provider/storeConsts';

export default ({ t, offerId, onSubmit }) => {
  const [{ [offerId]: offer }] = useStore(PAGE_OFFER);
  const [loading] = useStore(PAGE_OFFER_LOADING);

  if (!loading && !offer) {
    return null;
  }

  return (
    <Root>
      <Content>
        {!loading ? (
          <React.Fragment>
            <PositionDetailsContainer>
              <PositionDetails position={offer} />
            </PositionDetailsContainer>

            <FormContainer>
              <ApplicationFromTitle fontSize={24} fontFamily={fontFamily.extraBold} fontWeight={fontWeight.extraBold}>
                {t('careerPosition.applicationForm.title')}
              </ApplicationFromTitle>

              <ApplicationForm onSubmit={onSubmit} />
            </FormContainer>
          </React.Fragment>
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
      </Content>
    </Root>
  );
};
