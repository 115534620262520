import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;

const ScrollList = styled.div`
  position: relative;
  max-height: 325px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-right: -30px;
`;

const ScrollContent = styled.div`
  position: relative;
  width: 100%;
  border-right: 30px solid transparent;
`;

const ScrollVertical = styled.div`
  position: absolute;
  right: 8px;
  top: 0px;
  bottom: 0px;
  width: 4px;
`;

const ScrollBar = styled.div`
  position: absolute;
  z-index: 9999;
  top: 0px;
  width: 4px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: ${colors.CLOUDY_BLUE};
`;

export { ScrollList, ScrollContent, ScrollVertical, ScrollBar, Wrapper };

export default {
  ScrollList,
  ScrollContent,
  ScrollVertical,
  ScrollBar,
  Wrapper
};
