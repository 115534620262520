import styled from 'styled-components';
import Text from '@src/components/common/Text';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  margin-bottom: 50px;

  ${props => props.styles}
`;

const Title = styled(Text)`
  margin-bottom: 15px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.semibold};
  font-size: 24px;

  ${props => props.titleStyles}
`;

const Container = styled.div`
  padding: 30px;

  border: 1px solid ${colors.WHITE};
  border-radius: 10px;
  background-color: ${colors.WHITE};
  box-sizing: border-box;

  ${props => props.containerStyles}
`;

export { Root, Container, Title };

export default {
  Root,
  Container,
  Title
};
