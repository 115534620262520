import React from 'react';
import styled from 'styled-components';
import { Header, Footer } from '@src/components/pages';

const GeneralLayout: React.FC = (props): JSX.Element | null => {
  return (
    <Root>
      <Header />
      <Content>{props.children}</Content>
      {/*    <Footer /> */}
    </Root>
  );
};
export default GeneralLayout;

const Root = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
`;
