import React from 'react';
import Slider from '@src/components/common/Slider';
import StoryCard from '@src/components/common/StoryCard';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { Container, Root, Title, TopSection, QuoteImage, Content } from './styled';
import { IMAGES } from '@src/assets/images';
import { RealStories } from 'src/config';
import I18n from 'i18next';

type Props = {};
const Story: React.FC<Props> = ({}): JSX.Element | null => {
  const { tablet, mobile } = useMediaQuery();

  return (
    <Root>
      <Content>
        <TopSection>
          <QuoteImage src={IMAGES.QUOTE_GREEN} />
          <Title>{I18n.t('landing.testimonial.header')}</Title>
        </TopSection>
        <Container>
          <Slider
            autoPlay
            dynamicHeight
            noArrows={mobile || tablet}
            itemsPerPage={mobile || tablet ? 1 : 3}
            items={RealStories.map(({ author, logo, position, message }, index) => (
              <StoryCard key={index} name={author} position={position} story={message} logo={logo} />
            ))}
          />
        </Container>
      </Content>
    </Root>
  );
};

export default Story;
