import styled from 'styled-components';
import { Text } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 10px 20px;

  box-sizing: border-box;

  cursor: pointer;

  &:hover {
    background-color: rgb(238, 238, 241, 0.3);

    & > .shevron path {
      fill: ${colors.ALGAE_GREEN};
    }
  }

  ${props => props.styles}
`;

const ItemIcon = styled.div`
  margin-right: 10px;
  display: flex;

  ${props => props.styles}
`;

const Image = styled.img`
  width: 30px;
  height: 16px;

  ${({ imageProps }) => imageProps && imageProps.styles}
`;

const Description = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  overflow: hidden;
`;

const Title = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: #282a31;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  letter-spacing: 0.5px;
`;

const Subtitle = styled(Text)`
  font-size: 14px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  line-height: 20px;
  letter-spacing: 0.5px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${colors.BATTLESHIP_GREY};
`;

const Price = styled(Text)``;

export { Description, Image, ItemIcon, Root, Price, Subtitle, Title };

export default {
  Description,
  Image,
  ItemIcon,
  Root,
  Price,
  Subtitle,
  Title
};
