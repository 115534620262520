import React, { useCallback } from 'react';

import { ICONS } from '@src/assets/icons';
import IconButton from '@src/components/common/IconButton';
import { GOOGLE_STORE_LINK } from '@src/config';
import ahoy, { PLATFORM_DESKTOP } from '@src/core/ahoy';
import useAhoyEventParams from '@src/hooks/useAhoyEventParams';

interface Props {
  buttonStyles?: any;
  appAndroidUrl?: any;
  src?: any;
}
const GooglePlayButton: React.FC<Props> = ({
  buttonStyles,
  appAndroidUrl = GOOGLE_STORE_LINK,
  src = ICONS.GOOGLE_PLAY_BADGE_ICON
}): JSX.Element | null => {
  const ahoyEventParams = useAhoyEventParams({ platform: PLATFORM_DESKTOP });

  const onClick = useCallback(() => {
    ahoy.trackGooglePlayLinkClick(ahoyEventParams);
  }, [ahoyEventParams]);

  return (
    <a href={appAndroidUrl} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <IconButton src={src} width={156} height={50} styles={buttonStyles} />
    </a>
  );
};

export default GooglePlayButton;
