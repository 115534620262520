import { API_URL } from 'config';
import gql from 'graphql-tag';
import i18n from 'lib/i18n';
import toastr from 'toastr';
import * as career from './career';
import { mutation } from './gql';
import * as storage from './storage';

function createOptions(body) {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
}

export async function send_mail(fullName, email, message) {
  const options = createOptions({ name: fullName, email, message });

  try {
    await fetch(`${API_URL}/landing/send_mail`, options);
  } catch (e) {
    // no op
  }

  return true;
}

export async function subscribe(email) {
  const options = createOptions({ email });

  try {
    await fetch(`${API_URL}/landing/subscribe`, options);
  } catch (e) {
    // no op
  }

  return true;
}

export async function create_partner(partnerData) {
  const options = createOptions(partnerData);

  try {
    const response = await fetch(`${API_URL}/partners`, options);
    return await response.text();
  } catch (e) {
    // no op
  }
}

const SignUpMutation = gql`
  mutation SignUpMutation($email: String!, $phone: String!, $password: String!, $source: SourceInputData) {
    contractorSignUp(
      identity: { email: { email: $email, password: $password }, phone: { phone: $phone, password: $password } }
      source: $source
    ) {
      success
    }
  }
`;

export async function signup(params, source, history) {
  const { email, phone, password } = params;
  const result = await mutation(
    SignUpMutation,
    {
      email,
      phone: phone[0] === '+' ? phone : `+${phone}`,
      password,
      source
    },
    null
  );
  if (result != null && result.contractorSignUp && result.contractorSignUp.success) {
    toastr.success(i18n.t('toastr.success.signup'));

    let redirectURL = '/app';
    if (source.referralCode) {
      redirectURL += '?referral=true';
    }

    history.push(redirectURL);
  }

  return true;
}

export { career, storage };
