import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import sanitize from '@src/lib/sanitize';
import I18n from 'i18next';
import { IMAGES } from '@src/assets/images';
import { Services, ServicesSectionRoot, ServicesSectionHeader, Root } from './styled';
import CustomServicesItem from './CustomServicesItem';

type Props = {
  id?: string;
};

const CustomSoftwareDevelopmentServices: React.FC<Props> = ({ id }): JSX.Element | null => {
  const { t } = useTranslation();
  const customServicesItem1Handler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/custom-software-development', '_blank').focus();
  }, []);
  const customServicesItem2Handler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/web-development', '_blank').focus();
  }, []);
  const customServicesItem3Handler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/mobile-apps-development', '_blank').focus();
  }, []);
  const customServicesItem4Handler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/ecommerce-development', '_blank').focus();
  }, []);
  const customServicesItem5Handler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/product-development', '_blank').focus();
  }, []);
  const customServicesItem6Handler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/cloud-application-development', '_blank').focus();
  }, []);
  const customServicesItem7Handler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/software-testing-qa', '_blank').focus();
  }, []);

  return (
    <Root id={id}>
      <ServicesSectionRoot>
        <ServicesSectionHeader
          dangerouslySetInnerHTML={sanitize(I18n.t('services.customSoftwareDevelopment.customServices.header'))}
        />
      </ServicesSectionRoot>

      <Services>
        <CustomServicesItem
          onClick={customServicesItem1Handler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('services.customSoftwareDevelopment.customServices.item1.title')}
          text={I18n.t('services.customSoftwareDevelopment.customServices.item1.subtitle')}
          author={I18n.t('services.customSoftwareDevelopment.customServices.item1.author')}
          position={I18n.t('services.customSoftwareDevelopment.customServices.item1.position')}
        />

        <CustomServicesItem
          onClick={customServicesItem2Handler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('services.customSoftwareDevelopment.customServices.item2.title')}
          text={I18n.t('services.customSoftwareDevelopment.customServices.item2.subtitle')}
          author={I18n.t('services.customSoftwareDevelopment.customServices.item2.author')}
          position={I18n.t('services.customSoftwareDevelopment.customServices.item2.position')}
        />

        <CustomServicesItem
          onClick={customServicesItem3Handler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('services.customSoftwareDevelopment.customServices.item3.title')}
          text={I18n.t('services.customSoftwareDevelopment.customServices.item3.subtitle')}
          author={I18n.t('services.customSoftwareDevelopment.customServices.item3.author')}
          position={I18n.t('services.customSoftwareDevelopment.customServices.item3.position')}
        />
        <CustomServicesItem
          onClick={customServicesItem4Handler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('services.customSoftwareDevelopment.customServices.item4.title')}
          text={I18n.t('services.customSoftwareDevelopment.customServices.item4.subtitle')}
          author={I18n.t('services.customSoftwareDevelopment.customServices.item4.author')}
          position={I18n.t('services.customSoftwareDevelopment.customServices.item4.position')}
        />
        <CustomServicesItem
          onClick={customServicesItem5Handler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('services.customSoftwareDevelopment.customServices.item5.title')}
          text={I18n.t('services.customSoftwareDevelopment.customServices.item5.subtitle')}
          author={I18n.t('services.customSoftwareDevelopment.customServices.item5.author')}
          position={I18n.t('services.customSoftwareDevelopment.customServices.item5.position')}
        />
        <CustomServicesItem
          onClick={customServicesItem6Handler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('services.customSoftwareDevelopment.customServices.item6.title')}
          text={I18n.t('services.customSoftwareDevelopment.customServices.item6.subtitle')}
          author={I18n.t('services.customSoftwareDevelopment.customServices.item6.author')}
          position={I18n.t('services.customSoftwareDevelopment.customServices.item6.position')}
        />
        <CustomServicesItem
          onClick={customServicesItem7Handler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('services.customSoftwareDevelopment.customServices.item7.title')}
          text={I18n.t('services.customSoftwareDevelopment.customServices.item7.subtitle')}
          author={I18n.t('services.customSoftwareDevelopment.customServices.item7.author')}
          position={I18n.t('services.customSoftwareDevelopment.customServices.item7.position')}
        />
      </Services>
    </Root>
  );
};
export default CustomSoftwareDevelopmentServices;
