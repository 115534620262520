import React, { useCallback } from 'react';
import styled from 'styled-components';
import { IconLinkedin } from '@src/components/common/icons';

type Props = {
  className?: any;
  width: number;
  height: number;
  color?: string;
};
const LinkedinButton: React.FC<Props> = ({ className, width, height, color }): JSX.Element | null => {
  const onLinkedinClick = useCallback(() => {
    window.open('https://www.linkedin.com/company/smartcodev/', '_blank').focus();
  }, []);

  return <IconLinkedinStyled className={className} onClick={onLinkedinClick} width={width} height={height} color={color} />;
};

export default LinkedinButton;

const IconLinkedinStyled = styled(IconLinkedin)`
  &:hover {
    opacity: 0.5;
  }
`;
