import React from 'react';
import { Card, Image, Typography } from 'antd';
import { Root } from './styled';
import { number } from '@shared/lib';

type Props = {
  styles?: any;
  backgroundColor?: string;
  cardWidth?: string;
  cardHeight?: string;
  imageWidth?: string;
  imageHeight?: string;
  titleColor?: string;
  descriptionColor?: string;
  alt?: string;
  imageSrc?: string;
  title?: string;
  description?: string;
};

const CardType1: React.FC<Props> = ({
  styles,
  backgroundColor = '#6666FF',
  cardWidth,
  cardHeight,
  imageWidth,
  imageHeight,
  titleColor,
  descriptionColor,
  alt,
  imageSrc,
  title,
  description
}): JSX.Element | null => {
  return (
    <Root
      styles={styles}
      backgroundColor={backgroundColor}
      cardWidth={cardWidth}
      cardHeight={cardHeight}
      titleColor={titleColor}
      descriptionColor={descriptionColor}
    >
      <Card className="card">
        <Image className="image" preview={false} width={imageWidth} height={imageHeight} alt={alt} src={imageSrc} />
        <Typography.Title className="title">{title}</Typography.Title>
        <Typography.Text className="description">{description}</Typography.Text>
      </Card>
    </Root>
  );
};

export default CardType1;
