import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import sanitize from '@src/lib/sanitize';

const Root = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  background-color: ${({ dark }) => (dark ? '#0066FF' : '#6772E5')};
  padding: ${({ height }) => (height > 27 ? '6px 15px' : '0 10px 0 2px')};
  font-size: 14px;
  height: ${({ height }) => `${height}px`};
  border-radius: ${({ height }) => `${height * 0.5}px`};
  margin-bottom: 40px;
  line-height: 1;
  box-sizing: border-box;

  width: fit-content;

  @media (${breakpoints.tablet}) {
    margin-bottom: 30px;
  }

  @media (${breakpoints.mobile}) {
    line-height: 20;
    letter-spacing: 0.55px;
    margin-bottom: 27px;
  }
`;

const ChipPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 11.5px;
  max-height: 23px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Chip = styled.div`
  margin-top: 2px;
  line-height: normal;
  font-weight: ${fontWeight.semibold};
`;

const Text = styled.div`
  margin: 3px 5px 0;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.semibold};
  font-size: 14px;
  color: ${({ color }) => color};
  padding-left: 10px;
  text-align: left;

  @media (${breakpoints.mobile}) {
    line-height: 1.2;
  }
`;

interface Props {
  dark?: boolean;
  chip: string;
  text: string;
  textColor?: string;
  chipColor?: string;
}
const ChipedText: React.FC<Props> = ({
  dark = false,
  chip,
  text,
  textColor = colors.BLACK,
  chipColor = colors.ALGAE_GREEN
}): JSX.Element | null => {
  const rootRef: { current: any } = useRef();
  // min height according to the sketch
  const [currentHeight, setHeight] = useState(27);

  useEffect(() => {
    const handleResize = () => {
      if (rootRef && rootRef.current) {
        const height = rootRef.current.clientHeight;

        setHeight(height);
      }
    };

    if (rootRef && rootRef.current) {
      window.addEventListener('resize', handleResize);

      handleResize();
    }

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, [rootRef.current]);

  let height = Math.max(27, currentHeight);

  if (height > 27) {
    height += 12;
  }

  return (
    <Root height={height} dark={dark}>
      <ChipPlaceholder backgroundColor={chipColor}>
        <Chip dangerouslySetInnerHTML={sanitize(chip)} />
      </ChipPlaceholder>
      <Text ref={rootRef} key={height} dangerouslySetInnerHTML={sanitize(text)} color={textColor} />
    </Root>
  );
};

export default ChipedText;
