import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  align-items: center;

  ${props => props.styles}
`;

export { Root };
