import React from 'react';
import styled from 'styled-components';
import { Text } from '@src/components/common';
import { IMAGES } from '@src/assets/images';
import { fontWeight, fontFamily, breakpoints } from '@src/defaultStyles';
import { colors } from '@src/defaultStyles';

export default function Quote({ author, quote, ...restProps }) {
  return (
    <Root {...restProps}>
      <QuoteText className="slider-text">{quote}</QuoteText>
      <Author className="slider-author">
        {author}
        <Image src={IMAGES.SMART_CODEV_LOGO} />
      </Author>
    </Root>
  );
}

const Root = styled.div`
  max-width: 770px;
  width: 100%;

  .slider-author {
    display: flex;
    justify-content: space-between;
  }
`;

const Image = styled.img`
  margin-bottom: 32px;
`;
const QuoteText = styled(Text)`
  display: block;
  font-size: 25px;
  font-family: ${fontFamily.regular};
  font-weight: ${fontWeight.normal};
  line-height: 1.7;
  margin-bottom: 30px;
  text-align: justify;
  color: #8f8f8f;

  @media (${breakpoints.mobile}) {
    font-size: 22px;
    line-height: 1.92;
    letter-spacing: 0.5px;
  }
`;

const Author = styled(Text)`
  display: block;
  text-align: left;
  font-size: 24px;
  font-weight: ${fontWeight.black};
  color: ${colors.GULL_GRAY};
`;
