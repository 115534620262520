import React from 'react';
import { IMAGES } from '@src/assets/images';
import { Root, SectionTitle, CardsGrid } from './styled';
import { css } from 'styled-components';
import { CardType1 } from '@src/components/common';
import I18n from 'i18next';
import sanitize from '@src/lib/sanitize';
import { colors } from '@src/defaultStyles';
import { CloudApplicationDevelopmentTechStackConfig } from '@src/config/TechStackConfig';

type Props = {
  id?: string;
};

const CloudApplicationDevelopmentTechnologies: React.FC<Props> = ({ id }): JSX.Element | null => {
  const cardStyles = {
    backgroundColor: '#333397',
    cardWidth: '280px',
    cardHeight: '170px',
    imageHeight: '80px',
    titleColor: '#C0FFC9'
  };
  return (
    <Root id={id}>
      <SectionTitle dangerouslySetInnerHTML={sanitize(I18n.t('services.cloudApplicationDevelopment.techStack.title'))} />
      <CardsGrid>
        {CloudApplicationDevelopmentTechStackConfig.map(({ techTitle, techImageSrc }, index) => (
          <CardType1
            key={index}
            styles={css``}
            backgroundColor={cardStyles.backgroundColor}
            cardWidth={cardStyles.cardWidth}
            cardHeight={cardStyles.cardHeight}
            imageHeight={cardStyles.imageHeight}
            titleColor={cardStyles.titleColor}
            imageSrc={techImageSrc}
            title={techTitle}
          />
        ))}
      </CardsGrid>
    </Root>
  );
};

export default CloudApplicationDevelopmentTechnologies;
