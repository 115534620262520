import React from 'react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'components/common';
import { colors } from 'defaultStyles';
import { formikForm, PhoneNumberInput, TextInput } from 'components/common/forms';
import composeValidators from 'lib/composeValidators';
import { email, phone, required, minLength, matches } from 'lib/validators';

const passwordMinLength = minLength(8);

function SignupForm({ handleSubmit, isSubmitting, isValid, dirty, ...restProps }) {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} {...restProps}>
      <Field
        name="email"
        id="email"
        label={t('signUp.input.email.label')}
        placeholder={t('signUp.input.email.placeholder')}
        component={TextInput}
        validate={composeValidators(email, required)}
      />

      <Field
        name="phone"
        id="phone"
        label={t('signUp.input.phone.label')}
        placeholder={t('signUp.input.phone.placeholder')}
        component={PhoneNumberInput}
        validate={composeValidators(phone, required)}
      />

      <Field
        name="password"
        id="password"
        type="password"
        label={t('signUp.input.password.label')}
        placeholder={t('signUp.input.password.placeholder')}
        component={TextInput}
        validate={composeValidators(required, passwordMinLength, matches)}
      />

      <SubmitButton type="submit" primary disabled={isSubmitting || !isValid || !dirty}>
        {t('signUp.button')}
      </SubmitButton>
    </Form>
  );
}

export default formikForm(SignupForm, {
  initialValues: {
    email: '',
    phone: '',
    password: ''
  }
});

SignupForm.propTypes = {};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;

  padding: 30px;
  border: 1px solid ${colors.LIGHT};
  border-radius: 15px;

  input {
    background-color: ${colors.VERY_LIGHT_PINK};
  }

  label {
    color: ${colors.CHARCOAL_GREY};
  }

  textarea {
    min-height: 120px;
    height: 120px;
    background-color: ${colors.VERY_LIGHT_PINK};
  }
`;

const SubmitButton = styled(Button)`
  align-self: center;
  margin-top: 20px;
`;
