import React from 'react';
import styled from 'styled-components';
import { breakpoints, fontFamily } from '@src/defaultStyles';
import { ORDER_BROKRETE_URL } from '@src/config';

const Root = styled.div`
  margin: 0;
  display: grid;
  //grid-template-rows: auto auto;
  box-sizing: border-box;
  align-self: stretch;

  // max-width: 167px;
  // max-height: 100%;
  // min-width: 167px;
  // min-height: 100%;
  // text-align: center;
  //
  // cursor: pointer;
  // box-sizing: border-box;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
`;

const BG = styled.div`
  background-image: ${({ src }) => `url(${src})`};
`;

const PartnerName = styled.div`
  //background-color: black;
  color: white;
  text-align: center;
  font-family: ${fontFamily.bold};
  font-size: 12px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 7px 0 7px 0;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  align-self: stretch;
  justify-self: stretch;
  background-color: white;
  //padding-bottom: 100%;
  position: relative;
  box-sizing: border-box;
  //max-height: 370px;
  overflow: hidden;
  padding: 15px;
  max-height: 124px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (${breakpoints.tablet}) {
    max-height: 113px;
    padding: 10px;
  }

  @media (${breakpoints.mobile}) {
    max-height: 100px;
  }
`;

const PartnerItem = ({ logo, code, name }) => {
  const logoClickHandler = () => {
    const win = window.open(`${ORDER_BROKRETE_URL}/${code}`, '_blank');
    win.focus();
  };

  return (
    <Root onClick={logoClickHandler}>
      <Placeholder>
        <Image src={logo} />
      </Placeholder>
    </Root>
  );
};

export default PartnerItem;
