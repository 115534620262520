/* eslint-disable no-undef */
import { decode } from 'base64-arraybuffer';
import toastr from 'toastr';

export default class Storage {
  constructor(config) {
    this.config = config;

    const spacesEndpoint = new AWS.Endpoint(config.region + '.digitaloceanspaces.com');

    this.s3 = new AWS.S3({
      endpoint: spacesEndpoint.href,
      accessKeyId: this.config.key,
      secretAccessKey: this.config.secret
    });
  }

  async readData(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  async put({ file, path }) {
    if (!file) {
      return null;
    }

    try {
      const data = await this.readData(file);
      const ext = file.name.split('.').pop();
      const contentType = file.type;
      const version = new Date().getTime();

      const result = await new Promise((resolve, reject) =>
        this.s3.upload(
          {
            Bucket: this.config.bucket,
            Body: decode(data),
            Key: `${path}.${version}.${ext}`,
            ContentType: contentType,
            ACL: 'public-read'
          },
          (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          }
        )
      );

      return {
        status: true,
        location: result.Location
      };
    } catch (error) {
      toastr.error(error.message);
    }

    return {
      status: false
    };
  }
}
