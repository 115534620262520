import styled, { css } from 'styled-components';

import ContentContainer from '@src/components/common/ContentContainer';
import RootContainer from '@src/components/common/RootContainer';
import { Heading, Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: ${colors.BACKGROUND_NEW_WHITE};
  @media (${breakpoints.mobile}) {
    padding: 0px;
  }
  .scrollButton {
    width: 200px;
    height: 70px;
    background-color: #009dff;
    border-radius: 15px;
    cursor: pointer;
    @media (${breakpoints.mobile}) {
      width: 150px;
      height: 80px;
    }
  }
`;

export { Root };
