import { query } from 'api/core/gql';
import gql from 'graphql-tag';

const Jobs = gql`
  query Jobs($limit: Int!, $skip: Int!) {
    jobs(skip: $skip, limit: $limit) {
      values {
        id
        additionalInfo
        experience
        salary
        title
        direction
        type
        location
        description

        requirements
        responsibilities
      }
      count
    }
  }
`;

export default async function fetchOpenPositions({ skip, limit }) {
  const result = await query(Jobs, {
    skip,
    limit
  });

  return result;
}
