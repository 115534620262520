import React from 'react';
import { Element } from 'react-scroll';
import ContactsScreen from '@src/components/common/FooterWithContacts';
import { Header } from '@src/components/pages';
import { FrontendTechnologies } from './screens';
import { Content, PageWrapper } from './styled';
import LdJsonInclude from './LdJsonInclude';
import ReadyToStarted from '@src/components/common/FooterWithContacts/ReadyToStarted';
import MainTopScreen from '@src/components/common/MainTopScreen';
import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';
import ConstructionForm from './ConstructionForm';
import querystring from 'querystring';
import jwt from 'jsonwebtoken';
import { ORDER_BROKRETE_URL } from '@src/config';
import { css } from 'styled-components';
import { colors, breakpoints } from '@src/defaultStyles';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { ScheduleDemoButton, WatchVideoButton } from '@src/components/pages';
import { ContactUsButton } from '@src/components/common';
const Frontend = () => {
  const brokreteInfo = 'brokreteInfo';
  const { tablet } = useMediaQuery();

  const onSubmit = (values, { setSubmitting }) => {
    const location = values?.location;

    if (location) {
      const query = querystring.stringify({
        resetPartner: true,
        address: jwt.sign(location, 'internal')
      });

      const win = window.open(`${ORDER_BROKRETE_URL}/setup-suppliers?${query}`, '_blank');
      win.focus();
    }
  };

  return (
    <PageWrapper>
      {/* <LdJsonInclude /> */}

      <Header light commerce />

      <Content>
        <MainTopScreen
          backgroundImage={IMAGES.FRONTEND_TOP_SCREEN}
          titleStyles={css`
            color: ${colors.LIGHT_BLUE_2};
          `}
          subtitleStyles={css`
            color: ${colors.DARK_MINT};
          `}
          news={{
            chip: I18n.t('technologies.frontend.topScreen.headerIntroduceChip'),
            text: I18n.t('technologies.frontend.topScreen.headerIntroduceText')
          }}
          title={I18n.t('technologies.frontend.topScreen.title')}
          subtitle={I18n.t('technologies.frontend.topScreen.subtitle')}
          footerContent={
            <>
              <ScheduleDemoButton />
              <ContactUsButton />
              <WatchVideoButton videoUrl={'https://www.youtube.com/watch?v=mgooqyWMTxk'} />
            </>
          }
        />
        <FrontendTechnologies id="FRONTEND_TECH_STACK" />
        <ContactsScreen />
      </Content>
    </PageWrapper>
  );
};

export default Frontend;
