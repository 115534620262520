import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const Svg = styled.svg`
  path {
    fill: ${colors.WHITE};
  }

  ${props => props.styles}
`;

export { Svg };
