import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import sanitize from '@src/lib/sanitize';
import I18n from 'i18next';
import {
  Root,
  TextItem,
  TextSection,
  TextTitle,
  TextInfo,
  Content,
  SliderContainer,
  SliderItem,
  SliderImage,
  TitleMobile
} from './styled';
import { IMAGES } from '@src/assets/images';
import { WebDevelopmentPreviewInfo } from '@src/config/WebDevelopmentPreviewInfoConfig';
import Slider from '@src/components/common/Slider';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
type Props = {
  id?: string;
};
const WebDevelopmentPreview: React.FC<Props> = ({ id }): JSX.Element | null => {
  const { t } = useTranslation();
  const [activeSlide, setActiveSlide] = useState(0);
  const { mobile } = useMediaQuery();

  if (mobile) {
    const SliderItems = WebDevelopmentPreviewInfo.map(({ title, description, image }, index) => (
      <SliderItem key={index}>
        <TitleMobile key={index} active={activeSlide === index}>
          {title}
        </TitleMobile>
        <SliderImage src={image} draggable={false} />

        {description}
      </SliderItem>
    ));
    return (
      <Root id={id}>
        <Content>
          <Slider
            onChangeActiveSlide={setActiveSlide}
            autoPlay
            dynamicHeight
            itemsWrapperStyle={{ alignItems: 'center' }}
            noArrows
            itemsPerPage={1}
            items={SliderItems}
          />
        </Content>
      </Root>
    );
  }

  const SliderItems = WebDevelopmentPreviewInfo.map(({ image }, index) => (
    <SliderItem key={index}>
      <SliderImage src={image} draggable={false} />
    </SliderItem>
  ));
  return (
    <Root id={id}>
      <Content>
        <TextSection>
          {WebDevelopmentPreviewInfo.map(({ title, description }, index) => (
            <TextItem key={index} onClick={setActiveSlide.bind(this, index)} active={activeSlide === index}>
              <TextTitle>{title}</TextTitle>
              <TextInfo>{description}</TextInfo>
            </TextItem>
          ))}
        </TextSection>
        <SliderContainer>
          <Slider
            onChangeActiveSlide={setActiveSlide}
            autoPlay
            itemsWrapperStyle={{ alignItems: 'center' }}
            activeSlide={activeSlide}
            dragEnabled={false}
            noDots
            noArrows
            itemsPerPage={1}
            items={SliderItems}
          />
        </SliderContainer>
      </Content>
    </Root>
  );
};

export default WebDevelopmentPreview;
