import { fontFamily, colors, fontWeight } from '@src/defaultStyles';

const GREEN_DOT = `<span style="font-size:1em ;color:${colors.ALGAE_GREEN}">.</span>`;

export default {
  common: {
    empty: '',
    others: 'Others',
    scheduleDemoButton: 'Schedule a Demo',
    watchVideoButton: 'Watch Video',
    watchVideoClose: 'Close Video',
    contactUsButton: 'Contact US'
  },
  header: {
    home: 'Home',
    services: 'Services',
    technologies: 'Technologies',
    about: 'About Us',
    career: 'Career',
    mobileBackButton: 'Back',
    contactUsButton: 'CONTACT US',
    contactUs: 'Contact Us',
    servicesItems: {
      item1: 'Custom Software Development',
      item2: 'Web Development',
      item3: 'Mobile Apps Development',
      item4: 'Ecommerce Development',
      item5: 'Product Development',
      item6: 'Cloud Application Development',
      item7: 'Software Testing & QA',
      item8: 'UI/UX Design'
    },
    technologiesItems: {
      item1: 'Backend',
      item2: 'Frontend',
      item3: 'Database & API',
      item4: 'DevOps',
      item5: 'Mobile',
      item6: 'Others'
    }
  },

  contactSection: {
    header: 'Contact Us',
    contacts: {
      title: 'Contacts',
      email: 'Email',
      phoneNumber: 'Phone Number'
    },
    office: {
      title: 'Office Address'
    },
    form: {
      title: 'Send us a message',
      nameTitle: 'Your Name',
      namePlaceholder: 'Paul',
      emailTitle: 'Your Email',
      emailPlaceholder: 'e.g. paul@gmail.com',
      messageTitle: 'Your Message',
      messagePlaceholder: 'Write...',
      button: 'Submit'
    },
    modal: {
      title: 'Thanks for your message',
      text: 'We will reach you within 24 hours'
    }
  },
  desktopDrawer: {
    home: {
      item1: 'Services',
      item2: 'Technologies',
      item3: 'Custom Software Development',
      item4: 'Web Development',
      item5: 'Discuss your project',
      item6: 'Mobile Apps Development',
      item7: 'Ecommerce Development',
      item8: 'Get a Quote',
      item9: 'Product Development',
      item10: 'Cloud Application Development',
      item11: 'Email Us',
      item12: 'Software Testing & QA',
      item13: 'UI/UX Design',
      item14: 'Contact Us'
    },
    services: {
      customSoftwareDevelopment: {
        item1: 'Preview',
        item2: 'Custom Software Services'
      },
      webDevelopment: {
        item1: 'Preview',
        item2: 'Our Web Development Tech Stack'
      },
      mobileAppsDevelopment: {
        item1: 'Our Mobile Development Tech Stack'
      },
      cloudApplicationDevelopment: {
        item1: 'Our Cloud Application Development Tech Stack'
      },
      uiUxDesign: {
        item1: 'Our UI/UX Design Tech Stack'
      }
    },
    technologies: {
      backend: {
        item1: 'Our Backend Development Tech Stack'
      },
      frontend: {
        item1: 'Our Frontend Development Tech Stack'
      },
      databaseApi: {
        item1: 'Our Database & API Tech Stack'
      },
      devOps: {
        item1: 'Our DevOps Tech Stack'
      }
    }
  },
  landing: {
    topScreen: {
      headerIntroduceChip: 'Good News',
      headerIntroduceText: '30% off for your first app with us',
      title: 'Software Development Services',
      subtitle: `From product design to software continuous delivery. Predictable, Measurable, Agile. <br /> We enable progressive businesses to transform, scale and gain competitive advantage, through the expert delivery of innovative, tailor-made software.`
    },
    scrollSection: {
      services: 'Services',
      technologies: 'Technologies',
      content1: 'Custom Software Development',
      content2: 'Web Development',
      section1: `Let's discuss`,
      content3: 'Mobile Apps Development',
      content4: 'Ecommerce Development',
      section2: 'Get a Quote',
      content5: 'Product Development',
      content6: 'Cloud Application Development',
      section3: 'Email Us',
      content7: 'Software Testing & QA',
      content8: 'UI/UX Design',
      contactSection: 'Contact Us'
    },
    servicesSection: {
      header: 'We offer these services',
      customSoftwareDevelopment: {
        title: 'Custom Software Development',
        subtitle: 'Discover more',
        author: '',
        position: ''
      },
      webDevelopment: {
        title: 'Web Development',
        subtitle: 'Discover more',
        author: '',
        position: ''
      },
      mobileAppsDevelopment: {
        title: 'Mobile Apps Development',
        subtitle: 'Discover more',
        author: '',
        position: ''
      },
      ecommerceDevelopment: {
        title: 'Ecommerce Development',
        subtitle: 'Discover more',
        author: '',
        position: ''
      },
      productDevelopment: {
        title: 'Product Development',
        subtitle: 'Discover more',
        author: '',
        position: ''
      },
      cloudApplicationDevelopment: {
        title: 'Cloud Application Development',
        subtitle: 'Discover more',
        author: '',
        position: ''
      },
      softwareTestingQa: {
        title: 'Software Testing & QA',
        subtitle: 'Discover more',
        author: '',
        position: ''
      },
      uiUxDesign: {
        title: 'UI/UX Design',
        subtitle: 'Discover more',
        author: '',
        position: ''
      }
    },
    technologiesSection: {
      header: 'Technologies',
      backend: {
        title: 'Backend',
        subtitle: 'Learn more'
      },
      frontend: {
        title: 'Frontend',
        subtitle: 'Learn more'
      },
      databaseApi: {
        title: 'Database & API',
        subtitle: 'Learn more'
      },
      devOps: {
        title: 'DevOps',
        subtitle: 'Learn more'
      },
      mobile: {
        title: 'Mobile',
        subtitle: 'Learn more'
      },
      others: {
        title: 'Others',
        subtitle: 'Learn more'
      }
    },
    content1: {
      title: 'Looking to create custom, scalable software solution?',
      info: 'We help you with Custom Software Development Company by allowing your teams to experiment with desired features and build new capabilities on top of your existing tech stack.',
      linkTitle: 'Discover Custom Software Development'
    },
    content2: {
      title: 'Web Development',
      info: 'High-performing, intuitive and secure web solutions that support business processes and serve users globally.',
      linkTitle: 'Discover Web Development'
    },
    section1: {
      title: `Let's discuss your project`,
      info: 'Send and email to "info@smartcodev.com"'
    },
    content3: {
      title: 'Mobile Apps Development',
      info: 'Achieve digital transformation by developing scalable engaging and feature rich mobile applications',
      linkTitle: 'Discover Mobile Apps Development'
    },

    content4: {
      title: 'Ecommerce Development',
      info: 'We help eCommerce business owners to achieve their goals through branding, marketing, and design. Your vision is just a step away from getting transformed into a successful venture.',
      linkTitle: 'Discover Ecommerce Development'
    },
    section2: {
      title: `Get a Quote`,
      info: 'Call us at +1 647 375 7176'
    },
    content5: {
      title: 'Product Development',
      info: 'Helping you deliver better product experiences for happier and more productive users',
      linkTitle: 'Discover Product Development'
    },
    content6: {
      title: 'Cloud Application Development',
      info: 'we leverage our experience working with all major cloud providers — including Amazon Web Services, Microsoft Azure, and Google Cloud Platform — to help organizations accelerate their transformation and get the most from high-performing cloud environments, whether public, private, or hybrid. We provide custom cloud software development services that enable enterprises and tech innovators to future-proof their infrastructure investments and streamline the shift to SaaS, PaaS, and IaaS business models.',
      linkTitle: 'Discover Cloud Application Development'
    },
    section3: {
      title: `Let's discuss your project`,
      info: 'Send and email to "info@smartcodev.com"'
    },
    content7: {
      title: 'Software Testing & QA',
      info: `If it's software, websites, or mobile apps, we test it. The breadth of our outsourced software testing services is second to none. Our team has expertise you can count on to test everything from websites to mobile apps.`,
      linkTitle: 'Discover Software Testing & QA'
    },
    content8: {
      title: 'UI/UX Design',
      info: `We offer design thinking-based and technology driven services in digital product innovation,
customer experience management, and digital transformation.`,
      linkTitle: 'Discover UI/UX Design'
    },
    testimonial: {
      header: 'Testimonials'
    }
  },
  services: {
    customSoftwareDevelopment: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Looking for Custom Software Development Company in your business domain?',
        subtitle:
          'We are Custom Software Development Company in your business domain and deliver highly scalable, interoperable systems that fit your business.'
      },
      previewInfo: {
        header: 'Preview Software Development Custom Services',
        item1: {
          title: 'Requirements analysis',
          description:
            'Detailed project estimation that incorporates market research, functionality outline and client’s custom requirements.'
        },
        item2: {
          title: 'Business analysis & planning',
          description:
            'Project Manager will guide you through a series of workshops to define the architecture and necessary tools'
        },
        item3: {
          title: 'Design',
          description:
            'With the creative power of the UX/UI team, this stage is all about visualizing and preparing the concept and user interfaces'
        },
        item4: {
          title: 'Coding',
          description:
            'Writing and ensuring code is future-ready by considering the changing technology landscape and the required features'
        },
        item5: {
          title: 'Quality assurance',
          description:
            'Diligent testing and quality assurance to ensure it meets the required standards of usability, compliance and security.'
        },
        item6: {
          title: 'Deployment',
          description:
            'Follow an implementation plan to ensure the runtime installation or deployment of the developed software is seamless.'
        },
        item7: {
          title: 'Maintenance & support',
          description:
            'Initiate the ongoing phase of Run & Maintain that ensures smooth operations, regular updates, and compliance checks.'
        }
      },
      customServices: {
        header: 'Our Software Development Custom Services',
        item1: {
          title: 'Requirements analysis',
          subtitle:
            'Detailed project estimation that incorporates market research, functionality outline and client’s custom requirements.',
          author: '',
          position: ''
        },
        item2: {
          title: 'Business analysis & planning',
          subtitle: 'Project Manager will guide you through a series of workshops to define the architecture and necessary tools',
          author: '',
          position: ''
        },
        item3: {
          title: 'Design',
          subtitle:
            'With the creative power of the UX/UI team, this stage is all about visualizing and preparing the concept and user interfaces',
          author: '',
          position: ''
        },
        item4: {
          title: 'Coding',
          subtitle:
            'Writing and ensuring code is future-ready by considering the changing technology landscape and the required features',
          author: '',
          position: ''
        },
        item5: {
          title: 'Quality assurance',
          subtitle:
            'Diligent testing and quality assurance to ensure it meets the required standards of usability, compliance and security.',
          author: '',
          position: ''
        },
        item6: {
          title: 'Deployment',
          subtitle:
            'Follow an implementation plan to ensure the runtime installation or deployment of the developed software is seamless.',
          author: '',
          position: ''
        },
        item7: {
          title: 'Maintenance & support',
          subtitle:
            'Initiate the ongoing phase of Run & Maintain that ensures smooth operations, regular updates, and compliance checks.',
          author: '',
          position: ''
        }
      }
    },
    webDevelopment: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Web Development',
        subtitle:
          'Our specialists turn web applications into powerful tools to meet clients’ requirements and fulfill end-user expectations. Custom web development services at Smartcodev go along with the original style inside the web solution.'
      },
      previewInfo: {
        header: 'Preview Web Development Custom Services',
        item1: {
          title: 'Understanding clients. Project initiation.',
          description:
            'Smartcodev offers Discovery Stage to assess market trends, end-users’ needs. It helps improve and clarify requirements – Business, Stakeholder, Functional and Non-functional. Then, a team of professionals will conduct design definitions and proof of concept.'
        },
        item2: {
          title: 'Project Planning',
          description:
            'It is all about planning the Scope and managing activities to see results in time. We effectively allocate resources, present team composition and estimate the best plan to match the budget and requested time limits.'
        },
        item3: {
          title: 'Agile Development',
          description:
            'Agile project management is a key to successful projects. Skilled Project managers at Inoxoft always view and control the transparency of communication, direct tasks execution, report, and monitor progress. Regular communication with clients allows us to control changes and stay flexible.'
        },
        item4: {
          title: 'Quality Assurance Testing',
          description:
            'Smartcodev provides automated testing services. Depending on clients’ requirements and project peculiarities, Quality Assurance engineers will conduct the necessary testing.'
        },
        item5: {
          title: 'Delivery',
          description:
            'Smartcodev has developed standards of delivery that make each final release and deployment successful. We also get applications to Google Play and Apple Store.'
        },
        item6: {
          title: 'Maintenance and Support',
          description: `At Smartcodev you can be confident in running your business with
            after-release support, troubleshooting and updating functionality from our team.`
        }
      },
      techStack: {
        title: 'Our Web Development Tech Stack',
        itemTitle: {
          0: 'React',
          1: 'Angular',
          2: 'Vue',
          3: 'NodeJS',
          4: 'Django',
          5: 'Ruby on Rails',
          6: 'Laravel',
          7: 'ASP.NET',
          8: 'Java',
          9: 'MySQL',
          10: 'PostgreSQL',
          11: 'mongoDB',
          12: 'Oracle',
          13: 'AWS',
          14: 'Google Cloud',
          15: 'Azure',
          16: 'Kubernetes',
          17: 'Docker',
          18: 'Jenkins'
        }
      }
    },
    mobileAppsDevelopment: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Mobile Apps Development',
        subtitle:
          'Native and cross-platform mobile applications development to provide businesses with effective means to reach their customers anywhere they go. Full life-cycle management for iOS- and Android-based applications from prototyping to deployment and testing'
      },
      techStack: {
        title: 'Our Mobile Development Tech Stack',
        itemTitle: {
          0: 'React Native',
          1: 'Flutter',
          2: 'Kotlin',
          3: 'Swift'
        }
      }
    },
    ecommerceDevelopment: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Ecommerce Development',
        subtitle: `There are solutions for everyone, depending on your
        business process and your needs.Having an online
        storefront is one of the most straightforward ways to
        conduct e-commerce.`
      }
    },
    productDevelopment: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Product Development',
        subtitle:
          'Know and predict which combination of features and customer actions in the product (we’re talking billions of them) translate to business outcomes—revenue, loyalty and lifetime value.'
      }
    },
    cloudApplicationDevelopment: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Cloud Application Development',
        subtitle:
          'Cloud app development implies building apps that run in the cloud and may leverage cloud features and services offered by cloud vendors. In cloud computing since 2012, ScienceSoft quickly and reliably delivers cloud-native and cloud-only apps that efficiently employ all cloud capabilities.'
      },
      techStack: {
        title: 'Our Cloud Application Development Tech Stack',
        itemTitle: {
          0: 'AWS',
          1: 'Google Cloud',
          2: 'Azure'
        }
      }
    },
    softwareTestingQa: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Software Testing & QA',
        subtitle:
          'Business is powered by software. Software is prone to defects. It is the responsibility of those who produce software to ship products that are as defect-free as possible so that business can run as usual. As release cycles get shorter it is becoming harder and harder to do all the testing that is necessary before the software is released. That’s where we come in. We’re your third-party sanity check. An outsourced software testing and QA partner you can count on when you need it most.'
      }
    },
    uiUxDesign: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'UI/UX Design',
        subtitle:
          'No matter the industry, device, objective or technology platform, we’re driven to improve the human relationship with technology by designing and building meaningful connections between companies and their customers.'
      },
      techStack: {
        title: 'Our UI/UX Design Tech Stack',
        itemTitle: {
          0: 'Figma',
          1: 'Sketch',
          2: 'AdobeXd'
        }
      }
    }
  },
  technologies: {
    backend: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Backend Development',
        subtitle:
          'Our strong and dedicated backend development team will ensure the security and resilience of your website. Every business needs a flawless system behind the website. We provide cutting-edge backend web development solutions that meet this demand.'
      },
      techStack: {
        title: 'Our Backend Development Tech Stack',
        itemTitle: {
          0: 'NodeJS',
          1: 'Django',
          2: 'Ruby on Rails',
          3: 'Laravel',
          4: 'ASP.NET',
          5: 'Java'
        }
      },
      content1: {
        sectionTitle: 'Our Backend Tech Stack',
        item1: {
          title: 'Nodejs',
          description: 'Nodejs'
        },
        item2: {
          title: 'Django',
          description: 'Django'
        },
        item3: {
          title: 'Ruby on Rails',
          description: 'Ruby on Rails'
        },
        item4: {
          title: 'Laravel',
          description: 'Laravel'
        },
        item5: {
          title: 'ASP.Net',
          description: 'ASP.Net'
        }
      }
    },
    frontend: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Frontend Development',
        subtitle:
          'With no doubt, an intuitive web interface adapted to users’ needs and expectations has an enormous impact on the way potential customers perceive the product or the service. It can both make it sexy or kill it straight away. Using HTML, CSS, JavaScript, and its frameworks, experienced frontend developers can truly make miracles!'
      },
      techStack: {
        title: 'Our Frontend Development Tech Stack',
        itemTitle: {
          0: 'React',
          1: 'Angular',
          2: 'Vue'
        }
      },
      content1: {
        sectionTitle: 'Our Frontend Tech Stack',
        item1: {
          title: 'React',
          description: 'React'
        },
        item2: {
          title: 'Angular',
          description: 'Angular'
        },
        item3: {
          title: 'Vue',
          description: 'Vue'
        }
      }
    },
    databaseApi: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Database & API',
        subtitle:
          'Database is the heart of a software application. By collecting all information pertaining to individuals and organizations onto one platform, database development is quintessential to maintaining and nurturing a relationship with prospects and customers. If not managed properly, data across the organization can become quite unwieldy.'
      },
      techStack: {
        title: 'Our Database & API Tech Stack',
        itemTitle: {
          0: 'MySQL',
          1: 'PostgreSQL',
          2: 'mongoDB',
          3: 'Oracle'
        }
      }
    },
    devOps: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'DevOps',
        subtitle:
          'We conduct comprehensive DevOps consulting services—analyzing your development and operational environment and creating a roadmap that shows you how to integrate DevOps automation into your IT ecosystem.'
      },
      techStack: {
        title: 'Our DevOps Tech Stack',
        itemTitle: {
          0: 'Kubernetes',
          1: 'Docker',
          2: 'Jenkins'
        }
      }
    },
    mobile: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Mobile Development',
        subtitle: 'Mobile Development'
      }
    },
    others: {
      topScreen: {
        headerIntroduceChip: 'Good News',
        headerIntroduceText: '30% off your first app with us',
        title: 'Others',
        subtitle: 'Others'
      }
    }
  },
  aboutUs: {
    topScreen: {
      headerIntroduceChip: 'Good News',
      headerIntroduceText: '30% off your first app with us',
      title: 'About Us',
      subtitle: 'Get more familiar with us.'
    }
  },
  contactUs: {
    topScreen: {
      headerIntroduceChip: 'Good News',
      headerIntroduceText: '30% off your first app with us',
      title: 'Contact Us',
      subtitle: 'Reach us to talk more. Call us at +1 647 375 7176 or email "info@smartcodev.com" or "milad@smartcodev.com"'
    }
  }
};
