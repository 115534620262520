import styled from 'styled-components';
import { Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  cursor: pointer;
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 130px auto;
  align-self: normal;
  padding: 35px 50px 35px 0;
  margin: 20px;
  background-color: #fef6e7;
  border-radius: 20px;
  box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
  line-height: 1.5;

  @media (${breakpoints.tablet}) {
    grid-template-columns: 1fr;
    grid-template-rows: 100px auto;
    justify-items: center;
    padding: 20px;
  }

  @media (${breakpoints.mobile}) {
    margin-bottom: 40px;
  }
`;
const LinkStyled = styled(Text)`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: left;
  font-size: 15px;
  letter-spacing: 0.97px;
  font-weight: ${fontWeight.bold};
  color: ${colors.CORNFLOWER_BLUE};
  @media (${breakpoints.tablet}) {
    font-size: 16px;
    letter-spacing: 1.04px;
    line-height: 24px;
    text-align: center;
    justify-self: center;

    .arrow {
      //display: none;
    }
  }
  @media (${breakpoints.mobile}) {
  }
`;

const Image = styled.img`
  position: relative;
  width: 90%;
  padding-left: 10px;
  ${props => props.imageStyles}
  @media (${breakpoints.tablet}) {
    padding-left: 0px;
  }
  @media (${breakpoints.mobile}) {
    width: 70%;
  }
`;

const Suggestions = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1300px;
  margin: auto;
  padding: 80px 80px 50px 80px;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
  }
`;

const SuggestionsLeftSide = styled.div`
  margin-right: 50px;
  width: 540px;
  @media (${breakpoints.mobile}) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const SuggestionsRightSide = styled.div`
  width: 540px;
  @media (${breakpoints.mobile}) {
    width: 100%;
    margin-bottom: 30px;
  }
`;

const InfoWrapper = styled.div`
  padding-left: 20px;

  @media (${breakpoints.tablet}) {
    padding-left: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 56px auto;
    align-items: center;
  }
`;

const TechnologiesItemImage = styled.div`
  width: 133px;
  height: 96px;
  object-fit: contain;
  margin-right: 20px;

  @media (${breakpoints.tablet}) {
    margin: 0;
  }
`;

const TechnologiesItemInfo = styled.div`
  font-size: 15px;
  letter-spacing: 0.35px;
  line-height: 20px;
  font-family: ${fontFamily.thin};
  font-weight: 100;
  opacity: 0.8;
  color: ${colors.SLATE_GRAY};

  @media (${breakpoints.tablet}) {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.38px;
    line-height: 24px;
  }
`;
const LinkArrowStyled = styled.img`
  width: 10%;
  position: absolute;
  bottom: 30px;
  right: 30px;

  @media (${breakpoints.mobile}) {
    position: relative;
    bottom: unset;
    right: unset;
    margin: 20px auto 0;
    width: 37px;
    height: 32px;
  }
`;
export {
  LinkStyled,
  Image,
  Suggestions,
  SuggestionsLeftSide,
  SuggestionsRightSide,
  Root,
  TechnologiesItemImage,
  InfoWrapper,
  TechnologiesItemInfo,
  LinkArrowStyled
};
