import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Root, PageHeading, TextBlock, Description, Right, Left, Muted, Salary, Paragraph } from './styled';
import { snakeCaseToCamelCase } from 'lib/string';
import { List } from 'components/pages';

export default function PositionDetails(props) {
  const { t } = useTranslation();

  const {
    position: { title, location, additionalInfo, salary, experience, description, type, responsibilities, requirements }
  } = props;

  const descriptionText = useMemo(() => {
    if (!description) {
      return null;
    }

    return description.split('\\n').map((text, index) => {
      return <Paragraph key={index}>{text}</Paragraph>;
    });
  }, [description]);

  const additionalInfoText = useMemo(() => {
    if (!additionalInfo) {
      return null;
    }

    return additionalInfo.split('\\n').map((text, index) => {
      return <Paragraph key={index}>{text}</Paragraph>;
    });
  }, [additionalInfo]);

  return (
    <Root>
      <PageHeading>{title}</PageHeading>

      <TextBlock>
        <Left>
          <Muted>{`${location} • ${t(`common.jobTypes.${snakeCaseToCamelCase(type)}`)}`}</Muted>
          {experience && <Muted>{`${t('positionDetails.experience')} ${experience}`}</Muted>}
        </Left>

        {salary && (
          <Right>
            <Salary>{salary}</Salary>
          </Right>
        )}
      </TextBlock>

      {descriptionText && <Description>{descriptionText}</Description>}

      {responsibilities.length > 0 && <List title={t('positionDetails.responsibilities')} items={responsibilities} />}

      {requirements.length > 0 && <List title={t('positionDetails.requirements')} items={requirements} />}

      {additionalInfoText && <Description>{additionalInfoText}</Description>}
    </Root>
  );
}
