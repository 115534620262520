import { Query } from './Query';
import { ByLocation } from './queries';
import { Location, Place } from '../../../interfaces';

import { PlaceAdapter } from './adapters/PlaceAdapter';

const API_ENDPOINT = 'https://maps.googleapis.com/maps/api/geocode';

export default class GoogleGeocode {
  private readonly apiKey: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  public byLocation({ location, resultType }: { location: Location; resultType?: string }): Promise<Place[]> {
    const query: Query = new ByLocation({
      latlng: `${location.latitude},${location.longitude}`,
      result_type: resultType,
      key: this.apiKey
    });
    return this.execute(query).then(({ results }) => results.map(pr => new PlaceAdapter().from(pr).toData()));
  }

  private execute(query: Query): Promise<any> {
    return fetch(`${API_ENDPOINT}/json?${query.toQuery()}`)
      .then((response: Response) => {
        return response.json();
      })
      .catch((error: any) => {
        throw error;
      });
  }
}
