import { css } from 'styled-components';
import { colors } from '@src/defaultStyles';

const select = css`
  width: 280px;
`;

const rootContainer = css`
  position: relative;

  background-color: ${colors.BACKGROUND_LIGHT};
`;

const locker = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

export default {
  rootContainer,
  select,
  locker
};
