import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import Invite from '@src/pages/InvitePage/Invite';
import { query } from '@src/api/core/gql';

const useInvite = id => {
  const [state, setState] = useState<{ invite: Invite | null; loaded: boolean }>({
    invite: null,
    loaded: false
  });

  useEffect(() => {
    query(inviteQuery, { id }).then(({ invite }) => {
      setState({ invite: new Invite(invite), loaded: true });
    });
  }, [id, setState]);

  return state;
};

export default useInvite;

const inviteQuery = gql`
  query invite($id: ID!) {
    invite(id: $id) {
      id
      contractor {
        id
        info {
          name
        }
        companyInfo {
          logo
        }
      }
      partner {
        id
        name
        code
        logo
        largeLogo
      }
      status
      type
      pending
      redirectTo
      targetContractor {
        info {
          name
          country {
            id
            name
            code
          }
        }
        owner {
          identities {
            id
            provider
            uid
          }
        }
      }
      targetDriver {
        info {
          name
        }
        identities {
          id
          provider
          uid
        }
        city {
          id
          name
          country {
            id
            name
            code
          }
        }
      }
      targetMember {
        name
        identities {
          id
          provider
          uid
        }
      }
    }
  }
`;
