import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

interface Props {
  src: any;
  width?: number;
  height?: number;
  onClick?: (event: React.MouseEvent) => void;
  [x: string]: any;
}
const IconButton: React.FC<Props> = ({ src, width, height, onClick, ...rest }): JSX.Element | null => {
  return (
    <Root className="IconButton" {...rest}>
      <img src={src} width={width} height={height} onClick={onClick} alt="" />
    </Root>
  );
};

const Root = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;

  ${props => props.styles}
`;

export default IconButton;
