import React, { useEffect, useRef, useState } from 'react';

import { Content, RightBlock, FooterIntroduce, Root, Image, ImageWrapper } from './styled';
import sanitize from '@src/lib/sanitize';
import { useTranslation } from 'react-i18next';
import { ScheduleDemoButton } from '@src/components/pages';
import { ChipedText, ContactUsButton, SectionInfo, PageLink } from '@src/components/common';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import SectionHeading from '@src/components/common/SectionHeader';
import { isEmpty } from '@shared/lib';
import WatchVideoButton from '../../pages/WatchVideoButton';
import { ImageComponent } from '@src/components/common';

const ImageAndTextSection: React.FC<{
  image?: string;
  imageHeightKoeff?: number;
  chip?: string;
  chipText?: string;
  chipColor?: string;
  sectionHeading?: string;
  sectionInfo?: string;
  imageWrapperStyles?: any;
  imageText?: string;
  styles?: any;
  displayLink?: boolean;
  linkTitle?: string;
  linkUrl?: string;
  videoUrl?: string;
  id?: string;
}> = ({
  image,
  imageHeightKoeff,
  chip,
  chipText,
  chipColor,
  sectionHeading,
  sectionInfo,
  imageWrapperStyles,
  imageText,
  displayLink,
  styles,
  linkTitle,
  linkUrl,
  videoUrl,
  id
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { xs, mobile, tablet, desktop } = useMediaQuery();
  const imageWrapperRef = useRef();
  const [imageWrapperHeight, setImageWrapperHeight] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      if (imageWrapperRef && imageWrapperRef.current) {
        // @ts-ignore
        const width = imageWrapperRef.current.clientWidth;

        const h = width * imageHeightKoeff;

        setImageWrapperHeight(h);

        if (isEmpty(h)) {
          setTimeout(() => {
            handleResize();
          }, 100);
        }
      }
    };

    if (imageWrapperRef && imageWrapperRef.current) {
      window.addEventListener('resize', handleResize);

      handleResize();
    }

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, [mobile, tablet, imageWrapperRef.current, imageHeightKoeff]);

  return (
    <Root styles={styles} id={id}>
      <Content>
        {desktop && (
          <ImageWrapper ref={imageWrapperRef} styles={imageWrapperStyles} height={imageWrapperHeight}>
            <ImageComponent image={image} />
          </ImageWrapper>
        )}

        <RightBlock className="iandtext-right-block">
          <ChipedText chip={chip} text={chipText} chipColor={chipColor} />
          <SectionHeading dangerouslySetInnerHTML={sanitize(sectionHeading)} />

          {tablet && (
            <ImageWrapper ref={imageWrapperRef} styles={imageWrapperStyles} height={imageWrapperHeight}>
              <ImageComponent image={image} />
            </ImageWrapper>
          )}

          <SectionInfo>{sectionInfo}</SectionInfo>
          <FooterIntroduce>
            <ScheduleDemoButton />
            <ContactUsButton />
            <WatchVideoButton videoUrl={videoUrl} />
            {/*{imageText && <ImageText>{imageText}</ImageText>}*/}
          </FooterIntroduce>
          {displayLink && <PageLink linkTitle={linkTitle} linkUrl={linkUrl} />}
        </RightBlock>
      </Content>
    </Root>
  );
};

export default ImageAndTextSection;
