import React, { useCallback } from 'react';
import { ICONS } from '@src/assets/icons';
import IconButton from '@src/components/common/IconButton';
import { APPLE_STORE_LINK } from '@src/config';
import ahoy, { PLATFORM_DESKTOP } from '@src/core/ahoy';
import useAhoyEventParams from '@src/hooks/useAhoyEventParams';

interface Props {
  appIosUrl?: any;
  src?: any;
  buttonStyles?: any;
}
const AppleStoreButton: React.FC<Props> = ({
  appIosUrl = APPLE_STORE_LINK,
  src = ICONS.APPLE_STORE_BADGE_ICON,
  buttonStyles
}): JSX.Element | null => {
  const ahoyEventParams = useAhoyEventParams({ platform: PLATFORM_DESKTOP });

  const onClick = useCallback(() => {
    ahoy.trackAppleStoreLinkClick(ahoyEventParams);
  }, [ahoyEventParams]);

  return (
    <a href={appIosUrl} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <IconButton src={src} width={156} height={50} styles={buttonStyles} />
    </a>
  );
};

export default AppleStoreButton;
