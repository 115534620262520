import React from 'react';
import { Element, scroller } from 'react-scroll';
import { Root } from './styled';
import sanitize from '@src/lib/sanitize';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';
import { ScrollIcon1 } from '@src/components/common';
import { useMediaQuery } from '@src/hooks/useMediaQuery';

const ScrollComponent: React.FC<{ scrollSectionName?: string; buttonTitle?: string }> = ({
  scrollSectionName,
  buttonTitle
}): JSX.Element | null => {
  const { t } = useTranslation();
  const { tablet, desktop } = useMediaQuery();
  const goToScrollSection = () => {
    scroller.scrollTo(`${scrollSectionName}`, {
      duration: 2400,
      delay: 20,
      smooth: true
    });
  };
  return (
    <Root>
      <Button onClick={goToScrollSection} className="scrollButton" shape="round" icon={<ScrollIcon1 color="#8a2be2" />}>
        {buttonTitle}
      </Button>
    </Root>
  );
};

export default ScrollComponent;
