import styled from 'styled-components';
import { Text } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  display: flex;
  align-items: center;

  ${props => props.styles}
`;

const Input = styled.input`
  height: 70px;
  width: 100%;

  padding: 20px 20px 20px
    ${props => {
      return props.leftPad ? '50px' : '20px';
    }};

  border-radius: 4px;
  border: solid 2px ${colors.MIDLLE_GREY};
  background-color: ${colors.WHITE};

  transition: border-color 0.2s linear;
  outline: none;
  box-sizing: border-box;

  font-size: 18px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.semibold};

  &:focus,
  &:hover {
    border-color: ${colors.ALGAE_GREEN};
  }

  &::placeholder {
    color: ${colors.BATTLESHIP_GREY};
  }

  ${props => props.styles}
`;

const Units = styled(Text)`
  margin-left: 15px;

  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;

  line-height: 1.78;
  letter-spacing: 0.5px;

  color: ${colors.CHARCOAL_GREY};
`;

export { Input, Root, Units };

export default {
  Input,
  Root,
  Units
};
