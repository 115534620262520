import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.validateResetToken(');

const query = gql`
  mutation ValidateResetToken($email: String, $phone: String, $token: String!) {
    contractorValidateResetToken(email: $email, phone: $phone, resetToken: $token) {
      success
    }
  }
`;

type ServerResponse = {
  contractorValidateResetToken: Response;
};

type Response = {
  success: boolean;
};

export default async (
  { email, phone, token }: { email?: string; phone?: string; token: string },
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      email,
      phone,
      token
    },
    options
  );

  return result.contractorValidateResetToken;
};
