import gql from 'graphql-tag';
import { mutation } from '@src/api/core/gql';
import { buildIdInput } from '@shared/api/brokrete/helper';

const inviteConfirm = async ({ city, country, invite, email, name, phone, password }) => {
  return mutation(inviteConfirmMutation, {
    ...(country && { country: buildIdInput(country) }),
    ...(city && { city: buildIdInput(city) }),
    ...(email && { email }),
    invite: buildIdInput(invite),
    phone,
    name,
    password
  }).then(({ inviteConfirm }) => inviteConfirm.success);
};
export default inviteConfirm;

const inviteConfirmMutation = gql`
  mutation inviteConfirm(
    $city: IdInput
    $country: IdInput
    $invite: IdInput!
    $email: String
    $name: String
    $password: String
    $phone: String
  ) {
    inviteConfirm(
      city: $city
      country: $country
      invite: $invite
      name: $name
      password: $password
      phone: $phone
      email: $email
    ) {
      success
    }
  }
`;
