import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { Text as TextCommon } from '@src/components/common';

const Color = styled.img`
  min-width: 80px;
  height: 80px;

  margin-right: 15px;

  border-radius: 50%;

  cursor: pointer;
`;

const Root = styled.div`
  height: 80px;
  border-radius: 50px;
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  min-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;

  background-color: rgb(40, 42, 49, 0.2);
  cursor: default;

  z-index: 2147483647;

  display: ${props => (props.active ? 'block' : 'none')};
`;

const Palette = styled.div`
  position: absolute;
  padding: 30px;
  right: 0;
  top: 0;
  min-width: 400px;
  min-height: 100vh;
  max-height: 100vh;

  overflow-y: scroll;

  background-color: ${colors.WHITE};

  box-sizing: border-box;
`;

const Options = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Title = styled(TextCommon)`
  margin-bottom: 20px;
  font-family: ${fontFamily.semiBold};
  font-size: 24px;
  font-weight: ${fontWeight.semibold};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.2px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 50px;
  }
`;

const ButtonPaint = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  min-width: 80px;
  min-height: 80px;

  margin-right: 15px;

  cursor: pointer;
`;

const Placeholder = styled(TextCommon)`
  display: block;
  font-family: ${fontFamily.semiBold};
  font-size: 18px;
  font-weight: ${fontWeight.semibold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.5px;

  color: ${colors.BATTLESHIP_GREY};
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled(TextCommon)`
  margin-top: 5px;

  font-family: ${fontFamily.regular};
  font-size: 18px;
  font-weight: ${fontWeight.normal};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
`;

const Price = styled(TextCommon)`
  font-family: ${fontFamily.extraBold};
  font-size: 18px;
  font-weight: ${fontWeight.extraBold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
`;

export { Background, ButtonPaint, Col, Color, Icon, Palette, Root, Title, Row, Options, Placeholder, Text, Price };

export default {
  Background,
  ButtonPaint,
  Col,
  Color,
  Icon,
  Palette,
  Root,
  Title,
  Row,
  Options,
  Placeholder,
  Text,
  Price
};
