import React from 'react';
import { ICONS } from '@src/assets/icons';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';
import { breakpoints, fontWeight } from '@src/defaultStyles';

type Props = {
  className?: any;
  onClick: Function;
};
const SeeMoreButton: React.FC<Props> = ({ className, onClick }): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className} onClick={onClick}>
      <Bounce>
        <Icon src={ICONS.ARROW_RIGHT} />
      </Bounce>
      {t('landing.main.more')}
    </Wrapper>
  );
};
export default SeeMoreButton;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: ${fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 1px;
  cursor: pointer;

  @media (${breakpoints.tablet}) {
    display: none;
  }
`;

// FIXME: move everything to an interpolation style
const bounceKeyframes = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0px);
    }
    40% {
        transform: translateY(-15px);
    }
    60% {
        transform: translateY(-5px);
    }
`;

const animation = css`
  animation: ${bounceKeyframes} 1.8s;
  animation-iteration-count: 3;
  animation-delay: 1s;
`;

const Bounce = styled.div`
  display: flex;
  align-items: center;
  ${animation};
`;

const Icon = styled.img({
  transform: 'rotate(90deg)',
  marginRight: '15px',
  height: '25px',
  width: '20px'
});
