import React from 'react';
import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

interface Props {
  children: JSX.Element;
  [x: string]: any;
}
const Button: React.FC<Props> = ({ children, ...otherProps }): JSX.Element | null => {
  return <Root {...otherProps}>{children}</Root>;
};

export default Button;

const Root = styled.button`
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${props => props.width || '150px'};
  height: 50px;
  padding: 0 20px;
  font-size: 14px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.semibold};
  color: ${props => (props.disabled ? colors.CLOUDY_BLUE : props.primary ? colors.WHITE : colors.CHARCOAL_GREY)};
  border-radius: 2px;
  background-color: ${props => (props.disabled ? colors.VERY_LIGHT_PINK : props.primary ? colors.LIGHT_PURPLE : colors.WHITE)};
  border: none;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s ease, color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;

  pointer-events: ${props => (props.disabled ? 'none' : 'all')};

  &:hover {
    color: ${props => (props.primary ? colors.WHITE : colors.CHARCOAL_GREY)};
    transform: scale(0.96);
  }

  &:focus {
    -webkit-box-shadow: 0 0 10px 0 rgba(31, 184, 65, 1);
    -moz-box-shadow: 0 0 10px 0 rgba(31, 184, 65, 1);
    box-shadow: 0 0 10px 0 rgba(31, 184, 65, 1);
  }

  -webkit-font-smoothing: antialiased;
  backface-visibility: hidden;
  ${props => props.styles}
`;
