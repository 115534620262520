import React from 'react';

import { colors } from '@src/defaultStyles';

interface Props {
  className?: any;
  color?: string;
  width?: number;
  height?: number;
  onClick: (e: React.MouseEvent) => void;
}

const IconFacebook: React.FC<Props> = ({
  className,
  color = colors.BLACK,
  width = 22,
  height = 22,
  onClick
}): JSX.Element | null => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Brokrete_main_SELL" transform="translate(-185.000000, -8109.000000)" fill={color} fillRule="nonzero">
          <g id="Group-88-Copy-2" transform="translate(150.000000, 7652.000000)">
            <g id="Group-87" transform="translate(0.000000, 0.012568)">
              <g id="Group-23" transform="translate(0.000000, 420.871949)">
                <g id="Group-24" transform="translate(0.000000, 36.980426)">
                  <g id="ic_facebook" transform="translate(35.338497, 0.000000)">
                    <path
                      d="M10.0967134,0 C4.52080342,0 0,4.42865269 0,9.89090494 C0,14.8495453 3.72905281,18.9443799 8.58725473,19.659822 L8.58725473,12.5119947 L6.08915956,12.5119947 L6.08915956,9.91233523 L8.58725473,9.91233523 L8.58725473,8.18225111 C8.58725473,5.31800989 10.0117327,4.06104072 12.4416751,4.06104072 C13.6053213,4.06104072 14.2212208,4.14593765 14.5123427,4.18385279 L14.5123427,6.45299123 L12.8547989,6.45299123 C11.8232514,6.45299123 11.4631353,7.41158477 11.4631353,8.49134189 L11.4631353,9.91233523 L14.4862595,9.91233523 L14.0765012,12.5119947 L11.4631353,12.5119947 L11.4631353,19.6804281 C16.3911728,19.0259799 20.1934268,14.8981756 20.1934268,9.89090494 C20.1934268,4.42865269 15.6726234,0 10.0967134,0 Z"
                      id="Path"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconFacebook;
