import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const MenuToggleButton: React.FC<{ className?: any; onClick?: Function }> = ({ className, onClick }): JSX.Element | null => {
  return (
    <Button className={className} onClick={onClick}>
      <Line />
      <Line />
      <Line />
    </Button>
  );
};

export default MenuToggleButton;

const Button = styled.div`
  cursor: pointer;

  & > span:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const Line = styled.span`
  display: block;
  width: 33px;
  height: 4px;
  position: relative;

  background: ${colors.ALGAE_GREEN};
  border-radius: 3px;
`;
