import styled from 'styled-components';
import Heading from '@src/components/common/Heading';
import { breakpoints, fontWeight, fontFamily, colors } from '@src/defaultStyles';
import Text from '@src/components/common/Text';

const PageHeading = styled(Heading)`
  margin-bottom: 20px;
  font-size: 27px;
  font-weight: ${fontWeight.bold};

  @media (${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
  }

  @media (${breakpoints.mobile}) {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 20px;
  }
`;

const TextInfo = styled(Text)`
  display: inline-block;
  font-size: 22px;
  width: 100%;
  font-weight: ${fontWeight.normal};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 30px;

  @media (${breakpoints.mobile}) {
    text-align: center;
  }
`;

const ProductsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));

  margin-top: 80px;
  box-sizing: border-box;

  @media (${breakpoints.tablet}) {
    grid-template-columns: repeat(auto-fit, minmax(275px, 330px));
    justify-content: center;
    grid-gap: 30px;
  }

  @media (${breakpoints.mobile}) {
    grid-template-columns: repeat(auto-fit, minmax(230px, 275px));
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ViewMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  color: ${colors.ALGAE_GREEN};
  cursor: pointer;
`;

export { PageHeading, TextInfo, LoaderContainer, ProductsContainer, ViewMoreButton };
