import React, { useCallback } from 'react';
import styled from 'styled-components';
import Label from 'components/common/controls/Label';
import { ErrorMessage, Select } from 'components/common/index';

const Dropdown = ({
  id,
  field,
  className,
  form: { errors, touched, setFieldValue },
  label,
  toggleButtonStyles,
  options,
  disabled,
  prepareItem
}) => {
  const { name, value } = field;
  const invalid = errors[name] && touched[name];

  const onChange = useCallback(
    selectedFiles => {
      setFieldValue(name, selectedFiles);
    },
    [name, setFieldValue]
  );

  return (
    <InputGroup className={className}>
      {label && (
        <Label htmlFor={id}>
          {label}
          {invalid && <ErrorMessage message={errors[name]} />}
        </Label>
      )}

      <Select
        disabled={disabled}
        toggleButtonStyles={toggleButtonStyles}
        items={options}
        prepareItem={prepareItem}
        selectedItem={options.find(opt => opt === value || (opt.id && value && opt.id === value.id))}
        onChange={onChange}
      />
    </InputGroup>
  );
};

export default Dropdown;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;
