import styled from 'styled-components';
import RootContainer from '@src/components/common/RootContainer';
import { breakpoints, colors } from '@src/defaultStyles';

const Root = styled(RootContainer)`
  display: flex;
  justify-content: center;
  @media (${breakpoints.tablet}) {
    padding: 0;
  }
`;
const Content = styled.div`
  background: ${props => props.backgroundColor};
  display: flex;
  color: ${colors.WHITE};
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1300px;

  @media (${breakpoints.tablet}) {
    /* padding-left: 40px; */

    border-radius: 0px;
  }
  @media (${breakpoints.mobile}) {
    flex-direction: column;
    padding: 0;
  }
`;
const Info = styled.div`
  //   flex-grow: 1;
  display: flex;
  max-width: 50%;
  flex-direction: column;
  justify-content: center;
  padding: 0 100px;
  @media (${breakpoints.tablet}) {
    border-radius: 0px;

    padding: 50px 50px;
  }
  @media (${breakpoints.mobile}) {
    align-self: center;
    text-align: center;
    margin: 10px 10px;
    padding: 50px 5px;

    max-width: 100%;
  }
`;
const Title = styled.div`
  font-size: 35px;
  font-weight: bold;
  line-height: 1.3;
  @media (${breakpoints.mobile}) {
    font-size: 28px;
  }
`;
const Paragraph = styled.p`
  color: ${colors.MIDLLE_GREY};
`;
const Buttons = styled.div`
  display: flex;
  > button {
    margin-right: 10px;
    @media (${breakpoints.tablet}) {
      margin-bottom: 20px;
      align-self: flex-start;
    }
    @media (${breakpoints.mobile}) {
      align-self: center;
    }
  }
  @media (${breakpoints.tablet}) {
    flex-direction: column;
    margin-top: 30px;
  }
  @media (${breakpoints.mobile}) {
    align-self: center;
  }
`;
const Image = styled.img`
  //   flex-grow: 1;
  max-width: 50%;

  @media (${breakpoints.mobile}) {
    max-width: 100%;
  }
`;
export { Root, Content, Info, Title, Paragraph, Buttons, Image };
