import gql from 'graphql-tag';

import { FeePrice, OptionPrice, Order, Place, Product, ProductPrice, TrucksConfig } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';
import { buildAddressInput, buildIdInput, buildProductPricesInput } from '../helper';

const gqlQuery = gql`
  query OrderPreparatoryConfig(
    $product: IdInput!
    $productPrices: [ProductPriceInput!]
    $address: AddressInput!
    $currency: String
    $order: IdInput
  ) {
    orderPreparatoryConfig(order: $order, input: { category: $product, address: $address, productPrices: $productPrices }) {
      options(currency: $currency) {
        option {
          ...option
        }
        price {
          ...price
        }
      }
    }
  }
  ${fragments.option}
  ${fragments.price}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  options: Array<OptionPrice>;
};

type Props = {
  product: Product;
  productPrices: Array<ProductPrice>;
  address: Place;
  currency?: string;
  order?: Order;
};

export default async (
  { product, productPrices, address, currency, order }: Props,
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      product: buildIdInput(product),
      productPrices: buildProductPricesInput(productPrices),
      address: buildAddressInput(address),
      order: buildIdInput(order),
      currency
    },
    options
  );

  return result.orderPreparatoryConfig;
};
