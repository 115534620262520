/* eslint-disable no-unused-vars */
import { ApolloClient, ApolloLink, DefaultOptions, from, HttpLink, InMemoryCache } from '@apollo/client';
import { getClientInfo } from '@shared/core/server/common';
import { logs } from '@shared/lib';
import ErrorsResolver from '@shared/lib/ErrorsResolver';
import { API_URL } from '@src/config';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const httpLink = new HttpLink({ uri: `${API_URL}/gql` });

const logger = logs.getLogger('server.gql');

const options: DefaultOptions = {
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

const clientInfoMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      clientInfo: getClientInfo()
    }
  }));
  return forward(operation).map(response => {
    const context = operation.getContext();
    const {
      response: { headers }
    } = context;
    return response;
  });
});

const client = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: options
});

const query = async (query, variables = null, options = null) => {
  logger.info('Try to start query', query, variables);

  const result = await client.query({
    query: query,
    variables: variables
  });

  logger.info('Response', result);

  if (result.errors != null && result.errors.length > 0) {
    result.errors.forEach(error => {
      toastr.error(error.message);
    });
  }

  return result.data;
};

const mutation = async (query, variables = null, options = null) => {
  logger.info('Try to start mutation', query, variables);

  const result = await client.mutate({
    mutation: query,
    variables: variables
  });

  logger.info('Response', result);

  if (result.errors != null && result.errors.length > 0) {
    result.errors.forEach(error => {
      toastr.error(error.message);
    });
  }

  return result.data;
};

export type Options = {
  errorsResolver?: ErrorsResolver;
};

export { query, mutation };
