import * as martinez from 'martinez-polygon-clipping';
import { cast } from './index';
import { LatLng, Location, Plant } from '../interfaces';

// the radius of the earth, km
const earthsRadius = 6371;

function deg2rad(value) {
  return value * (Math.PI / 180);
}

function rad2deg(value) {
  return value * (180 / Math.PI);
}

export function drawCircle(point: Location, radius: number, dir: 1 | -1 = 1): Array<Location> {
  const count = 32;

  // find the radius in lat/lon
  const rLat = rad2deg(radius / earthsRadius);
  const rLng = rLat / Math.cos(deg2rad(point.latitude));

  const result: Array<Location> = [];

  // one extra here makes sure we connect the
  const start = dir === 1 ? 0 : count + 1;
  const end = dir === 1 ? count + 1 : 0;

  for (let i = start; dir == 1 ? i < end : i > end; i += dir) {
    const theta = Math.PI * (i / (count / 2));
    const ey = point.longitude + rLng * Math.cos(theta); // center a + radius x * cos(theta)
    const ex = point.latitude + rLat * Math.sin(theta); // center b + radius y * sin(theta)
    result.push({
      latitude: ex,
      longitude: ey
    });
  }

  return result;
}

export function distanceBetween(point1: Location, point2: Location) {
  const dLat = deg2rad(point2.latitude - point1.latitude); // deg2rad below
  const dLon = deg2rad(point2.longitude - point1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(point1.latitude)) * Math.cos(deg2rad(point2.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = earthsRadius * c; // Distance in km

  return d;
}

export function buildPlantGroups(plants: Array<Plant>): Array<Array<Plant>> {
  return plants.reduce<Array<Array<Plant>>>((result, plant) => {
    if (result.length === 0) {
      result.push([plant]);
      return result;
    }
    const intersectionGroups = result
      .map((group, index) => {
        const intersection =
          group.find(
            value => distanceBetween(value.address.location, plant.address.location) < value.deliveryRadius + plant.deliveryRadius
          ) != null;

        return intersection ? index : -1;
      })
      .filter(value => value >= 0);

    if (intersectionGroups.length === 0) {
      result.push([plant]);
      return result;
    }

    result[intersectionGroups[0]].push(plant);

    if (intersectionGroups.length > 1) {
      for (let i = intersectionGroups.length - 1; i > 0; i--) {
        result[intersectionGroups[0]].push(...result[intersectionGroups[i]]);
        delete result[intersectionGroups[i]];
      }
    }

    return result;
  }, []);
}

export function optimize(value: Array<Array<Location>>): Array<LatLng> {
  const polygons = value.map(item => item.map(point => [point.latitude, point.longitude]));

  let result = polygons[0];

  for (let j = 1; j < polygons.length; j++) {
    const request = polygons[j];
    const union = martinez.union([result], [request]);

    result = cast(union[0][0]);
  }

  return result.map(point => ({ lat: point[0], lng: point[1] }));
}
