import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import { withTranslation } from 'react-i18next';
import SignupForm from './SignupForm';
import { signup } from 'api/core';
import RootContainer from 'components/common/RootContainer';
import ContentContainer from 'components/common/ContentContainer';
import { BrokreteLogo } from '@src/components/common';
import ContactsScreen from 'components/common/FooterWithContacts';
import ReadyToStarted from 'components/common/FooterWithContacts/ReadyToStarted';
import Heading from 'components/common/Heading';
import { breakpoints } from 'defaultStyles';
import sanitize from '@src/lib/sanitize';

class SingupPage extends Component {
  get queryParams() {
    return qs.parse(this.props.location.search);
  }

  get referralCode() {
    return this.queryParams.referral_code;
  }

  get referralWidget() {
    return this.queryParams.referral_widget;
  }

  onSubmit = async (params, actions) => {
    const { history } = this.props;

    await signup(
      params,
      {
        referralCode: this.referralCode,
        referralWidget: this.referralWidget
      },
      history
    );

    // actions.resetForm()
  };

  render() {
    return (
      <Fragment>
        <BrokreteLogo />

        <Root>
          <ContentContainerStyled>
            <HeadingWrapper>
              <PageHeading dangerouslySetInnerHTML={sanitize(this.props.t('landing.main.title'))} />
            </HeadingWrapper>

            <SignupForm onSubmit={this.onSubmit} />
          </ContentContainerStyled>
        </Root>
        <ReadyToStarted />
        <ContactsScreen />
        {/* <Footer support={false} /> */}
      </Fragment>
    );
  }
}

export default withTranslation()(SingupPage);

const Root = styled(RootContainer)`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 135px;
  min-height: 100vh;
`;

const ContentContainerStyled = styled(ContentContainer)`
  max-width: 100%;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PageHeading = styled(Heading)`
  max-width: 700px;
  margin-bottom: 50px;
  font-size: 52px;

  text-align: center;

  @media (${breakpoints.tablet}) {
    width: 100%;
    text-align: center;
    font-size: 42px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 32px;
  }
`;
