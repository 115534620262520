import I18n from 'i18next';

export const DRAWER_MENU_LIST = [
  {
    items: [
      {
        title: I18n.t('header.home'),
        link: '/',
        sections: [
          {
            title: I18n.t('desktopDrawer.home.item1'),
            description: '',
            link: '#OFFER_SERVICES'
          },
          {
            title: I18n.t('desktopDrawer.home.item2'),
            description: '',
            link: '#TECH_STACK'
          },
          {
            title: I18n.t('desktopDrawer.home.item3'),
            description: '',
            link: '#LANDING_CONTENT_1'
          },
          {
            title: I18n.t('desktopDrawer.home.item4'),
            description: '',
            link: '#LANDING_CONTENT_2'
          },
          {
            title: I18n.t('desktopDrawer.home.item5'),
            description: '',
            link: '#LANDING_SECTION_1'
          },
          {
            title: I18n.t('desktopDrawer.home.item6'),
            description: '',
            link: '#LANDING_CONTENT_3'
          },
          {
            title: I18n.t('desktopDrawer.home.item7'),
            description: '',
            link: '#LANDING_CONTENT_4'
          },
          {
            title: I18n.t('desktopDrawer.home.item8'),
            description: '',
            link: '#LANDING_SECTION_2'
          },
          {
            title: I18n.t('desktopDrawer.home.item9'),
            description: '',
            link: '#LANDING_CONTENT_5'
          },
          {
            title: I18n.t('desktopDrawer.home.item10'),
            description: '',
            link: '#LANDING_CONTENT_6'
          },
          {
            title: I18n.t('desktopDrawer.home.item11'),
            description: '',
            link: '#LANDING_SECTION_3'
          },
          {
            title: I18n.t('desktopDrawer.home.item12'),
            description: '',
            link: '#LANDING_CONTENT_7'
          },
          {
            title: I18n.t('desktopDrawer.home.item13'),
            description: '',
            link: '#LANDING_CONTENT_8'
          },
          {
            title: I18n.t('desktopDrawer.home.item14'),
            description: '',
            link: '#LANDING_CONTACT_SECTION'
          }
        ]
      }
    ]
  },
  {
    title: I18n.t('header.services'),
    items: [
      {
        title: I18n.t('header.servicesItems.item1'),
        link: '/services/custom-software-development',
        sections: [
          {
            title: I18n.t('desktopDrawer.services.customSoftwareDevelopment.item1'),
            description: '',
            link: '#CUSTOM_SOFTWARE_DEVELOPMENT_PREVIEW'
          },
          {
            title: I18n.t('desktopDrawer.services.customSoftwareDevelopment.item2'),
            description: '',
            link: '#CUSTOM_SOFTWARE_DEVELOPMENT_SERVICES'
          }
        ]
      },
      {
        title: I18n.t('header.servicesItems.item2'),
        link: '/services/web-development',
        sections: [
          {
            title: I18n.t('desktopDrawer.services.webDevelopment.item1'),
            description: '',
            link: '#WEB_DEVELOPMENT_PREVIEW'
          },
          {
            title: I18n.t('desktopDrawer.services.webDevelopment.item2'),
            description: '',
            link: '#WEB_DEVELOPMENT_TECH_STACK'
          }
        ]
      },
      {
        title: I18n.t('header.servicesItems.item3'),
        link: '/services/mobile-apps-development',
        sections: [
          {
            title: I18n.t('desktopDrawer.services.mobileAppsDevelopment.item1'),
            description: '',
            link: '#MOBILE_APPS_DEVELOPMENT_TECH_STACK'
          }
        ]
      },
      {
        title: I18n.t('header.servicesItems.item4'),
        link: '/services/ecommerce-development',
        sections: [
          {
            title: '',
            description: '',
            link: ''
          }
        ]
      },
      {
        title: I18n.t('header.servicesItems.item5'),
        link: '/services/product-development',
        sections: [
          {
            title: '',
            description: '',
            link: ''
          }
        ]
      },
      {
        title: I18n.t('header.servicesItems.item6'),
        link: '/services/cloud-application-development',
        sections: [
          {
            title: I18n.t('desktopDrawer.services.cloudApplicationDevelopment.item1'),
            description: '',
            link: '#CLOUD_APPLICATION_DEVELOPMENT_TECH_STACK'
          }
        ]
      },
      {
        title: I18n.t('header.servicesItems.item7'),
        link: '/services/software-testing-qa',
        sections: [
          {
            title: '',
            description: '',
            link: ''
          }
        ]
      },
      {
        title: I18n.t('header.servicesItems.item8'),
        link: '/services/ui-ux-design',
        sections: [
          {
            title: I18n.t('desktopDrawer.services.uiUxDesign.item1'),
            description: '',
            link: '#UI_UX_DESIGN_TECH_STACK'
          }
        ]
      }
    ]
  },
  {
    title: I18n.t('header.technologies'),
    items: [
      {
        title: I18n.t('header.technologiesItems.item1'),
        link: '/technologies/backend',
        sections: [
          {
            title: I18n.t('desktopDrawer.technologies.backend.item1'),
            description: '',
            link: '#BACKEND_TECH_STACK'
          }
        ]
      },
      {
        title: I18n.t('header.technologiesItems.item2'),
        link: '/technologies/frontend',
        sections: [
          {
            title: I18n.t('desktopDrawer.technologies.frontend.item1'),
            description: '',
            link: '#FRONTEND_TECH_STACK'
          }
        ]
      },
      {
        title: I18n.t('header.technologiesItems.item3'),
        link: '/technologies/database-api',
        sections: [
          {
            title: I18n.t('desktopDrawer.technologies.databaseApi.item1'),
            description: '',
            link: '#DATABASE_API_TECH_STACK'
          }
        ]
      },
      {
        title: I18n.t('header.technologiesItems.item4'),
        link: '/technologies/dev-ops',
        sections: [
          {
            title: I18n.t('desktopDrawer.technologies.devOps.item1'),
            description: '',
            link: '#DEV_OPS_TECH_STACK'
          }
        ]
      },
      {
        title: I18n.t('header.technologiesItems.item5'),
        link: '/technologies/mobile',
        sections: [
          {
            title: '',
            description: '',
            link: ''
          }
        ]
      },
      {
        title: I18n.t('header.technologiesItems.item6'),
        link: '/technologies/others',
        sections: [
          {
            title: '',
            description: '',
            link: ''
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: I18n.t('header.about'),
        link: '/about',
        sections: [
          {
            title: '',
            description: '',
            link: ''
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        title: I18n.t('header.contactUs'),
        link: '/contact-us',
        sections: [
          {
            title: '',
            description: '',
            link: ''
          }
        ]
      }
    ]
  }
];
