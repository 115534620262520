import React, { useCallback } from 'react';
import styled from 'styled-components';
import { IconFacebook } from '@src/components/common/icons';

type Props = {
  className?: any;
  width: number;
  height: number;
  color?: string;
};
const FacebookButton: React.FC<Props> = ({ className, width, height, color }): JSX.Element | null => {
  const onFacebookClick = useCallback(() => {
    window.open('https://www.facebook.com/Smartcodev-INC-103307452008212/', '_blank').focus();
  }, []);

  return <IconFacebookStyled className={className} onClick={onFacebookClick} width={width} height={height} color={color} />;
};

export default FacebookButton;

const IconFacebookStyled = styled(IconFacebook)`
  &:hover {
    opacity: 0.5;
  }
`;
