import styled from 'styled-components';
import { Text } from '@src/components/common/index';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const SectionInfo = styled(Text)`
  display: block;
  text-align: left;
  max-width: 550px;
  font-size: 20px;
  letter-spacing: 0.49px;
  line-height: 28px;
  font-family: ${({ ff = fontFamily.medium }) => ff};
  font-weight: ${fontWeight.semibold};
  margin-bottom: 50px;
  color: ${colors.BLACK};
  line-height: 1.5;

  @media (${breakpoints.tablet}) {
    text-align: center;
    align-self: center;
    max-width: 600px;
    width: 100%;
    margin-bottom: 50px;
  }

  @media (${breakpoints.mobile}) {
    max-width: 500px;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  @media (${breakpoints.xs}) {
    max-width: 335px;
  }
`;

export default SectionInfo;
