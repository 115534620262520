import styled from 'styled-components';
import { breakpoints } from '@src/defaultStyles';

const RequestInfoButtonGroup = styled.div`
  position: relative;
  z-index: 10;
  max-width: 320px;

  grid-template-columns: minmax(auto, 250px) minmax(auto, 250px);
  display: grid;
  grid-gap: 20px;

  & > * {
    max-width: 250px;
    width: 100%;
  }

  @media (${breakpoints.xs}) {
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    max-width: 100vw;

    & > * {
      max-width: 100%;
      width: 100%;
    }
  }
`;

export default RequestInfoButtonGroup;
