import { Query } from './Query';
import { DrivingDirection } from './queries';
import { Location } from '../../../interfaces';

const API_ENDPOINT = 'https://maps.googleapis.com/maps/api/directions';

export default class GoogleDirections {
  private readonly apiKey;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  /**
   *
   * distance in kilometers
   * duration in minutes
   */
  public routePoints(
    origin: Location,
    destination: Location,
    mode = 'driving',
    precision: 'low' | 'high' = 'low'
  ): Promise<{
    distance: number;
    duration: number;
    coordinates: Location[];
  } | null> {
    const query: Query = new DrivingDirection({
      origin: `${origin.latitude},${origin.longitude}`,
      destination: `${destination.latitude},${destination.longitude}`,
      key: this.apiKey
    });

    return this.execute(query).then(result => {
      if (result.status !== 'OK') {
        console.log(result.error_message || result.status || 'Unknown error');
        return null;
      }

      if (result.routes.length > 0) {
        const route = result.routes[0];

        return {
          distance:
            route.legs.reduce((carry, curr) => {
              return carry + curr.distance.value;
            }, 0) / 1000,

          duration:
            route.legs.reduce((carry, curr) => {
              return carry + (curr.duration_in_traffic ? curr.duration_in_traffic.value : curr.duration.value);
            }, 0) / 60,

          coordinates:
            precision === 'low'
              ? this.decodePolyline([{ polyline: route.overview_polyline }])
              : route.legs.reduce((carry, curr) => {
                  return [...carry, ...this.decodePolyline(curr.steps)];
                }, [])
        };
      } else {
        return null;
      }
    });
  }

  private execute(query: Query): Promise<any> {
    return fetch(`${API_ENDPOINT}/json?${query.toQuery()}`)
      .then((response: Response) => {
        return response.json();
      })
      .catch((error: any) => {
        throw error;
      });
  }

  private decodePolyline(encodedRoutes: Array<{ polyline: { points: string } }>) {
    const points = [];
    for (const step of encodedRoutes) {
      const encoded = step.polyline.points;
      let index = 0;
      const len = encoded.length;
      let lat = 0,
        lng = 0;
      while (index < len) {
        let b,
          shift = 0,
          result = 0;
        do {
          b = encoded.charAt(index++).charCodeAt(0) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);

        const dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
        lat += dlat;
        shift = 0;
        result = 0;
        do {
          b = encoded.charAt(index++).charCodeAt(0) - 63;
          result |= (b & 0x1f) << shift;
          shift += 5;
        } while (b >= 0x20);
        const dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
        lng += dlng;

        points.push({ latitude: lat / 1e5, longitude: lng / 1e5 });
      }
    }
    return points;
  }
}
