import React from 'react';
import styled from 'styled-components';
import { ContentContainer } from 'components/common';
import { colors } from 'defaultStyles';
import { RootContainer } from 'components/common';

export default function MediaScreen() {
  return (
    <Root>
      <ContentContainer>
        <IFrameWrapper>
          <IFrame
            className="embed-responsive-video"
            src="https://www.youtube.com/embed/GSh6F2HJe3Y?rel=0"
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
          />
        </IFrameWrapper>
      </ContentContainer>
    </Root>
  );
}

const Root = styled(RootContainer)`
  background-color: ${colors.BACKGROUND_LIGHT};
`;

const IFrameWrapper = styled.div`
  position: relative;
  display: block;

  &:before {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
`;

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;
