import styled, { css } from 'styled-components';

import ContentContainer from '@src/components/common/ContentContainer';
import RootContainer from '@src/components/common/RootContainer';
import { Heading, Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { isEmpty } from '@shared/lib';

const Root = styled(RootContainer)`
  position: relative;
  background-color: ${colors.WHITE};
  box-sizing: border-box;
  padding-top: 75px;
  padding-bottom: 75px;

  @media (${breakpoints.tablet}) {
    padding: 60px 50px 60px 50px;
  }

  @media (${breakpoints.mobile}) {
    padding: 35px 20px 35px 20px;
  }

  ${props => props.styles}
`;

const Content = styled(ContentContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1240px;

  @media (${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }

  @media (${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .header {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-around;
      text-align: center;
      color: #ffffff;
      margin-bottom: 60px;
    }
    .footer {
      margin-bottom: 40px;
      width: 100%;
    }
    z-index: 1;
  }
`;

const SectionHeading = styled(Heading)`
  font-size: 42px;
  letter-spacing: 0.06px;
  line-height: 50px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.normal};
  line-height: 50px;
  text-align: left;
  color: ${colors.BLACK};

  @media (${breakpoints.tablet}) {
    width: 100%;
    text-align: center;
    font-family: ${fontFamily.semiBold};
    margin-top: 20px;
    margin-bottom: 20px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 24px;
    letter-spacing: 0.03px;
    line-height: 30px;
    width: 280px;
    margin-top: 0px;
    line-height: 36px;
  }
  letter-spacing: 1px;
`;

const PageHeading = styled(Heading)`
  font-size: 42px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.normal};
  text-align: center;
  color: ${colors.BLACK};
  margin-bottom: 80px;
`;

const RightBlock = styled.div`
  padding-left: 66px;

  @media (${breakpoints.tablet}) {
    height: 100%;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  @media (${breakpoints.mobile}) {
    height: 100%;

    display: flex;
    flex-direction: column;
  }
`;

const LeftBlock = styled.div`
  @media (${breakpoints.tablet}) {
    height: 100%;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
  @media (${breakpoints.tablet}) {
    justify-content: center;
  }
  @media (${breakpoints.mobile}) {
  }
`;

const HeaderIntroduce = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  background-color: ${colors.MERCURY};
  padding: 1px 2px;
  max-width: 565px;
  font-size: 14px;
  border-radius: 20px;
  margin-bottom: 30px;
  @media (${breakpoints.mobile}) {
    padding: 10px 15px;
    border-radius: 40px;
    margin-bottom: 20px;
  }
`;

const FooterIntroduce = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      margin: 5px 0;
    }
  }
`;

const HeaderIntroduceText = styled.div`
  margin: 0px 5px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  font-size: 14px;
  color: ${colors.BLACK};
  padding-left: 10px;

  @media (${breakpoints.mobile}) {
    line-height: 1.2;
  }
`;

const HeaderIntroduceChip = styled.div`
  background-color: ${colors.ALGAE_GREEN};
  padding: 1px 10px;
  border-radius: 50px;
`;

const SectionInfo = styled(Text)`
  display: block;
  text-align: left;
  max-width: 535px;
  font-size: 20px;
  letter-spacing: 0.49px;
  line-height: 28px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  margin-bottom: 0;
  color: ${colors.BLACK};
  line-height: 1.5;

  @media (${breakpoints.tablet}) {
    text-align: center;
    align-self: center;
    max-width: 620px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
    max-width: 320px;
  }
`;

const Image = styled.img`
  position: relative;
  width: 100%;
  align-self: center;
`;

const ImageWrapper = styled.div`
  display: grid;
  ${({ height }) =>
    !isEmpty(height)
      ? css`
          height: ${height}px;
        `
      : ''};

  @media (${breakpoints.tablet}) {
    margin-bottom: 40px;
  }

  @media (${breakpoints.mobile}) {
    margin-bottom: 20px;
  }

  ${props => props.styles}
`;

const ImageText = styled.div``;

export {
  ImageWrapper,
  ImageText,
  Image,
  Root,
  Content,
  SectionHeading,
  SectionInfo,
  PageHeading,
  RightBlock,
  LeftBlock,
  HeaderIntroduce,
  FooterIntroduce,
  HeaderIntroduceChip,
  HeaderIntroduceText
};
