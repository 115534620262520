import { Formik } from 'formik';

import React from 'react';

const defaultProps = {
  enableReinitialize: false,
  initialValues: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSubmit: () => {}
};
interface FormikInitialPropsType {
  onSubmit?: Function;
  enableReinitialize?: boolean;
  children?: JSX.Element | null;
  [x: string]: any;
}
const FormikForm = (Form, { validationSchema, initialValues, initialErrors }: FormikInitialPropsType) =>
  function (props): JSX.Element | null {
    const { children, onSubmit, className, formProps, ...restProps } = props;

    const onSubmitWrapper = (values, formikActions) => {
      if (onSubmit) {
        const submitResult = onSubmit(values, formikActions);
        if (submitResult instanceof Promise) {
          return submitResult.catch(error => {
            console.error(error);
          });
        }
      }

      return undefined;
    };

    return (
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validationSchema}
        {...restProps}
        onSubmit={onSubmitWrapper}
      >
        {renderProps => {
          return (
            <Form className={className} {...formProps} {...renderProps}>
              {children}
            </Form>
          );
        }}
      </Formik>
    );
  };

FormikForm.defaultProps = defaultProps;

export default FormikForm;
