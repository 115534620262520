import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

class DynamicComponent extends React.Component<{ load: Function; children?: any }, { component: any }> {
  constructor(props) {
    super(props);

    this.state = {
      component: null
    };
  }

  componentDidMount() {
    const { load } = this.props;

    load().then(module => {
      this.setState(() => ({
        component: module.default
      }));
    });
  }

  render() {
    return this.props.children(this.state.component);
  }
}

export default DynamicComponent;
