import { useContext } from 'react';
import { CTXConsumer } from '../provider/ctxProvider';

export default function useStore(stateName) {
  const [ctx, setCtx] = useContext<any>(CTXConsumer);

  const setValue = value => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(ctx[stateName]) : value;
      // Save to store
      setCtx({ [stateName]: valueToStore });
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [ctx[stateName], setValue];
}
