import { ParsedNumber, parseNumber } from 'libphonenumber-js';
import cast from './cast';

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const lowerCaseRegExp = /(?=.*[a-z])/;
const upperCaseRegExp = /(?=.*[A-Z])/;
const numberRegExp = /(?=.*[0-9])/;

export const email = (value: string) => {
  return value && value.length > 0 && emailRegExp.test(value);
};

export const phone = (value: string) => {
  try {
    const phoneNumber: ParsedNumber = cast(parseNumber(value, 'US'));

    return !!phoneNumber && phoneNumber.phone != null;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const passwordRegExps = [
  /(?=.{8,})/, // Must contain 8 or more characters
  /(?=.*[a-z])/, // Must contain a lower case character
  /(?=.*[A-Z])/ // Must contain an upper case character
];

export const password = (value: string) => {
  try {
    return value && passwordRegExps.map(regExp => regExp.test(value)).reduce((result, item) => result && item, true);
  } catch (e) {
    return false;
  }
};

export const match = (parentValue: string) => (value: string) => {
  return value && parentValue === value;
};

export const requireMinLength = (min: number) => (value: string) => {
  if (value) {
    return value.length >= min;
  }

  return min === 0;
};

export const required = (value: any) => {
  return !!value;
};

export const requireLowerCase = value => {
  try {
    return value && lowerCaseRegExp.test(value);
  } catch (e) {
    return false;
  }
};

export const requireUpperCase = value => {
  try {
    return value && upperCaseRegExp.test(value);
  } catch (e) {
    return false;
  }
};

export const requireNumber = value => {
  try {
    return value && numberRegExp.test(value);
  } catch (e) {
    return false;
  }
};
