import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import sanitize from '@src/lib/sanitize';
import I18n from 'i18next';
import { IMAGES } from '@src/assets/images';
import { Services, ServicesSectionRoot, ServicesSectionHeader, Root } from './styled';
import ServicesItem from './ServicesItem';

type Props = {
  id?: string;
};

const ServicesSection: React.FC<Props> = ({ id }): JSX.Element | null => {
  const { t } = useTranslation();
  const customSoftwareDevelopmentHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/custom-software-development', '_blank').focus();
  }, []);
  const webDevelopmentHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/web-development', '_blank').focus();
  }, []);
  const mobileAppsDevelopmentHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/mobile-apps-development', '_blank').focus();
  }, []);
  const ecommerceDevelopmentHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/ecommerce-development', '_blank').focus();
  }, []);
  const productDevelopmentHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/product-development', '_blank').focus();
  }, []);
  const cloudApplicationDevelopmentHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/cloud-application-development', '_blank').focus();
  }, []);
  const softwareTestingQaHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/software-testing-qa', '_blank').focus();
  }, []);
  const uiUxDesignHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/services/ui-ux-design', '_blank').focus();
  }, []);
  return (
    <Root id={id}>
      <ServicesSectionRoot>
        <ServicesSectionHeader dangerouslySetInnerHTML={sanitize(I18n.t('landing.servicesSection.header'))} />
      </ServicesSectionRoot>

      <Services>
        <ServicesItem
          onClick={customSoftwareDevelopmentHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.customSoftwareDevelopment.title')}
          text={I18n.t('landing.servicesSection.customSoftwareDevelopment.subtitle')}
          author={I18n.t('landing.servicesSection.customSoftwareDevelopment.author')}
          position={I18n.t('landing.servicesSection.customSoftwareDevelopment.position')}
        />

        <ServicesItem
          onClick={webDevelopmentHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.webDevelopment.title')}
          text={I18n.t('landing.servicesSection.webDevelopment.subtitle')}
          author={I18n.t('landing.servicesSection.webDevelopment.author')}
          position={I18n.t('landing.servicesSection.webDevelopment.position')}
        />

        <ServicesItem
          onClick={mobileAppsDevelopmentHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.mobileAppsDevelopment.title')}
          text={I18n.t('landing.servicesSection.mobileAppsDevelopment.subtitle')}
          author={I18n.t('landing.servicesSection.mobileAppsDevelopment.author')}
          position={I18n.t('landing.servicesSection.mobileAppsDevelopment.position')}
        />
        <ServicesItem
          onClick={ecommerceDevelopmentHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.ecommerceDevelopment.title')}
          text={I18n.t('landing.servicesSection.ecommerceDevelopment.subtitle')}
          author={I18n.t('landing.servicesSection.ecommerceDevelopment.author')}
          position={I18n.t('landing.servicesSection.ecommerceDevelopment.position')}
        />
        <ServicesItem
          onClick={productDevelopmentHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.productDevelopment.title')}
          text={I18n.t('landing.servicesSection.productDevelopment.subtitle')}
          author={I18n.t('landing.servicesSection.productDevelopment.author')}
          position={I18n.t('landing.servicesSection.productDevelopment.position')}
        />
        <ServicesItem
          onClick={cloudApplicationDevelopmentHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.cloudApplicationDevelopment.title')}
          text={I18n.t('landing.servicesSection.cloudApplicationDevelopment.subtitle')}
          author={I18n.t('landing.servicesSection.cloudApplicationDevelopment.author')}
          position={I18n.t('landing.servicesSection.cloudApplicationDevelopment.position')}
        />
        <ServicesItem
          onClick={softwareTestingQaHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.softwareTestingQa.title')}
          text={I18n.t('landing.servicesSection.softwareTestingQa.subtitle')}
          author={I18n.t('landing.servicesSection.softwareTestingQa.author')}
          position={I18n.t('landing.servicesSection.softwareTestingQa.position')}
        />
        <ServicesItem
          onClick={uiUxDesignHandler}
          image={IMAGES.SMART_CODEV_LOGO}
          title={I18n.t('landing.servicesSection.uiUxDesign.title')}
          text={I18n.t('landing.servicesSection.uiUxDesign.subtitle')}
          author={I18n.t('landing.servicesSection.uiUxDesign.author')}
          position={I18n.t('landing.servicesSection.uiUxDesign.position')}
        />
      </Services>
    </Root>
  );
};
export default ServicesSection;
