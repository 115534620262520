import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import I18n from 'i18next';
import ContactItem from './ContactItem';
import { CompanyPhoneNumber, CompanySalesEmail, Addresses } from '@src/config';
import WeWillReachYouModal from '@src/pages/LandingPage/modals/WeWillReachYouModal';
//import { send_mail } from '@src/api/core';
import sanitize from '@src/lib/sanitize';
import { InstagramButton, FacebookButton, LinkedinButton, TwitterButton } from '@src/components/pages';
import { Footer } from '@src/components/pages';
import {
  Root,
  Grid,
  Right,
  GetInTouchText,
  Form,
  SectionBottom,
  Section,
  Icons,
  SocialsContainer,
  LogoContainer,
  Container,
  WeOnSocialsText,
  SocialSection,
  SocialSectionRow,
  ContactItemLine,
  ContactSection,
  FooterLink,
  FooterLinkItem,
  FooterLinkTitle,
  BrokreteTextSubtitle,
  BrokreteSocialTop,
  TabletLinks,
  Image,
  GridContainer,
  TopGrid,
  OurContacts,
  ContactsContainer,
  ContactsHead,
  EmailPhone,
  ContactsTitle,
  ContactsInfo,
  OurOffices,
  OfficeContainer,
  OfficesHead,
  Office12,
  OfficesTitle,
  OfficesInfo,
  FooterSocial,
  TopContainer
} from './styled';
import { IMAGES } from '@src/assets/images';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { colors } from '@src/defaultStyles';
import { ContactSectionConfig } from '@src/config/ContactSectionConfig';

type Props = {
  id?: string;
};

const ContactsScreen: React.FC<Props> = ({ id }): JSX.Element | null => {
  const { t } = useTranslation();
  const [modalIsOpened, setModalOpened] = useState(false);
  const { mobile, tablet } = useMediaQuery();
  /*   const onSubmit = useCallback(async ({ fullName, email, message }, actions) => {
    await send_mail(fullName, email, message);

    actions.resetForm();

    setModalOpened(true);
  }, []); */

  const closeModal = useCallback(() => {
    setModalOpened(false);
  }, []);

  if (mobile) {
    return (
      <React.Fragment>
        <Root>
          <Container>
            <LogoContainer>
              <Image src={IMAGES.BROKRETE_LOGO} />
              <BrokreteTextSubtitle>{t('contactSection.header')}</BrokreteTextSubtitle>
            </LogoContainer>
            <Grid>
              <TabletLinks>
                {ContactSectionConfig.map(({ title, sections }, index) => (
                  <FooterLink key={index + title}>
                    <FooterLinkTitle>{title}</FooterLinkTitle>
                    {sections.map(({ text, link }, _indx) => (
                      <FooterLinkItem to={link} key={text + _indx}>
                        {text}
                      </FooterLinkItem>
                    ))}
                  </FooterLink>
                ))}
              </TabletLinks>

              <ContactSection>
                <GetInTouchText dangerouslySetInnerHTML={sanitize(t('contactSection.form.title'))} />
                <Form />
              </ContactSection>
              <OurContacts>
                <ContactsHead>{t('contactSection.contacts.title')}</ContactsHead>
                <EmailPhone>
                  <ContactsContainer>
                    <ContactsTitle>{t('contactSection.contacts.email')}</ContactsTitle>
                    <ContactsInfo>{CompanySalesEmail}</ContactsInfo>
                  </ContactsContainer>
                  <ContactsContainer>
                    <ContactsTitle>{t('contactSection.contacts.phoneNumber')}</ContactsTitle>
                    <ContactsInfo>{CompanyPhoneNumber}</ContactsInfo>
                  </ContactsContainer>
                </EmailPhone>
              </OurContacts>
              <OurOffices>
                <OfficesHead>{t('contactSection.office.title')}</OfficesHead>

                <OfficeContainer>
                  <OfficesTitle>{Addresses.Toronto.location}</OfficesTitle>
                  <OfficesInfo>{Addresses.Toronto.address}</OfficesInfo>
                </OfficeContainer>
              </OurOffices>
              <SocialsContainer>
                <Icons>
                  <TwitterButton className="twitter-icon" width={25.176} height={19.332} color={colors.GRAY} />
                  <FacebookButton className="facebook-icon" width={25.176} height={24.168} color={colors.GRAY} />
                  <LinkedinButton className="linkedin-icon" width={25.176} height={22.956} color={colors.GRAY} />
                  <InstagramButton className="instagram-icon" width={25.176} height={24.168} color={colors.GRAY} />
                </Icons>
              </SocialsContainer>
            </Grid>
            {/* <Footer /> */}
          </Container>

          <WeWillReachYouModal opened={modalIsOpened} onClose={closeModal} />
        </Root>
      </React.Fragment>
    );
  }

  if (tablet) {
    return (
      <React.Fragment>
        <Root>
          <Container>
            <SocialsContainer>
              <BrokreteSocialTop>
                <Image src={IMAGES.BROKRETE_LOGO} />
                <BrokreteTextSubtitle>{t('contactSection.header')}</BrokreteTextSubtitle>
              </BrokreteSocialTop>
            </SocialsContainer>
            <Grid>
              <TabletLinks>
                {ContactSectionConfig.map(({ title, sections }, index) => (
                  <FooterLink key={index + title}>
                    <FooterLinkTitle>{title}</FooterLinkTitle>
                    {sections.map(({ text, link }, _indx) => (
                      <FooterLinkItem to={link} key={text + _indx}>
                        {text}
                      </FooterLinkItem>
                    ))}
                  </FooterLink>
                ))}
              </TabletLinks>

              <ContactSection>
                <GetInTouchText dangerouslySetInnerHTML={sanitize(t('contactSection.form.title'))} />
                <Form />
              </ContactSection>
            </Grid>
            <OurContacts>
              <ContactsHead>{t('contactSection.contacts.title')}</ContactsHead>
              <EmailPhone>
                <ContactsContainer>
                  <ContactsTitle>{t('contactSection.contacts.email')}</ContactsTitle>
                  <ContactsInfo>{CompanySalesEmail}</ContactsInfo>
                </ContactsContainer>
                <ContactsContainer>
                  <ContactsTitle>{t('contactSection.contacts.phoneNumber')}</ContactsTitle>
                  <ContactsInfo>{CompanyPhoneNumber}</ContactsInfo>
                </ContactsContainer>
              </EmailPhone>
            </OurContacts>
            <OurOffices>
              <OfficesHead>{t('contactSection.office.title')}</OfficesHead>
              <Office12>
                <OfficeContainer>
                  <OfficesTitle>{Addresses.Toronto.location}</OfficesTitle>
                  <OfficesInfo>{Addresses.Toronto.address}</OfficesInfo>
                </OfficeContainer>
              </Office12>
            </OurOffices>
            <Icons>
              <TwitterButton className="twitter-icon" width={25.176} height={19.332} color={colors.GRAY} />
              <FacebookButton className="facebook-icon" width={25.176} height={24.168} color={colors.GRAY} />
              <LinkedinButton className="linkedin-icon" width={25.176} height={22.956} color={colors.GRAY} />
              <InstagramButton className="instagram-icon" width={25.176} height={24.168} color={colors.GRAY} />
            </Icons>
            {/* <Footer /> */}
          </Container>

          <WeWillReachYouModal opened={modalIsOpened} onClose={closeModal} />
        </Root>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Root id={id}>
        <Container>
          <SocialsContainer>
            <Image src={IMAGES.BROKRETE_LOGO} />
            <BrokreteTextSubtitle>{t('contactSection.header')}</BrokreteTextSubtitle>
          </SocialsContainer>

          <GridContainer>
            <TopContainer>
              <TopGrid>
                {ContactSectionConfig.map(({ title, sections }, index) => (
                  <FooterLink key={index + title}>
                    <FooterLinkTitle>{title}</FooterLinkTitle>
                    {sections.map(({ text, link }, _indx) => (
                      <FooterLinkItem to={link} key={text + _indx}>
                        {text}
                      </FooterLinkItem>
                    ))}
                  </FooterLink>
                ))}
              </TopGrid>
              <OurContacts>
                <ContactsHead>{t('contactSection.contacts.title')}</ContactsHead>
                <EmailPhone>
                  <ContactsContainer>
                    <ContactsTitle>{t('contactSection.contacts.email')}</ContactsTitle>
                    <ContactsInfo>{CompanySalesEmail}</ContactsInfo>
                  </ContactsContainer>
                  <ContactsContainer>
                    <ContactsTitle>{t('contactSection.contacts.phoneNumber')}</ContactsTitle>
                    <ContactsInfo>{CompanyPhoneNumber}</ContactsInfo>
                  </ContactsContainer>
                </EmailPhone>
              </OurContacts>
            </TopContainer>
            <ContactSection>
              <GetInTouchText dangerouslySetInnerHTML={sanitize(t('contactSection.form.title'))} />
              <Form />
            </ContactSection>
          </GridContainer>

          {/* <Right> */}
          {/* </Right> */}

          <OurOffices>
            <OfficesHead>{t('contactSection.office.title')}</OfficesHead>
            <Office12>
              <OfficeContainer>
                <OfficesTitle>{Addresses.Toronto.location}</OfficesTitle>
                <OfficesInfo>{Addresses.Toronto.address}</OfficesInfo>
              </OfficeContainer>
            </Office12>
          </OurOffices>
          <FooterSocial>
            {/*  <Footer /> */}
            <Icons>
              <TwitterButton className="twitter-icon" width={25.176} height={19.332} color={colors.GRAY} />
              <FacebookButton className="facebook-icon" width={25.176} height={24.168} color={colors.GRAY} />
              <LinkedinButton className="linkedin-icon" width={25.176} height={22.956} color={colors.GRAY} />
              <InstagramButton className="instagram-icon" width={25.176} height={24.168} color={colors.GRAY} />
            </Icons>
          </FooterSocial>
        </Container>

        <WeWillReachYouModal opened={modalIsOpened} onClose={closeModal} />
      </Root>
    </React.Fragment>
  );
};

export default ContactsScreen;
