import styled, { css } from 'styled-components';

import { Text } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 60px 10px 20px;
  min-height: 70px;
  max-height: 70px;

  background-color: ${colors.WHITE};
  border: 2px solid ${props => (props.bordered ? colors.MIDLLE_GREY : colors.WHITE)};
  border-radius: 4px;
  box-sizing: border-box;

  cursor: pointer;

  opacity: ${props => (props.disabled ? 0.3 : 1)};
  pointer-events: ${props => (props.disabled ? 'none' : 'all')};

  ${props => {
    if (!props.bordered) {
      return '';
    }

    return css`
      border: 2px solid ${props => (props.active ? colors.ALGAE_GREEN : colors.MIDLLE_GREY)};

      &:hover,
      &:focus,
      &:active {
        border: 2px solid ${colors.ALGAE_GREEN};
      }
    `;
  }}

  ${props => props.styles}
`;

const ItemIcon = styled.div`
  display: flex;
  margin-right: 10px;

  ${props => props.styles}
`;

const Image = styled.img`
  width: 30px;
  height: 16px;

  ${({ imageProps }) => imageProps && imageProps.styles}
`;

const Description = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

const Title = styled(Text)`
  margin-top: 4px;
  font-size: 14px;
  color: #282a31;
  line-height: 32px;
  letter-spacing: 0.5px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Subtitle = styled(Text)`
  font-size: 14px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
  line-height: 20px;
  letter-spacing: 0.5px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${colors.BATTLESHIP_GREY};
`;

export { Description, Image, ItemIcon, Root, Subtitle, Title };

export default {
  Description,
  Image,
  ItemIcon,
  Root,
  Subtitle,
  Title
};
