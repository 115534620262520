import gql from 'graphql-tag';

import { Order, Place, Product, ProductDecoratePrice, ProductPrice, ProductTypePrice } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';
import { buildAddressInput, buildIdInput } from '../helper';

const gqlQuery = gql`
  query Config($product: IdInput!, $address: AddressInput!, $productTypePrice: IdInput, $currency: String) {
    orderPreparatoryConfig(input: { category: $product, address: $address }) {
      productDecorates(productTypePrice: $productTypePrice, currency: $currency) {
        ...productDecoratePrice
      }
    }
  }
  ${fragments.productDecoratePrice}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  productDecorates: Array<ProductDecoratePrice>;
};

type Props = {
  product: Product;
  productTypePrice: ProductTypePrice;
  address: Place;
  currency?: string;
};

export default async (
  { product, productTypePrice, address, currency }: Props,
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      product: buildIdInput(product),
      productTypePrice: buildIdInput(productTypePrice.price),
      address: buildAddressInput(address),
      currency
    },
    options
  );

  return result.orderPreparatoryConfig;
};
