import React from 'react';
import styled from 'styled-components';
import { Text } from '@src/components/common';
import { colors, fontWeight } from '@src/defaultStyles';
import { breakpoints } from '@src/defaultStyles';

const ContactItem: React.FC<{
  icon: JSX.Element;
  text: Function | string;
  link?: boolean;
  href?: string;
  className?: string;
  info?: string;
  width?: number;
  height?: number;
  bold?: boolean;
}> = ({ icon, text, link, href, className, info, width, height, bold }): JSX.Element | null => {
  return (
    <Root className={className} align={info ? undefined : 'center'}>
      {typeof icon === 'string' ? <Icon className="contact-item-icon" src={icon} width={width} height={height} /> : icon}

      {link ? (
        <Link bold={bold} href={href}>
          {text}
        </Link>
      ) : (
        <div>
          <ContactText bold={bold}>{typeof text === 'function' ? text() : text}</ContactText>
          {info && <ContactText>{info}</ContactText>}
        </div>
      )}
    </Root>
  );
};

export default ContactItem;

const Root = styled.div`
  display: flex;
  align-items: ${({ align = 'flex-start' }) => align};
  margin-bottom: 15px;

  .contact-item-icon {
    margin-right: 15px;
  }

  @media (${breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .contact-item-icon {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  @media (${breakpoints.mobile}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .contact-item-icon {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }
`;

const Icon = styled.img({
  marginTop: '3px',
  marginRight: '20px'
});

// TODO: needs refactoring
const ContactText = styled(Text)`
  font-size: 16px;
  color: ${colors.BLACK};
  line-height: 1.33;
  font-weight: ${props => (props.bold ? fontWeight.black : 100)};

  @media (${breakpoints.tablet}) {
    text-align: center;
    padding: 5px 15px;
  }

  @media (${breakpoints.mobile}) {
    text-align: left;
    padding: 0;
    font-size: 14px;
  }
`;

const Link = styled.a`
  font-size: 16px;
  color: ${colors.BLACK};
  line-height: 1.33;
  font-weight: ${props => (props.bold ? fontWeight.black : fontWeight.semibold)};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  @media (${breakpoints.mobile}) {
    font-size: 14px;
  }
`;
