import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { City, Partner, Product, Region } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';
import { buildIdInput, buildRegionInput } from '../helper';

const logger = logs.getLogger('api.brokrete.config');

const gqlQuery = gql`
  query OrderPreparatoryConfig($partner: IdInput, $withPossibles: Boolean) {
    orderPreparatoryConfig(input: {}, partner: $partner) {
      cities(withPossibles: $withPossibles) {
        ...city
      }
    }
  }
  ${fragments.city}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  cities: Array<City>;
};

type Props = {
  partner?: Partner;
};

export default async ({ partner }: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      partner: buildIdInput(partner),
      withPossibles: true
    },
    options
  );

  return result.orderPreparatoryConfig;
};
