import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Root, Content, TechnologiesSectionHeader, TechnologiesSectionRoot } from './styled';
import TechnologiesItems from './TechnologiesItems';
import sanitize from '@src/lib/sanitize';
import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';
import { css } from 'styled-components';
import { colors } from '@src/defaultStyles';

type Props = {
  id?: string;
};

const TechnologiesSection: React.FC<Props> = ({ id }): JSX.Element | null => {
  const { t } = useTranslation();
  const backendHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/technologies/backend', '_blank').focus();
  }, []);
  const frontendHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/technologies/frontend', '_blank').focus();
  }, []);
  const databaseApiHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/technologies/database-api', '_blank').focus();
  }, []);
  const devOpsHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/technologies/dev-ops', '_blank').focus();
  }, []);
  const mobileHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/technologies/mobile', '_blank').focus();
  }, []);
  const othersHandler = useCallback(() => {
    window.open('https://www.smartcodev.com/technologies/others', '_blank').focus();
  }, []);

  return (
    <Root id={id}>
      <TechnologiesSectionRoot>
        <TechnologiesSectionHeader dangerouslySetInnerHTML={sanitize(I18n.t('landing.technologiesSection.header'))} />
      </TechnologiesSectionRoot>
      <Content>
        <TechnologiesItems
          title={I18n.t('landing.technologiesSection.backend.title')}
          info={I18n.t('landing.technologiesSection.backend.subtitle')}
          image={IMAGES.BACKEND_LOGO}
          onClick={backendHandler}
          imageStyles={css`
            bottom: 12%;
          `}
        />
        <TechnologiesItems
          title={I18n.t('landing.technologiesSection.frontend.title')}
          info={I18n.t('landing.technologiesSection.frontend.subtitle')}
          image={IMAGES.FRONTEND_LOGO}
          onClick={frontendHandler}
          imageStyles={css`
            top: 14%;
          `}
        />
        <TechnologiesItems
          title={I18n.t('landing.technologiesSection.databaseApi.title')}
          info={I18n.t('landing.technologiesSection.databaseApi.subtitle')}
          image={IMAGES.DATABASE_API_LOGO}
          onClick={databaseApiHandler}
          imageStyles={css`
            bottom: 12%;
          `}
        />
        <TechnologiesItems
          title={I18n.t('landing.technologiesSection.devOps.title')}
          info={I18n.t('landing.technologiesSection.devOps.subtitle')}
          image={IMAGES.DEV_OPS_LOGO}
          onClick={devOpsHandler}
          imageStyles={css`
            top: 21%;
          `}
        />
        <TechnologiesItems
          title={I18n.t('landing.technologiesSection.mobile.title')}
          info={I18n.t('landing.technologiesSection.mobile.subtitle')}
          image={IMAGES.MOBILE_LOGO}
          onClick={mobileHandler}
          imageStyles={css`
            bottom: 8%;
          `}
        />
        <TechnologiesItems
          title={I18n.t('landing.technologiesSection.others.title')}
          info={I18n.t('landing.technologiesSection.others.subtitle')}
          image={IMAGES.OTHERS_LOGO}
          onClick={othersHandler}
          imageStyles={css`
            bottom: 7%;
          `}
        />
      </Content>
    </Root>
  );
};
export default TechnologiesSection;
