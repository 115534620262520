import appleStoreBadgeIcon from './app-store-badge.svg';
import googlePlayBadgeIcon from './google-play-badge.svg';
import appleStoreBadgeBigIcon from './app-store-badge-big.svg';
import googlePlayBadgeBigIcon from './google-play-badge-big.svg';
import quote from './quote.svg';
import arrowLeft from './arrow-left.svg';
import arrowRight from './arrow-right.svg';
import twitterIcon from './twitter_icon.svg';
import facebookIcon from './facebook_icon.svg';
import messengerIcon from './messenger_icon.svg';
import email from './email.svg';
import location from './location.svg';
import phone from './phone.svg';
import logo from './logo.svg';

import advantages_01 from './advantages_01.svg';
import advantages_02 from './advantages_02.svg';
import advantages_03 from './advantages_03.svg';
import advantages_04 from './advantages_04.svg';
import advantages_05 from './advantages_05.svg';
import advantages_06 from './advantages_06.svg';
import advantages_07 from './advantages_07.svg';
import GoRightButton from './goRightButton.svg';
import GoLeftButton from './goLeftButton.svg';
import mouseIcon from './mouseIcon.svg';

import shevron from './shevron.svg';
import pdf from './pdf.svg';
import close from './close.svg';
import flags from './flags';
import pin_grey from './pin_grey.svg';
import search from './search.svg';
import close2 from './close2.svg';
import check from './check.svg';
import paint from './paint.svg';
import schedule from './schedule.svg';
import empty_avatar from './empty_avatar.svg';
import logout from './icon_logout.svg';
import icons_cancel from './icons_cancel.svg';

import play from './play.svg';
import play_2 from './play_2.svg';
import play_3 from './play3.svg';
import play_4 from './play4.svg';
import link from './link.svg';

import twitter from './twitter.svg';
import instagram from './instagram.svg';
import linkedin from './linkedin.svg';
import facebook from './facebook.svg';
import triangle from './triangle.svg';
import triangle2 from './triangle2.svg';

import googlePlayLogo from './google-play-logo.svg';
import appleStoreLogo from './apple-store-logo.svg';
import googleRatings from './google-ratings.svg';
import scheduleDemoIcon from './scheduleDemoIcon.svg';
import checkGrey from './check-grey.svg';
import checkGreen from './check-green.svg';
import oval from './oval.svg';
import commerce from './commerce.svg';

export const ICONS = {
  FLAGS: flags,
  BROKRETE_LOGO: logo,
  APPLE_STORE_BADGE_ICON: appleStoreBadgeIcon,
  GOOGLE_PLAY_BADGE_ICON: googlePlayBadgeIcon,
  APPLE_STORE_BADGE_BIG_ICON: appleStoreBadgeBigIcon,
  GOOGLE_PLAY_BADGE_BIG_ICON: googlePlayBadgeBigIcon,
  QUOTE: quote,
  ARROW_LEFT: arrowLeft,
  ARROW_RIGHT: arrowRight,
  TWITTER_ICON: twitterIcon,
  FACEBOOK_ICON: facebookIcon,
  MESSENGER_ICON: messengerIcon,
  EMAIL: email,
  LOCATION: location,
  PHONE: phone,
  ADVANTAGES_1: advantages_01,
  ADVANTAGES_2: advantages_02,
  ADVANTAGES_3: advantages_03,
  ADVANTAGES_4: advantages_04,
  ADVANTAGES_5: advantages_05,
  ADVANTAGES_6: advantages_06,
  ADVANTAGES_7: advantages_01,
  ADVANTAGES_8: advantages_07,
  ADVANTAGES_9: advantages_05,
  ADVANTAGES_10: advantages_03,
  GO_RIGHT_BUTTON: GoRightButton,
  GO_LEFT_BUTTON: GoLeftButton,
  MOUSE_ICON: mouseIcon,
  PDF: pdf,
  CLOSE: close,
  SHEVRON: shevron,
  PIN_GREY: pin_grey,
  SEARCH: search,
  CHECK: check,
  PAINT: paint,
  SCHEDULE: schedule,
  CLOSE_2: close2,
  EMPTY_AVATAR: empty_avatar,
  LOGOUT: logout,
  ICON_CANCEL: icons_cancel,
  PLAY: play,
  PLAY_2: play_2,
  LINK: link,
  TWITTER_ICON_SMALL: twitter,
  INSTAGRAM_ICON_SMALL: instagram,
  LINKEDIN_ICON_SMALL: linkedin,
  FACEBOOK_ICON_SMALL: facebook,
  TRIANGLE: triangle,
  TRIANGLE_2: triangle2,
  GOOGLE_PLAY_LOGO: googlePlayLogo,
  APPLE_STORE_LOGO: appleStoreLogo,
  GOOGLE_RATINGS: googleRatings,
  SCHEDULE_DEMO_ICON: scheduleDemoIcon,
  PLAY_3: play_3,
  PLAY_4: play_4,
  CHECK_GREY: checkGrey,
  CHECK_GREEN: checkGreen,
  OVAL: oval,
  COMMERCE: commerce
};
