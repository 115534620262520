import React from 'react';
import styled from 'styled-components';
import ApplicationRouter from './ApplicationRouter';
import { CTXProvider } from '@src/reactContext/provider/ctxProvider';
import HttpsRedirect from 'react-https-redirect';

const App: React.FC = (): JSX.Element | null => {
  return (
    <HttpsRedirect>
      <CTXProvider>
        <Root>
          <ApplicationRouter />
        </Root>
      </CTXProvider>
    </HttpsRedirect>
  );
};

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: table;
  overflow: hidden;
  table-layout: fixed;
  overflow-x: hidden;
`;

export default App;
