import React from 'react';
import styled from 'styled-components';
import { IMAGES } from '@src/assets/images';
import { breakpoints, colors } from '@src/defaultStyles';

type Props = {
  color?: string;
  commerce?: any;
  [x: string]: any;
};
const Logo: React.FC<Props> = ({ color = colors.CHARCOAL_GREY, commerce, ...restProps }): JSX.Element | null => {
  return (
    <Root {...restProps}>
      <Image src={IMAGES.SMART_CODEV_LOGO} />
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .commerce {
    position: absolute;
    display: block;
    top: 25px;
  }
`;
const Image = styled.img`
  width: 100px;
  @media (${breakpoints.tablet}) {
    width: 80px;
  }
  @media (${breakpoints.mobile}) {
    width: 60px;
    padding-left: 20px;
  }
`;

export default Logo;
