import styled from 'styled-components';
import { ContentContainer, RootContainer, Text } from '@src/components/common';
import { breakpoints } from '@src/defaultStyles';

export const Root = styled(RootContainer)`
  padding-top: 80px;
  padding-bottom: 80px;
`;

export const Content = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
`;

export const FormContainer = styled.div`
  min-width: 470px;
  max-width: 600px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;

  @media (${breakpoints.tablet}) {
    max-width: 450px;
  }

  @media (${breakpoints.tablet}) {
    max-width: 100%;
  }
`;

export const PositionDetailsContainer = styled.div`
  min-width: 500px;
  margin-right: 80px;

  flex: 1 1 500px;

  @media (${breakpoints.tablet}) {
    min-width: 300px;
    width: 100%;
    margin-right: 0px;
  }
`;

export const ApplicationFromTitle = styled(Text)`
  margin-bottom: 20px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
