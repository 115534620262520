import React, { memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Text } from '@src/components/common';
import { breakpoints, colors, fontFamily } from '@src/defaultStyles';
import Modal from '@src/components/common/Modal';

const customStyles = {
  title: {
    size: 'md',
    align: 'center',
    display: 'inline-block',
    color: colors.DARK_MINT
  }
};

const WeWillReachYouModal: React.FC<{ opened: boolean; onClose: Function }> = ({ opened, onClose }): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <StyledModal title={t('contactSection.modal.title')} opened={opened} onClose={onClose} styles={customStyles}>
      <FlexCenter>
        <Info>{t('contactSection.modal.text')}</Info>
      </FlexCenter>
    </StyledModal>
  );
};

export default memo(WeWillReachYouModal);

const StyledModal = styled(Modal)`
  max-width: 500px;
  width: 100%;
  max-height: 300px;
`;

const Info = styled(Text)`
  font-size: 32px;
  font-family: ${fontFamily.bold};
  font-weight: ${fontFamily.bold};
  text-align: center;
  display: block;
  margin-bottom: 50px;
  line-height: normal;

  @media (${breakpoints.mobile}) {
    font-size: 24px;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
