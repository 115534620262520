import gql from 'graphql-tag';

import { Order, Partner, Place, Plant, Product, ProductPrice, ProductTypePrice } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';
import { buildAddressInput, buildIdInput, buildProductPricesInput } from '../helper';
import { isEmpty } from '@shared/lib';

const gqlQuery = gql`
  query OrderPreparatoryConfig(
    $order: IdInput
    $product: IdInput!
    $address: AddressInput!
    $productPrices: [ProductPriceInput!]
    $currency: String
    $keepPlant: Boolean
    $partner: IdInput
    $plant: IdInput
    $pickup: Boolean
  ) {
    orderPreparatoryConfig(
      order: $order
      input: { category: $product, address: $address, productPrices: $productPrices, plant: $plant, pickup: $pickup }
      partner: $partner
    ) {
      productTypes(currency: $currency, keepPlant: $keepPlant) {
        ...productTypePrice
      }
    }
  }
  ${fragments.productTypePrice}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  productTypes: Array<ProductTypePrice>;
};

type Props = {
  product: Product;
  productPrices?: Array<ProductPrice>;
  address: Place;
  keepPlant: boolean;
  currency?: string;
  order?: Order;
  partner?: Partner;
  plant: Plant;
  pickup: boolean;
};

export default async (
  { product, productPrices, address, keepPlant, currency, order, partner, plant }: Props,
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      product: buildIdInput(product),
      productPrices: !isEmpty(productPrices) ? buildProductPricesInput(productPrices) : undefined,
      address: buildAddressInput(address),
      keepPlant: keepPlant === true,
      order: buildIdInput(order),
      partner: buildIdInput(partner),
      plant: buildIdInput(plant),
      currency
    },
    options
  );

  return result.orderPreparatoryConfig;
};
