import React from 'react';
import { Description, Image, ItemIcon, Root, Subtitle, Title } from './styled';
import styles from './styles';
import { ArrowDown, Price } from '@src/components/common';
import isEmpty from '@src/lib/isEmpty';

interface PropsType {
  item?: { icon?: any; title: string; price: { [x: string]: any }; subtitle: string };
  onClick: Function;
  bordered: boolean;
  active: boolean;
  arrowIconStyles?: any;
  disabled: boolean;
  styles?: any;
}

class SelectedItem extends React.Component<PropsType> {
  renderIcon(icon) {
    if (typeof icon === 'string' && !isEmpty(icon)) {
      return (
        <ItemIcon>
          <Image src={icon} />
        </ItemIcon>
      );
    }

    //TODO: UNCOMMENT
    // if (React.isValidElement(icon)) {
    //   return <ItemIcon>{React.createElement(icon)}</ItemIcon>;
    // }

    return null;
  }

  render() {
    const { item, onClick, bordered, active, arrowIconStyles, disabled } = this.props;

    const { icon, title, price, subtitle } = item;

    return (
      <Root styles={this.props.styles} onClick={onClick} bordered={bordered} active={active} disabled={disabled}>
        {this.renderIcon(icon)}

        <Description>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Description>

        {price && <Price styles={styles.price} value={price.value} units={price.units} />}

        <ArrowDown styles={arrowIconStyles} />
      </Root>
    );
  }
}

export default SelectedItem;
