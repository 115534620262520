import React, { memo } from 'react';
import styled from 'styled-components';
import ContactItem from './ContactItem';

function ContactsList({ contacts, ...restProps }) {
  return (
    <Root {...restProps}>
      {contacts.map((contact, i) => (
        <ContactItem key={i} contact={contact} />
      ))}
    </Root>
  );
}

export default memo(ContactsList);

const Root = styled.ul`
  margin: 0;
  padding: 0;
  overflow-y: scroll;
`;
