import styled from 'styled-components';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import RootContainer from '@src/components/common/RootContainer';
import ContentContainer from '@src/components/common/ContentContainer';

const ReadyToStart = styled(RootContainer)`
  background: ${props => (props.suppliersReady ? 'none' : 'linear-gradient(152.95deg, #1c1b1b -6.46%, #252424 56.43%)')};
  display: flex;
  justify-content: center;
  padding: 45px 20px 200px 20px;
  @media (${breakpoints.tablet}) {
    padding: 30px 20px 100px 20px;
  }
  @media (${breakpoints.mobile}) {
    padding: 30px 0px 75px 0px;
    flex-direction: column;
    align-items: unset;
    padding-top: 0;
    align-items: center;
  }
  ${props => props.styles}
`;

const Content = styled.div`
  max-width: 1240px;
  display: flex;
  // grid-template-columns: 1fr 1fr;
  align-items: center;
  flex-direction: column;

  @media (${breakpoints.tablet}) {
    /* grid-template-columns: 1fr; */
    align-items: center;
    justify-items: center;
  }
  @media (${breakpoints.mobile}) {
    max-width: 85%;
  }
`;

const ReadyToStartCard = styled.div`
  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
`;

const ReadyToStartLeftSide = styled.div`
  justify-self: flex-start;

  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-self: unset;
  }
`;

const ReadyToStartRightSide = styled.div`
  display: flex;
  justify-self: flex-end;
  margin-top: 30px;
  & > *:not(:last-child) {
    margin-right: 20px;
  }

  @media (${breakpoints.tablet}) {
    justify-self: unset;
    margin: 0 auto;
  }

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    button {
      width: 100%;
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
`;

const ReadyToStartTitle = styled.div`
  color: ${colors.WHITE};
  font-size: 40px;
  word-spacing: 2px;
  line-height: 65px;
  font-weight: ${fontWeight.bold};
  text-align: center;
  @media (${breakpoints.tablet}) {
    line-height: 52px;
    font-size: 32px;
  }
  @media (${breakpoints.mobile}) {
    width: 325px;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 5px;
  }
`;

const ReadyToStartInfo = styled.div`
  font-size: 18px;
  letter-spacing: 0.4px;

  font-weight: ${fontWeight.normal};
  color: ${colors.WHITE};
  @media (${breakpoints.tablet}) {
    margin-bottom: 40px;
  }
  @media (${breakpoints.mobile}) {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: ${fontWeight.medium};
  }
`;

const ReadyToStartButtonAccount = styled.button`
  max-width: 200px;
  margin-right: 10px;
  font-size: 12px;
  background-color: ${colors.DARK_MINT};
  color: ${colors.WHITE};
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;

  @media (${breakpoints.mobile}) {
    margin-bottom: 10px;
  }
`;

const ReadyToStartButtonSales = styled.button`
  max-width: 200px;
  font-size: 12px;
  background-color: ${colors.WHITE};
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  cursor: pointer;
`;
const ButtonGroup = styled.div`
  display: flex;
  align-content: center;
  margin-top: 30px;
  a:first-of-type {
    padding-right: 20px;
  }
  .buttons {
    & > a:not(:last-child) {
      .IconButton {
        margin-bottom: 20px;
      }
    }
  }
`;

export {
  ReadyToStart,
  Content,
  ReadyToStartCard,
  ReadyToStartLeftSide,
  ReadyToStartRightSide,
  ReadyToStartTitle,
  ReadyToStartInfo,
  ReadyToStartButtonAccount,
  ReadyToStartButtonSales,
  ButtonGroup
};
