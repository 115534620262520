import React from 'react';
import styled from 'styled-components';

const Nav: React.FC = (props): JSX.Element | null => {
  return <Root {...props} />;
};
export default Nav;
const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
