import React, { useCallback } from 'react';
import styled from 'styled-components';
import { IconTwitter } from '@src/components/common/icons';

type Props = {
  className?: any;
  width: number;
  height: number;
  color?: string;
};
const TwitterButton: React.FC<Props> = ({ className, width, height, color }): JSX.Element | null => {
  const onTwitterClick = useCallback(() => {
    window.open('https://twitter.com/smartcodev', '_blank').focus();
  }, []);

  return <IconTwitterStyled className={className} onClick={onTwitterClick} width={width} height={height} color={color} />;
};

export default TwitterButton;

const IconTwitterStyled = styled(IconTwitter)`
  &:hover {
    opacity: 0.5;
  }
`;
