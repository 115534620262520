import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@src/components/common';
import I18n from 'i18next';
import { fontFamily, fontWeight, colors } from '@src/defaultStyles';

const ButtonSales = styled(Button)`
  color: ${colors.CORNFLOWER_BLUE};
  box-shadow: 1px 1px 1px 1px rgba(156, 156, 156, 0.27);
  border-radius: 5px;
  background-color: #f9f9f9;
  height: 40px;
  letter-spacing: 0.8px;
  font-size: 16px;
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
`;

const ContactSalesButton = () => {
  const onContactSalesClick = useCallback(() => {
    window.open('https://calendly.com/brokrete', '_blank').focus();
  }, []);

  return <ButtonSales onClick={onContactSalesClick}>{I18n.t('forContractors.eCommerce.contactSales')}</ButtonSales>;
};

export default ContactSalesButton;
