import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContentContainer, Heading } from 'components/common';
import { ProductTypes } from 'config';
import { breakpoints, colors } from 'defaultStyles';
import { chunkify } from 'lib/array';
import ProductCard from './ProductCard';
import RootContainer from 'components/common/RootContainer';
import sanitize from '@src/lib/sanitize';

export default function ProductsScreen() {
  const { t } = useTranslation();

  const products = chunkify(ProductTypes, 2);

  return (
    <Root>
      <ContentContainer>
        <PageHeading align="center" dangerouslySetInnerHTML={sanitize(t('landing.products.title'))} />

        <ProductsContainer>
          {products.map((group, i) => (
            <Group key={i}>
              {group.map(({ type, image }) => {
                return (
                  <ProductCard
                    key={type}
                    title={t(`landing.products.${type}.title`)}
                    description={t(`landing.products.${type}.description`)}
                    image={image}
                  />
                );
              })}
            </Group>
          ))}
        </ProductsContainer>
      </ContentContainer>
    </Root>
  );
}

const Root = styled(RootContainer)`
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: ${colors.BACKGROUND_LIGHT};

  @media (${breakpoints.mobile}) {
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
  }
`;

const PageHeading = styled(Heading)`
  margin-bottom: 50px;
  font-size: 68px;
  line-height: 1.24;
  letter-spacing: 1px;

  @media (${breakpoints.tablet}) {
    font-size: 52px;
    line-height: 1.23;
    letter-spacing: 1px;
  }

  @media (${breakpoints.mobile}) {
    margin-bottom: 30px;
    font-size: 32px;
    ine-height: 1.31;
    letter-spacing: 1px;
  }
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (${breakpoints.mobile}) {
    flex-wrap: nowrap;
    overflow: scroll;

    & > * {
      margin-bottom: 0px;
    }
  }
`;

const Group = styled.div`
  display: flex;
  justify-content: center;

  & > *:first-child {
    margin-right: 15px;
  }

  & > *:last-child {
    margin-left: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;

    @media (${breakpoints.mobile}) {
      margin-right: 15px;
      margin-bottom: 0;
    }
  }

  @media (${breakpoints.mobile}) {
    & > *:last-child {
      margin-left: 0;
    }
  }
`;
