import React from 'react';
import styled from 'styled-components';
import Label from 'components/common/controls/Label';
import { InputGroup } from '@src/components/common';
import { colors } from '@src/defaultStyles';

const RadioButton = ({ id, field, defaultValue, form: { setFieldValue }, label }) => {
  const { name, value: selectedValues } = field;

  const checked = selectedValues.includes(defaultValue);

  const onChange = () => {
    setFieldValue(name, checked ? selectedValues.filter(value => value != defaultValue) : [...selectedValues, defaultValue]);
  };

  return (
    <InputGroup>
      <RadioLabel htmlFor={id} onClick={onChange}>
        <Radio checked={checked}>{checked && <Marker />}</Radio>
        {label}
      </RadioLabel>
    </InputGroup>
  );
};

export default RadioButton;

const Radio = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;

  background-color: ${colors.WHITE};
  border: 1px solid ${props => (props.checked ? colors.ALGAE_GREEN : colors.MIDLLE_GREY)};
  border-radius: 50%;
`;

const Marker = styled.div`
  width: 14px;
  height: 14px;

  border: 1px solid ${colors.ALGAE_GREEN};
  border-radius: 50%;
  background-color: ${colors.ALGAE_GREEN};
`;

const RadioLabel = styled(Label)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
