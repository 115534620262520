import styled from 'styled-components';
import { Text } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  width: 100%;
  position: relative;

  ${props => props.styles}
`;

const Menu = styled.div`
  position: absolute;
  z-index: 999999;
  width: 100%;

  mix-blend-mode: normal;
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.07);

  background-color: ${colors.WHITE};

  display: ${props => (props.active ? 'block' : 'none')};

  ${props => props.styles}
`;

const ItemWrapper = styled.div`
  pointer-events: ${props => (props.disabled ? 'none' : 'all')} ${props => props.styles};
`;

const GroupTitle = styled(Text)`
  display: block;
  padding: 20px 20px 10px 20px;
  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};
  font-size: 14px;
  color: ${colors.BATTLESHIP_GREY};
  text-transform: uppercase;
`;

export { GroupTitle, Root, ItemWrapper, Menu };

export default {
  GroupTitle,
  Root,
  ItemWrapper,
  Menu
};
