import React, { Component } from 'react';
import ReactModal from 'react-modal';
import cx from 'classnames';
import styled from 'styled-components';
// Use modules here to avoid inline styles on modals
import CloseButton from '@src/components/common/CloseButton';
import Heading from '@src/components/common/Heading';

ReactModal.setAppElement('#root');

interface PropsType {
  styles: { [x: string]: any };
  [x: string]: any;
}

export default class Modal extends React.Component<PropsType> {
  static defaultProps = {
    showCloseButton: true
  };
  render() {
    const {
      opened,
      title,
      onClose,
      onAfterOpen,
      modalClasses,
      overlayClasses,
      styles = {},
      showCloseButton,
      className,
      ...restProps
    } = this.props;

    return (
      <StyledReactModal
        closeTimeoutMS={300}
        isOpen={opened}
        onAfterOpen={onAfterOpen}
        bodyOpenClassName="modal-open"
        onRequestClose={onClose}
        className={cx(className, modalClasses)}
        //className is Required because styled-components send it through props
        overlayClassName={cx('overlay', overlayClasses)}
        // overlay class is required because we can't style it through styled-component.
        // it's the ancestor of our styled-component
      >
        <ModalContainer styles={restProps.containerStyles}>
          {showCloseButton && <ModalClose onClick={onClose} />}
          <ModalBody>
            {title && (
              <ModalHeader>
                <ModalHeading size="md" {...styles.title}>
                  {title}
                </ModalHeading>
              </ModalHeader>
            )}

            {restProps.children}
          </ModalBody>
        </ModalContainer>
      </StyledReactModal>
    );
  }
}

const StyledReactModal = styled(ReactModal)`
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  padding: 0;
  width: 400px;
  min-height: 300px;
  height: 100%;
  box-sizing: border-box;
`;

const ModalContainer = styled.div`
  height: 100%;
  padding: 30px 30px 0px 30px;
  box-sizing: border-box;

  ${props => props.styles}
`;

export const ModalBody = styled.div`
  //height: calc(100% - 64px);
  height: 100%;
  box-sizing: border-box;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const ModalHeading = styled(Heading)`
  width: 100%;
  line-height: 1.44;
  letter-spacing: 0.5px;
`;

const ModalClose = styled(props => <CloseButton {...props} />)`
  margin-left: auto;
`;
