import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Input, Modal } from 'components/common';
import ContactsList from './ContactsList';
import { colors } from 'defaultStyles';
import Loader from 'components/common/Loader';

function GmailContactsModal(props) {
  const { t } = useTranslation();
  const { opened, onClose, contacts, loading } = props;

  return (
    <StyledModal
      title={t('modals.gmailContacts.title')}
      opened={opened}
      onClose={onClose}
      modalClasses="mobile-fullscreen"
      overlayClasses="mobile-fullscreen"
    >
      <SearchInput placeholder={t('modals.gmailContacts.input.placeholder')} />
      {loading ? (
        <FlexContainer>
          <Loader />
        </FlexContainer>
      ) : (
        <ListContainer contacts={contacts} />
      )}
    </StyledModal>
  );
}

export default GmailContactsModal;

const StyledModal = styled(Modal)`
  width: 570px;
  max-height: 720px;
`;

const SearchInput = styled(Input)`
  background-color: ${colors.VERY_LIGHT_PINK};
`;

const ListContainer = styled(ContactsList)`
  max-height: calc(100% - 180px);

  & > *:not(:last-child) {
    border-bottom: 1px solid ${colors.VERY_LIGHT_PINK};
  }
`;

const FlexContainer = styled.div`
  width: 100%;
  height: calc(100% - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
`;
