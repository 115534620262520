import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

interface Props {
  label: new () => React.Component<any, any>;
  name: string;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  [x: string]: any;
}
const Checkbox: React.FC<Props> = ({ label: LabelElement, name, onChange, ...props }): JSX.Element | null => {
  return (
    <Label {...props}>
      <SwitchWrapper>
        <input type="checkbox" name={name} onChange={onChange} />
        <div className="switch" />
      </SwitchWrapper>

      {/* {typeof LabelElement === 'function' ? <LabelElement /> : LabelElement} */}
    </Label>
  );
};

export default Checkbox;

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const SwitchWrapper = styled.div`
  cursor: pointer;
  overflow: hidden;
  margin-right: 10px;

  background: ${colors.CLOUDY_BLUE};
  min-width: 30px;
  height: 22px;
  border-radius: 20px;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch {
    width: 100%;
    height: 100%;
    margin-left: -60%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: ${colors.CHARCOAL_GREY};
    transition: 0.2s;
  }

  input:checked {
    + .switch {
      margin-left: 0;
      background-color: ${colors.ALGAE_GREEN};
    }
  }
`;
