import React from 'react';
import styled from 'styled-components';
import RootContainer from 'components/common/RootContainer';
import { ContentContainer, Heading, Text } from 'components/common';
import { breakpoints, colors } from 'defaultStyles';
import { useTranslation } from 'react-i18next';
import Logo from 'components/common/Logo';
import GooglePlayButton from 'components/common/controls/GooglePlayButton';
import AppleStoreButton from 'components/common/controls/AppleStoreButton';

export default function ReferralScreen() {
  const { t } = useTranslation();

  return (
    <Root>
      <Container>
        <BrokreteLogo />
        <Avatar />
        <Title>{t('landing.referralScreen.title', { referralName: 'Mike Alfonsi' })}</Title>
        <Info>{t('landing.referralScreen.text')}</Info>
        <ButtonGroup>
          <GooglePlayButton />
          <AppleStoreButton />
        </ButtonGroup>
      </Container>
    </Root>
  );
}

const Root = styled(RootContainer)`
  height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: ${colors.ALGAE_GREEN};
`;

const Container = styled(ContentContainer)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled(Heading)`
  margin-bottom: 20px;
  font-size: 52px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: 1px;
  color: ${colors.WHITE};
  text-align: center;

  @media (${breakpoints.mobile}) {
    font-size: 32px;
    line-height: 1.31;
    letter-spacing: 1px;
  }
`;

const Info = styled(Text)`
  margin-bottom: 47px;
  display: block;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.WHITE};
`;

const BrokreteLogo = styled(Logo)`
  position: absolute;
  top: 0;
  left: 0;

  svg > g {
    fill: ${colors.WHITE};
  }

  @media (${breakpoints.tablet}) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 15px;
  }
`;

const Avatar = styled.img`
  margin-bottom: 30px;
  width: 120px;
  height: 120px;
  background-color: #cccccc;
  border-radius: 50%;
`;
