import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { ContractorMember } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.removeIdentity');

const query = gql`
  mutation RemoveIdentity($input: [IdentityRemoveInput!]) {
    contractorUpdate(identity: { remove: $input }) {
      success
      contractorMember {
        identities {
          ...identity
        }
      }
    }
  }
  ${fragments.identity}
`;

type ServerResponse = {
  contractorUpdate: Response;
};

type Response = {
  success: boolean;
  contractorMember: ContractorMember;
};

export default async (
  { values }: { values: Array<{ email?: string; phone?: string }> },
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      input: values
    },
    options
  );

  return result.contractorUpdate;
};
