import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';

import { useTranslation } from 'react-i18next';

import { Button, MenuItem, Modal, Nav } from 'components/common';
import { breakpoints, colors, fontFamily, fontWeight } from 'defaultStyles';

function MenuModal({ opened, onClose, onVolumePricingClick, displayStartSelling = false, onContactUsClick }) {
  const [currentScreen, setCurrentScreen] = useState(undefined);
  const { t } = useTranslation();

  const goToServices = e => {
    e.preventDefault();
    setCurrentScreen('services');
  };

  const goToTechnologies = e => {
    e.preventDefault();
    setCurrentScreen('technologies');
  };

  return (
    <Modal opened={opened} onClose={onClose} modalClasses="fullscreen" overlayClasses="fullscreen">
      <NavStyled>
        <MenuItemsContainer>
          {currentScreen === undefined && (
            <>
              <MenuItemStyled to="/" label={t('header.home')} />
              <MenuItemStyled to="/services" label={t('header.services')} onClick={goToServices} />
              <MenuItemStyled to="/technologies" label={t('header.technologies')} onClick={goToTechnologies} />

              {/*<MenuItemStyled*/}
              {/*  to='https://store.brokrete.com/sourcing'*/}
              {/*  label={t('landing.header.forSourcing')}*/}
              {/*  target='_blank'*/}
              {/*  isExternal*/}
              {/*/>*/}
              <MenuItemStyled to="/about" label={t('header.about')} />
              {/*  <MenuItemStyled to="/career" label={t('header.career')} /> */}
            </>
          )}

          {currentScreen === 'services' && (
            <>
              <MenuItemStyled to="/services/custom-software-development" label={t('header.servicesItems.item1')} />
              <MenuItemStyled to="/services/web-development" label={t('header.servicesItems.item2')} />
              <MenuItemStyled to="/services/mobile-apps-development" label={t('header.servicesItems.item3')} />
              <MenuItemStyled to="/services/ecommerce-development" label={t('header.servicesItems.item4')} />
              <MenuItemStyled to="/services/product-development" label={t('header.servicesItems.item5')} />
              <MenuItemStyled to="/services/cloud-application-development" label={t('header.servicesItems.item6')} />
              <MenuItemStyled to="/services/software-testing-qa" label={t('header.servicesItems.item7')} />
              <MenuItemStyled to="/services/ui-ux-design" label={t('header.servicesItems.item8')} />
            </>
          )}

          {currentScreen === 'technologies' && (
            <>
              <MenuItemStyled to="/technologies/backend" label={t('header.technologiesItems.item1')} />
              <MenuItemStyled to="/technologies/frontend" label={t('header.technologiesItems.item2')} />
              <MenuItemStyled to="/technologies/database-api" label={t('header.technologiesItems.item3')} />
              <MenuItemStyled to="/technologies/dev-ops" label={t('header.technologiesItems.item4')} />
              <MenuItemStyled to="/technologies/mobile" label={t('header.technologiesItems.item5')} />
              <MenuItemStyled to="/technologies/others" label={t('header.technologiesItems.item6')} />
            </>
          )}
        </MenuItemsContainer>
        {currentScreen !== undefined && (
          <Button
            secondary
            onClick={() => setCurrentScreen(undefined)}
            styles={css`
              margin-top: auto;
              margin-bottom: 60px;
              width: 100%;
              border: 1px solid ${colors.CHARCOAL_GREY};
            `}
          >
            {t('header.mobileBackButton')}
          </Button>
        )}

        {displayStartSelling ? (
          <ActionButton onClick={onContactUsClick}>{t('header.contactUsButton')}</ActionButton>
        ) : (
          <ActionButton primary onClick={onVolumePricingClick}>
            {t('header.contactUsButton')}
          </ActionButton>
        )}
      </NavStyled>
    </Modal>
  );
}

export default memo(MenuModal);

const ActionButton = styled(Button)`
  font-size: 12px;
  font-family: ${fontFamily.semiBold};
  position: absolute;
  //min-width: 105px;
  //width: 105px;
  //height: 40px;
  bottom: 0;
  background-color: ${props => (props.primary ? colors.ALGAE_GREEN : colors.BLACK)};
  color: ${colors.WHITE};
`;

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  max-height: calc(100% - 50px);
`;

// 64px = 30 padding + 34px close button
const NavStyled = styled(Nav)`
  position: relative;
  flex-direction: column;
  height: calc(100% - 64px);
`;

export const MenuItemStyled = styled(MenuItem)`
  & > a {
    font-family: ${fontFamily.regular};
    font-weight: ${fontWeight.normal};
    font-size: 64px;
  }

  @media (${breakpoints.mobile}) {
    text-align: center;
    & > a {
      font-size: 23px;
    }
  }
`;
