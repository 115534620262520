import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { Partner, Product, Region } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';
import { buildIdInput, buildRegionInput } from '../helper';

const logger = logs.getLogger('api.brokrete.config');

const gqlQuery = gql`
  query OrderPreparatoryConfig($region: RegionInput, $partner: IdInput, $withPossibles: Boolean) {
    orderPreparatoryConfig(input: {}, partner: $partner) {
      products(region: $region, withPossibles: $withPossibles) {
        ...product
      }
    }
  }
  ${fragments.product}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  products: Array<Product>;
};

type Props = {
  region?: Region;
  partner?: Partner;
};

export default async ({ region, partner }: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      region: buildRegionInput(region),
      partner: buildIdInput(partner),
      withPossibles: true
    },
    options
  );

  return result.orderPreparatoryConfig;
};
