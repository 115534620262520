import ahoy from './ahoy';

import geolocation from './geolocation';

const core = {
  ahoy,
  geolocation
};

export default core;
