import styled from 'styled-components';
import { RootContainer, Text, Heading } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled(RootContainer)``;

const PageInfo = styled(Text)`
  display: block;
  text-align: left;
  font-size: 22px;
  margin-bottom: 20px;

  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};

  @media (${breakpoints.tablet}) {
    font-size: 20px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 18px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PageHeading = styled(Heading)`
  margin-bottom: 60px;
  font-size: 27px;
  font-weight: ${fontWeight.bold};

  @media (${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    margin-top: 27px;
    margin-bottom: 50px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 18px;
    margin-bottom: 40px;
    text-align: left;
    margin-left: 20px;
  }
`;
const PartnersList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(157px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(167px, 1fr));
  grid-gap: 24px;
  padding: 0;
  list-style-type: none;
  box-sizing: border-box;

  @media (${breakpoints.tablet}) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 30px;
  }

  @media (${breakpoints.mobile}) {
    grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(142px, 1fr));
    grid-gap: 19px;

    @media (max-width: 466px) {
      & > *:nth-child(odd) {
        margin-left: auto;
      }

      & > *:nth-child(even) {
        margin-right: auto;
      }
    }
  }
`;

const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 157px;
  min-height: 167px;
  box-sizing: border-box;
  display: grid;
  grid-template-rows: auto 42px;

  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${colors.ALTO};
  cursor: pointer;

  :hover {
    border: 1px solid ${colors.ALGAE_GREEN};
  }

  @media (${breakpoints.tablet}) {
    max-width: 150px;
    min-height: 160px;
  }

  @media (${breakpoints.mobile}) {
    max-width: 133px;
    min-height: 142px;
  }
`;

const ViewMoreButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  color: ${colors.ALGAE_GREEN};
  cursor: pointer;
`;

const InfoText = styled.div`
  margin-top: -35px;
  margin-bottom: 40px;
  text-align: center;
`;

export { Root, PageInfo, LoaderContainer, PartnersList, PageHeading, ItemWrapper, ViewMoreButton, InfoText };
