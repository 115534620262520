import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';

export const LandingScrollConfig = [
  {
    scrollSection: 'landingServices',
    scrollText: I18n.t('landing.scrollSection.services')
  },
  {
    scrollSection: 'landingTechnologies',
    scrollText: I18n.t('landing.scrollSection.technologies')
  },
  {
    scrollSection: 'landingContent1',
    scrollText: I18n.t('landing.scrollSection.content1')
  },
  {
    scrollSection: 'landingContent2',
    scrollText: I18n.t('landing.scrollSection.content2')
  },
  {
    scrollSection: 'landingSection1',
    scrollText: I18n.t('landing.scrollSection.section1')
  },
  {
    scrollSection: 'landingContent3',
    scrollText: I18n.t('landing.scrollSection.content3')
  },
  {
    scrollSection: 'landingContent4',
    scrollText: I18n.t('landing.scrollSection.content4')
  },
  {
    scrollSection: 'landingSection2',
    scrollText: I18n.t('landing.scrollSection.section2')
  },
  {
    scrollSection: 'landingContent5',
    scrollText: I18n.t('landing.scrollSection.content5')
  },
  {
    scrollSection: 'landingContent6',
    scrollText: I18n.t('landing.scrollSection.content6')
  },
  {
    scrollSection: 'landingSection3',
    scrollText: I18n.t('landing.scrollSection.section3')
  },
  {
    scrollSection: 'landingContent7',
    scrollText: I18n.t('landing.scrollSection.content7')
  },
  {
    scrollSection: 'landingContent8',
    scrollText: I18n.t('landing.scrollSection.content8')
  },
  {
    scrollSection: 'landingContactSection',
    scrollText: I18n.t('landing.scrollSection.contactSection')
  }
];
