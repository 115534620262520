import React from 'react';
import * as PropTypes from 'prop-types';

import { colors, fontFamily } from '@src/defaultStyles';

const ErrorMessage: React.FC<{ message: string }> = ({ message }): JSX.Element | null => {
  const msg = Array.isArray(message) ? message[0] : message;

  return <span style={{ color: colors.RED, fontFamily: fontFamily.regular, padding: 10 }}>{msg}</span>;
};

export default ErrorMessage;
