export default function isEmpty(value: any): boolean {
  if (value != null && Array.isArray(value)) {
    return value.length == 0;
  }

  if (value && value.constructor === Object) {
    return Object.entries(value).length === 0;
  }

  switch (value) {
    case '':
    case 0:
    case '0':
    case null:
    case undefined:
    case false:
      return true;
    default:
      return false;
  }
}
