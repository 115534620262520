import React from 'react';
import styled, { css } from 'styled-components';

const ScrollLoopOuter = styled.div`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  ${props => props.css}
`;

const ScrollLoopInner = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  > * {
    margin-right: 20px;
  }

  ${props =>
    props.shadow &&
    css`
      height: 200px;

      .partner-line-item {
        max-width: 177px;
        min-width: 177px;
        max-height: 118px;
        min-height: 118px;

        box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
          rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
      }
    `}
`;

interface Props {
  surroundingBackup?: number;
  children: JSX.Element;
  css?: any;
  shadow?: any;
}
const InfiniteScrollLoop: React.FC<Props> = ({ surroundingBackup = 2, children, css, shadow }): JSX.Element | null => {
  const contentRef = React.useRef(null);
  const scrollRef = React.useRef(null);
  const [width, setWidth] = React.useState(0);

  const backupWidth = width * surroundingBackup;

  const handleScroll = React.useCallback(() => {
    if (scrollRef.current) {
      const scroll = scrollRef.current.scrollLeft;
      if (scroll > backupWidth || scroll <= backupWidth + width) {
        scrollRef.current.scrollLeft = backupWidth + (scroll % width);
      }
    }
  }, [width]);

  React.useLayoutEffect(() => {
    if (contentRef.current) {
      setWidth(contentRef.current.offsetWidth);
      scrollRef.current.scrollLeft = backupWidth;
    }
  });

  return (
    <ScrollLoopOuter css={css}>
      <ScrollLoopInner onScroll={handleScroll} ref={scrollRef} style={{ width }}>
        {Array(surroundingBackup)
          .fill(undefined)
          .map((_, index) => (
            <ScrollContainer key={`key_ScrollLoopInner_1_${index}`} shadow={shadow}>
              {children}
            </ScrollContainer>
          ))}

        <ScrollContainer key={'key_ScrollLoopInner_2'} ref={contentRef} shadow={shadow}>
          {children}
        </ScrollContainer>

        {Array(surroundingBackup)
          .fill(undefined)
          .map((_, index) => (
            <ScrollContainer key={`key_ScrollLoopInner_3_${index}`} shadow={shadow}>
              {children}
            </ScrollContainer>
          ))}
      </ScrollLoopInner>
    </ScrollLoopOuter>
  );
};

export default InfiniteScrollLoop;
