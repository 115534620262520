import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.forgotPassword');

const query = gql`
  mutation ForgotPassword($email: String, $phone: String) {
    contractorForgotPassword(email: $email, phone: $phone) {
      success
      status
    }
  }
`;

type ServerResponse = {
  contractorForgotPassword: Response;
};

type Response = {
  success: boolean;
  status: string;
};

export default async (
  { email, phone }: { email?: string; phone?: string },
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      email,
      phone
    },
    options
  );

  return result.contractorForgotPassword;
};
