import styled from 'styled-components';
import { breakpoints, colors } from '@src/defaultStyles';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin:0 120px; */
`;
const DownTriangle = styled.div`
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* padding: 20px; */
  width: 100%;
  line-height: 1.9;
  font-size: 18px;
  /* width: 100%; */
  /* height: 200px; */
  background-color: white;
  position: relative;
  border-radius: 10px;
  > * {
    margin: 40px;
  }
  &:after {
    content: '';
    display: block;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: white transparent transparent;
    margin-left: -10px;
    position: absolute;
    bottom: -10px;
    left: 50%;
  }

  /* margin: 0px 10px; */
  float: left;
`;
const CardOfStory = styled.div`
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  /* background-color: ${colors.WHITE}; */
  box-shadow: 0 5px 30px 0 rgb(0 0 0 / 9%), 0 6px 20px 0 rgb(0 0 0 / 6%);
  /* @media (${breakpoints.tablet}) {
    padding: 18px 26px 30px;
  }

  @media (${breakpoints.mobile}) {
    max-width: 330px;
    min-width: 330px;
  } */
`;
const Logo = styled.img`
  height: 40px;
  margin-bottom: 0;
`;
const Story = styled.div``;
const PersonInfo = styled.div`
  text-align: center;
  margin-top: 20px;
`;
const Name = styled.div`
  font-weight: 500;
  font-size: 23px;
`;
const Position = styled.div`
  color: ${colors.DARK_GRAY};
  font-size: 16px;
`;
export { Root, CardOfStory, Logo, Story, PersonInfo, Name, Position, DownTriangle };
