import { IMAGES } from '@src/assets/images';
import I18n from 'i18next';

export const WebDevelopmentPreviewInfo = [
  {
    title: I18n.t('services.webDevelopment.previewInfo.item1.title'),
    description: I18n.t('services.webDevelopment.previewInfo.item1.description'),
    image: IMAGES.PROJECT_INITIATION
  },
  {
    title: I18n.t('services.webDevelopment.previewInfo.item2.title'),
    description: I18n.t('services.webDevelopment.previewInfo.item2.description'),
    image: IMAGES.PROJECT_PLANNING
  },
  {
    title: I18n.t('services.webDevelopment.previewInfo.item3.title'),
    description: I18n.t('services.webDevelopment.previewInfo.item3.description'),
    image: IMAGES.AGILE_DEVELOPMENT
  },
  {
    title: I18n.t('services.webDevelopment.previewInfo.item4.title'),
    description: I18n.t('services.webDevelopment.previewInfo.item4.description'),
    image: IMAGES.QUALITY_ASSURANCE_2
  },
  {
    title: I18n.t('services.webDevelopment.previewInfo.item5.title'),
    description: I18n.t('services.webDevelopment.previewInfo.item5.description'),
    image: IMAGES.SOFTWARE_DELIVERY
  },
  {
    title: I18n.t('services.webDevelopment.previewInfo.item6.title'),
    description: I18n.t('services.webDevelopment.previewInfo.item6.description'),
    image: IMAGES.MAINTENANCE_SUPPORT_2
  }
];
