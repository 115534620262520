import React, { useCallback, useMemo } from 'react';
import Checkbox from '@src/components/common/Checkbox';
import InputLabel from '@src/components/common/InputLabel';
import { isEmpty } from '@shared/lib';
import { Root } from './styled';
import styles from './styles';

type FormType = {
  setFieldValue: Function;
};
type StylesType = {
  label: any;
};
type FieldType = {
  value: any;
  name: string;
  onChange: Function;
};
interface Props {
  field: FieldType;
  form: FormType;
  label?: any;
  styles?: StylesType;
  labelStyles?: any;
  checkboxStyles?: any;
}
const CheckboxControl: React.FC<Props> = ({
  field,
  form: { setFieldValue },
  label,
  styles,
  labelStyles,
  checkboxStyles
}): JSX.Element | null => {
  const { value, name, onChange } = field;

  const handleClick = useCallback(() => {
    setFieldValue(name, !value);
  }, [value, onChange]);

  const Label = useMemo(() => {
    if (typeof label === 'string' && !isEmpty(label)) {
      return <InputLabel styles={[styles.label, labelStyles]} label={label} />;
    }
    if (React.isValidElement(label)) {
      return label;
    }
    return null;
  }, [label, labelStyles]);

  return (
    <Root styles={styles}>
      <Checkbox styles={checkboxStyles} checked={value} onClick={handleClick} />

      {Label}
    </Root>
  );
};

export default CheckboxControl;
