import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const Svg = styled.svg`
  #sign {
    fill: ${props => (props.disabled ? colors.MIDLLE_GREY : colors.ALGAE_GREEN)};
  }

  #border {
    stroke: ${props => (props.disabled ? 'transparent' : colors.LIGHT)};
  }

  &:hover #border {
    stroke: ${props => (props.disabled ? colors.MIDLLE_GREY : colors.ALGAE_GREEN)};
  }

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};

  display: ${props => (props.visible === false ? 'none' : 'block')};
`;

export { Svg };

export default {
  Svg
};
