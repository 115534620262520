export default function composeValidators(...validators) {
  return value => {
    const validationErrors = [];

    for (const validator of validators) {
      const error = validator(value);

      if (error) {
        validationErrors.push(error);
      }
    }

    return validationErrors.length > 0 ? validationErrors : undefined;
  };
}
