import gql from 'graphql-tag';

export const account = gql`
  fragment account on ContractorAccount {
    id
    balance
    credit
    creditExpiredAt
  }
`;

export const transaction = gql`
  fragment transaction on Transaction {
    id
    provider
    amount
    currency
    type
    status
    createdAt
  }
`;

export const orderImage = gql`
  fragment orderImage on OrderImage {
    id
    type
    image {
      id
      source
    }
    createdAt
  }
`;

export const deliveryMethod = gql`
  fragment deliveryMethod on DeliveryMethod {
    name
    caption
    description
  }
`;

export const projectImage = gql`
  fragment projectImage on ProjectImage {
    id
    image {
      id
      source
    }
    createdAt
  }
`;

export const card = gql`
  fragment card on ContractorPaymentCard {
    id
    uid
    brand
    last4
    expMonth
    expYear
    country
  }
`;

export const product = gql`
  fragment product on ProductCategory {
    id
    name
    industry
    image
    permissions {
      decorates
      options
      trucks
    }
  }
`;

export const productType = gql`
  fragment productType on ProductType {
    id
    name
    group
    image
    description
    descriptionImages
    specification
  }
`;

export const productDecorate = gql`
  fragment productDecorate on ProductDecorate {
    id
    name
    image
  }
`;

export const price = gql`
  fragment price on Price {
    id
    value
    originalValue
    content
    exchangeRate
    formula {
      value
    }
    formulaArguments
  }
`;

export const productTypePrice = gql`
  fragment productTypePrice on OrderPreparatoryConfigProductTypePrice {
    productType {
      ...productType
    }
    price {
      ...price
    }
    units
  }
  ${productType}
  ${price}
`;

export const productDecoratePrice = gql`
  fragment productDecoratePrice on OrderPreparatoryConfigProductDecoratePrice {
    productDecorate {
      ...productDecorate
    }
    price {
      ...price
    }
  }
  ${productDecorate}
  ${price}
`;

export const address = gql`
  fragment address on Address {
    id
    location {
      latitude
      longitude
    }
    mainText
    secondaryText
    city
    country
    state
    zip
  }
`;

export const city = gql`
  fragment city on City {
    id
    name
    country {
      id
      name
      code
      currency
    }
    address {
      ...address
    }
  }
  ${address}
`;

export const fee = gql`
  fragment fee on Fee {
    id
    name
    type
    code
    details {
      from
      to
      multiplier
    }
  }
`;

export const option = gql`
  fragment option on Option {
    id
    name
  }
`;

export const orderPrice = gql`
  fragment orderPrice on OrderPrice {
    id
    fee {
      ...fee
    }
    option {
      ...option
    }
    productDecorate {
      ...productDecorate
    }
    productType {
      ...productType
    }
    price {
      ...price
    }
    quantity
    value
    anchorPrice {
      id
    }
  }
  ${fee}
  ${option}
  ${productDecorate}
  ${productType}
  ${price}
`;

export const driver = gql`
  fragment driver on Driver {
    id
  }
`;

export const orderTruck = gql`
  fragment orderTruck on OrderTruck {
    driver {
      ...driver
    }
    location {
      latitude
      longitude
    }
    deliveryTime
    quantity
    status
  }
  ${driver}
`;

export const partner = gql`
  fragment partner on Partner {
    id
    name
    code
    logo
    largeLogo
    banner
    address {
      ...address
    }
    email
    phone
    website
    caption
    shortCaption
    termsAndConditions
    description
    productCategories {
      count
    }
  }
  ${address}
`;

export const plant = gql`
  fragment plant on Plant {
    id
    name
    address {
      ...address
    }
    deliveryRadius
    pickup
  }
  ${address}
`;

export const trucksConfig = gql`
  fragment trucksConfig on TrucksConfig {
    quantity {
      min
      step
      max
    }
    count {
      max
    }
    deliveryMethod {
      ...deliveryMethod
    }
    order {
      minQuantity
      minAmount
    }
  }
  ${deliveryMethod}
`;

export const order = gql`
  fragment order on Order {
    id
    address {
      ...address
    }
    deliveryTime
    lockToUpdateTime
    lockToCancelTime
    deliveryDetails
    timezone
    images {
      ...orderImage
    }
    prices {
      ...orderPrice
    }
    product {
      ...product
    }
    quantity
    timeBetweenTrucks
    totalPrice
    deliveryMethod {
      ...deliveryMethod
    }
    trucks
    trucksConfig {
      ...trucksConfig
    }
    status
    paymentMethod(legacy: false) {
      provider
      card {
        ...card
      }
    }
    holdLimits
    pouringTime
    hasToBeRated
    currency
    truckStatus
    project {
      id
      name
    }
    partner {
      ...partner
    }
    route {
      distance
    }
    plant {
      ...plant
    }
    trucksDrivers {
      deliveryTime
      quantity
      status
    }
    pickup
    link
    askForPayment
    totalPaid
    units
    updatedAt
  }
  ${address}
  ${fee}
  ${option}
  ${orderPrice}
  ${product}
  ${productDecorate}
  ${productType}
  ${card}
  ${orderImage}
  ${partner}
  ${trucksConfig}
  ${deliveryMethod}
  ${plant}
`;

export const project = gql`
  fragment project on Project {
    id
    name
    type
    status
    spent
    address {
      ...address
    }
    orders {
      id
      totalPrice
    }
    images {
      ...projectImage
    }
    createdAt
  }
  ${address}
  ${projectImage}
`;

export const identity = gql`
  fragment identity on Identity {
    uid
    provider
    confirmed
  }
`;

export const member = gql`
  fragment member on ContractorMember {
    id
    info {
      name
      avatar
    }
    role
    identities {
      ...identity
    }
  }
  ${identity}
`;

export const contractor = gql`
  fragment contractor on Contractor {
    id
    info {
      type
      additional
      country {
        id
        name
        code
        currency
      }
    }
    companyInfo {
      name
      address
      logo
    }
    account {
      ...account
    }
    referralCode
    referees {
      id
      info {
        avatar
        name
      }
      earnings
      activeStatus
    }
    owner {
      id
      info {
        name
        avatar
      }
    }
    members {
      ...member
    }
    verified
    savedPartners {
      ...partner
    }
    lockedPartner {
      ...partner
    }
  }
  ${account}
  ${member}
  ${partner}
`;

export const contractorMember = gql`
  fragment contractorMember on ContractorMember {
    ...member
    contractor {
      id
      info {
        type
        country {
          id
          name
          code
          currency
        }
      }
      companyInfo {
        name
        address
        logo
      }
      owner {
        ...member
      }
    }
    other {
      ...member
      contractor {
        id
        owner {
          ...member
        }
        companyInfo {
          name
          logo
        }
      }
    }
  }
  ${identity}
  ${member}
`;
