import gql from 'graphql-tag';

import { Order, Partner, Place, Product, ProductPrice, ProductTypePrice } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';
import { buildAddressInput, buildIdInput, buildProductPricesInput } from '../helper';

const gqlQuery = gql`
  query OrderPreparatoryConfig($product: IdInput!, $address: AddressInput!) {
    orderPreparatoryConfig(input: { category: $product, address: $address }) {
      partners {
        ...partner
      }
    }
  }
  ${fragments.partner}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  partners: Array<Partner>;
};

type Props = {
  product: Product;
  address: Place;
};

export default async ({ product, address }: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      product: buildIdInput(product),
      address: buildAddressInput(address)
    },
    options
  );

  return result.orderPreparatoryConfig;
};
