import { query } from 'api/core/gql';
import gql from 'graphql-tag';

const JobDetails = gql`
  query JobDetails($id: ID!) {
    jobDetails(id: $id) {
      id
      additionalInfo
      title
      direction
      type
      location
      description
      salary
      experience

      requirements
      responsibilities
    }
  }
`;

export default async function fetchPositionDetails(id) {
  const result = await query(JobDetails, {
    id
  });

  return result;
}
