import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { colors } from '@src/defaultStyles';

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${colors.ALGAE_GREEN};
  cursor: pointer;
`;

const MoreInfo: React.FC = (): JSX.Element | null => {
  const history = useHistory();

  return <Root onClick={() => history.push('/appstorefront')}>More Info &gt;</Root>;
};

export default MoreInfo;
