import React from 'react';
import styled from 'styled-components';
import { Heading, Text } from 'components/common';
import { breakpoints } from 'defaultStyles';

export default function AdvantageItem({ title, icon, description, ...restProps }) {
  return (
    <Root {...restProps}>
      <IconWrapper>
        <Icon src={icon} />
      </IconWrapper>
      <TextBox>
        <Title size="sm">{title}</Title>
        <Description>{description}</Description>
      </TextBox>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: 130px;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const IconWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;

  @media (${breakpoints.mobile}) {
    margin: 0 0 20px;
  }
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
  display: inline-block;
`;

const TextBox = styled.div`
  display: inline-block;
`;

const Title = styled(Heading)`
  letter-spacing: normal;
  margin-bottom: 15px;

  @media (${breakpoints.mobile}) {
    text-align: center;
  }
`;

const Description = styled(Text)`
  font-size: 16px;

  @media (${breakpoints.mobile}) {
    display: inline-block;
    text-align: center;
  }
`;
