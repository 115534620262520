import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

export default function RightArrowIcon({ color = colors.CORNFLOWER_BLUE, ...restProps }) {
  return (
    <Root {...restProps}>
      <svg xmlns="http://www.w3.org/2000/svg" width="9.992" height="8.039" viewBox="0 0 9.992 8.039">
        <path
          fill={color}
          id="Combined_Shape"
          data-name="Combined Shape"
          d="M3.424,7.5a.9.9,0,0,1,.188-1.355L5.5,4.944H.987a.988.988,0,0,1,0-1.976H5.665L3.813,1.612A.9.9,0,0,1,3.719.234,1.268,1.268,0,0,1,5.351.122L9.614,3.244a.9.9,0,0,1,.37.709.9.9,0,0,1-.409.877L5.022,7.72a1.246,1.246,0,0,1-1.6-.221Z"
          transform="translate(0 0.124)"
        />
      </svg>
    </Root>
  );
}

const Root = styled.span`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`;
