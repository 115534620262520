import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { Contractor, ContractorMember } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, Options } from '@src/api/core/gql';
import { buildIdInput } from '../helper';

const logger = logs.getLogger('api.brokrete.contractor.removeManager');

const query = gql`
  mutation RemoveManager($manager: IdInput!) {
    contractorManagerRemove(manager: $manager) {
      success
      contractor {
        members {
          ...member
        }
      }
    }
  }
  ${fragments.member}
`;

type ServerResponse = {
  contractorManagerRemove: Response;
};

type Response = {
  success: boolean;
  contractor: Contractor;
};

export default async ({ manager }: { manager: ContractorMember }, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      manager: buildIdInput(manager)
    },
    options
  );

  return result.contractorManagerRemove;
};
