import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ContactUsForm from '@src/components/common/FooterWithContacts/ContactUsForm';
import RootContainer from '@src/components/common/RootContainer';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { Heading, Text } from '@src/components/common';
import ContactItem from './ContactItem';
import ContentContainer from '@src/components/common/ContentContainer';

const Root = styled(RootContainer)`
  background-color: #18385d;
  display: flex;
  padding: 95px 150px 90px 150px;
  justify-content: center;
  position: relative;

  @media (${breakpoints.tablet}) {
    padding: 65px 10px 60px 10px;
  }

  @media (${breakpoints.mobile}) {
    min-height: 1060px;
    padding: 40px 20px 40px 20px;
  }
`;

const Container = styled(ContentContainer)`
  max-width: 1300px;
  width: 100%;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (${breakpoints.mobile}) {
    max-width: 85%;
  }

  @media (${breakpoints.tablet}) {
    max-width: 90%;

    justify-content: center;
    align-items: center;
    height: 100%;
    /* padding: 0; */
    margin: 0;
  }
`;

const Title = styled(Heading)`
  font-size: 68px;
  margin-bottom: 20px;
  color: ${colors.WHITE};

  @media (${breakpoints.tablet}) {
    text-align: center;
    font-size: 52px;
  }

  @media (${breakpoints.tablet}) {
    font-size: 32px;
  }
`;

const Section = styled.div`
  grid-area: header;
  width: 100%;
  margin-bottom: 30px;

  @media (${breakpoints.tablet}) {
    width: 100%;
  }
`;

const SectionBottom = styled(Section)`
  position: relative;
  grid-area: addresses;

  @media (${breakpoints.tablet}) {
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    column-gap: 10px;
  }

  @media (${breakpoints.mobile}) {
    display: block;
  }
`;
const ContactSection = styled.div`
  @media (${breakpoints.mobile}) {
    width: 100%;
  }
`;
const FooterLink = styled.div`
  padding-right: 80px;
  display: flex;
  flex-direction: column;
  color: ${colors.WHITE};

  @media (${breakpoints.tablet}) {
    padding-right: 0px;
    :first-child {
      padding-right: 10px;
    }
    :last-child {
      padding-right: 80px;
    }
    min-width: 150px;
    margin-bottom: 70px;
  }
  @media (${breakpoints.mobile}) {
    padding-right: 25px;
    margin: 40px 0 0 0;
    min-width: 0;
  }
`;
const FooterLinkTitle = styled.div`
  font-size: 16px;
  font-weight: ${fontWeight.semibold};
  margin-bottom: 25px;
  @media (${breakpoints.tablet}) {
    margin-bottom: 20px;
  }
`;
const FooterLinkItem = styled(Link)`
  font-size: 16px;
  letter-spacing: 0.5px;
  font-weight: ${fontWeight.normal};
  color: ${colors.GRAY};
  margin-bottom: 17px;
  text-decoration: none;
  &:hover {
    color: ${colors.WHITE};
  }
  @media (${breakpoints.tablet}) {
    margin-bottom: 15px;
  }
`;

const BrokreteTextSubtitle = styled.div`
  font-size: 36px;
  width: 220px;
  font-weight: ${fontWeight.normal};
  color: #ff8916;
  @media (${breakpoints.tablet}) {
    width: 100%;
  }
`;
const Right = styled.div`
  width: 100%;

  @media (max-width: 1180px) {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    margin-bottom: 80px;

    form {
      width: 100%;
    }
  }

  @media (${breakpoints.mobile}) {
    width: 100%;
  }
`;

const GetInTouchText = styled(Text)`
  display: block;
  font-size: 16px;
  font-weight: ${fontWeight.semibold};
  margin-bottom: 30px;
  color: ${colors.WHITE};

  @media (${breakpoints.tablet}) {
    margin-bottom: 20px;
  }
  @media (${breakpoints.mobile}) {
    text-align: left;
    margin: 0;
    margin-top: 45px;
    margin-bottom: 20px;
  }
`;

const BottomText = styled(Text)`
  grid-area: info;
  margin-top: 60px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};

  @media (${breakpoints.mobile}) {
    text-align: justify;
  }
`;

const SocialsContainer = styled.div`
  max-width: 200px;
  padding-right: 30px;
  padding-bottom: 40px;
  @media (${breakpoints.tablet}) {
    padding-bottom: 0px;
    padding-right: 0px;
    margin-top: 0;
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 80px;
    align-items: center;
  }

  @media (${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 70px;
    margin-bottom: 40px;
    margin-left: 0;
  }
`;
const LogoContainer = styled.div`
  max-width: 200px;
  @media (${breakpoints.tablet}) {
    margin-top: 0;
    display: flex;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    margin-bottom: 80px;
    align-items: center;
  }

  @media (${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 45px;
    margin-bottom: 20px;
    margin-left: 0;
  }
`;
const BrokreteSocialTop = styled.div`
  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }
`;
const WeOnSocialsText = styled(Text)`
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: ${fontWeight.black};

  @media (${breakpoints.mobile}) {
    align-self: center;
    margin-bottom: 0px;
    margin-top: 20px;
  }
`;

const Grid = styled.div`
  margin: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  //grid-template-columns: auto 470px;
  column-gap: 10px;

  grid-template-areas:
    'header form'
    'addresses form'
    'info info';
  @media (${breakpoints.tablet}) {
    grid-template-columns: 1fr;
    justify-content: space-between;
    margin-bottom: 0px;
    width: 100%;
    align-items: flex-start;
    grid-template-areas:
      'header'
      'form'
      'addresses'
      'info';
  }
  @media (${breakpoints.mobile}) {
    flex-direction: column;
    grid-template-columns: 1fr;
    justify-content: center;
    align-content: center;
    grid-template-areas:
      'header'
      'form'
      'addresses'
      'info';
  }
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  max-height: 20.14px;
  margin: 32px 0px 0px auto;
  @media (${breakpoints.tablet}) {
    margin: 10px auto 50px auto;
    position: relative;
    top: -19px;
  }
  & > *:not(:last-child) {
    margin-right: 15px;
  }

  .twitter-icon,
  .facebook-icon,
  .instagram-icon {
    margin-top: 2px;
  }

  @media (${breakpoints.mobile}) {
    justify-content: center;

    > * {
      margin-right: 7px;
      margin-left: 7px;
    }
  }

  @media (${breakpoints.mobile}) {
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;

const Form = styled(ContactUsForm)`
  min-width: 400px;
  button {
    margin: 0;
    align-self: flex-start;
  }

  @media (${breakpoints.tablet}) {
    min-width: 350px;
    width: 50%;
    margin: 0 auto 80px auto;
  }

  @media (${breakpoints.mobile}) {
    width: 100%;
    min-width: 100%;
    margin: 0 auto;
  }
`;

const SocialSection = styled.div`
  margin-bottom: 80px;

  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
  }

  @media (${breakpoints.mobile}) {
    display: block;
  }
`;

const SocialSectionRow = styled.div`
  @media (${breakpoints.tablet}) {
    display: flex;
    div {
      flex: 1;
    }
  }
`;

const ContactItemLine = styled(ContactItem)`
  flex-direction: row;

  .contact-item-icon {
    margin-right: 15px;
    margin-top: 10px;
  }
`;
const TabletLinks = styled.div`
  @media (${breakpoints.tablet}) {
    display: flex;
    flex-wrap: wrap;
  }
  @media (${breakpoints.mobile}) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
`;
const Image = styled.img`
  width: 212px;
  @media (${breakpoints.tablet}) {
    width: 190px;
    margin-bottom: 15px;
  }
  @media (${breakpoints.mobile}) {
    width: 212px;
  }
`;
const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const TopGrid = styled.div`
  display: flex;
`;
const OurContacts = styled.div`
  padding: 80px 0px 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (${breakpoints.tablet}) {
    padding: 0 0 40px 0;
  }
  @media (${breakpoints.mobile}) {
    padding: 70px 0 20px 0;
  }
`;
const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  :first-child {
    padding: 0 80px 0 0;
  }
  :last-child {
    padding: 0 10px 0 0;
  }
  @media (${breakpoints.tablet}) {
    :first-child {
      padding: 0 90px 0 0;
    }
  }
  @media (${breakpoints.mobile}) {
    :first-child {
      padding: 0 50px 35px 0;
    }
  }
`;
const ContactsHead = styled.div`
  padding: 0px 0 20px 0;
  color: #ff8916;
  font-size: 24px;
  font-weight: ${fontWeight.semibold};

  @media (${breakpoints.tablet}) {
  }
  @media (${breakpoints.mobile}) {
    padding: 0px 0 15px 0;
  }
`;
const EmailPhone = styled.div`
  display: flex;
  @media (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

const ContactsTitle = styled.div`
  color: ${colors.GRAY};
  font-size: 12px;
  font-weight: ${fontWeight.semibold};
  margin-bottom: 5px;
`;
const ContactsInfo = styled.div`
  color: ${colors.WHITE};
  font-size: 18px;
  font-weight: ${fontWeight.normal};
`;
const OurOffices = styled.div`
  width: 100%;
  padding: 40px 0 20px 0;
  display: flex;
  flex-direction: column;

  @media (${breakpoints.tablet}) {
    padding: 40px 0 105px 0;
  }
  @media (${breakpoints.mobile}) {
    padding: 40px 0 0 0;
  }
`;
const OfficeContainer = styled.div`
  padding: 0 140px 20px 0;
  display: flex;
  flex-direction: column;

  @media (${breakpoints.tablet}) {
    width: 332px;
    padding: 0 0px 20px 0;
    :first-child {
      padding: 0 10px 0 0;
    }
  }
  @media (${breakpoints.mobile}) {
    padding: 0 0 20px 0;
    width: 250px;
    :last-child {
      padding: 0;
    }
  }
`;
const OfficesHead = styled.div`
  padding: 0px 0 20px 0;
  color: #ff8916;
  font-size: 24px;
  font-weight: ${fontWeight.semibold};
  @media (${breakpoints.tablet}) {
  }
  @media (${breakpoints.mobile}) {
    padding: 0px 0 15px 0;
  }
`;
const Office12 = styled.div`
  display: flex;
`;
const OfficesTitle = styled.div`
  color: ${colors.WHITE};
  font-size: 18px;
  font-weight: ${fontWeight.bold};
  margin-bottom: 5px;
`;
const OfficesInfo = styled.div`
  color: ${colors.GRAY};
  &:hover {
    color: ${colors.WHITE};
    cursor: pointer;
  }
  font-size: 16px;
  font-weight: ${fontWeight.normal};
  margin-bottom: 15px;
`;
const FooterSocial = styled.div`
  display: flex;
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export {
  Root,
  Container,
  Title,
  Section,
  SectionBottom,
  Right,
  GetInTouchText,
  BottomText,
  SocialsContainer,
  LogoContainer,
  BrokreteSocialTop,
  WeOnSocialsText,
  Grid,
  Icons,
  Form,
  SocialSection,
  SocialSectionRow,
  ContactItemLine,
  ContactSection,
  FooterLink,
  FooterLinkItem,
  FooterLinkTitle,
  BrokreteTextSubtitle,
  TabletLinks,
  Image,
  GridContainer,
  TopGrid,
  OurContacts,
  ContactsContainer,
  ContactsHead,
  EmailPhone,
  ContactsTitle,
  ContactsInfo,
  OurOffices,
  OfficeContainer,
  OfficesHead,
  Office12,
  OfficesTitle,
  OfficesInfo,
  FooterSocial,
  TopContainer
};
