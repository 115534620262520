import React from 'react';
import { css } from '@emotion/react';
import PacmanLoader from 'react-spinners/PacmanLoader';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #0073ff;
`;
type Props = {
  color?: string;
};
const PacmanLoaderSpinner: React.FC<Props> = ({ color }): JSX.Element | null => {
  return (
    <div className="sweet-loading">
      <PacmanLoader color={color} css={override} size={25} margin={2} />
    </div>
  );
};

export default PacmanLoaderSpinner;
