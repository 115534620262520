import React from 'react';
import PropTypes from 'prop-types';
import { Root, Text } from './styled';
import I18n from '@src/lib/i18n';
import isEmpty from '@src/lib/isEmpty';

interface Props {
  value: number;
  units?: string;
  forceSign?: boolean;
  styles?: any;
  titleStyles?: any;
}

const Price: React.FC<Props> = ({ styles, titleStyles, value, units, forceSign }): JSX.Element | null => {
  const sign = value < 0 ? '-' : value > 0 && forceSign ? '+' : '';

  return (
    <Root styles={styles}>
      <Text styles={titleStyles}>
        {sign}
        {I18n.t('common.price', { value: Math.abs(value) })}
      </Text>
      {!isEmpty(units) && <Text styles={titleStyles}>/{units}</Text>}
    </Root>
  );
};
export default Price;
