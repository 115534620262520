import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Text } from '@src/components/common';
import { breakpoints, colors } from '@src/defaultStyles';
import { Link } from 'react-router-dom';
import sanitize from '@src/lib/sanitize';

const LinkText = styled(Text)`
  @media (${breakpoints.mobile}) {
    font-size: 12px !important;
    text-align: center;
    margin-bottom: 20px;
  }
  @media (${breakpoints.xs}) {
    font-size: 12px !important;
    text-align: center;
  }
`;

const Footer: React.FC<{ support?: boolean }> = ({ support }): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Root>
      {support && (
        <SupportBlock>
          <Link to="/terms">
            <LinkText color={colors.ALGAE_GREEN}>{t('landing.supportPanel.link.terms')}</LinkText>
          </Link>
          <Link to="/privacy">
            <LinkText color={colors.ALGAE_GREEN}>{t('landing.supportPanel.link.privacy')}</LinkText>
          </Link>
          <Link to="/supplier_terms">
            <LinkText color={colors.ALGAE_GREEN}>{t('landing.supportPanel.link.supplier_terms')}</LinkText>
          </Link>
          <Link to="/partner_terms">
            <LinkText color={colors.ALGAE_GREEN}>{t('landing.supportPanel.link.partner_terms')}</LinkText>
          </Link>
        </SupportBlock>
      )}

      <Copyright dangerouslySetInnerHTML={sanitize(t('footer.text'))} />
    </Root>
  );
};
export default Footer;
Footer.defaultProps = {
  support: true
};

const Root = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  @media (${breakpoints.tablet}) {
    margin-top: 0px;
    align-self: center;
  }
  @media (${breakpoints.mobile}) {
    padding-bottom: 20px;
    align-self: center;
  }
`;

const Copyright = styled(Text)`
  align-self: flex-start;
  font-size: 16px;
  color: ${colors.WHITE};

  @media (${breakpoints.tablet}) {
    align-self: center;
  }
`;

const SupportBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  max-width: 90vw;

  text-align: center;
  flex-wrap: wrap;
  a {
    text-decoration: none;

    span {
      font-size: 16px;
      color: ${colors.ALGAE_GREEN};
      @media (${breakpoints.xs}) {
        text-align: center;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media (${breakpoints.xs}) {
    flex-direction: column;

    a {
      margin-right: 0 !important;
    }
  }
`;
