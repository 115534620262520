import React, { useEffect } from 'react';
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { LandingPage, SignupPage, InvitePage } from './pages';
import asyncComponent from '@src/lib/asyncComponent';
import GeneralLayout from '@src/pages/GeneralLayout';
import { PageInfo } from '@src/config/PageInfoConfig';
import isEmpty from '@src/lib/isEmpty';
import ScrollToTop from '@src/ScrollToTop';
import AnchorLink from '@src/AnchorLink';

const WithLayout = (Component, rest: any = {}) =>
  function (props): JSX.Element | null {
    return (
      <GeneralLayout {...rest}>
        <Component {...props} />
      </GeneralLayout>
    );
  };
/* declare global {
  interface Window {
    Intercom: any;
  }
} */
const WithPageInfo =
  (code, options = {}) =>
  Component =>
  props => {
    const configDefault = PageInfo['default'];
    const config = PageInfo[code] || {};
    //const hideIntercom = options['hideIntercom'];

    const title = isEmpty(config.title) ? configDefault.title : config.title;
    const description = isEmpty(config.description) ? configDefault.description : config.description;
    const keywords = isEmpty(config.keywords) ? configDefault.keywords : config.keywords;

    /*    useEffect(() => {
      if (hideIntercom) {
        window.Intercom('update', {
          hide_default_launcher: true
        });
      } else {
        window.Intercom('update', {
          hide_default_launcher: false
        });
      }
    }, []); */

    return (
      <div>
        <Helmet>
          {title && <title>{title}</title>}
          {description && <meta name="description" content={description} />}
          {keywords && <meta name="keywords" content={keywords} />}
        </Helmet>

        <Component {...props} />
      </div>
    );
  };

/* const TermsAndConditionsAsync = asyncComponent(() => import('./pages/TermsAndConditions'));
const PrivacyPolicy = asyncComponent(() => import('./pages/PrivacyPolicy'));
const SupplierTerms = asyncComponent(() => import('./pages/SupplierTermsAndConditions'));
const PartnerTerms = asyncComponent(() => import('./pages/PartnerTermsAndConditions'));
const Partner = asyncComponent(() => import('./pages/Partner'));
const RequestInfoScreen = asyncComponent(() => import('./pages/RequestInfoScreen'));
const Career = WithLayout(asyncComponent(() => import('./pages/CareerPage')));
const CareerPosition = WithLayout(asyncComponent(() => import('./pages/CareerPositionPage')));*/

const ServicesCustomSoftwareDevelopment = asyncComponent(() => import('./pages/Services/CustomSoftwareDevelopment'));
const ServicesWebDevelopment = asyncComponent(() => import('./pages/Services/WebDevelopment'));
const ServicesMobileAppsDevelopment = asyncComponent(() => import('./pages/Services/MobileAppsDevelopment'));
const ServicesEcommerceDevelopment = asyncComponent(() => import('./pages/Services/EcommerceDevelopment'));
const ServicesProductDevelopment = asyncComponent(() => import('./pages/Services/ProductDevelopment'));
const ServicesCloudApplicationDevelopment = asyncComponent(() => import('./pages/Services/CloudApplicationDevelopment'));
const ServicesSoftwareTestingQa = asyncComponent(() => import('./pages/Services/SoftwareTestingQa'));
const ServicesUiUxDesign = asyncComponent(() => import('./pages/Services/UiUxDesign'));
const TechnologiesBackend = asyncComponent(() => import('./pages/Technologies/Backend'));
const TechnologiesFrontend = asyncComponent(() => import('./pages/Technologies/Frontend'));
const TechnologiesDatabaseApi = asyncComponent(() => import('./pages/Technologies/DatabaseApi'));
const TechnologiesDevOps = asyncComponent(() => import('./pages/Technologies/DevOps'));
const TechnologiesMobile = asyncComponent(() => import('./pages/Technologies/Mobile'));
const TechnologiesOthers = asyncComponent(() => import('./pages/Technologies/Others'));
const About = WithLayout(asyncComponent(() => import('./pages/AboutUs')));
const ContactUs = WithLayout(asyncComponent(() => import('./pages/ContactUs')));

const ApplicationRouter: React.FC = (): JSX.Element | null => (
  <BrowserRouter>
    <ScrollToTop />
    <AnchorLink />
    <Switch>
      {/*       <Route path="/terms" component={WithPageInfo('terms')(TermsAndConditionsAsync)} />
      <Route path="/privacy" component={WithPageInfo('privacy')(PrivacyPolicy)} />
      <Route path="/supplier_terms" component={WithPageInfo('supplier_terms')(SupplierTerms)} />
      <Route path="/partner_terms" component={WithPageInfo('partner_terms')(PartnerTerms)} />
      <Route path="/partner" component={Partner} />
      <Route path="/signup" component={SignupPage} />
      <Route path="/career" component={WithPageInfo('career')(Career)} exact />
      <Route path="/career/position/:id" component={WithPageInfo('careerPosition')(CareerPosition)} />
      <Route path="/request-info" component={RequestInfoScreen} />
      <Route path="/confirm_invite/:id" component={InvitePage} />
 */}

      <Route path="/" exact component={WithPageInfo('main')(LandingPage)} />
      <Route
        path="/services/custom-software-development"
        component={WithPageInfo('ServicesCustomSoftwareDevelopment')(ServicesCustomSoftwareDevelopment)}
      />
      <Route path="/services/web-development" component={WithPageInfo('ServicesWebDevelopment')(ServicesWebDevelopment)} />
      <Route
        path="/services/mobile-apps-development"
        component={WithPageInfo('ServicesMobileAppsDevelopment')(ServicesMobileAppsDevelopment)}
      />
      <Route
        path="/services/ecommerce-development"
        component={WithPageInfo('ServicesEcommerceDevelopment')(ServicesEcommerceDevelopment)}
      />
      <Route
        path="/services/product-development"
        component={WithPageInfo('ServicesProductDevelopment')(ServicesProductDevelopment)}
      />
      <Route
        path="/services/cloud-application-development"
        component={WithPageInfo('ServicesCloudApplicationDevelopment')(ServicesCloudApplicationDevelopment)}
      />
      <Route
        path="/services/software-testing-qa"
        component={WithPageInfo('ServicesSoftwareTestingQa')(ServicesSoftwareTestingQa)}
      />
      <Route path="/services/ui-ux-design" component={WithPageInfo('ServicesUiUxDesign')(ServicesUiUxDesign)} />
      <Route path="/technologies/backend" component={WithPageInfo('TechnologiesBackend')(TechnologiesBackend)} />
      <Route path="/technologies/frontend" component={WithPageInfo('TechnologiesFrontend')(TechnologiesFrontend)} />
      <Route path="/technologies/database-api" component={WithPageInfo('TechnologiesDatabaseApi')(TechnologiesDatabaseApi)} />
      <Route path="/technologies/dev-ops" component={WithPageInfo('TechnologiesDevOps')(TechnologiesDevOps)} />
      <Route path="/technologies/mobile" component={WithPageInfo('TechnologiesMobile')(TechnologiesMobile)} />
      <Route path="/technologies/others" component={WithPageInfo('TechnologiesOthers')(TechnologiesOthers)} />
      <Route path="/about" component={WithPageInfo('about')(About)} />
      <Route path="/contact-us" component={WithPageInfo('contactUs')(ContactUs)} />

      <Route component={() => <Redirect to="/" />} />
    </Switch>
  </BrowserRouter>
);

export default ApplicationRouter;
