import React, { useEffect, useMemo } from 'react';
import toastr from 'toastr';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';
import { pick, camelCase } from 'lodash';
import { BrokreteLogo } from '@src/components/common';
import Footer from 'components/pages/Footer';
import { RootContainer, ContentContainer, Heading, Locker, Text, Loader } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import InviteForm, { DefaultInitialFormValues } from './InviteForm';
import { useParams } from 'react-router';
import useInvite from '@src/pages/InvitePage/useInvite';
import inviteConfirm from '@src/pages/InvitePage/inviteConfirm';
import inviteReject from '@src/pages/InvitePage/inviteReject';
import I18n from 'i18next';
import prepareFormValues from '@src/pages/InvitePage/prepareFormValues';
import { InviteTypeEnum } from '@shared/interfaces';
import sanitize from '@src/lib/sanitize';
import PartnerLogo from '@src/pages/InvitePage/PartnerLogo';
import * as Yup from 'yup';
import { lowerCaseRegExp, numberRegExp, upperCaseRegExp } from '@src/lib/validators';

const RootContainerStyled = styled(RootContainer)`
  position: relative;

  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 135px;
  padding-top: 20px !important;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-color: ${colors.ALGAE_GREEN};
  margin-bottom: 20px;
`;

const PageHeading = styled(Heading)`
  padding: 20px 20px;
  max-width: 700px;
  font-size: 52px;

  color: ${colors.WHITE};
  text-align: center;

  @media (${breakpoints.tablet}) {
    width: 100%;
    text-align: center;
    font-size: 42px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 32px;
  }
`;

const InviteText = styled(Text)`
  font-size: 28px;
  font-weight: ${fontWeight.bold};
  font-family: ${fontFamily.bold};
  text-align: center;
  line-height: 1.2;

  margin-bottom: 20px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InvitationInstructions = styled(Text)`
  //max-width: 300px;
  font-size: 18px;
  text-align: center;

  margin-bottom: 40px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  & > *:last-child {
    margin-top: auto;
  }
`;

const PartnerLogoWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;

  img {
    object-fit: contain;
    max-width: 1000px !important;
    max-height: 240px !important;
  }
`;

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required(I18n.t('errors.validation.required'))
    .min(8, I18n.t('errors.validation.password.require8SymLength'))
    .matches(lowerCaseRegExp, I18n.t('errors.validation.password.requireLowercase'))
    .matches(upperCaseRegExp, I18n.t('errors.validation.password.requireUppercase'))
    .matches(numberRegExp, I18n.t('errors.validation.password.requireNumber')),
  password_confirm: Yup.string()
    .required(I18n.t('errors.validation.required'))
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const logoStyles = css`
  margin-top: 40px;
  margin-bottom: 40px;

  @media (${breakpoints.mobile}) {
    max-width: 200px;
    margin: 0 auto;
    font-size: 32px;
  }
`;

const InvitePage = ({}) => {
  const history = useHistory();
  const { id: invitationId } = useParams();
  const { invite, loaded } = useInvite(invitationId);

  useEffect(() => {
    if (invite) {
      if (invite.isConfirmed) {
        if (invite.redirectTo) {
          window.location.href = invite.redirectTo;
        } else {
          history.push('/');
        }
      }
    }
  }, [invite]);

  const validationSchema = useMemo(() => {
    return invite && invite.pending ? ValidationSchema : undefined;
  }, [invite]);

  const onSubmit = async (values, formActions) => {
    formActions.setSubmitting(true);

    let params = { invite };

    if (invite.pending) {
      switch (invite.type) {
        case InviteTypeEnum.PartnerContractor:
          params = {
            ...params,
            ...pick(values, ['name', 'city', 'country', 'password', 'phone'])
          };
          break;
        case InviteTypeEnum.PartnerDriver:
          params = {
            ...params,
            ...pick(values, ['name', 'city', 'password', 'phone'])
          };
          break;
        default:
          params = {
            ...params,
            ...pick(values, ['name', 'email', 'password'])
          };
          break;
      }
    }

    const result = await inviteConfirm(params);

    if (result) {
      toastr.success(I18n.t('invite.accepted'));
      if (invite.redirectTo) {
        window.location.href = invite.redirectTo;
      } else {
        history.push('/');
      }
    }

    formActions.setSubmitting(false);
  };

  const declineInvitation = async formActions => {
    formActions.setSubmitting(true);

    const result = await inviteReject(invite);

    if (result) {
      toastr.success(I18n.t('invite.rejected'));
      history.push('/');
    }

    formActions.setSubmitting(false);
  };

  const getInfoMessage = invite => {
    if (!invite) {
      return null;
    }

    if (invite.isCanceled) {
      return I18n.t('toastr.invite.canceled');
    }

    if (invite.isConfirmed) {
      return I18n.t('toastr.invite.confirmed');
    }

    return null;
  };

  if (!invite || invite.isConfirmed) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  const infoMessage = getInfoMessage(invite);
  const formValues = prepareFormValues(invite);

  return (
    <Root>
      {!invite.isPartnerInvite && (
        <BrokreteLogo
          containerStyles={css`
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          `}
        />
      )}
      <RootContainerStyled>
        <ContentContainer>
          <HeadingWrapper>
            <PageHeading>{I18n.t('invite.confirmYourInvitation')}</PageHeading>
          </HeadingWrapper>

          {invite.isPartnerInvite ||
            (invite.contractor.companyInfo.logo && (
              <>
                <PartnerLogoWrapper>
                  <PartnerLogo
                    partner={invite.isPartnerInvite ? invite.partner : invite.contractor.companyInfo.logo}
                    styles={logoStyles}
                  />
                </PartnerLogoWrapper>
              </>
            ))}

          <InviteText
            dangerouslySetInnerHTML={sanitize(
              I18n.t(`invite.${camelCase(invite.type)}`, {
                ...(invite.isPartnerInvite
                  ? {
                      partner: invite.partner.name
                    }
                  : {
                      contractor: invite.contractor.info.name
                    })
              })
            )}
          />

          <InvitationInstructions
            tag="div"
            dangerouslySetInnerHTML={sanitize(
              I18n.t(`invite.confirmInvitationInstructions.${invite.pending ? 'new' : 'existing'}`)
            )}
          />

          {!loaded ? (
            <div style={{ position: 'relative' }}>
              <Locker
                locked="true"
                styles={css`
                  position: absolute;
                  height: auto;
                `}
              />
            </div>
          ) : (
            <>
              {invite.status === 'pending' && (
                <InviteForm
                  formProps={{
                    editable: invite.pending,
                    type: invite.type,
                    onReject: declineInvitation
                  }}
                  onSubmit={onSubmit}
                  validationSchema={validationSchema}
                  initialValues={{ ...DefaultInitialFormValues, ...formValues }}
                />
              )}

              {infoMessage && (
                <Text
                  tag="div"
                  fontWeight={fontWeight.extraBold}
                  fontFamily={fontFamily.extraBold}
                  fontSize={32}
                  css={css`
                    text-align: center;
                    padding: 20px;
                    border: 1px solid ${colors.LIGHT};
                    border-radius: 15px;
                  `}
                >
                  {infoMessage}
                </Text>
              )}
            </>
          )}
        </ContentContainer>
      </RootContainerStyled>

      {/*<ContactsScreen />*/}
      <Footer support="true" />
    </Root>
  );
};

export default InvitePage;
