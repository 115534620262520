import React from 'react';
import { css } from '@emotion/react';
import BounceLoader from 'react-spinners/BounceLoader';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #0073ff;
`;
type Props = {
  color?: string;
};
const BounceLoaderSpinner: React.FC<Props> = ({ color }): JSX.Element | null => {
  return (
    <div className="sweet-loading">
      <BounceLoader color={color} css={override} size={60} />
    </div>
  );
};

export default BounceLoaderSpinner;
