import { Location, Place } from '../../interfaces';

import { TimedCacheValue } from '../../lib';
import { google } from '../../core';
import { handleError } from '../../errors';

const cacheOptions = { ttl: 5 * 60 * 1000 };

const cachedPlacesByQuery = {};
const cachedPlaceById = {};

function updatePlaceInCache(place: Place) {
  if (place != null && place.location != null) {
    const cachedValue = cachedPlaceById[place.id] || new TimedCacheValue<Place>(cacheOptions);
    cachedValue.value = place;
    cachedPlaceById[place.id] = cachedValue;
  }
}

export async function autocomplete(query: string, options?: { type?: string; ref?: any }): Promise<Array<Place>> {
  const cachedValue = cachedPlacesByQuery[query] || new TimedCacheValue<Array<Place>>(cacheOptions);

  if (cachedValue.value == null) {
    cachedValue.value = await google.places.queryAutocompleteSearch(query, options);
  }

  cachedPlacesByQuery[query] = cachedValue;

  if (cachedValue.value != null) {
    cachedValue.value.forEach(place => {
      updatePlaceInCache(place);
    });
  }

  return cachedValue.value;
}

export async function placeInfo(placeId: string, skipFetch = false): Promise<Place> {
  const cachedValue = cachedPlaceById[placeId] || new TimedCacheValue<Place>(cacheOptions);

  if (cachedValue.value == null && !skipFetch) {
    cachedValue.value = await google.places.details(placeId);
    cachedPlaceById[placeId] = cachedValue;
  }

  return cachedValue.value;
}

export function prepare(value): Place {
  return google.buildPlace(value);
}
