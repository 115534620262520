import styled from 'styled-components';
import { Heading } from '@src/components/common/index';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const SectionHeading = styled(Heading)`
  //width: 450px;
  font-size: 42px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.bold};
  line-height: 50px;
  text-align: left;
  color: ${colors.BLACK};
  margin-bottom: 20px;

  @media (${breakpoints.tablet}) {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 24px;
    width: 100%;
    line-height: 30px;
    text-align: center;
    margin-bottom: 20px;
  }
  letter-spacing: 1px;
`;

export default SectionHeading;
