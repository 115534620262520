import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '@src/defaultStyles';

const RootContainer = (props: any): JSX.Element | null => {
  return <Root {...props} />;
};

const Root = styled.div`
  position: relative;
  width: 100vw;
  padding: 120px 80px 115px 80px;
  box-sizing: border-box;

  @media (${breakpoints.tablet}) {
    padding: 80px 40px;
  }

  @media (${breakpoints.mobile}) {
    padding: 60px 15px 80px 15px;
  }

  ${props => props.styles}
`;

export default RootContainer;
