import styled from 'styled-components';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  padding: 0px 0 75px 0;

  @media (${breakpoints.tablet}) {
    padding: 120px 0 60px 0;
  }

  @media (${breakpoints.tablet}) {
    padding: 70px 0 50px 0;
  }
`;
const ServicesSectionHeader = styled.div`
  font-size: 42px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.bold};
  color: ${colors.EBONY_CLAY};

  @media (${breakpoints.tablet}) {
    font-size: 42px;
  }

  @media (${breakpoints.tablet}) {
    font-size: 28px;
  }
`;
const Services = styled.div`
  max-width: 1060px;
  margin: auto;
  padding: 50px 100px 0 100px;
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr 1fr;

  & > *:nth-child(odd) {
    margin-left: auto;
  }
  & > *:nth-child(even) {
    margin-right: auto;
  }

  @media (${breakpoints.tablet}) {
    padding: 60px 40px 0 40px;
  }

  @media (${breakpoints.mobile}) {
    grid-template-columns: 1fr;
    padding: 60px 20px 0 20px;

    & > *:not(:last-child) {
      margin-bottom: 30px;
    }

    & > *:nth-child(odd),
    & > *:nth-child(even) {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const OptionContainer = styled.div``;

const OpinionLeftSide = styled.div`
  position: relative;
  min-height: 220px;
  margin-left: 20px;
  padding: 10px;
  box-shadow: 16px 16px 32px -12px rgba(156, 156, 156, 0.27);
  border-radius: 20px;
  margin-right: 40px;
`;
const OpinionRightSide = styled.div`
  position: relative;
  min-height: 220px;
  margin-right: 20px;
  padding: 10px;
  box-shadow: 16px 16px 32px -12px rgba(156, 156, 156, 0.27);
  border-radius: 20px;
`;

const OpinionsInfo = styled.div``;

const OpinionsQuoted = styled.div`
  position: absolute;
  right: 10%;
  bottom: 10%;
`;

const OpinionsAuthor = styled.div`
  margin: 0px auto;
  margin-left: 25px;
  font-size: 18px;
  font-family: ${fontFamily.semiBold};
  color: ${colors.EBONY_CLAY};
`;

const OpinionsDesc = styled.div`
  max-width: 350px;
  opacity: 0.5;
  margin-left: 25px;
  margin-bottom: 25px;
  font-size: 15px;
  font-family: ${fontFamily.regular};
  color: ${colors.PALE_SKY};
`;

const ServicesSectionRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
`;
const Image = styled.img`
  width: 64px;
  height: 64px;
`;

export {
  Services,
  OpinionLeftSide,
  OpinionRightSide,
  OpinionsInfo,
  OpinionsQuoted,
  OpinionsAuthor,
  OpinionsDesc,
  ServicesSectionRoot,
  ServicesSectionHeader,
  Image,
  Root,
  OptionContainer
};
