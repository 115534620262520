import React from 'react';
import styled from 'styled-components';
import { breakpoints, colors } from 'defaultStyles';
import { Heading, Text } from 'components/common';

export default function ProductCard({ title, description, image }) {
  return (
    <Root>
      <ImageContainer>
        <img src={image} width="100%" alt="" />
      </ImageContainer>
      <CardHeading>{title}</CardHeading>
      <Description>{description}</Description>
    </Root>
  );
}

const Root = styled.div`
  flex: 1 1;
  width: 100%;
  max-width: 570px;
  min-width: 330px;
  min-height: 436px;
  //max-height: 486px;
  height: 100%;
  padding: 30px 50px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${colors.WHITE};

  @media (${breakpoints.tablet}) {
    padding: 18px 26px 30px;
  }

  @media (${breakpoints.mobile}) {
    max-width: 330px;
    min-width: 330px;
  }
`;

const CardHeading = styled(Heading)`
  margin-bottom: 20px;
  font-size: 32px;
  line-height: 1.44;
  letter-spacing: 0.5px;
  text-align: center;

  @media (${breakpoints.tablet}) {
    font-size: 24px;
    line-height: 1.92;
    letter-spacing: 0.5px;
  }
`;

const Description = styled(Text)`
  text-align: center;
  font-size: 18px;

  @media (${breakpoints.tablet}) {
    font-size: 16px;
    ine-height: 1.5;
  }
`;

const ImageContainer = styled.div`
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;
