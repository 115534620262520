import React, { useEffect, useRef, useState } from 'react';
import { Content, LeftBlock, FooterIntroduce, Root, Image, PageHeading, ImageWrapper, ImageText } from './styled';
import sanitize from '@src/lib/sanitize';
import { useTranslation } from 'react-i18next';
import { ScheduleDemoButton, WatchVideoButton } from '@src/components/pages';
import { ChipedText, ContactUsButton, SectionInfo, PageLink } from '@src/components/common';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import SectionHeading from '@src/components/common/SectionHeader';
import SectionSubheading from '@src/components/common/SectionSubheading';
import { isEmpty } from '@shared/lib';
import { ImageComponent } from '@src/components/common';

const TextAndImageSection: React.FC<{
  pageHeading?: string;
  chip?: string;
  chipText?: string;
  chipColor?: string;
  sectionHeading?: string;
  sectionSubHeading?: string;
  sectionInfo?: string;
  image?: any;
  imageWrapperStyles?: any;
  // used only for Table and Mobile screen sizes to properly set height
  imageHeightKoeff?: number;
  styles?: any;
  imageText?: string;
  imageTextStyles?: any;
  displayLink?: boolean;
  linkTitle?: string;
  linkUrl?: string;
  videoUrl?: string;
  id?: string;
}> = ({
  pageHeading,
  chip,
  chipText,
  chipColor,
  sectionHeading,
  sectionSubHeading,
  sectionInfo,
  image,
  imageWrapperStyles,
  // used only for Table and Mobile screen sizes to properly set height
  imageHeightKoeff,
  styles,
  imageText,
  imageTextStyles,
  displayLink,
  linkTitle,
  linkUrl,
  videoUrl,
  id
}): JSX.Element | null => {
  const imageWrapperRef = useRef();
  const [imageWrapperHeight, setImageWrapperHeight] = useState(undefined);
  const { t } = useTranslation();
  const { xs, mobile, tablet, desktop } = useMediaQuery();

  useEffect(() => {
    const handleResize = () => {
      if (imageWrapperRef && imageWrapperRef.current) {
        // @ts-ignore
        const width = imageWrapperRef.current.clientWidth;

        const h = width * imageHeightKoeff;

        setImageWrapperHeight(h);

        if (isEmpty(h)) {
          setTimeout(() => {
            handleResize();
          }, 100);
        }
      }
    };

    if (imageWrapperRef && imageWrapperRef.current) {
      window.addEventListener('resize', handleResize);

      handleResize();
    }

    return () => {
      window.addEventListener('resize', handleResize);
    };
  }, [tablet, imageWrapperRef, imageHeightKoeff]);

  if (tablet || mobile) {
    return (
      <Root styles={styles} id={id}>
        {pageHeading && <PageHeading dangerouslySetInnerHTML={sanitize(pageHeading)} />}

        <Content>
          <ChipedText chip={chip} text={chipText} chipColor={chipColor} />

          <SectionHeading dangerouslySetInnerHTML={sanitize(sectionHeading)} />

          {sectionSubHeading && <SectionSubheading>{sectionSubHeading}</SectionSubheading>}

          <ImageWrapper ref={imageWrapperRef} styles={imageWrapperStyles} height={imageWrapperHeight}>
            <ImageComponent image={image} />
          </ImageWrapper>

          <SectionInfo>{sectionInfo}</SectionInfo>
          <FooterIntroduce>
            <ScheduleDemoButton />
            <ContactUsButton />
            <WatchVideoButton videoUrl={videoUrl} />
          </FooterIntroduce>

          {displayLink && <PageLink linkTitle={linkTitle} linkUrl={linkUrl} />}
        </Content>
      </Root>
    );
  }

  return (
    <Root styles={styles} id={id}>
      {pageHeading && <PageHeading tag="h1" dangerouslySetInnerHTML={sanitize(pageHeading)} />}

      <Content>
        <LeftBlock className="tandi-left-block">
          <ChipedText chip={chip} text={chipText} chipColor={chipColor} />

          <SectionHeading dangerouslySetInnerHTML={sanitize(sectionHeading)} />
          {sectionSubHeading && <SectionSubheading>{sectionSubHeading}</SectionSubheading>}
          <SectionInfo>{sectionInfo}</SectionInfo>

          <FooterIntroduce>
            <ScheduleDemoButton />
            <ContactUsButton />
            <WatchVideoButton videoUrl={videoUrl} />
          </FooterIntroduce>

          {displayLink && <PageLink linkTitle={linkTitle} linkUrl={linkUrl} />}
        </LeftBlock>

        <ImageWrapper ref={imageWrapperRef} styles={imageWrapperStyles} height={imageWrapperHeight}>
          <ImageComponent image={image} />
        </ImageWrapper>

        {imageText && (
          <>
            <div />
            <ImageText styles={imageTextStyles}>{imageText}</ImageText>
          </>
        )}
      </Content>
    </Root>
  );
};

export default TextAndImageSection;
