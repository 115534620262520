import { Location } from '@shared/interfaces';

const getCurrentPosition = async () => {
  return new Promise<Location | null>((resolve, reject) => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    function onSuccess(position) {
      resolve(position.coords);
    }

    function onError(error) {
      console.warn(`ERROR(${error.code}): ${error.message}`);

      resolve(null);
    }

    try {
      navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    } catch (e) {
      console.warn(`ERROR`, e);

      resolve(null);
    }
  });
};

export { getCurrentPosition };

export default {
  getCurrentPosition
};
