import i18n from 'lib/i18n';
import isEmpty from '@src/lib/isEmpty';

// US postal code example: 1234-56789
const USPostalRegEx = /^[0-9]{5}(?:-[0-9]{4})?$/;
// CA postal code example: M4B 1B3
const CAPostalRegEx = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;

const countryCodeToRegEx = {
  US: USPostalRegEx,
  CA: CAPostalRegEx
};

export const validatePostalCode = (countryCode, postalCode) => {
  if (!countryCodeToRegEx[countryCode]) {
    return false;
  }

  const regEx = countryCodeToRegEx[countryCode];
  return regEx.test(postalCode);
};

export const required = value => {
  return value || typeof value === 'number' ? undefined : i18n.t('errors.validation.required');
};

export const checked = value => {
  return value ? undefined : i18n.t('errors.validation.required');
};

export const notEmpty = value => {
  return value && value.length > 0 ? undefined : i18n.t('errors.validation.required');
};

export const email = value => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? i18n.t('errors.validation.emailIsIncorrect')
    : undefined;
};

export const phone = value => {
  return value && !/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/i.test(value)
    ? i18n.t('errors.validation.phoneNumberIsIncorrect')
    : undefined;
};

export const minLength = min => value => {
  return value && value.length < min ? i18n.t('errors.validation.minLength', { length: min }) : undefined;
};

export const matches = value => {
  return value && !/(?=.*[A-Z])/.test(value) ? i18n.t('errors.validation.passwordIsIncorrect') : undefined;
};

export const url = value => {
  return !value || /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i.test(value)
    ? undefined
    : i18n.t('errors.validation.urlIsInvalid');
};

export const lowerCaseRegExp = /(?=.*[a-z])/;
export const upperCaseRegExp = /(?=.*[A-Z])/;
export const numberRegExp = /(?=.*[0-9])/;
export const minLengthRegExp = /(?=.{8,})/;

export const requireLowerCaseLetter = value => {
  return !value || lowerCaseRegExp.test(value) ? undefined : i18n.t('errors.validation.password.requireLowercase');
};

export const requireUpperCaseLetter = value => {
  return !value || upperCaseRegExp.test(value) ? undefined : i18n.t('errors.validation.password.requireUppercase');
};

export const requireNumber = value => {
  return !value || numberRegExp.test(value) ? undefined : i18n.t('errors.validation.password.requireNumber');
};

export const require8SymLength = value => {
  return !value || minLengthRegExp.test(value) ? undefined : i18n.t('errors.validation.password.require8SymLength');
};

export const passwordMatch = confirmation => value => {
  console.log('passwordMatch', value);
  console.log('confirmation', confirmation);
  return isEmpty(value) || isEmpty(confirmation) || value === confirmation
    ? undefined
    : i18n.t('errors.validation.password.shouldMatch');
};
