import styled from 'styled-components';

const ArrowDown = styled.div`
  position: absolute;
  width: 0px;
  height: 0px;

  right: 20px;

  border-top: 8px solid black;
  border-bottom: 0px solid transparent;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;

  transition: opacity ease-out 0.15s;

  ${props => props.styles}
`;

export default ArrowDown;
