import React from 'react';
import styled from 'styled-components';
import { Button } from '@src/components/common';
import I18n from 'i18next';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { ICONS } from '@src/assets/icons';
import ReactPlayer from 'react-player';
import ReactModal from 'react-modal';

const CloseVideoButtonStyled = styled(Button)`
  color: ${colors.WHITE} !important;
  border: 1px solid ${colors.WHITE};
  border-color: ${colors.WHITE} !important;
  background-color: ${colors.ALGAE_GREEN} !important;
  cursor: pointer;
  margin: 50px auto 50px auto;
`;

const WatchVideoButtonStyled = styled(Button)`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 4px;
  cursor: pointer;
  width: 129px;
  min-width: 129px;
  max-width: 129px;
  height: 40px;
  font-size: 14px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.semibold};
  color: ${({ color }) => color};
  letter-spacing: 0.35px;

  //box-shadow: 0px 2px 4px 0px rgba(100, 100, 100, 0.5);

  &:hover {
    color: ${colors.DOVE_GRAY};
  }

  @media (${breakpoints.mobile}) {
    max-width: 191px;
  }
`;

const StyledReactModal = styled(ReactModal)`
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
`;

const ReactPlayerStyled = styled(ReactPlayer)`
  overflow: hidden;
  position: relative;
  position: relative;
  width: 100%;
  height: 56.25vw;
  max-height: calc(100vh - 169px);
  min-height: 350px;
  background: #000;

  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Icon = styled.img`
  margin-right: 10px;
`;
const WatchVideoButton: React.FC<{ backgroundColor?: string; color?: string; videoUrl?: string }> = ({
  color = colors.DOVE_GRAY,
  backgroundColor = colors.WHITE,
  videoUrl
}): JSX.Element | null => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <StyledReactModal isOpen={modalIsOpen}>
        <CloseVideoButtonStyled onClick={closeModal}>{I18n.t('common.watchVideoClose')}</CloseVideoButtonStyled>
        <ReactPlayerStyled url={videoUrl} width="100%" height="auto" playing controls onEnded={closeModal} />
      </StyledReactModal>

      <WatchVideoButtonStyled primary onClick={openModal} color={color} backgroundColor={backgroundColor}>
        <Icon src={ICONS.PLAY_4} />
        {I18n.t('common.watchVideoButton')}
      </WatchVideoButtonStyled>
    </>
  );
};

export default WatchVideoButton;
