import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { City, Product } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.config');

const gqlQuery = gql`
  query OrderPreparatoryConfig {
    config {
      units {
        code
        name
        plural
      }
    }
  }
`;

type ServerResponse = {
  config: Response;
};

type Response = {
  units: Array<{ code: string; name: string; plural: Array<string> }>;
};

export default async ({}: {} = {}, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await query(gqlQuery, {}, options);

  return result.config;
};
