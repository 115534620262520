import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { Order, Partner, Plant, Product, Region } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, query, Options } from '@src/api/core/gql';

import { buildIdInput, buildRegionInput } from '../helper';

const logger = logs.getLogger('api.brokrete.config');

const gqlQuery = gql`
  query Config(
    $product: IdInput!
    $region: RegionInput!
    $order: IdInput
    $partner: IdInput
    $withPossibles: Boolean
    $pickup: Boolean
  ) {
    orderPreparatoryConfig(order: $order, input: { category: $product }, partner: $partner) {
      plants(region: $region, withPossibles: $withPossibles, pickup: $pickup) {
        ...plant
      }
    }
  }
  ${fragments.plant}
`;

type ServerResponse = {
  orderPreparatoryConfig: Response;
};

type Response = {
  plants: Array<Plant>;
};

type Props = {
  product: Product;
  region: Region;
  order?: Order;
  partner?: Partner;
  pickup?: boolean;
};

export default async ({ product, region, order, partner, pickup }: Props, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await query(
    gqlQuery,
    {
      product: buildIdInput(product),
      region: buildRegionInput(region),
      order: buildIdInput(order),
      partner: buildIdInput(partner),
      withPossibles: true,
      pickup
    },
    options
  );

  return result.orderPreparatoryConfig;
};
