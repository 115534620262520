import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { fontFamily, fontWeight, colors } from '@src/defaultStyles';

interface Props {
  tag?: string;
  align?: 'left' | 'center' | 'right' | 'justify';
  size?: 'sm' | 'md' | 'lg' | 'xl';
  display?: 'block' | 'inline' | 'inline-block';
  color?: string;
}
const Heading: React.FC<Props> = ({ tag, ...props }): JSX.Element | null => {
  return <Root {...props} as={tag} />;
};

Heading.defaultProps = {
  tag: 'span',
  align: 'left',
  size: 'lg',
  display: 'block',
  color: colors.CHARCOAL_GREY
};

function getSize(size) {
  switch (size) {
    case 'sm':
      return '24px';
    case 'md':
      return '32px';
    case 'xl':
      return '68px';
    case 'lg':
      return '52px';
    default:
      return '32px';
  }
}

function getLineHeight(size) {
  switch (size) {
    case 'sm':
      return 1.33;
    case 'lg':
      return 1.23;
    case 'xl':
      return 1.12;
    default:
      return 'normal';
  }
}

const Root = styled.span(props => ({
  padding: 0,
  margin: 0,
  textAlign: props.align,
  display: props.display,
  fontSize: getSize(props.size),
  fontFamily: fontFamily.extraBold,
  fontWeight: fontWeight.extraBold,
  lineHeight: getLineHeight(props.size),
  color: props.color
}));

export default Heading;
