import React from 'react';

import { colors } from '@src/defaultStyles';

interface Props {
  className?: any;
  color?: string;
  width?: number;
  height?: number;
  onClick: (e: React.MouseEvent) => void;
}

const IconLinkedin: React.FC<Props> = ({
  className,
  color = colors.BLACK,
  width = 22,
  height = 20,
  onClick
}): JSX.Element | null => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 22 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Brokrete_main_SELL" transform="translate(-220.000000, -8109.000000)" fill={color} fillRule="nonzero">
          <g id="Group-88-Copy-2" transform="translate(150.000000, 7652.000000)">
            <g id="Group-87" transform="translate(0.000000, 0.012568)">
              <g id="Group-23" transform="translate(0.000000, 420.871949)">
                <g id="Group-24" transform="translate(0.000000, 36.980426)">
                  <g id="icons8-linkedin-2-(1)" transform="translate(70.676994, 0.000000)">
                    <path
                      d="M4.38987538,18.9217312 L0,18.9217312 L0,6.02055083 L4.38987538,6.02055083 L4.38987538,18.9217312 Z M2.19581567,4.30039345 C0.981576136,4.30039345 0,3.33624524 0,2.14933665 C0,0.962428054 0.983332086,0 2.19581567,0 C3.40566532,0 4.38987538,0.964148212 4.38987538,2.14933665 C4.38987538,3.33624524 3.40566532,4.30039345 2.19581567,4.30039345 Z M20.1934268,18.9217312 L15.9730006,18.9217312 L15.9730006,12.6431567 C15.9730006,11.1457597 15.9440274,9.22004356 13.7789409,9.22004356 C11.5813692,9.22004356 11.2433488,10.8507528 11.2433488,12.5347868 L11.2433488,18.9217312 L7.02380062,18.9217312 L7.02380062,6.01108997 L11.0747776,6.01108997 L11.0747776,7.77511136 L11.131846,7.77511136 C11.695506,6.7602185 13.0730489,5.69028061 15.1275106,5.69028061 C19.4032492,5.69028061 20.1934268,8.36426526 20.1934268,11.8407033 C20.1934268,11.8407033 20.1934268,18.9217312 20.1934268,18.9217312 Z"
                      id="Shape"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconLinkedin;
