import styled, { css } from 'styled-components';

import ContentContainer from '@src/components/common/ContentContainer';
import RootContainer from '@src/components/common/RootContainer';
import { Heading, Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled(RootContainer)`
  padding: 10px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1300px;
  margin: auto;
  justify-content: center;
  background-color: ${colors.BACKGROUND_NEW_WHITE};
  @media (${breakpoints.mobile}) {
    padding: 0px;
  }
`;
const ScrollsGrid = styled(RootContainer)`
  padding: 0px 80px 60px 80px;
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
  margin-top: 20px;
  box-sizing: border-box;
  @media (${breakpoints.tablet}) {
    padding: 0px 50px 60px 50px;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    grid-gap: 30px;
  }
  @media (${breakpoints.mobile}) {
    padding: 0px 20px 60px 20px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
`;
export { Root, ScrollsGrid };
