import React from 'react';

import { Svg } from './styled';

const ButtonRemove: React.FC<{ [x: string]: any }> = (props): JSX.Element | null => {
  return (
    <Svg {...props} width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
      <g id="UI-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2.1.1.-Order-Schedule" transform="translate(-224.000000, -343.000000)">
          <g id="Group-11" transform="translate(0.000000, 329.000000)">
            <g id="Group-3" transform="translate(225.000000, 15.000000)">
              <g id="button_remove">
                <rect id="border" stroke="#BABBC0" x="0" y="0" width="40" height="40" rx="20" />
                <rect id="sign" fill="#72747E" x="12" y="19" width="16" height="2" rx="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default ButtonRemove;
