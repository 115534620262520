import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'components/common';
import { colors, fontFamily, fontWeight } from 'defaultStyles';

export function FlowItem({ counter, title, primary, ...restProps }) {
  return (
    <Root primary={primary} {...restProps}>
      <Counter primary={primary}>{counter}</Counter>
      <ItemText primary={primary}>{title}</ItemText>
    </Root>
  );
}

FlowItem.propTypes = {
  counter: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  primary: PropTypes.bool,
  secondary: PropTypes.bool
};

FlowItem.defaultProps = {
  primary: false
};

const Root = styled.div(props => ({
  display: 'flex',
  alignItems: 'baseline',
  padding: '15px',
  ...(props.primary && {
    borderRadius: '4px',
    boxShadow: '0 5px 15px 0 rgba(40, 42, 49, 0.07)',
    backgroundColor: colors.ALGAE_GREEN
  })
}));

const Counter = styled.div(props => ({
  width: '36px',
  height: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '20px',
  borderRadius: '18px',
  fontSize: '22px',
  fontFamily: fontFamily.extraBold,
  fontWeight: fontWeight.extraBold,
  color: props.primary ? colors.ALGAE_GREEN : colors.WHITE,
  backgroundColor: props.primary ? colors.WHITE : colors.BATTLESHIP_GREY
}));

const ItemText = styled(Text)(props => ({
  fontSize: '22px',
  fontFamily: fontFamily.semiBold,
  fontWeight: fontWeight.semibold,
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: props.primary ? colors.WHITE : colors.CHARCOAL_GREY
}));
