import React from 'react';
import styled from 'styled-components';

type Props = {
  children: JSX.Element | null;
  [x: string]: any;
};
const InputGroup: React.FC<Props> = ({ children, ...otherProps }): JSX.Element | null => {
  return <Root {...otherProps}>{children}</Root>;
};
export default InputGroup;
const Root = styled.div({
  display: 'flex',
  alignItems: 'center'
});
