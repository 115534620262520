import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { Text } from '@src/components/common';
import { breakpoints } from '@src/defaultStyles';

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: minmax(auto, 253px) 132px;
  align-items: center;
  justify-content: center;

  max-width: 370px;
  max-height: 412px;

  border-radius: 10px;
  background-color: ${colors.WHITE};

  ${props => props.styles}

  &:hover {
    background-color: #ffffff;
    box-shadow: 0 20px 35px rgba(0, 0, 0, 0.0856643);
  }

  @media (${breakpoints.tablet}) {
    max-width: 100%;
    max-height: 360px;
  }

  @media (${breakpoints.mobile}) {
    max-width: 100%;
    max-height: 299px;
  }
`;

const Title = styled(Text)`
  display: block;
  min-width: 130px;
  width: 100%;
  margin-bottom: 20px;

  text-align: center;
  font-size: 24px;
  font-weight: ${fontWeight.black};
  line-height: 1;
  letter-spacing: 0.2px;
`;

const Image = styled.img`
  margin: auto;
  max-height: 253px;
  max-width: 100%;
  height: auto;
  width: auto;

  @media (${breakpoints.tablet}) {
    max-height: 192px;
  }

  @media (${breakpoints.mobile}) {
    max-height: 165px;
  }
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 15px 30px;
  min-height: 132px;
`;

export { Root, Title, Image, TextBlock };

export default {
  Root,
  Title,
  Image,
  TextBlock
};
