import React from 'react';

import { Description, Image, ItemIcon, Root, Subtitle, Title } from './styled';

import { Price } from '@src/components/common';

import isEmpty from '@src/lib/isEmpty';

interface PropsType {
  item: { title: string; subtitle: string; icon?: any; options: any; price: { [x: string]: any }; [x: string]: any };
  onClick: Function;
  styles?: any;
}
class MenuItem extends React.Component<PropsType> {
  renderIcon(icon: any, styles?: any, className?: any) {
    if (typeof icon === 'string' && !isEmpty(icon)) {
      return (
        <ItemIcon className={className} styles={styles}>
          <Image src={icon} />
        </ItemIcon>
      );
    }

    //TODO: UNCOMMENT
    // if (React.isValidElement(icon)) {
    //   return (
    //     <ItemIcon className={className} styles={styles}>
    //       {React.createElement<{[x:string]:any}>(icon)}
    //     </ItemIcon>
    //   );
    // }

    return null;
  }

  render(): JSX.Element | null {
    const { item, onClick } = this.props;

    const { title, subtitle, icon, options, price } = item;

    return (
      <Root stytles={this.props.styles} onClick={onClick}>
        {this.renderIcon(icon)}

        <Description>
          <Title>{title}</Title>
          {!isEmpty(subtitle) && <Subtitle>{subtitle}</Subtitle>}
        </Description>

        {price && <Price value={price.value} units={price.units} />}

        {options && options.length > 0 && (
          <svg className="shevron" xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10">
            <path
              fill="#72747E"
              d="M1.385 10H.462c-.179 0-.341-.093-.417-.238-.077-.146-.054-.319.06-.442L4.019 5 .105.68C-.01.557-.032.384.045.238.121.093.283 0 .462 0h.923c.139 0 .27.057.357.153l4.154 4.583c.139.153.139.375 0 .528L1.742 9.847c-.087.096-.218.153-.357.153z"
            />
          </svg>
        )}
      </Root>
    );
  }
}

export default MenuItem;
