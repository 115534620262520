import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMedia from 'use-media';
import styled from 'styled-components';
import { Button, ContentContainer, RootContainer, Logo, MenuItem, Nav, MenuToggleButton } from '@src/components/common';
import MenuModal from '@src/pages/LandingPage/modals/MenuModal';
import MenuDropdownItem from './MenuDropdownItem';
import { breakpoints, colors, fontFamily } from '@src/defaultStyles';
import { Link } from 'react-router-dom';
import { CONTACT_US_URL } from '@src/config';
import { useHistory } from 'react-router';
import I18n from 'i18next';
import { InstagramButton, FacebookButton, LinkedinButton, TwitterButton } from '@src/components/pages';
import Drawer from '../Drawer';
import { ICONS } from '@src/assets/icons';
import { IMAGES } from '@src/assets/images';

const MAX_SCREEN_AVAILABLE = 980;

const ActionButton = styled(Button)`
  font-size: 12px;
  font-family: ${fontFamily.semiBold};
  min-width: 105px;
  width: 105px;
  height: 40px;

  background-color: ${props => (props.primary ? colors.ALGAE_GREEN : colors.BLACK)};
  color: ${colors.WHITE};
`;

const Header: React.FC<{
  className?: string;
  light?: boolean;
  commerce?: boolean;
  displayStartSelling?: boolean;
  logo?: JSX.Element;
}> = ({ className, light = false, commerce = false, displayStartSelling = false, logo = null }): JSX.Element | null => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const isScreenWidthRestricted = useMedia({ maxWidth: `${MAX_SCREEN_AVAILABLE}px` });
  const [menuModalIsOpened, setMenuModalOpened] = useState(false);

  const openMenuModal = useCallback(() => {
    setMenuModalOpened(true);
  }, []);

  const closeMenuModal = useCallback(() => {
    setMenuModalOpened(false);
  }, []);

  useEffect(() => {
    if (!isScreenWidthRestricted && menuModalIsOpened) {
      closeMenuModal();
    }
  }, [isScreenWidthRestricted, menuModalIsOpened, closeMenuModal]);

  const headerContactUsClick = useCallback(() => {
    history.push('/contact-us');
  }, []);

  const onContactUsClick = () => {
    history.push('/contact-us');
  };

  return (
    <Root className={className}>
      <Container>
        <Drawer isOpen={isDrawerOpened} onClose={setIsDrawerOpened.bind(this, false)} />
        <Link to="/">{logo ? logo : <Logo color={light ? colors.WHITE : colors.CHARCOAL_GREY} commerce={commerce} />}</Link>
        <Icons>
          <TwitterButton className="twitter-icon" width={25.176} height={19.332} color={colors.GRAY} />
          <FacebookButton className="facebook-icon" width={25.176} height={24.168} color={colors.GRAY} />
          <LinkedinButton className="linkedin-icon" width={25.176} height={22.956} color={colors.GRAY} />
          <InstagramButton className="instagram-icon" width={25.176} height={24.168} color={colors.GRAY} />
        </Icons>
        <ToggleButton onClick={openMenuModal} />

        <NavStyled>
          {/*<MenuItem to='/supplier' label={t('landing.header.forSuppliers')} light={light} />*/}
          <MenuItem key={'key_menu_home'} to="/" label={t('header.home')} light={light} />
          <MenuDropdownItem
            key={'key_menu_services'}
            to="/services"
            label={t('header.services')}
            light={light}
            options={[
              {
                label: I18n.t('header.servicesItems.item1'),
                url: '/services/custom-software-development'
              },
              {
                label: I18n.t('header.servicesItems.item2'),
                url: '/services/web-development'
              },
              {
                label: I18n.t('header.servicesItems.item3'),
                url: '/services/mobile-apps-development'
              },
              {
                label: I18n.t('header.servicesItems.item4'),
                url: '/services/ecommerce-development'
              },
              {
                label: I18n.t('header.servicesItems.item5'),
                url: '/services/product-development'
              },
              {
                label: I18n.t('header.servicesItems.item6'),
                url: '/services/cloud-application-development'
              },
              {
                label: I18n.t('header.servicesItems.item7'),
                url: '/services/software-testing-qa'
              },
              {
                label: I18n.t('header.servicesItems.item8'),
                url: '/services/ui-ux-design'
              }
            ]}
          />

          <MenuDropdownItem
            key={'key_menu_technologies'}
            to="/technologies"
            label={t('header.technologies')}
            light={light}
            options={[
              {
                label: I18n.t('header.technologiesItems.item1'),
                url: '/technologies/backend'
              },
              {
                label: I18n.t('header.technologiesItems.item2'),
                url: '/technologies/frontend'
              },
              {
                label: I18n.t('header.technologiesItems.item3'),
                url: '/technologies/database-api'
              },
              {
                label: I18n.t('header.technologiesItems.item4'),
                url: '/technologies/dev-ops'
              },
              {
                label: I18n.t('header.technologiesItems.item5'),
                url: '/technologies/mobile'
              },
              {
                label: I18n.t('header.technologiesItems.item6'),
                url: '/technologies/others'
              }
            ]}
          />

          {/*<MenuItem to='https://store.brokrete.com/sourcing' label={t('landing.header.forSourcing')} target='_blank' isExternal />*/}
          <MenuItem key={'key_menu_about'} to="/about" label={t('header.about')} light={light} />
          {/*  <MenuItem key={'key_menu_career'} to="/career" label={t('landing.header.career')} exact={false} light={light} /> */}

          {displayStartSelling ? (
            <ActionButton onClick={onContactUsClick}>{t('header.contactUsButton')}</ActionButton>
          ) : (
            <ActionButton primary onClick={headerContactUsClick}>
              {t('header.contactUsButton')}
            </ActionButton>
          )}
          <MenuImage onClick={setIsDrawerOpened.bind(this, true)} src={IMAGES.DRAWER_MENU} />
        </NavStyled>
      </Container>

      <MenuModal
        opened={menuModalIsOpened}
        onClose={closeMenuModal}
        onVolumePricingClick={headerContactUsClick}
        displayStartSelling={displayStartSelling}
        onContactUsClick={onContactUsClick}
      />
    </Root>
  );
};

export default Header;

const Root = styled(RootContainer)`
  position: fixed;
  background-color: ${colors.DODGER_BLUE};
  padding-top: 12px;
  padding-bottom: 12px;
  z-index: 1000;
  margin: auto;
  @media (max-width: 1150px) {
    padding: 5px 10px 5px 10px;
  }

  @media (${breakpoints.mobile}) {
    padding: 5px 10px 5px 10px;
  }
`;

const Container = styled(ContentContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavStyled = styled(Nav)`
  & > *:not(:last-child) {
    margin-right: 40px;
  }

  @media (${breakpoints.tablet}) {
    & > *:not(:last-child) {
      margin-right: 30px;
    }
  }

  @media (max-width: ${MAX_SCREEN_AVAILABLE}px) {
    display: none;
  }
`;

const ToggleButton = styled(MenuToggleButton)`
  display: none;

  @media (max-width: ${MAX_SCREEN_AVAILABLE}px) {
    display: block;
  }
`;
const Icons = styled.div`
  cursor: pointer;

  @media (${breakpoints.tablet}) {
    margin-top: 0px;
  }
  > * {
    margin-right: 15px;
  }

  .twitter-icon,
  .facebook-icon,
  .instagram-icon {
    margin-top: 2px;
  }

  @media (${breakpoints.tablet}) {
    justify-content: center;

    > * {
      margin-right: 7px;
      margin-left: 7px;
    }
  }

  @media (${breakpoints.mobile}) {
    justify-content: center;
    margin-top: 10px;
  }
`;
const MenuImage = styled.img({
  cursor: 'pointer'
});
