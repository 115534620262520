import { RootContainer } from '@src/components/common';
import { breakpoints, colors } from '@src/defaultStyles';
import styled from 'styled-components';

const Root = styled(RootContainer)`
  background-color: ${colors.BACKGROUND_GREY_STORY};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0;
  position: relative;
  width: 100vw;
`;
const Container = styled.div`
  width: 100%;
  margin-top: 100px;
  @media (${breakpoints.tablet}) {
    margin-top: 50px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (${breakpoints.desktop}) {
    max-width: 1240px;
  }
  width: 80%;
  /* max-width: 1140px; */
  /* @media (min-width:767px) and ( max-width: 1024px) { */
  /* max-width: 80%; */

  /* min-width: 100%; */

  /* } */
`;
const TopSection = styled.div`
  /* width: 1140px; */
  width: 100%;

  /* max-width: 1140px; */

  position: relative;
  align-self: center;

  @media (${breakpoints.tablet}) {
    width: auto;

    align-self: center;
    /* padding: 60px 50px 60px 50px; */
  }

  @media (${breakpoints.mobile}) {
    /* padding: 35px 20px 35px 20px; */
  }
`;
const Title = styled.div`
  width: 400px;
  font-weight: bold;
  font-size: 40px;
  line-height: 1.2;
  @media (${breakpoints.tablet}) {
    text-align: center;
  }
  @media (${breakpoints.mobile}) {
    width: auto;
    font-size: 20px;
  }
  /* position: absolute; */
  /* z-index: 30; */
`;
const QuoteImage = styled.img`
  position: absolute;
  height: 65px;
  top: -40px;
  left: -25px;
  @media (${breakpoints.tablet}) {
    top: -30px;
    left: -30px;
  }
  @media (${breakpoints.mobile}) {
    height: 45px;
    left: -30px;
  }
  opacity: 0.4;
`;

export { Root, Container, TopSection, Title, QuoteImage, Content };
