import React from 'react';

import RightArrowIcon from '@src/components/common/RightArrowIcon';
import sanitize from '@src/lib/sanitize';
import { Image, InfoWrapper, Root, TechnologiesItemInfo, TechnologiesItemImage, LinkStyled, LinkArrowStyled } from './styled';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import styled from 'styled-components';
import { breakpoints } from '@src/defaultStyles';
import { IMAGES } from '@src/assets/images';

const RightArrowIconStyled = styled(RightArrowIcon)`
  align-self: flex-start;
  margin-top: 6px;
  @media (${breakpoints.tablet}) {
    margin-top: 0;
    position: absolute;
    top: 20px;
    right: 10px;
  }
`;
interface Props {
  onClick: Function;
  image: any;
  title: string;
  info: any;
  imageStyles?: any;
}
const TechnologiesItems: React.FC<Props> = ({ onClick, image, title, info, imageStyles }): JSX.Element | null => {
  return (
    <Root onClick={onClick}>
      <TechnologiesItemImage>
        <Image imageStyles={imageStyles} src={image} />
      </TechnologiesItemImage>
      <InfoWrapper>
        <LinkStyled>
          {title}
          <LinkArrowStyled src={IMAGES.LINK_ARROW} />
        </LinkStyled>
        <TechnologiesItemInfo dangerouslySetInnerHTML={sanitize(info)} />
      </InfoWrapper>
    </Root>
  );
};

export default TechnologiesItems;
