import styled from 'styled-components';
import { breakpoints } from '@src/defaultStyles';

const PageWrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  //margin-top: 74px;

  @media (${breakpoints.mobile}) {
    margin-top: 40px;
  }
`;

export { Content, PageWrapper };
