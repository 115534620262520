import React from 'react';
import styled from 'styled-components';
import PartnerItem from './PartnerItem';
import usePartners from '@src/hooks/usePartners';
import InfiniteScrollLoop from '@src/components/common/PartnersLine/InfiniteScrollLoop';

const Root = styled.div`
  display: flex;
  flex-wrap: nowrap;
  > * {
    margin-right: 20px;
  }

  align-items: center;

  max-width: 100vw;

  overflow-x: scroll;

  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none;
  }

  ${props => props.css}
`;

const Empty = styled.div`
  display: block;
  max-width: 30px;
  max-height: 60px;
  min-width: 30px;
  min-height: 60px;

  box-sizing: border-box;
`;

const SimpleScroll: React.FC<{ [x: string]: any }> = ({ children, shadow, cssProps }): JSX.Element | null => {
  return (
    <Root css={cssProps} shadow={shadow}>
      <Empty />
      {children}
      <Empty />
    </Root>
  );
};

const PartnersLine: React.FC<{ [x: string]: any }> = ({ cssProps, shadow }): JSX.Element | null => {
  const { partners } = usePartners();

  const ScrollComponent = partners.length > 5 ? InfiniteScrollLoop : SimpleScroll;

  return (
    <ScrollComponent css={cssProps} shadow={shadow}>
      {partners.map(partner => (
        <PartnerItem key={`key_partner_${partner.id}`} {...partner} />
      ))}
    </ScrollComponent>
  );
};

export default PartnersLine;
