import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from 'components/common';
import { breakpoints } from 'defaultStyles';
import { Field } from 'formik';
import { formikForm, TextInput } from 'components/common/forms';
import composeValidators from 'lib/composeValidators';
import { email, required } from 'lib/validators';

function SubscribeForm({ handleSubmit, isSubmitting, isValid, dirty, ...restProps }) {
  const { t } = useTranslation();

  return (
    <Form onSubmit={handleSubmit} {...restProps}>
      <Field
        name="email"
        id="email"
        placeholder={t('signUp.input.email.placeholder')}
        component={TextInputStyled}
        validate={composeValidators(email, required)}
      />

      <SendButton type="submit" width={150} primary disabled={isSubmitting || !isValid || !dirty}>
        {t('landing.subscribeScreen.button.send')}
      </SendButton>
    </Form>
  );
}

export default formikForm(SubscribeForm, {
  initialValues: {
    email: ''
  }
});

const Form = styled.form`
  width: 100%;
  margin-bottom: 82px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const TextInputStyled = styled(TextInput)`
  position: relative;
  max-width: 360px;
  width: 100%;
  margin-right: 10px;

  @media (${breakpoints.mobile}) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const SendButton = styled(Button)`
  @media (${breakpoints.mobile}) {
    width: 100%;
  }
`;
