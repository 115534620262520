import React, { useEffect, useState } from 'react';
import { ContentContainer, Loader } from '@src/components/common';
import I18n from 'i18next';
import PartnerItem from './PartnerItem';
import { Root, PageHeading, LoaderContainer, PartnersList, ItemWrapper, ViewMoreButton, InfoText } from './styled';
import usePartners from '@src/hooks/usePartners';
import sanitize from '@src/lib/sanitize';
import styled from 'styled-components';
import { breakpoints, fontFamily, fontWeight } from '@src/defaultStyles';
import { useMediaQuery } from '@src/hooks/useMediaQuery';

const PartnerName = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  padding: 2px 7px;
  max-height: 42px;
  height: 100%;
  box-sizing: border-box;
  line-height: 1.5;

  @media (${breakpoints.tablet}) {
    max-height: 46px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 10px;
    max-height: 41px;
    line-height: 1.4;
  }
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
  background-color: black;
  z-index: 1;
  height: 42px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const PartnerSuppliersScreen = () => {
  const { partners, loaded } = usePartners();
  const { tablet, xs } = useMediaQuery();
  const [displayAllPartners, setDisplayAllPartners] = useState(false);

  const [items, setItems] = useState(partners);

  useEffect(() => {
    if (xs) {
      setItems(partners.slice(0, 8));
    } else if (tablet) {
      setItems(partners.slice(0, 16));
    } else {
      setItems(partners);
    }
  }, [partners, tablet, xs]);

  const displayVewMoreButton = !displayAllPartners && partners.length > items.length;

  return (
    <Root>
      <ContentContainer>
        <PageHeading dangerouslySetInnerHTML={sanitize(I18n.t('landing.partnerSuppliers.title'))} />
        {xs && <InfoText dangerouslySetInnerHTML={sanitize(I18n.t('landing.partnerSuppliers.subtitle'))} />}

        {loaded ? (
          <PartnersList>
            {(displayAllPartners ? partners : items).map((partner, idx) => {
              return (
                <ItemWrapper key={idx}>
                  <PartnerItem name={partner.name} logo={partner.logo} code={partner.code} />
                  <PartnerName>{partner.name}</PartnerName>
                  <Line />
                </ItemWrapper>
              );
            })}
          </PartnersList>
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}

        {displayVewMoreButton && (
          <ViewMoreButton onClick={() => setDisplayAllPartners(true)}>
            {I18n.t('landing.partnerSuppliers.viewMore')}
          </ViewMoreButton>
        )}
      </ContentContainer>
    </Root>
  );
};

export default PartnerSuppliersScreen;
