import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@src/defaultStyles';
import { useHistory } from 'react-router';
import { Button } from '@src/components/common';

const ButtonStyled = styled(Button)`
  color: ${colors.ALGAE_GREEN} !important;
  border: 1px solid ${colors.ALGAE_GREEN};
  border-color: ${colors.ALGAE_GREEN} !important;
  background-color: ${colors.WHITE} !important;
  cursor: pointer;
  width: 154px;
`;

const RequestInfoButton: React.FC = (): JSX.Element | null => {
  const history = useHistory();

  return (
    <ButtonStyled secondary onClick={() => history.push('/request-info')}>
      Contact Us
    </ButtonStyled>
  );
};

export default RequestInfoButton;
