import { DRAWER_MENU_LIST } from '@src/config/DrawerMenuConfig';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Shadow,
  DrawerWrapper,
  CloseIcon,
  MenuItems,
  SectionsItems,
  SectionItemRoot,
  SectionTitle,
  SectionDescription,
  MenuTitle,
  MenuItemsWrapper
} from './styled';
import { ICONS } from '@src/assets/icons';

const Drawer: React.FC<{
  isOpen?: boolean;
  onClose?: Function;
}> = ({ isOpen, onClose }): JSX.Element | null => {
  const [sections, setSections] = useState([0, 0]);
  const [activeRoute, setActiveRoute] = useState([0, 0, 0]);
  const { pathname, hash } = useLocation();

  const calculateActiveRoute = () => {
    const _drawerIndex = DRAWER_MENU_LIST.findIndex(({ items }) => items.find(({ link }) => link === pathname));
    const _itemsIndex = DRAWER_MENU_LIST[_drawerIndex]?.items.findIndex(
      () =>
        ({ link }) =>
          link === pathname
    );
    const _sectionIndex = DRAWER_MENU_LIST[_drawerIndex]?.items[_itemsIndex].sections.findIndex(({ link }) => link === hash);
    setSections([_drawerIndex, _itemsIndex]);
    setActiveRoute([_drawerIndex, _itemsIndex, _sectionIndex]);
  };
  useEffect(() => {
    calculateActiveRoute();
  }, [pathname, hash]);

  const onHoverMenu = (indx, _indx) => {
    setSections([indx, _indx]);
  };
  return (
    <>
      <Shadow isOpen={isOpen} onClick={onClose} src={ICONS.CLOSE_2} />

      <DrawerWrapper isOpen={isOpen}>
        <CloseIcon onClick={onClose} />
        <MenuItemsWrapper>
          {DRAWER_MENU_LIST.map(({ title, items }, index) => (
            <>
              {title && <MenuTitle key={index + title}>{title}</MenuTitle>}
              {items.map(({ title, link }, _indx) => (
                <MenuItems
                  key={title + _indx}
                  to={link}
                  onMouseMove={() => onHoverMenu(index, _indx)}
                  active={sections.length === 2 && sections[0] === index && sections[1] === _indx}
                >
                  {title}
                </MenuItems>
              ))}
            </>
          ))}
        </MenuItemsWrapper>

        <SectionsItems>
          {sections.length === 2 &&
            DRAWER_MENU_LIST[sections[0]]?.items[sections[1]].sections.map((infos, indx) => (
              <SectionItem
                onClose={onClose}
                key={indx}
                mainLink={DRAWER_MENU_LIST[sections[0]].items[sections[1]].link}
                {...infos}
                activeSectionIndex={activeRoute[0] === sections[0] && activeRoute[1] === sections[1] && activeRoute[2] == indx}
              />
            ))}
        </SectionsItems>
      </DrawerWrapper>
    </>
  );
};

const SectionItem: React.FC<{
  title: string;
  description: string;
  link: string;
  mainLink: string;
  activeSectionIndex: boolean;
  onClose?: Function;
}> = ({ title, description, link, mainLink, activeSectionIndex, onClose }): JSX.Element | null => {
  return (
    <SectionItemRoot to={mainLink + link}>
      <SectionTitle onClick={onClose} active={activeSectionIndex}>
        {title}
      </SectionTitle>
      <SectionDescription>{description}</SectionDescription>
    </SectionItemRoot>
  );
};

export default Drawer;
