import styled from 'styled-components';
import { Text as TextCommon } from '@src/components/common';
import { fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  ${props => props.styles}
`;

const Text = styled(TextCommon)`
  display: inline;
  font-family: ${fontFamily.regular};
  font-size: 18px;
  font-weight: ${fontWeight.normal};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.5px;
  text-align: right;

  ${props => props.styles}
`;

export { Root, Text };

export default {
  Root,
  Text
};
