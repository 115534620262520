import React, { Component } from 'react';
import SelectedItem from './SelectedItem';
import SelectMenu from './SelectMenu';
import MenuItem from './MenuItem';
import { SelectProps } from './selectPropTypes';
import isEmpty from '@src/shared/lib/isEmpty';
import { Root, Label } from './styled';
import styles from './styles';

interface PropsType {
  items: Array<any>;
  label?: string;
  menuItemRenderer?: Function;
  menuHeight?: number;
  placeholder: string;
  bordered: boolean;
  scrollable: boolean;
  prepareItem: any;
  menuTitle: string;
  align?: 'bottom' | 'right';
  disabled: boolean;
  onChange?: Function;
  toggleButtonStyles?: any;
  styles?: any;
  arrowStyles?: string;
  selectedItem?: any;
  menuStyles?: any;
}
interface StateType {
  items: Array<any>;
  cursor: any;
  opened: any;
}

class Select extends React.Component<PropsType, StateType> {
  static defaultProps = {
    items: [],
    align: 'bottom',
    menuHeight: 200,
    arrowStyles: [],
    menuItemRenderer: MenuItem,
    scrollable: false,
    prepareItem: item => item
  };
  containerRef: any;
  inputRef: any;

  constructor(props) {
    super(props);

    this.state = {
      items: this.props.items,
      cursor: null,
      opened: false
    };

    this.containerRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.items !== state.items) {
      return {
        items: props.items
      };
    }

    return null;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick, true);
  }

  handleDocumentClick = e => {
    const container = this.containerRef.current;
    if (container.contains(e.target) && container !== e.target) {
      return;
    }

    this.setState({ opened: false });
  };

  handleShowMenu = () => {
    this.setState({ opened: !this.state.opened });
  };

  handleItemOnClick = item => {
    this.setState(
      {
        opened: false
      },
      () => {
        const { onChange } = this.props;
        onChange && onChange(item);

        const target = this.inputRef && this.inputRef.current;
        if (target) {
          this.inputRef.current.blur();
        }
      }
    );
  };

  render() {
    const { label, menuItemRenderer, menuHeight, placeholder, bordered, scrollable, prepareItem, menuTitle, align, disabled } =
      this.props;

    const { opened, items, cursor } = this.state;

    return (
      <Root styles={this.props.styles} ref={this.containerRef}>
        {!isEmpty(label) && <Label>{label}</Label>}

        <SelectedItem
          styles={this.props.toggleButtonStyles}
          arrowIconStyles={[
            styles.arrow,
            this.props.arrowStyles,
            align === 'right' ? [styles.arrowRight, opened ? styles.arrowRightOpened : ''] : opened ? styles.arrowDownOpened : ''
          ]}
          item={
            this.props.selectedItem || typeof this.props.selectedItem === 'number'
              ? prepareItem(this.props.selectedItem, true)
              : { title: placeholder }
          }
          bordered={bordered}
          active={opened}
          disabled={disabled}
          onClick={this.handleShowMenu}
        />

        <SelectMenu
          items={items}
          containerStyles={align === 'right' ? styles.alignRight : null}
          menuStyles={[styles.menu, align === 'right' ? styles.menuMarginLeft : styles.menuMarginTop, this.props.menuStyles]}
          menuTitle={menuTitle}
          cursorPosition={cursor}
          active={opened}
          dropdownMenuHeight={menuHeight}
          itemRenderer={menuItemRenderer}
          onItemClick={this.handleItemOnClick}
          scrollable={scrollable}
          prepareItem={prepareItem}
        />
      </Root>
    );
  }
}

export default Select;
