import { Place, Price, Product, ProductPrice, Region } from '@shared/interfaces';
import { isEmpty } from '@shared/lib';

export function buildProductInput(product: Product, prices?: Array<ProductPrice>) {
  return {
    ...buildIdInput(product),
    ...(!isEmpty(prices) && {
      prices: prices.map(value => ({
        typePrice: buildIdInput(value.productTypePrice.price),
        ...(value.productDecoratePrice && {
          decoratePrice: buildIdInput(value.productDecoratePrice.price)
        }),
        ...(!isEmpty(value.quantity) && {
          quantity: value.quantity
        })
      }))
    })
  };
}

export function buildProductPricesInput(prices: Array<ProductPrice>) {
  return prices.map(value => ({
    typePrice: buildIdInput(value.productTypePrice.price),
    ...(value.productDecoratePrice && {
      decoratePrice: buildIdInput(value.productDecoratePrice.price)
    }),
    ...(!isEmpty(value.quantity) && {
      quantity: value.quantity
    })
  }));
}

export function buildAddressInput(place: Place) {
  return {
    location: {
      latitude: place.location.latitude,
      longitude: place.location.longitude
    },
    state: place.state,
    city: place.city,
    fixed: true,
    streetAddress: place.mainText,
    zip: place.zip
  };
}

export function buildPriceIdInput(values: Array<{ price: Price }>) {
  return values.map(value => {
    return buildIdInput(value.price);
  });
}

export function buildIdInput<T = string>(value: { id: T }) {
  if (value == null) {
    return undefined;
  }

  return {
    id: value.id
  };
}

export function buildRegionInput(value: Region) {
  if (value == null) {
    return undefined;
  }

  return {
    latitude: value.latitude,
    longitude: value.longitude,
    deltaLatitude: value.deltaLatitude,
    deltaLongitude: value.deltaLongitude,
    radius: value.radius
  };
}
