import styled from 'styled-components';
import { RootContainer, Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { ContentContainer } from '@src/components/common';

const Root = styled(RootContainer)`
  padding-top: 150px;
  @media (${breakpoints.tablet}) {
    padding-top: 100px;
    padding-bottom: 120px;
  }
  @media (${breakpoints.mobile}) {
    padding-top: 80px;
    padding-bottom: 100px;
  }
`;
const Content = styled(ContentContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  & > * {
    justify-self: center;

    :not(:last-child) {
      margin-bottom: 70px;
    }
  }

  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const Block1 = styled.div`
  @media (${breakpoints.mobile}) {
    margin-top: 40px;
    flex-direction: column;
  }
`;

const Block1Icon = styled.div`
  margin-right: 10px;
  margin-bottom: 20px;
`;

const Block1LeftSide = styled.div`
  @media (${breakpoints.mobile}) {
    border-right: none;
  }
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 140px auto auto auto;

  @media (${breakpoints.tablet}) {
    text-align: center;
  }
  @media (${breakpoints.mobile}) {
    text-align: left;
  }
`;

const Block1LeftSideHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
`;
const TitleIcon = styled.img`
  width: 65px;
`;
const SideHeading = styled.div`
  font-size: 17px;
  letter-spacing: 1.55px;
  font-weight: ${fontWeight.semibold};
  color: ${colors.ALGAE_GREEN};
  max-width: 500px;
  @media (${breakpoints.tablet}) {
  }
  @media (${breakpoints.mobile}) {
    font-size: 16px;
    max-width: 380px;
  }
`;
const SideInfo = styled.div`
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 17px;
  font-weight: ${fontWeight.light};
  line-height: 26px;
  letter-spacing: 0.4px;
  max-width: 450px;
  color: #6b7c93;

  @media (${breakpoints.tablet}) {
  }

  @media (${breakpoints.mobile}) {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 10px;
  }
`;
const LinkStyled = styled(Text)`
  display: flex;
  align-items: center;
  font-size: 17px;
  letter-spacing: 0.5px;
  font-weight: 700;
  cursor: pointer;
  color: ${colors.CORNFLOWER_BLUE};

  @media (${breakpoints.tablet}) {
    margin: 0 auto;
  }

  @media (${breakpoints.mobile}) {
    margin: 0;
    text-align: left;
  }
`;

export { Block1, Block1Icon, Block, Block1LeftSideHeader, TitleIcon, SideHeading, SideInfo, LinkStyled, Root, Content };
