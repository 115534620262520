import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AnimatedSlider, RootContainer, ContentContainer, Heading, Text } from '@src/components/common';
import Q from './Quote';
import { whatPeopleSayQuotes } from '@src/config';
import { ICONS } from '@src/assets/icons';
import { breakpoints } from '@src/defaultStyles';
import IconButton from './IconButton';
import sanitize from '@src/lib/sanitize';
import { fontFamily, fontWeight } from '@src/defaultStyles';
import { IMAGES } from '@src/assets/images';

class WhatPeopleSayScreen extends Component<{ t: any }, { currentSlideIndex: number; animating: boolean }> {
  _sliderRef: any = React.createRef();

  state = {
    currentSlideIndex: 0,
    animating: false
  };

  onSlideChange = () => {
    this.setState({ animating: false });
  };

  goNext = () => {
    if (this.sliderRef) {
      this.sliderRef.next();
      this.setState(prevState => {
        return {
          currentSlideIndex: prevState.currentSlideIndex + 1,
          animating: true
        };
      });
    }
  };

  goPrevious = () => {
    if (this.sliderRef) {
      this.sliderRef.previous();
      this.setState(prevState => {
        return {
          currentSlideIndex: prevState.currentSlideIndex - 1,
          animating: true
        };
      });
    }
  };

  render() {
    const { t } = this.props;

    const quotes = whatPeopleSayQuotes;

    return (
      <Root>
        <ContentContainer>
          <Title1 align="center" dangerouslySetInnerHTML={sanitize(t('landing.whatPeopleSay.testimonials'))} />
          <Title2 align="center" dangerouslySetInnerHTML={sanitize(t('landing.whatPeopleSay.title'))} />
          <Container>
            <SliderWrapper>
              <AnimatedSlider
                // @ts-ignore
                ref={this._sliderRef}
                className="slider-wrapper wow fadeInUp animated"
                data-wow-duration="1s"
                infinite
                onSlideChange={this.onSlideChange}
                style={{ height: 400 }}
              >
                {quotes.map(({ author, quote }, i) => {
                  return (
                    <div key={i} className="slider-content">
                      <Quote author={author} quote={quote} />
                    </div>
                  );
                })}
              </AnimatedSlider>

              <ButtonGroup>
                <IconButton icon={ICONS.ARROW_LEFT} onClick={this.goPrevious} />
                <IconButton icon={ICONS.ARROW_RIGHT} onClick={this.goNext} />
              </ButtonGroup>
            </SliderWrapper>
            <GoogleRatesImage src={IMAGES.SMART_CODEV_LOGO} />
          </Container>
        </ContentContainer>
      </Root>
    );
  }

  get sliderRef() {
    return this._sliderRef.current;
  }
}

export default withTranslation()(WhatPeopleSayScreen);

const Root = styled(RootContainer)`
  padding-top: 90px;
  padding-bottom: 0px;
  @media (${breakpoints.tablet}) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (${breakpoints.tablet}) {
    display: none;
  }
  @media (${breakpoints.mobile}) {
    padding-top: 50px;
    padding-bottom: 50px;
    display: none;
  }
`;

const RatesText = styled(Text)`
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
`;

const Title1 = styled(Heading)`
  font-size: 15px;
  font-family: ${fontFamily.regular};
  font-weight: ${fontWeight.normal};

  @media (${breakpoints.mobile}) {
    margin-bottom: 30px;
    font-size: 20px;
  }
`;

const Title2 = styled(Heading)`
  font-size: 44px;
  font-weight: ${fontWeight.light};

  @media (${breakpoints.mobile}) {
    margin-bottom: 30px;
    font-size: 32px;
  }
`;

const Quote = styled(Q)`
  margin: 0 auto 127px;

  @media (${breakpoints.mobile}) {
    margin-bottom: 30px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  top: -100px;
  @media (${breakpoints.mobile}) {
    justify-content: flex-end;
    top: 0px;
  }
  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

const SliderWrapper = styled.div`
  grid-area: reviews;
  max-width: 570px;
  width: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  column-gap: 10px;
  justify-items: center;
  align-items: center;

  grid-template-areas: 'reviews icon';

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'icon'
      'reviews';
  }
  @media (${breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

const GoogleRatesImage = styled.img`
  grid-area: icon;
  width: 357px;
  height: 86px;
  margin-left: auto;
  align-self: flex-start;
  margin-top: 70px;
  @media (max-width: 1024px) {
    max-width: 300px;
  }
  @media (${breakpoints.mobile}) {
    margin-top: 50px;
  }
`;
