import React, { Component } from 'react';
import querystring from 'querystring';
import jwt from 'jsonwebtoken';
import api from '@shared/api';
import { ContentContainer, Locker, RootContainer } from '@src/components/common';
import { ICONS } from '@src/assets/icons';
import I18n from '@src/lib/i18n';
import styles from './styles';
import { PageHeading, ProductsContainer, ViewMoreButton } from './styled';
import sanitize from '@src/lib/sanitize';
import { isEmpty } from '@shared/lib';
import ProductTypeItem from './ProductTypeItem';
import { distanceBetween } from '@shared/lib/map';
import { ORDER_BROKRETE_URL } from '@src/config';
import core from '@src/core';
import { useMediaQuery } from '@src/hooks/useMediaQuery';

const XS_MIN_COUNT = 8;
const TABLET_MIN_COUNT = 16;

class MarketPlace extends Component<
  {
    xs: any;
    tablet: any;
    desktop: any;
  },
  {
    availableCities: any;
    availableProducts: any[];
    availableCitiesGroup: any;
    selectedPlace: any;
    loading: boolean;
    productsInARaw: number;
    displayAllPartners: boolean;
    items: any[];
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      availableCities: undefined,
      availableProducts: [],
      availableCitiesGroup: {},
      selectedPlace: null,
      loading: true,
      productsInARaw: 3,
      displayAllPartners: false,
      items: []
    };
  }

  async fetchMarketData() {
    try {
      const { cities } = await api.brokrete.config.common();

      const geolocationCoordinates = await core.geolocation.getCurrentPosition();

      let selectedPlace = null;

      if (geolocationCoordinates) {
        const citiesWithDistance = cities
          .map(city => ({
            city,
            distance: distanceBetween(city.address.location, geolocationCoordinates)
          }))
          .filter(v => v.distance < 100)
          .sort((a, b) => a.distance - b.distance);

        selectedPlace = isEmpty(citiesWithDistance) ? null : citiesWithDistance[0].city;
      }

      if (selectedPlace == null) {
        selectedPlace = cities.find(city => city.name === 'Toronto');
      }

      const availableCitiesGroup = cities.reduce((memo, city) => {
        const country = city.country.name;
        const code = city.country.code;

        if (!(country in memo)) {
          memo[country] = {
            title: country,
            icon: ICONS.FLAGS[code],
            options: []
          };
        }

        memo[country].options.push(city);

        return memo;
      }, {});

      const { products } = selectedPlace ? await this.fetchProducts(selectedPlace) : { products: [] };
      const { xs, tablet } = this.props;

      this.setState({
        selectedPlace: selectedPlace,
        availableProducts: products,
        items: xs ? products.slice(0, XS_MIN_COUNT) : tablet ? products.slice(0, TABLET_MIN_COUNT) : products,
        availableCities: cities,
        availableCitiesGroup,
        loading: false
      });
    } catch (e) {}
  }

  componentDidMount() {
    this.fetchMarketData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.xs !== prevProps.xs || this.props.tablet !== prevProps.tablet) {
      if (this.props.xs) {
        this.setState({ items: this.state.availableProducts.slice(0, XS_MIN_COUNT) });
      } else if (this.props.tablet) {
        this.setState({ items: this.state.availableProducts.slice(0, TABLET_MIN_COUNT) });
      }
    } else {
      if (this.props.desktop !== prevProps.desktop) {
        this.setState({ items: this.state.availableProducts });
      }
    }
  }

  prepareItem = (item, asTitle = false) => {
    return {
      icon: asTitle ? ICONS.FLAGS[item.country.code] : undefined,
      title: !asTitle ? `${item.name}` : item.country.name,
      subtitle: !asTitle ? undefined : item.name
    };
  };

  onSelectCity = async selectedPlace => {
    if (selectedPlace) {
      this.setState({ loading: true });

      const { products } = await this.fetchProducts(selectedPlace);

      this.setState({ selectedPlace, availableProducts: products, loading: false });
    } else {
      this.setState({ selectedPlace: null, availableProducts: [] });
    }
  };

  onCreateOrder = product => {
    const query = querystring.stringify({
      product: product.id,
      selectedCity: this.state.selectedPlace.id,
      resetPartner: true,
      address: jwt.sign(this.state.selectedPlace.address, 'internal')
    });

    const win = window.open(`${ORDER_BROKRETE_URL}/setup-suppliers?${query}`, '_blank');
    win.focus();
  };

  findPlaceByPosition = async position => {
    const places = await api.geocode.byLocation({
      position: position,
      type: 'premise|subpremise|street_address|route|political'
    });

    const foundPlace =
      places.length > 0
        ? {
            ...places[0],
            position
          }
        : null;

    return {
      ...foundPlace,
      ...(position && {
        location: position
      })
    };
  };

  fetchProducts = async selectedPlace => {
    return await api.brokrete.config.products({
      region: {
        ...selectedPlace.address.location,
        radius: 50
      }
    });
  };

  render() {
    const { items, loading, displayAllPartners, availableProducts } = this.state;

    const displayVewMoreButton = !displayAllPartners && availableProducts.length > items.length;
    return (
      <RootContainer styles={styles.rootContainer}>
        <ContentContainer>
          <PageHeading dangerouslySetInnerHTML={sanitize(I18n.t('landing.marketPlace.title'))} />

          {loading && <Locker styles={styles.locker} locked />}

          {!isEmpty(items) && (
            <ProductsContainer>
              {(displayAllPartners ? availableProducts : items).map(product => (
                <ProductTypeItem key={product.id} product={product} onClick={() => this.onCreateOrder(product)} />
              ))}
            </ProductsContainer>
          )}

          {displayVewMoreButton && (
            <ViewMoreButton onClick={() => this.setState({ displayAllPartners: true })}>
              {I18n.t('landing.partnerSuppliers.viewMore')}
            </ViewMoreButton>
          )}
        </ContentContainer>
      </RootContainer>
    );
  }
}

const withMediaQuery = Component => props => {
  const { xs, tablet, desktop } = useMediaQuery();
  return <Component {...props} xs={xs} tablet={tablet} desktop={desktop} />;
};

export default withMediaQuery(MarketPlace);
