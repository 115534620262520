import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as TriangleSVGIcon } from '../../assets/icons/triangle2.svg';
import { ReactComponent as BracketGreySVGIcon } from '../../assets/icons/bracket-grey.svg';
import { MenuItem } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { useRouteMatch } from 'react-router-dom';

const Root = styled.div`
  position: relative;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const MenuContainer = styled.div`
  position: relative;
`;

const Menu = styled.div`
  position: absolute;
  width: 276px;
  border: 1px solid ${colors.LIGHT};
  background-color: #adff2f;
  border-radius: 5px;
  padding: 5px;
  left: -10px;

  & > *:not(:last-child) {
    border-bottom: 1px solid #d8d8d8;
  }

  & > * {
    padding: 10px 25px 10px 25px;
  }
`;

const Icon = styled(TriangleSVGIcon)`
  margin-left: 10px;
  transform: ${props => (props.opened ? 'rotate(90deg);' : 'rotate(0deg);')};
  transition: all 0.2s;

  #TR-G2 {
    fill: ${props => (props.active ? colors.ALGAE_GREEN : props.light ? colors.WHITE : 'black')};
  }
`;

const MenuItemText = styled.span`
  font-size: 13px;
  font-family: ${fontFamily.medium};
  font-weight: ${fontWeight.normal};
`;

const MenuItemIcon = styled(BracketGreySVGIcon)`
  margin-left: auto;
  width: 16px;
  height: 16px;
`;
type Props = {
  light?: boolean;
  [x: string]: any;
};
const MenuDropdownItem: React.FC<Props> = ({ to, label, light, options }): JSX.Element | null => {
  const match = useRouteMatch({
    path: to,
    exact: false
  });

  const menuRef: { current: any } = useRef();
  const [opened, setOpened] = useState(false);

  const setMenuOpened: (e: MouseEvent) => void = e => {
    e.preventDefault();
    setOpened(!opened);
  };

  useEffect(() => {
    const onDocumentClick = e => {
      const container = menuRef.current;
      if (container?.contains(e.target) && container !== e.target) {
        return;
      }

      setOpened(false);
    };

    document.addEventListener('click', onDocumentClick);

    return () => {
      document.removeEventListener('click', onDocumentClick);
    };
  }, [setOpened]);

  return (
    <Root ref={menuRef}>
      <Row>
        <MenuItem key={`key_menu_${to}`} to={to} label={label} exact={false} onClick={setMenuOpened} light={light} />
        <Icon light={light} active={match} opened={opened} />
      </Row>

      <MenuContainer>
        {opened && (
          <Menu>
            {options.map(option => (
              <MenuItem
                key={`key_menu_${option.url}`}
                to={option.url}
                label={
                  <Row>
                    <MenuItemText>{option.label}</MenuItemText>
                    <MenuItemIcon />
                  </Row>
                }
              />
            ))}
          </Menu>
        )}
      </MenuContainer>
    </Root>
  );
};

export default MenuDropdownItem;
