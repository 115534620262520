import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

const useFormFieldResetOnChange = (target, dependents, withValue = '') => {
  const [dependentFields] = useState(dependents);
  const { values, setFieldValue, dirty } = useFormikContext();
  const [targetFieldValue, setTargetFieldValue] = useState(values[target]);
  const currentTargetValue = values[target];

  useEffect(() => {
    if (dirty && currentTargetValue !== targetFieldValue) {
      dependentFields.forEach(field => {
        setFieldValue(field, withValue, true);
      });
    }

    if (currentTargetValue !== targetFieldValue) {
      setTargetFieldValue(currentTargetValue);
    }
  }, [setFieldValue, dependentFields, targetFieldValue, dirty, currentTargetValue, setTargetFieldValue]);
};

export default useFormFieldResetOnChange;
