import React, { Suspense } from 'react';
import { useImage } from 'react-image';
import styled from 'styled-components';
import RiseLoaderSpinner from '@src/components/common/Spinners';
import { Loader } from '@src/components/common';

type Props = {
  image?: any;
};

const ImageComponent: React.FC<Props> = ({ image }): JSX.Element | null => {
  function MyImageComponent() {
    /*     const { src } = useImage({
      srcList: image
    });
 */
    return <Image src={image} />;
  }
  return (
    // @ts-ignore
    <Suspense fallback={<RiseLoaderSpinner />}>
      <MyImageComponent />
    </Suspense>
  );
};
export default ImageComponent;

const Image = styled.img`
  position: relative;
  width: 100%;
  align-self: center;
`;
