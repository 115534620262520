import styled from 'styled-components';
import { Text } from '@src/components/common';
import { breakpoints, fontWeight, colors, fontFamily } from '@src/defaultStyles';

const Root = styled.div`
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 70px;
  }
`;

const TextInfo = styled(Text)`
  text-align: center;
  font-size: 15px;
  width: 670px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.GULL_GRAY};

  @media (${breakpoints.tablet}) {
    order: 2;
    text-align: center;
  }

  @media (${breakpoints.mobile}) {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
`;

const PartnerPlatform1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;

  @media (${breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-top: 0px;
  }
`;

const PlatformImage1 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-right: 80px;

  @media (${breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    margin-right: 0px;
  }
`;
const PlatformImage2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;

  @media (${breakpoints.mobile}) {
    display: flex;
    justify-content: center;
  }
`;
const PlatformImage3 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-left: 80px;

  @media (${breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
`;

const Image = styled.img`
  cursor: pointer;
  width: auto;
  height: 64px;
  @media (${breakpoints.mobile}) {
    width: 50%;
    height: auto;
  }
`;

export { TextInfo, Image, PartnerPlatform1, PlatformImage1, PlatformImage2, PlatformImage3, Root };
