import { useLocation } from 'react-router-dom';
import qs from 'query-string';

export default function useAhoyEventParams({ platform }) {
  const location = useLocation();

  const path = location.pathname.replace('/', '');
  const { referral } = qs.parse(location.search);
  const source = path === '' ? 'landing' : (referral && 'referral') || path;

  return { platform, source };
}
