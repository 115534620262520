export interface Subscription {
  remove(): void;
}

type Listener<TParams> = (params?: TParams) => boolean;

interface ListenerRecord<TParams> {
  id: number;
  priority: number;
  listener: Listener<TParams>;
}

const nextId = (() => {
  let id = 0;
  return () => ++id;
})();

export default class EventEmitter<TParams = void> {
  private listeners: Array<ListenerRecord<TParams>> = [];

  constructor(defaultListener: Listener<TParams>) {
    this.subscribe(defaultListener, -1);
  }

  emit(params?: TParams) {
    const cachedListeners = [...this.listeners];

    const count = cachedListeners.length;

    for (let i = 0; i < count; i++) {
      const result = cachedListeners[i].listener(params);

      if (!result) return;
    }
  }

  subscribe(listener: Listener<TParams>, priority = 0): Subscription {
    const record = {
      id: nextId(),
      priority: priority,
      listener
    };

    this.listeners = [...this.listeners, record];

    this.listeners.sort((a, b) => {
      if (a.priority !== b.priority) return -(a.priority - b.priority);
      return a.id - b.id;
    });

    return {
      remove: () => {
        this.listeners = this.listeners.filter(value => value.id !== record.id);
      }
    };
  }
}
