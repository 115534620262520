import moment, { Moment, unitOfTime } from 'moment-timezone';

export class Date {
  private readonly moment: Moment;

  constructor(moment: Moment) {
    this.moment = moment;
  }

  get uxTime(): number {
    return this.moment.unix() * 1000;
  }

  clone() {
    return new Date(this.moment.clone());
  }

  startOfDay() {
    this.moment.hours(0).minutes(0).seconds(0).milliseconds(0);

    return this;
  }

  add(value: { days?: number; hours?: number; minutes?: number }) {
    this.moment.add(value);
    return this;
  }

  /**
   * https://momentjs.com/docs/#/displaying/
   */
  format(format?: string) {
    return this.moment.format(format);
  }

  isSame(other: Date, granularity?: unitOfTime.StartOf) {
    if (other == null || !(other instanceof Date)) {
      return false;
    }
    return this.moment.isSame(other.moment, granularity);
  }

  isBefore(other: Date, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isBefore(other.moment, granularity);
  }

  isAfter(other: Date, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isAfter(other.moment, granularity);
  }

  isSameOrBefore(other: Date, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isSameOrBefore(other.moment, granularity);
  }

  isSameOrAfter(other: Date, granularity?: unitOfTime.StartOf): boolean {
    if (other == null || !(other instanceof Date)) {
      return false;
    }

    return this.moment.isSameOrAfter(other.moment, granularity);
  }

  diff(end: Date, unitOfTime?: unitOfTime.Diff) {
    return this.moment.diff(end.moment, unitOfTime);
  }

  toString() {
    return this.format();
  }

  tz(timezone: string, keepLocalTime = true) {
    this.moment.tz(timezone, keepLocalTime);
    return this;
  }

  utc(keepLocalTime = true) {
    this.moment.utc(true);
    return this;
  }
}

export const now = () => {
  return new Date(moment(moment.now()));
};

export const fromUxTime = (uxtime: number, timezone?: string) => {
  if (timezone) {
    return new Date(moment.tz(uxtime, 'UTC').tz(timezone, true));
  } else {
    return new Date(moment(uxtime));
  }
};

export const fromString = (time: string, timezone?: string) => {
  return new Date(timezone ? moment.tz(time, timezone) : moment.parseZone(time));
};
