import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function AnchorLink({ history }) {
  const { pathname, hash, key } = history.location;
  console.log(history);
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return null;
}

export default withRouter(AnchorLink);
