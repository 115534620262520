import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { ContractorMember, Identity } from '@shared/interfaces';

import * as fragments from '../fragments';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.confirmIdentity');

const query = gql`
  mutation ConfirmIdentity($email: String, $phone: String, $token: String) {
    contractorConfirmIdentity(email: $email, phone: $phone, token: $token) {
      success
      status
      contractorMember {
        identities {
          ...identity
        }
      }
      identity {
        ...identity
      }
    }
  }
  ${fragments.identity}
`;

type ServerResponse = {
  contractorConfirmIdentity: Response;
};

type Response = {
  success: boolean;
  status: string;
  contractorMember: ContractorMember;
  identity: Identity;
};

export default async (
  { email, phone, token }: { email?: string; phone?: string; token?: string },
  options: Options = null
): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      email,
      phone,
      token
    },
    options
  );

  return result.contractorConfirmIdentity;
};
