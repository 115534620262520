import styled from 'styled-components';

const ButtonGroup = styled.div(props => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& > *:not(:last-child)': {
    marginRight: `${props.marginRight}px` || '0px'
  }
}));

export default ButtonGroup;
