import React from 'react';
import styled from 'styled-components';
import Heading from 'components/common/Heading';
import { useTranslation } from 'react-i18next';
import { OrderManagementFlow as Flow } from 'config';
import { FlowItem } from 'pages/LandingPage/screens/OrderManagementScreen/FlowItem';
import { breakpoints } from 'defaultStyles';

export default function OrderManagementFlow({ selectedScreen, onSelectScreen, ...props }) {
  const { t } = useTranslation();

  const selectScreen = index => {
    if (onSelectScreen) {
      onSelectScreen(index);
    }
  };

  return (
    <Root {...props}>
      {/*<ScreenHeading size='lg'>{t('landing.orderManagementFlow.title')}</ScreenHeading>*/}

      <List>
        {Flow.map((key, i) => (
          <ListItem
            key={i}
            onClick={() => selectScreen(i)}
            counter={i}
            title={t(`landing.orderManagementFlow.flow.${key}`)}
            primary={selectedScreen === i}
          />
        ))}
      </List>
    </Root>
  );
}

const Root = styled.div`
  max-width: 570px;
  width: 100%;
`;

const ScreenHeading = styled(Heading)`
  margin-bottom: 65px;
  font-size: 52px;
  line-height: 1.23;

  @media (${breakpoints.tablet}) {
    margin-bottom: 50px;
    font-size: 36px;
    line-height: 1.17;
    letter-spacing: 0.5px;
  }
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled(FlowItem)`
  max-width: 480px;
  width: 100%;
  height: 66px;
  box-sizing: border-box;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

export { ScreenHeading };
