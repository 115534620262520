import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

type Props = {
  heading: string;
  name: string;
  type?: string;
  reference?: Function;
  [x: string]: any;
};
const CustomInput: React.FC<Props> = ({ heading, name, type, reference, ...rest }): JSX.Element | null => {
  return (
    <Root {...rest}>
      <label>
        <StyledInput type={type ? type : 'text'} name={name} ref={reference} placeholder="&nbsp;" />
        <StyledLabel>{heading}</StyledLabel>
        <StyledBorder />
      </label>
    </Root>
  );
};

const Root = styled.div`
  display: block;
  width: 100%;
  padding: 0px 10px;
  position: relative;
  margin: auto;
  margin-top: 20px;
  max-width: 100%;
`;
const StyledLabel = styled.span`
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 16px;
  color: #9098a9;
  font-weight: 500;
  transform-origin: 0 0;
  transition: all 0.2s ease;
`;
const StyledBorder = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #50c367;
  transform: scaleX(0);
  transform-origin: 0 0;
  transition: all 0.15s ease;
`;
const StyledInput = styled.input`
  width: 100%;
  border: 0;
  font-family: inherit;
  padding: 10px 0;
  height: 45px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid #ddd;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
  &:hover {
    background: rgba(34, 50, 84, 0.03);
  }
  &:not(:placeholder-shown) + span {
    color: #50c367;
    transform: translateY(-26px) scale(0.75);
  }
  &:focus {
    background: none;
    outline: none;
  }
  &:focus + span {
    color: #50c367;
    transform: translateY(-26px) scale(0.75);
  }
  &:focus + span + ${StyledBorder} {
    transform: scaleX(1);
  }
`;

export default CustomInput;
