import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { workWithUsAdvantages } from 'config';
import AdvantageItem from 'pages/LandingPage/screens/WorkWithUsScreen/AdvantageItem';
import { ContentContainer, Heading, Text } from 'components/common';
import RootContainer from 'components/common/RootContainer';
import { breakpoints } from 'defaultStyles';
import { ICONS } from 'assets/icons';
import sanitize from '@src/lib/sanitize';

export default function WorkWithUsScreen() {
  const { t } = useTranslation();

  return (
    <Root>
      <ContentContainer>
        <TextInfo>{t('landing.workWithUs.whoWeAre')}</TextInfo>
        <PageHeading dangerouslySetInnerHTML={sanitize(t('landing.workWithUs.title'))} />
        <TextInfo>{t('landing.workWithUs.info')}</TextInfo>
        <Container>
          {workWithUsAdvantages.map((key, i) => (
            <Item
              key={key}
              icon={ICONS[`ADVANTAGES_${+key + 1}`]}
              title={t(`landing.workWithUs.points.${key}.title`)}
              description={t(`landing.workWithUs.points.${key}.description`)}
            />
          ))}
        </Container>
      </ContentContainer>
    </Root>
  );
}

const Root = styled(RootContainer)`
  padding-bottom: 40px;

  @media (${breakpoints.mobile}) {
    padding-bottom: 0;
  }
`;

const TextInfo = styled(Text)`
  text-align: center;
  font-size: 30px;

  @media (${breakpoints.tablet}) {
    font-size: 24px;
  }

  @media (${breakpoints.mobile}) {
    font-size: 18px;
  }
`;

const PageHeading = styled(Heading)`
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 40px;
  line-height: 1.24;
  letter-spacing: 1px;
  
  @media (${breakpoints.tablet}) {
    font-size: 32px;
    line-height: 1.23;
    letter-spacing: 1px;
  }
  
  @media (${breakpoints.mobile}) {
    font-size: 32px;
    line-height: 1.31;
    letter-spacing: 1px;]
  }
`;

const Item = styled(AdvantageItem)`
  margin-bottom: 80px;
  padding-left: 10px;
  padding-right: 10px;

  flex: 0 0 46%;
  max-width: 46%;

  box-sizing: border-box;

  @media (${breakpoints.tablet}) {
    width: 100%;
    min-width: 100%;
    margin-bottom: 49px;
  }
`;

const Container = styled.div`
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (${breakpoints.tablet}) {
    margin-top: 80px;
  }

  @media (${breakpoints.mobile}) {
    margin-top: 40px;
  }
`;
