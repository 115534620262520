export const PageInfo = {
  main: {
    title: 'Smartcodev | Software Development',
    description:
      "Construction Suppliers - Starting selling your construction materials online with your own mobile app and website ordering widget. Brokrete is the world's first construction e-commerce platform",
    keywords: 'Brokrete,Ready- Mix Concrete Delivery Toronto, Concrete Calculator'
  },
  contractor: {
    title: 'Smartcodev | Connect with Construction Suppliers',
    description:
      'Connect with your favourite construction suppliers online and order construction materials anytime, anywhere. Order concrete, interlock, flagstone, aggregates, soil, mulch, disposal bins and more.',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  TechnologiesBackend: {
    title: 'Smartcodev | Backend',
    description:
      'Connect with your favourite construction suppliers online and order construction materials anytime, anywhere. Order concrete, interlock, flagstone, aggregates, soil, mulch, disposal bins and more.',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  TechnologiesFrontend: {
    title: 'Smartcodev | Frontend',
    description:
      'Connect with your favourite construction suppliers online and order construction materials anytime, anywhere. Order concrete, interlock, flagstone, aggregates, soil, mulch, disposal bins and more.',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  TechnologiesDatabaseApi: {
    title: 'Smartcodev | Frontend',
    description:
      'Connect with your favourite construction suppliers online and order construction materials anytime, anywhere. Order concrete, interlock, flagstone, aggregates, soil, mulch, disposal bins and more.',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  TechnologiesDevOps: {
    title: 'Smartcodev | Frontend',
    description:
      'Connect with your favourite construction suppliers online and order construction materials anytime, anywhere. Order concrete, interlock, flagstone, aggregates, soil, mulch, disposal bins and more.',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  TechnologiesMobile: {
    title: 'Smartcodev | Mobile',
    description: 'Order',
    keywords: 'Order'
  },
  TechnologiesOthers: {
    title: 'Smartcodev | Others',
    description: 'Order',
    keywords: 'Order'
  },
  ServicesCustomSoftwareDevelopment: {
    title: 'Smartcodev | Custom Software Development',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  ServicesWebDevelopment: {
    title: 'Smartcodev | Web Development',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  ServicesMobileAppsDevelopment: {
    title: 'Smartcodev | Mobile Apps Development',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  ServicesEcommerceDevelopment: {
    title: 'Smartcodev | Ecommerce Development',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  ServicesProductDevelopment: {
    title: 'Smartcodev | Product Development',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  ServicesCloudApplicationDevelopment: {
    title: 'Smartcodev | Cloud Application Development',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  ServicesSoftwareTestingQa: {
    title: 'Smartcodev | Software Testing & QA',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  ServicesUiUxDesign: {
    title: 'Smartcodev | UI/UX Design',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  suppliersManage: {
    title: 'Smartcodev | Dispatch & Fleet Management',
    description:
      'Manage your order, dispatch and delievery operations with Storefront by Brokrete. Communicate with contractors, manage delivery notifactions, track drivers and more.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  suppliersSell: {
    title: 'Smartcodev | Build your Own Construction App',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Order Concrete Online, Ready Mix Concrete near me, Aggregate Delivery Toronto'
  },
  appstorefront: {
    title: 'Smartcodev | Build your Own Material Ordering App',
    description:
      'Starting selling online with Storefront by Brokrete. The fastest and easiest way to start selling your construction materials online through your own mobile app.',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  appstorefrontVideo: {
    title:
      'Concrete Ready Mix Toronto | Call Brokrete| Redi Mix Concrete Delivery Toronto, Aggregate Delivery Toronto, Gravel Delivery Toronto| Brokrete',
    description:
      'From custom mixes, Concrete, ready mix concrete, gravel, stones, mulch, mini bins to disposal bins, Brokrete provides all construction material on one single app. Easy way to order Concrete redi- mix online and get delivery of redi-mix , delivery of concrete in Toronto, GTA, Mississauga, Brampton, Houston, Vancouver, Canada and USA',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  appstorefrontLanding: {
    title: 'Smartcodev | Construction E-commerce Made Easy',
    description:
      'Bring your construction business online with Storefront by Brokrete. Make your own mobile app for contractors to order materials from anytime, anywhere.',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  appstorefrontLandingWoc: {
    title:
      'Concrete Ready Mix Toronto | Call Brokrete| Redi Mix Concrete Delivery Toronto, Aggregate Delivery Toronto, Gravel Delivery Toronto| Brokrete',
    description:
      'From custom mixes, Concrete, ready mix concrete, gravel, stones, mulch, mini bins to disposal bins, Brokrete provides all construction material on one single app. Easy way to order Concrete redi- mix online and get delivery of redi-mix , delivery of concrete in Toronto, GTA, Mississauga, Brampton, Houston, Vancouver, Canada and USA',
    keywords: 'Ready- Mix Concrete Delievry, Gravel Delivery in Toronto, Concrete Delivery near me'
  },
  terms: {
    title: 'Brokrete Terms and Conditions',
    description: 'Brokrete Detailed Terms and Conditions, No Hidden Policies',
    keywords: 'Brokrete, Terms, Conditions'
  },
  privacy: {
    title: 'Brokrete Privacy Policy',
    description: 'At Brokrete we believe in utmost privacy. Check detailed privacy policy',
    keywords: 'Brokrete, Privacy, Policy'
  },
  app: {
    title: '',
    description: '',
    keywords: ''
  },
  about: {
    title: 'Smartcodev | About Us',
    description:
      'Leading concrete company in Toronto- Brokrete. We made Ecommerce Easy for Construction . Brokrete is an online construction platform to buy concrete, cement, gravel, aggregate, stone, mulch from your favorite Construction suppliers. Anytime. Anywhere Online',
    keywords: 'Brokrete, Concrete, Cement, Gravel, Aggregate Supplier in Toronto'
  },
  contactUs: {
    title: 'Smartcodev | Contact Us',
    description:
      'Leading concrete company in Toronto- Brokrete. We made Ecommerce Easy for Construction . Brokrete is an online construction platform to buy concrete, cement, gravel, aggregate, stone, mulch from your favorite Construction suppliers. Anytime. Anywhere Online',
    keywords: 'Brokrete, Concrete, Cement, Gravel, Aggregate Supplier in Toronto'
  },
  carrier: {
    title: 'Smartcodev | Careers',
    description:
      'Kick Start your Career with Brokrete - Fast Growing Tech Company in Construction Industry, Brokrete Leading e-commerce platform for Construction Industry',
    keywords: 'Business Development Manager job, Sales Rep Toronto Jobs'
  },
  careerPosition: {
    title: 'Smartcodev | Positions',
    description:
      'Kick Start your Career with Brokrete - Fast Growing Tech Company in Construction Industry, Brokrete Leading e-commerce platform for Construction Industry',
    keywords: 'Business Development Manager job, Sales Rep Toronto Jobs'
  },
  default: {
    title: 'Smartcodev',
    description: 'Smartcodev',
    keywords: 'Smartcodev'
  }
};
