export default function formatNumber(text, pattern, code) {
  const prefix = `+${code}`;

  if (!text || text.length === 0) {
    return prefix;
  }

  if ((text && text.length < 2) || !pattern) {
    return prefix;
  }

  const formattedObject: { [x: string]: any } = [].reduce.call(
    pattern,
    (acc: { [x: string]: any }, character) => {
      if (acc.remainingText.length === 0) {
        return acc;
      }

      if (character !== '.') {
        return {
          formattedText: acc.formattedText + character,
          remainingText: acc.remainingText
        };
      }

      const [head, ...tail] = acc.remainingText;

      return {
        formattedText: acc.formattedText + head,
        remainingText: tail
      };
    },
    {
      formattedText: '',
      remainingText: text.split('')
    }
  );

  let formattedNumber = formattedObject.formattedText;
  if (formattedNumber.includes('(') && !formattedNumber.includes(')')) {
    formattedNumber += ')';
  }

  return formattedNumber;
}
