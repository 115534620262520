import styled from 'styled-components';
import { Counter as CommonCounter, Text } from '@src/components/common';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Counter = styled(CommonCounter)`
  width: 24px;
  height: 24px;
  margin-right: 10px;
  font-size: 14px;
  border-radius: 50%;
  color: ${colors.ALGAE_GREEN};
  background-color: ${colors.WHITE};

  ${props => props.styles || ''}
`;

const Root = styled.div`
  display: flex;
  align-items: center;

  ${props => props.styles || ''}
`;

const Title = styled(Text)`
  font-size: 10px;
  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};
  color: ${colors.CHARCOAL_GREY};
  letter-spacing: 1px;

  text-transform: uppercase;

  ${props => props.styles || ''}
`;

export { Counter, Root, Title };

export default {
  Counter,
  Root,
  Title
};
