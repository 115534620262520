import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Label = styled.label`
  margin-bottom: 5px;
  display: block;
  font-size: 10px;
  color: ${({ color = colors.WHITE }) => color};
  font-family: ${fontFamily.bold};
  font-weight: ${fontWeight.bold};
  text-transform: uppercase;
`;

export default Label;
