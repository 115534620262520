import styled from 'styled-components';
import { RootContainer } from '@src/components/common';
import Heading from '@src/components/common/Heading';
import { breakpoints, fontWeight, fontFamily, colors } from '@src/defaultStyles';

const Root = styled.div`
  padding: 100px 80px 60px 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;

  @media (${breakpoints.tablet}) {
    padding: 60px 50px 60px 50px;
  }

  @media (${breakpoints.mobile}) {
    padding: 95px 20px 35px 20px;
  }
`;

const SectionTitle = styled(Heading)`
  text-align: center;
  margin-bottom: 20px;
  font-size: 27px;
  font-weight: ${fontWeight.bold};

  @media (${breakpoints.tablet}) {
  }

  @media (${breakpoints.mobile}) {
    font-size: 18px;
  }
`;
const CardsGrid = styled(RootContainer)`
  padding: 0px 80px 60px 80px;
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(305px, 1fr));
  margin-top: 10px;
  box-sizing: border-box;
  @media (${breakpoints.tablet}) {
    padding: 0px 50px 60px 50px;
    grid-template-columns: repeat(auto-fit, minmax(315px, 375px));
    grid-gap: 30px;
  }
  @media (${breakpoints.mobile}) {
    padding: 0px 20px 60px 20px;
    grid-template-columns: repeat(auto-fit, minmax(230px, 355px));
  }
`;

export { Root, SectionTitle, CardsGrid };
