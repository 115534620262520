import GooglePlaceSearch from './places/GooglePlaceSearch';
import GoogleGeocode from './geocode/GoogleGeocode';
import GoogleDirections from './directions/GoogleDirections';

import { GOOGLE_AIP_KEY } from '../../../config';
import { DetailsPlaceAdapter } from './places/adapters/DetailsPlaceAdapter';
import safeExecute from '../../lib/safeExecute';

const places = new GooglePlaceSearch(GOOGLE_AIP_KEY);
const geocode = new GoogleGeocode(GOOGLE_AIP_KEY);
const directions = new GoogleDirections(GOOGLE_AIP_KEY);

const buildPlace = value => {
  return safeExecute(() => new DetailsPlaceAdapter().from(value).toData(), null);
};

export { places, geocode, directions, buildPlace };
