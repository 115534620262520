import React from 'react';
import styled from 'styled-components';
import { colors, fontFamily, fontWeight } from '@src/defaultStyles';

const Text: React.FC<{ tag?: string }> = ({ tag, ...props }): JSX.Element | null => {
  return <Root {...props} as={tag} />;
};

const Root = styled.span`
  font-family: ${props => props.fontFamily || fontFamily.regular};
  font-weight: ${props => props.fontWeight || fontWeight.normal};
  font-size: ${props => props.fontSize || 14}px;
  color: ${props => props.color || colors.CHARCOAL_GREY};
  display: ${props => (props.inline ? 'inline' : 'block')};

  ${props => props.css}
`;

Text.defaultProps = {
  tag: 'span'
};
export default Text;
