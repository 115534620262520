import React from 'react';
import { Root, ScrollsGrid } from './styled';
import sanitize from '@src/lib/sanitize';
import { useTranslation } from 'react-i18next';
import { ScrollComponent } from '@src/components/common';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import I18n from 'i18next';
import { LandingScrollConfig } from '@src/config/ScrollConfig';

const ScrollSection: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { tablet, desktop } = useMediaQuery();

  return (
    <Root>
      <ScrollsGrid>
        {LandingScrollConfig.map(({ scrollSection, scrollText }, index) => (
          <ScrollComponent key={index} scrollSectionName={scrollSection} buttonTitle={scrollText} />
        ))}
      </ScrollsGrid>
    </Root>
  );
};

export default ScrollSection;
