import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { breakpoints, colors, fontWeight } from '@src/defaultStyles';
import { Button } from '@src/components/common/index';
import I18n from 'i18next';

const Root = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 40px;
  border: 1px solid ${colors.DARK_MINT};
  border-radius: 4px;
  background-color: ${colors.WHITE};
  color: ${colors.DARK_MINT};
  cursor: pointer;
  letter-spacing: 0.4px;
  font-size: 16px;
  font-weight: ${fontWeight.semibold};

  :hover {
    color: ${colors.DARK_MINT};
  }

  @media (${breakpoints.tablet}) {
    max-width: 191px;
  }
`;

const ContactUsButton: React.FC = (): JSX.Element | null => {
  const history = useHistory();

  const onContactUsClick = () => {
    history.push('/contact-us');
  };

  return (
    <Root width="120px" onClick={onContactUsClick}>
      {I18n.t('common.contactUsButton')}
    </Root>
  );
};

export default ContactUsButton;
