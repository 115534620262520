export default (document, src, cb) => {
  const fjss = document.getElementsByTagName('script')[0];
  const script = document.createElement('script');
  script.src = src;

  if (fjss && fjss.parentNode) {
    fjss.parentNode.insertBefore(script, fjss);
  } else {
    document.head.appendChild(script);
  }

  script.onload = cb;
};
