import React, { Component } from 'react';
import cx from 'classnames';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IMAGES } from 'assets/images';
import OrderManagementFlow from './OrderManagementFlow';
import { ContentContainer, Text } from 'components/common';
import RootContainer from 'components/common/RootContainer';
import { breakpoints, colors } from 'defaultStyles';
import { OrderManagementFlow as Flow } from 'config';
import { ScreenHeading as Heading } from './OrderManagementFlow';
import AnimatedSlider from 'components/common/AnimatedSlider';

// TODO refactor slider. Make it more general
class OrderManagementScreen extends Component {
  _sliderRef = React.createRef();

  state = {
    selectedScreen: 0
  };

  componentWillUnmount() {
    if (this.sliderRef) {
      this.sliderRef.sliderRef.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleTouchStart = () => {
    this.sliderRef.sliderRef.classList.remove('fade-away');
  };

  initTouchEvents = ref => {
    if (!ref) return;

    this._sliderRef.current = ref;
    this.sliderRef.sliderRef.addEventListener('touchstart', this.handleTouchStart);
  };

  setSelectedScreen = index => {
    this.sliderRef.sliderRef.classList.add('fade-away');
    this.goTo(index);
  };

  onDotClick = ({ target }) => {
    const { screen } = target.dataset;
    this.setSelectedScreen(Number(screen));
  };

  goTo = index => {
    const { selectedScreen } = this.state;

    if (this.sliderRef) {
      this.sliderRef.goTo(index, index > selectedScreen ? 'next' : 'previous');
    }

    this.setState(() => {
      return {
        selectedScreen: index
      };
    });
  };

  onSlideChange = ({ slideIndex }) => {
    this.setState({ animating: false, selectedScreen: slideIndex });
  };

  render() {
    const { t } = this.props;
    const { selectedScreen } = this.state;

    return (
      <Root>
        <Container>
          <ScreenHeading>{t('landing.orderManagementFlow.title')}</ScreenHeading>
          <StepText fontSize={22}>{t(`landing.orderManagementFlow.flow.${selectedScreen}`)}</StepText>
          <AnimatedSlider
            ref={this.initTouchEvents}
            className={cx('flow-slider wow fadeIn animated')}
            duration={100}
            data-wow-duration="1s"
            infinite={false}
            onSlideChange={this.onSlideChange}
          >
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
            <Image className="slide-image" src={IMAGES.SMARTCODE_LOGO} />
          </AnimatedSlider>
          <SlideIndicator>
            {Flow.map((_, i) => (
              <Dot key={i} data-screen={i} selected={i === selectedScreen} onClick={this.onDotClick} />
            ))}
          </SlideIndicator>
          <Right selectedScreen={selectedScreen} onSelectScreen={this.setSelectedScreen} />
        </Container>
      </Root>
    );
  }

  get sliderRef() {
    return this._sliderRef.current;
  }
}

export default withTranslation()(OrderManagementScreen);

function Image({ className, src }) {
  return (
    <ImageRoot className={className}>
      <Img src={src} />
    </ImageRoot>
  );
}

const ImageRoot = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-right: auto;
  margin-left: -22px;
`;

const Root = styled(RootContainer)`
  padding-top: 280px;
  padding-bottom: 218px;

  @media (${breakpoints.mobile}) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Container = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
  }
`;

const ScreenHeading = styled(Heading)`
  display: none;
  text-align: center;
  width: 330px;
  line-height: 1.29;
  letter-spacing: 0.5px;

  @media (${breakpoints.mobile}) {
    display: block;
    font-size: 28px;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const StepText = styled(Text)`
  display: none;
  margin-bottom: 10px;

  @media (${breakpoints.mobile}) {
    display: block;
  }
`;

const Right = styled(OrderManagementFlow)`
  margin-left: auto;
  margin-top: -30px;

  @media (${breakpoints.mobile}) {
    display: none;
  }
`;

const Img = styled.img`
  width: 419px;
  height: 744px;

  @media (${breakpoints.tablet}) {
    width: 350px;
    height: 621px;
  }

  @media (${breakpoints.mobile}) {
    width: 350px;
    height: 621px;
  }
`;

const SlideIndicator = styled.div`
  margin-top: -40px;
  justify-content: center;
  align-items: center;
  display: none;
  z-index: 10;

  & > * {
    margin-right: 10px;
  }

  @media (${breakpoints.mobile}) {
    display: flex;
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.BATTLESHIP_GREY};
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    `
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 8px;
    background-color: ${colors.ALGAE_GREEN};
    cursor: none;
    pointer-events: none;
  `}
`;
