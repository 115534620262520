import styled from 'styled-components';
import { colors, fontWeight } from '@src/defaultStyles';

const TextArea = styled.textarea`
  padding: 15px 20px;
  height: ${props => props.height || '150px'};
  width: 100%;
  font-weight: ${fontWeight.semibold};
  font-size: 14px;
  color: ${colors.BATTLESHIP_GREY};
  line-height: 1.43;
  letter-spacing: 0.5px;
  appearance: none;
  border: none;
  border-radius: 2px;
  transition: border-color 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;
  resize: none;

  ${props => props.styles}
`;

export default TextArea;
