import highSizeImage from './highSizeImage.jpeg';

import smartCodevLogo from './common/smartCodevLogo.png';
import drawerMenu from './common/drawerMenu.png';
import linkArrow from './common/linkArrow.webp';
import quoteGreen from './common/quoteGreen.png';
import playVideo from './common/playVideo.png';
import reactLogo from './common/tech-logos/reactLogo.png';
import angularLogo from './common/tech-logos/angularLogo.png';
import vueLogo from './common/tech-logos/vueLogo.png';
import nodejsLogo from './common/tech-logos/nodejsLogo.png';
import djangoLogo from './common/tech-logos/djangoLogo.png';
import rubyOnRailsLogo from './common/tech-logos/rubyOnRailsLogo.png';
import laravelLogo from './common/tech-logos/laravelLogo.png';
import aspNetLogo from './common/tech-logos/aspNetLogo.webp';
import javaLogo from './common/tech-logos/javaLogo.png';
import phpLogo from './common/tech-logos/phpLogo.png';
import reactNativeLogo from './common/tech-logos/reactNativeLogo.png';
import flutterLogo from './common/tech-logos/flutterLogo.png';
import swiftLogo from './common/tech-logos/swiftLogo.png';
import kotlinLogo from './common/tech-logos/kotlinLogo.png';
import mySqlLogo from './common/tech-logos/mySqlLogo.png';
import postgreSqlLogo from './common/tech-logos/postgreSqlLogo.png';
import mongoDbLogo from './common/tech-logos/mongoDbLogo.png';
import oracleLogo from './common/tech-logos/oracleLogo.png';
import awsLogo from './common/tech-logos/awsLogo.png';
import googleCloudLogo from './common/tech-logos/googleCloudLogo.png';
import azureLogo from './common/tech-logos/azureLogo.png';
import jenkinsLogo from './common/tech-logos/jenkinsLogo.png';
import kubernetesLogo from './common/tech-logos/kubernetesLogo.png';
import dockerLogo from './common/tech-logos/dockerLogo.png';
import figmaLogo from './common/tech-logos/figmaLogo.png';
import sketchLogo from './common/tech-logos/sketchLogo.png';
import adobeXdLogo from './common/tech-logos/adobeXdLogo.png';
import frontendLogo from './common/tech-logos/frontendLogo.png';
import backendLogo from './common/tech-logos/backendLogo.png';
import databaseApiLogo from './common/tech-logos/databaseApiLogo.png';
import devOpsLogo from './common/tech-logos/devOpsLogo.png';
import mobileLogo from './common/tech-logos/mobileLogo.png';
import othersLogo from './common/tech-logos/othersLogo.png';

import landingHighResolutionTopScreen from './landing/landingHighResolutionTopScreen.jpg';
import landingLowResolutionTopScreen from './landing/landingLowResolutionTopScreen.jpg';
import landingContent1 from './landing/landingContent1.jpg';
import landingContent2 from './landing/landingContent2.jpg';
import landingSection1 from './landing/landingSection1.jpg';
import landingContent3 from './landing/landingContent3.jpg';
import landingContent4 from './landing/landingContent4.jpg';
import landingSection2 from './landing/landingSection2.jpg';
import landingContent5 from './landing/landingContent5.jpg';
import landingContent6 from './landing/landingContent6.jpg';
import landingSection3 from './landing/landingSection3.jpg';
import landingContent7 from './landing/landingContent7.jpg';
import landingContent8 from './landing/landingContent8.jpg';

import customSoftwareDevelopmentHighResolutionTopScreen from './services/custom-software-development/customSoftwareDevelopmentHighResolutionTopScreen.jpg';
import customSoftwareDevelopmentLowResolutionTopScreen from './services/custom-software-development/customSoftwareDevelopmentLowResolutionTopScreen.jpg';
import requirementsAnalysis from './services/custom-software-development/requirementsAnalysis.png';
import businessAnalysisPlanning from './services/custom-software-development/businessAnalysisPlanning.png';
import businessDesign from './services/custom-software-development/businessDesign.png';
import businessCoding from './services/custom-software-development/businessCoding.webp';
import qualityAssurance1 from './services/custom-software-development/qualityAssurance1.jpeg';
import softwareDeployment from './services/custom-software-development/softwareDeployment.png';
import maintenanceSupport1 from './services/custom-software-development/maintenanceSupport1.png';

import webDevelopmentHighResolutionTopScreen from './services/web-development/webDevelopmentHighResolutionTopScreen.jpg';
import webDevelopmentLowResolutionTopScreen from './services/web-development/webDevelopmentLowResolutionTopScreen.jpg';
import projectInitiation from './services/web-development/projectInitiation.png';
import projectPlanning from './services/web-development/projectPlanning.png';
import agileDevelopment from './services/web-development/agileDevelopment.png';
import qualityAssurance2 from './services/web-development/qualityAssurance2.jpeg';
import softwareDelivery from './services/web-development/softwareDelivery.jpeg';
import maintenanceSupport2 from './services/web-development/maintenanceSupport2.jpeg';

import mobileAppsDevelopmentHighResolutionTopScreen from './services/mobile-apps-development/mobileAppsDevelopmentHighResolutionTopScreen.jpg';
import mobileAppsDevelopmentLowResolutionTopScreen from './services/mobile-apps-development/mobileAppsDevelopmentLowResolutionTopScreen.jpg';

import ecommerceDevelopmentHighResolutionTopScreen from './services/ecommerce-development/ecommerceDevelopmentHighResolutionTopScreen.jpg';
import ecommerceDevelopmentLowResolutionTopScreen from './services/ecommerce-development/ecommerceDevelopmentLowResolutionTopScreen.jpg';

import productDevelopmentHighResolutionTopScreen from './services/product-development/productDevelopmentHighResolutionTopScreen.jpg';
import productDevelopmentLowResolutionTopScreen from './services/product-development/productDevelopmentLowResolutionTopScreen.jpg';

import cloudApplicationDevelopmentHighResolutionTopScreen from './services/cloud-application-development/cloudApplicationDevelopmentHighResolutionTopScreen.jpg';
import cloudApplicationDevelopmentLowResolutionTopScreen from './services/cloud-application-development/cloudApplicationDevelopmentLowResolutionTopScreen.jpg';

import softwareTestingQaHighResolutionTopScreen from './services/software-testing-qa/softwareTestingQaHighResolutionTopScreen.jpg';
import softwareTestingQaLowResolutionTopScreen from './services/software-testing-qa/softwareTestingQaLowResolutionTopScreen.jpg';

import uiUxDesignHighResolutionTopScreen from './services/ui-ux-design/uiUxDesignHighResolutionTopScreen.jpg';
import uiUxDesignLowResolutionTopScreen from './services/ui-ux-design/uiUxDesignLowResolutionTopScreen.jpg';

import backendHighResolutionTopScreen from './technologies/backend/backendHighResolutionTopScreen.jpg';
import backendLowResolutionTopScreen from './technologies/backend/backendLowResolutionTopScreen.jpg';

import frontendHighResolutionTopScreen from './technologies/frontend/frontendHighResolutionTopScreen.jpg';
import frontendLowResolutionTopScreen from './technologies/frontend/frontendLowResolutionTopScreen.jpg';

import databaseApiHighResolutionTopScreen from './technologies/database-api/databaseApiHighResolutionTopScreen.jpg';
import databaseApiLowResolutionTopScreen from './technologies/database-api/databaseApiLowResolutionTopScreen.jpg';

import devOpsHighResolutionTopScreen from './technologies/dev-ops/devOpsHighResolutionTopScreen.png';
import devOpsLowResolutionTopScreen from './technologies/dev-ops/devOpsLowResolutionTopScreen.png';

import mobileHighResolutionTopScreen from './technologies/mobile/mobileHighResolutionTopScreen.jpg';
import mobileLowResolutionTopScreen from './technologies/mobile/mobileLowResolutionTopScreen.jpg';

import othersHighResolutionTopScreen from './technologies/others/othersHighResolutionTopScreen.jpg';
import othersLowResolutionTopScreen from './technologies/others/othersLowResolutionTopScreen.jpg';

import aboutUsHighResolutionTopScreen from './about-us/aboutUsHighResolutionTopScreen.jpg';
import aboutUsLowResolutionTopScreen from './about-us/aboutUsLowResolutionTopScreen.jpg';

import contactUsHighResolutionTopScreen from './contact-us/contactUsHighResolutionTopScreen.jpg';
import contactUsLowResolutionTopScreen from './contact-us/contactUsLowResolutionTopScreen.jpg';

export const IMAGES = {
  HIGH_SIZE_IMAGE: highSizeImage,
  SMART_CODEV_LOGO: smartCodevLogo,
  DRAWER_MENU: drawerMenu,
  LINK_ARROW: linkArrow,
  QUOTE_GREEN: quoteGreen,
  PLAY_VIDEO: playVideo,
  REACT_LOGO: reactLogo,
  REACT_NATIVE_LOGO: reactNativeLogo,
  ANGULAR_LOGO: angularLogo,
  VUE_LOGO: vueLogo,
  NODEJS_LOGO: nodejsLogo,
  DJANGO_LOGO: djangoLogo,
  RUBY_ON_RAILS_LOGO: rubyOnRailsLogo,
  LARAVEL_LOGO: laravelLogo,
  ASP_NET_LOGO: aspNetLogo,
  JAVA_LOGO: javaLogo,
  PHP_LOGO: phpLogo,
  FLUTTER_LOGO: flutterLogo,
  SWIFT_LOGO: swiftLogo,
  KOTLIN_LOGO: kotlinLogo,
  MY_SQL_LOGO: mySqlLogo,
  POSTGRE_SQL_LOGO: postgreSqlLogo,
  MONGO_DB_LOGO: mongoDbLogo,
  ORACLE_LOGO: oracleLogo,
  AWS_LOGO: awsLogo,
  GOOGLE_CLOUD_LOGO: googleCloudLogo,
  AZURE_LOGO: azureLogo,
  JENKINS_LOGO: jenkinsLogo,
  KUBERNETES_LOGO: kubernetesLogo,
  DOCKER_LOGO: dockerLogo,
  FIGMA_LOGO: figmaLogo,
  SKETCH_LOGO: sketchLogo,
  ADOBE_XD_LOGO: adobeXdLogo,
  FRONTEND_LOGO: frontendLogo,
  BACKEND_LOGO: backendLogo,
  DATABASE_API_LOGO: databaseApiLogo,
  DEV_OPS_LOGO: devOpsLogo,
  MOBILE_LOGO: mobileLogo,
  OTHERS_LOGO: othersLogo,
  LANDING_TOP_SCREEN: [landingHighResolutionTopScreen, landingLowResolutionTopScreen],
  LANDING_CONTENT_1: landingContent1,
  LANDING_CONTENT_2: landingContent2,
  LANDING_SECTION_1: landingSection1,
  LANDING_CONTENT_3: landingContent3,
  LANDING_CONTENT_4: landingContent4,
  LANDING_SECTION_2: landingSection2,
  LANDING_CONTENT_5: landingContent5,
  LANDING_CONTENT_6: landingContent6,
  LANDING_SECTION_3: landingSection3,
  LANDING_CONTENT_7: landingContent7,
  LANDING_CONTENT_8: landingContent8,

  CUSTOM_SOFTWARE_DEVELOPMENT_TOP_SCREEN: [
    customSoftwareDevelopmentHighResolutionTopScreen,
    customSoftwareDevelopmentLowResolutionTopScreen
  ],
  REQUIREMENTS_ANALYSIS: requirementsAnalysis,
  BUSINESS_ANALYSIS_PLANNING: businessAnalysisPlanning,
  BUSINESS_DESIGN: businessDesign,
  BUSINESS_CODING: businessCoding,
  QUALITY_ASSURANCE_1: qualityAssurance1,
  SOFTWARE_DEPLOYMENT: softwareDeployment,
  MAINTENANCE_SUPPORT_1: maintenanceSupport1,
  WEB_DEVELOPMENT_TOP_SCREEN: [webDevelopmentHighResolutionTopScreen, webDevelopmentLowResolutionTopScreen],
  PROJECT_INITIATION: projectInitiation,
  PROJECT_PLANNING: projectPlanning,
  AGILE_DEVELOPMENT: agileDevelopment,
  QUALITY_ASSURANCE_2: qualityAssurance2,
  SOFTWARE_DELIVERY: softwareDelivery,
  MAINTENANCE_SUPPORT_2: maintenanceSupport2,

  MOBILE_APPS_DEVELOPMENT_TOP_SCREEN: [mobileAppsDevelopmentHighResolutionTopScreen, mobileAppsDevelopmentLowResolutionTopScreen],

  ECOMMERCE_DEVELOPMENT_TOP_SCREEN: [ecommerceDevelopmentHighResolutionTopScreen, ecommerceDevelopmentLowResolutionTopScreen],

  PRODUCT_DEVELOPMENT_TOP_SCREEN: [productDevelopmentHighResolutionTopScreen, productDevelopmentLowResolutionTopScreen],

  CLOUD_APPLICATION_DEVELOPMENT_TOP_SCREEN: [
    cloudApplicationDevelopmentHighResolutionTopScreen,
    cloudApplicationDevelopmentLowResolutionTopScreen
  ],

  SOFTWARE_TESTING_QA_TOP_SCREEN: [softwareTestingQaHighResolutionTopScreen, softwareTestingQaLowResolutionTopScreen],

  UI_UX_DESIGN_TOP_SCREEN: [uiUxDesignHighResolutionTopScreen, uiUxDesignLowResolutionTopScreen],

  BACKEND_TOP_SCREEN: [backendHighResolutionTopScreen, backendLowResolutionTopScreen],

  FRONTEND_TOP_SCREEN: [frontendHighResolutionTopScreen, frontendLowResolutionTopScreen],

  DATABASE_API_TOP_SCREEN: [databaseApiHighResolutionTopScreen, databaseApiLowResolutionTopScreen],

  DEV_OPS_TOP_SCREEN: [devOpsHighResolutionTopScreen, devOpsLowResolutionTopScreen],

  MOBILE_TOP_SCREEN: [mobileHighResolutionTopScreen, mobileLowResolutionTopScreen],

  OTHERS_TOP_SCREEN: [othersHighResolutionTopScreen, othersLowResolutionTopScreen],

  ABOUT_US_TOP_SCREEN: [aboutUsHighResolutionTopScreen, aboutUsLowResolutionTopScreen],

  CONTACT_US_TOP_SCREEN: [contactUsHighResolutionTopScreen, contactUsLowResolutionTopScreen]
};
