import React from 'react';
import styled from 'styled-components';
import { colors } from '@src/defaultStyles';

const IconButton: React.FC<{
  className?: string;
  icon: string;
  animating?: boolean;
  disabled?: boolean;
  onClick: Function;
}> = ({ className, icon, animating, disabled, onClick }): JSX.Element | null => {
  return (
    <Root className={className} animating={animating} disabled={disabled} onClick={onClick}>
      <img src={icon} alt="" />
    </Root>
  );
};

export default IconButton;

const Root = styled.button(props => ({
  width: '80px',
  height: '80px',
  boxSizing: 'border-box',
  backgroundColor: props.disabled ? colors.LIGHT : colors.WHITE,
  cursor: 'pointer',
  pointerEvents: props.disabled ? 'none' : 'all',
  border: props.disabled ? 'none' : `1px solid ${colors.ALGAE_GREEN}`,
  outline: 'none',
  ...(props.animating && {
    pointerEvents: 'none',
    cursor: 'none'
  }),
  '&:hover': {
    border: 'none',
    backgroundColor: colors.LIGHT
  }
}));
