import React from 'react';

import { Svg } from './styled';

const IconCheck: React.FC<{ styles?: any }> = ({ styles }): JSX.Element | null => {
  return (
    <Svg styles={styles} xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12">
      <path
        fill="#FFF"
        fillRule="evenodd"
        d="M5.65 8.356L13.707.279c.371-.372.973-.372 1.343 0l.672.673c.37.372.37.974 0 1.346l-9.4 9.423c-.372.372-.973.372-1.344 0L.278 7.01c-.37-.372-.37-.975 0-1.347L.95 4.99c.37-.371.972-.371 1.343 0L5.65 8.356z"
      />
    </Svg>
  );
};

export default IconCheck;
