import gql from 'graphql-tag';
import { mutation } from 'api/core/gql';

const InternalCandidateRequestCreate = gql`
  mutation InternalCandidateRequestCreate(
    $job: IdInput!
    $candidate: CareerCandidateInput!
    $requestPayload: CareerCandidateRequestCreateInput!
  ) {
    internalCandidateRequestCreate(job: $job, candidate: $candidate, requestPayload: $requestPayload) {
      success
    }
  }
`;

export default async function applyPosition({ job, candidate, requestPayload }) {
  const result = await mutation(InternalCandidateRequestCreate, {
    job,
    candidate,
    requestPayload
  });

  return result;
}
