import styled from 'styled-components';
import { colors, fontWeight } from '@src/defaultStyles';

const Input = styled.input`
  padding: 15px 20px;
  width: 100%;
  height: 50px;
  font-weight: ${fontWeight.semibold};
  font-size: 14px;
  color: ${colors.BATTLESHIP_GREY};
  line-height: 1.43;
  letter-spacing: 0.5px;
  appearance: none;
  border: none;
  border-radius: 2px;
  transition: border-color 0.3s ease-in-out;
  outline: none;
  box-sizing: border-box;

  pointer-events: ${props => (props.readonly ? 'none' : 'default')};

  &:focus {
    border: 1px solid ${colors.ALGAE_GREEN};
  }

  &:read-only {
    border: none;
    //padding: 0;
    //height: 20px;
  }
`;

export default Input;
