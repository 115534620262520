import styled from 'styled-components';
import { Heading } from '@src/components/common';
import { breakpoints, colors } from '@src/defaultStyles';

const PageHeading = styled(Heading)`
  white-space: nowrap;
  font-size: 68px;
  line-height: 1.23;
  margin-bottom: 10px;

  color: ${colors.TITLE};

  @media (${breakpoints.tablet}) {
    font-size: 36px;
    line-height: 1.17;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
  }

  @media (${breakpoints.mobile}) {
    display: block;
    font-size: 28px;
    width: 100%;
  }
`;

export default PageHeading;
