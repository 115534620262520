import styled from 'styled-components';
import { Text } from '@src/components/common/index';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';

const SectionSubheading = styled(Text)`
  display: block;
  text-align: left;
  font-size: 20px;
  margin-bottom: 30px;
  font-family: ${fontFamily.semiBold};
  font-weight: ${fontWeight.bold};
  color: ${colors.BLACK};
  line-height: 1.5;

  @media (${breakpoints.tablet}) {
    font-size: 20px;
    text-align: center;
    align-self: center;
    max-width: 600px;
    width: 100%;
    margin-bottom: 40px;
  }

  @media (${breakpoints.mobile}) {
    text-align: center;
    font-size: 18px;
    width: 100%;
    max-width: 335px;
    margin-bottom: 20px;
  }
`;

export default SectionSubheading;
