import { StoreProxy } from '../../interfaces';
import { encode } from 'base64-arraybuffer';
import { string } from '@shared/lib';

let currentStoreProxy: StoreProxy;

export function setup(storeProxy: StoreProxy) {
  currentStoreProxy = storeProxy;
}
export function getClientInfo(): string {
  return encode(
    string.toArrayBuffer(
      JSON.stringify({
        version: {
          platform: 'web-site',
          version: '1.0',
          build: 11
        },
        name: 'BrokreteWeb',
        partner:
          currentStoreProxy && currentStoreProxy.getState().partner.partner
            ? currentStoreProxy.getState().partner.partner.code
            : undefined
      })
    )
  );
}
