import styled from 'styled-components';
import ContentContainer from '@src/components/common/ContentContainer';
import RootContainer from '@src/components/common/RootContainer';
import { Heading, Text } from '@src/components/common';
import { breakpoints, colors, fontFamily, fontWeight } from '@src/defaultStyles';
import { array } from '@shared/lib';

const Root = styled(RootContainer)`
  position: relative;
  background-image: ${({ backgroundImage }) =>
    array
      .flatten([backgroundImage])
      .map(v => `url(${v})`)
      .join(',')};
  background-position: ${({ backgroundImage }) =>
    array
      .flatten([backgroundImage])
      .map(v => 'center')
      .join(',')};
  background-repeat: ${({ backgroundImage }) =>
    array
      .flatten([backgroundImage])
      .map(v => 'no-repeat')
      .join(',')};
  background-size: ${({ backgroundImage }) =>
    array
      .flatten([backgroundImage])
      .map(v => 'cover')
      .join(',')};
  box-sizing: border-box;
  padding-top: 206px;
  height: min(927px, max(927px, 100vh));

  @media (${breakpoints.tablet}) {
    padding: 190px 40px 177px 40px;
    height: auto;
    min-height: auto;
    background-color: rgba(1, 1, 1, 0.8);
    &::before {
      content: ' ';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      z-index: -1;
    }
  }

  @media (${breakpoints.mobile}) {
    margin-top: -40px;
    padding: 50px 20px 75px 20px;
    height: min(700px, 100vh);
    height: 100%;
  }
  @media (${breakpoints.xs}) {
    padding: 50px 20px;
  }
`;

const Content = styled(ContentContainer)`
  height: 100%;
  line-height: 28px;
  display: flex;
  max-width: 1300px;

  @media (${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .header {
      display: flex;
      height: 100%;
      flex-direction: column;
      text-align: center;
      //align-items: center;
      color: #ffffff;
      margin-bottom: 60px;
    }
    .footer {
      margin-bottom: 40px;
      width: 100%;
    }
    z-index: 1;
  }

  @media (${breakpoints.mobile}) {
    margin-top: 60px;

    .header {
      margin-bottom: 0;
    }
  }
`;

const PageHeading = styled(Heading)`
  width: 900px;
  font-size: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: ${fontWeight.bold};
  line-height: 60px;
  text-align: left;
  letter-spacing: 0.07px;

  @media (${breakpoints.tablet}) {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 0px;
    text-align: center;
  }

  @media (${breakpoints.mobile}) {
    font-size: 28px;
    padding-top: 20px;
    text-align: center;
    line-height: 32px;
    letter-spacing: 0.04px;
  }
  ${props => props.titleStyles}
`;

const PageContent = styled(Text)`
  font-size: 20px;
  letter-spacing: 0.49px;
  line-height: 28px;
  font-weight: ${fontWeight.semibold};
  max-width: 560px;
  @media (${breakpoints.tablet}) {
    text-align: center;
    max-width: 690px;
  }
  @media (${breakpoints.mobile}) {
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
  ${props => props.subtitleStyles}
`;

const RightBlock = styled.div`
  width: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }

  @media (${breakpoints.tablet}) {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
  }
`;

const HeaderIntroduce = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.WHITE};
  background-color: ${colors.DARK_GRAY};
  padding: 1px 2px;
  padding-right: 20px;
  max-width: 670px;
  width: fit-content;
  font-size: 14px;
  font-family: ${fontFamily.medium};
  letter-spacing: 0.55px;
  border-radius: 20px;
  margin-bottom: 30px;

  @media (${breakpoints.mobile}) {
    padding: 10px 15px;
    border-radius: 40px;
  }
`;

const Footer = styled.div`
  display: flex;
  margin-top: 25px;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (${breakpoints.tablet}) {
    //width: 100%;
  }

  ${props => props.styles}
`;

const HeaderIntroduceText = styled.div`
  margin: 0px 5px;

  @media (${breakpoints.mobile}) {
    line-height: 1.2;
    text-align: left;
  }

  @media (${breakpoints.mobile}) {
    line-height: 1.2;
  }
`;

const HeaderIntroduceChip = styled.div`
  background-color: ${colors.ALGAE_GREEN};
  padding: 1px 10px;
  border-radius: 50px;
`;
const FooterItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  button {
    width: 80%;
    margin: 7px 0;
  }
`;

export {
  Root,
  Content,
  PageHeading,
  RightBlock,
  HeaderIntroduce,
  Footer,
  HeaderIntroduceChip,
  HeaderIntroduceText,
  PageContent,
  FooterItem
};
