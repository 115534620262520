interface Options {
  ttl: number;
}

export class TimedCacheValue<T> {
  private readonly options: Options;
  private _value: T | null;
  private timestamp: number | null;

  constructor(options: Options) {
    this.options = options;
  }

  get value(): T | null {
    if (this.timestamp == null || this.timestamp + this.options.ttl < this.currentTime) {
      this.timestamp = null;
      this._value = null;
    }

    return this._value;
  }

  set value(v: T) {
    this.timestamp = this.currentTime;
    this._value = v;
  }

  private get currentTime(): number {
    return new Date().getTime();
  }
}
