import React from 'react';
import styled from 'styled-components';
import { Text } from '@src/components/common';
import { breakpoints, fontFamily, fontWeight } from '@src/defaultStyles';

const Root = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

const Icon = styled.img`
  margin-right: ${props => props.iconMargin + 'px'};
  width: ${props => props.iconSize + 'px'};
  height: auto;
`;

const Title = styled(Text)`
  font-size: ${props => props.titleSize + 'px'};
  margin-top: 5px;
  font-family: ${props => props.fontFamily};
  font-weight: ${fontWeight.normal};
  @media (${breakpoints.tablet}) {
    font-size: ${props => props.tabletTitleSize + 'px'};
  }
  @media (${breakpoints.mobile}) {
    font-size: ${props => props.mobileTitleSize + 'px'};
  }
`;

const MarkerListItem: React.FC<{
  title: string;
  color?: string;
  fontFamily: string;
  checkIconSrc: string;
  iconSize?: string;
  tabletTitleSize?: string;
  mobileTitleSize?: string;
  titleSize?: string;
  iconMargin?: string;
}> = ({
  title,
  color,
  fontFamily,
  checkIconSrc,
  iconSize = '17',
  tabletTitleSize = '18',
  mobileTitleSize = '16',
  titleSize = '15',
  iconMargin = '10'
}): JSX.Element | null => {
  return (
    <Root>
      <Icon src={checkIconSrc} alt="marker" iconSize={iconSize} iconMargin={iconMargin} />
      <Title
        tabletTitleSize={tabletTitleSize}
        mobileTitleSize={mobileTitleSize}
        color={color}
        fontFamily={fontFamily}
        titleSize={titleSize}
      >
        {title}
      </Title>
    </Root>
  );
};

export default MarkerListItem;
