import React from 'react';
import styled from 'styled-components';
import * as PropTypes from 'prop-types';
import { colors, fontWeight } from '@src/defaultStyles';
import { Text } from '@src/components/common';

type Props = {
  width?: number;
  height?: number;
  className?: any;
  title?: string;
  subTitle?: string;
};
const Paper: React.FC<Props> = ({ className, title, subTitle, width, height }): JSX.Element | null => {
  return (
    <Root className={className} width={width} height={height}>
      <Title color={colors.ALGAE_GREEN} fontSize={72} fontWeight={fontWeight.normal} inline>
        {title}
      </Title>

      <SubTitle fontSize={16}>{subTitle}</SubTitle>
    </Root>
  );
};
export default Paper;
Paper.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
};

Paper.defaultProps = {
  width: 200,
  height: 200
};

const Root = styled.div`
  //min-width: ${props => `${props.width}px`};
  width: 100%;
  max-width: ${props => `${props.width}px`};
  min-height: ${props => `${props.height}px`};
  max-height: ${props => `${props.height}px`};
  box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.22);
  background-color: ${colors.WHITE};

  padding: 43px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: 1px;
`;

const SubTitle = styled(Text)`
  line-height: 1.25;
  letter-spacing: normal;
`;
