import { array, cast, number } from '@shared/lib';

type CompareOptions = {
  deep?: boolean;
  number?: {
    round: boolean;
    roundOptions?: number.RoundOptions;
  };
};

export default function compare<T>(item1: T, item2: T, options: CompareOptions = {}): boolean {
  const { deep = true } = options;

  if ((!item1 && item2) || (item1 && !item2)) {
    return false;
  }

  if (typeof item1 != typeof item2) {
    return false;
  }

  if (options.number) {
    const { round = false, roundOptions = {} } = options.number;
    if (round && typeof item1 === 'number') {
      item1 = cast(number.round(item1, roundOptions));
    }
    if (round && typeof item2 === 'number') {
      item2 = cast(number.round(item2, roundOptions));
    }
  }

  if (item1 === item2) {
    return true;
  }

  if (!deep) {
    return false;
  }

  if (Array.isArray(item1) && Array.isArray(item2)) {
    return array.same(item1, item2, {
      compare: (item3, item4) => compare(item3, item4, options)
    });
  }

  if (typeof item1 != 'object') {
    return false;
  }

  for (const k of array.compact([...Object.keys(item1), ...Object.keys(item2)])) {
    if (k === '__typename') continue;

    const p1 = item1[k];
    const p2 = item2[k];

    let result = false;

    if (typeof p1 === 'object') {
      result = compare(p1, p2, options);
    } else {
      result = compare(p1, p2, options);
    }

    if (!result) {
      return false;
    }
  }

  return true;
}
