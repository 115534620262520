import gql from 'graphql-tag';

import { logs } from '@shared/lib';

import { mutation, Options } from '@src/api/core/gql';

const logger = logs.getLogger('api.brokrete.changePassword');

const query = gql`
  mutation ChangePassword($from: String!, $to: String!) {
    contractorUpdate(identity: { change: [{ password: { from: $from, to: $to } }] }) {
      success
    }
  }
`;

type ServerResponse = {
  contractorUpdate: Response;
};

type Response = {
  success: boolean;
};

export default async ({ from, to }: { from: string; to: string }, options: Options = null): Promise<Response | null> => {
  const result: ServerResponse = await mutation(
    query,
    {
      from,
      to
    },
    options
  );

  return result.contractorUpdate;
};
