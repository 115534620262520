import React from 'react';

import { Svg } from './styled';

const ButtonAdd: React.FC<{ [x: string]: any }> = (props): JSX.Element | null => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
      <g id="UI-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2.1.1.-Order-Schedule" transform="translate(-314.000000, -343.000000)">
          <g id="Group-11" transform="translate(0.000000, 329.000000)">
            <g id="Group-3" transform="translate(225.000000, 15.000000)">
              <g id="button_add" transform="translate(90.000000, 0.000000)">
                <rect id="border" stroke="#BABBC0" x="0" y="0" width="40" height="40" rx="20" />
                <path
                  id="sign"
                  d="M19,19 L19,13 C19,12.4477153 19.4477153,12 20,12 C20.5522847,12 21,12.4477153 21,13 L21,19 L27,19 C27.5522847,19 28,19.4477153 28,20 C28,20.5522847 27.5522847,21 27,21 L21,21 L21,27 C21,27.5522847 20.5522847,28 20,28 C19.4477153,28 19,27.5522847 19,27 L19,21 L13,21 C12.4477153,21 12,20.5522847 12,20 C12,19.4477153 12.4477153,19 13,19 L19,19 Z"
                  fill="#72747E"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default ButtonAdd;
