// max-width
export const breakpoints = {
  xs: 'max-width: 575px',
  mobile: 'max-width: 767px',
  tablet: 'max-width: 1024px',
  desktop: 'min-width: 1025px'
};

export const colors = {
  DODGER_BLUE: '#1E90FF',
  LIGHT_PURPLE: '#6666FF',
  DARK_BLUE: '#1E517F',
  LIGHT_BLUE: '#0066FF',
  LIGHT_BLUE_2: '#3bb5fa',
  SALMON: '#FA8072',
  WHITE: '#fff',
  BLACK: '#000',
  LIGHT: '#f6f6f6',
  GRAY: '#8F8F8F',
  BACKGROUND_GREY: '#FAFAFA',
  TITLE: '#19191b',
  ALGAE_GREEN: '#27B643',
  CHARCOAL_GREY: '#282a31',
  LIGHT_GREY: '#eeeef1',
  MIDLLE_GREY: '#dcdcdf',
  CLOUDY_BLUE: '#babbc0',
  BATTLESHIP_GREY: '#72747e',
  DARK_GRAY: '#707070',
  GULL_GRAY: '#9BA9BB',
  DOVE_GRAY: '#737373',
  BACKGROUND_LIGHT: '#fafafa',
  CORNFLOWER_BLUE: '#6772E5',
  BACKGROUND_TWITTER: '#049ff6',
  BACKGROUND_FACEBOOK: '#3a5998',
  BACKGROUND_MESSANGER: '#0084ff',
  DARK_MINT: '#50c468',
  VERY_LIGHT_PINK: '#f8f7f6',
  RED: '#ff0000',
  MINE_SHAFT: '#272727',
  ALTO: '#DADADA',
  DARK_GREEN: '#008080',
  CYAN: '#40e0d0',
  CONCRETE: '#F2F2F2',
  MERCURY: '#F5F5F5',
  TUNDORA: '#4D4D4D',
  WILD_SAND: '#F4F4F4',
  CAMARONE: '#006015',
  EBONY_CLAY: '#262B36',
  PALE_SKY: '#606B86',
  SLATE_GRAY: '#6B7C93',
  MARTINIQUE: '#32325D',
  GREEN: '#006015',

  BACKGROUND_NEW_GREY: '#313131',
  DARK_NERO_GRAY: '#2A2A2A',
  DARK_APPROAX_NERO_GRAY: '#272727',
  BACKGROUND_NEW_WHITE: '#FCFDFD',
  BACKGROUND_GREY_STORY: '#F4F5F7'
};

const systemFonts = 'system-ui, sans-serif';

export const fontFamily = {
  bold: `Catamaran, ${systemFonts}`,
  black: `Catamaran, ${systemFonts}`,
  extraBold: `Catamaran, ${systemFonts}`,
  medium: `Catamaran, ${systemFonts}`,
  regular: `Catamaran, ${systemFonts}`,
  semiBold: `Catamaran, ${systemFonts}`,
  thin: `Catamaran, ${systemFonts}`
};

export const fontWeight = {
  thin: 200,
  light: 300,
  normal: 400,
  semibold: 500,
  bold: 600,
  black: 800,
  extraBold: 900
};
