import React, { useCallback } from 'react';

import I18n from '@src/lib/i18n';

import { Button } from '@src/components/common';

import { Root, Title, Image, TextBlock } from './styled';

import styles from './styles';

const ProductTypeItem: React.FC<{
  checked?: boolean;
  product: { image: string; name: string };
  onClick: any;
}> = ({ checked, product, onClick }): JSX.Element | null => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(product);
    }
  }, []);

  return (
    <Root checked={checked}>
      <Image src={product.image} />
      <TextBlock>
        <Title>{product.name}</Title>
        <Button primary styles={styles.button} onClick={handleClick}>
          {I18n.t('landing.marketPlace.button.order')}
        </Button>
      </TextBlock>
    </Root>
  );
};

export default ProductTypeItem;
